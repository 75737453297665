import React from 'react';

import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';

interface BasicInformationCardProps {
  totalAttempts: number;
  classYear: string;
  questionCount: number;
  createdOn: string;
}

export default function BasicInfoCard(props: BasicInformationCardProps): JSX.Element {
  const {
    totalAttempts, classYear, questionCount, createdOn,
  } = props;

  return (
    <BasicCardContainer>
      <div className='quiz-detail-page-info-card'>
        <div className='quiz-detail-page-info-card-header'>
          <div className='label-style-bold'>Basic Info</div>
        </div>
        <div className='quiz-detail-page-info-card-box-container justify-content-between'>
          <div className='quiz-detail-page-info-card-box'>
            <div className='label-style-normal-4'>Class Year</div>
            <div className='label-style-bold quiz-detail-page-info-card-title'>{classYear}</div>
          </div>
          <div className='quiz-detail-page-info-card-box'>
            <div className='label-style-normal-4'>Questions</div>
            <div className='label-style-bold quiz-detail-page-info-card-title'>{questionCount}</div>
          </div>
          <div className='quiz-detail-page-info-card-box'>
            <div className='label-style-normal-4'>Total Attempts</div>
            <div className='label-style-bold quiz-detail-page-info-card-title'>{totalAttempts}</div>
          </div>
          <div className='quiz-detail-page-info-card-box'>
            <div className='label-style-normal-4'>Created on</div>
            <div className='label-style-bold quiz-detail-page-info-card-title'>{createdOn}</div>
          </div>
        </div>
      </div>
    </BasicCardContainer>
  );
}
