import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import type { AxiosError } from 'axios';
import Dropdown from 'components/shared/molecules/Dropdown/Dropdown';
import { signoutAPICall } from 'store/ApiCall/authApiCalls';
import { signoutToStore } from 'store/reducer/authSlice';
import type { DropdownType, APIResponse, AvailableQuizResponseData } from 'utils/types';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
// import Profile from 'assets/svgs/ts/Profile';
import Logout from 'assets/svgs/ts/Logout';
import Logo from 'assets/svgs/sidebarLogoNewWhite.svg';
import { getUserDetail } from 'utils/localStorage';
import Party from 'assets/svgs/ts/Party';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import PresentIcon from 'assets/svgs/ts/PresentIcon';
import ArrowRight from 'assets/svgs/ts/ArrowRight';
import ArrowDown from 'assets/svgs/ts/ArrowDown';
import { useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';
import { useGetAvailableQuizQuery } from 'store/ApiCall/studentApiCalls';
import { CircularProgress } from '@mui/material';
import QuizStatisticsBox from 'components/shared/molecules/StudentHomePage/QuizStatisticsBox';
import QuizSummary from 'components/shared/molecules/StudentHomePage/QuizSummary';
import clsx from 'clsx';

// Dropdown data
const DropdownData: DropdownType[] = [
  // { id: 0, name: 'Profile', icon: <Profile className='quiz-profile-icon' /> },
  { id: 1, name: 'Logout', icon: <Logout /> },
];

export default function StudentHomePage(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [showPreviousQuizSummary] = useState<boolean>(false);
  const [availableQuizState, setAvailableQuizState] = useState<AvailableQuizResponseData | null>(null);

  const { data: questionTypeList, isLoading: isGettingQuestionTypeList } = useGetQuestionTypeListQuery();
  const { data: availableQuiz, isLoading: isGettingQuiz } = useGetAvailableQuizQuery();

  // get user from localstorage
  const user = getUserDetail();

  // Dropdown handler
  const onClickHandler = (data: DropdownType): void => {
    if (data.name === 'Profile') {
      console.log('navigate to student profile');
    } else {
      signoutAPICall().then(() => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        dispatch(signoutToStore(null));
      }).catch((err: AxiosError<APIResponse<null, undefined>>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
      });
    }
  };

  // Save available quiz informatin to state
  useEffect(() => {
    if (availableQuiz) {
      setAvailableQuizState(availableQuiz.data);
    }
  }, [availableQuiz]);

  // Start quiz handler
  const startQuizHandler = (): void => {
    const questionId = availableQuizState?.current_quiz_id._id;
    if (questionId) navigate(`/quiz-start/${questionId}`, { state: { isFromHomePage: true } });
  };

  // Show Loader during API call
  if (isGettingQuiz || isGettingQuestionTypeList) {
    return (
      <div className='quiz-present-page d-center'>
        <CircularProgress className='bg-white' />
      </div>
    );
  }

  return (
    <div className='student-quiz-present-page'>
      <div className='student-quiz-present-page-header'>
        <div className='student-quiz-present-page-header-row-1'>
          <img className='logo' src={Logo} alt='app-log' />
          <div className='student-quiz-present-page-header-row-1-profile'>
            <div className='student-quiz-present-page-header-row-1-profile-text'>Logged in as</div>
            <Dropdown
              title={`${user?.first_name} ${user?.last_name}`}
              data={DropdownData}
              onClickHandler={onClickHandler}
            />
          </div>
        </div>
      </div>
      <div className={clsx('new-quiz-information', !showPreviousQuizSummary && 'flex-1')}>
        {availableQuiz?.data === null
          ? (<div className='new-quiz-information-text-2 d-center flex-1'>No Quiz Available</div>)
          : (
            <>
              <Party />
              <div className='new-quiz-information-text'>
                <div className='new-quiz-information-text-1 mb-10'>A new Quiz is available</div>
                <div className='new-quiz-information-text-2'>{availableQuiz?.data.current_quiz_id.name}</div>
              </div>
              <ButtonComponent
                className='new-quiz-information-btn blue-border'
                type='outlined'
                text='Start Quiz'
                size='large'
                onClick={startQuizHandler}
                startIcon={<PresentIcon className='fill-mono-dull-light' />}
              />
            </>
          ) }
      </div>
      {showPreviousQuizSummary
        ? (
          <div className='student-quiz-present-page-body mt-15'>
            <div className='last-quiz-info'>
              <div className='left-box'>
                <div className='new-quiz-information-text-1'>Last Quiz Performance</div>
                <div className='new-quiz-information-text-2'>Understanding fractions.</div>
              </div>
              <div className='right-box'>
                <div className='new-quiz-information-text-3'>Attempted on </div>
                <div className='new-quiz-information-text-4'>25 Jun 2022, 10: 30am</div>
              </div>
            </div>
            <div className='last-quiz-states'>
              <QuizStatisticsBox className='border no-padding' title='Total Question' count={16} />
              <QuizStatisticsBox className='border' title='Correct Answers' count={13} />
              <QuizStatisticsBox className='border' title='Incorrect Answers' count={3} />
              <QuizStatisticsBox title='Hints taken' count={10} />
            </div>
            <div className='last-quiz-summary'>
              <div
                className='last-quiz-summary-left-btn'
                role='presentation'
                onClick={(): void => { setIsExpand((prev) => !prev); }}
              >
                {isExpand ? <ArrowDown />
                  : <ArrowRight />}
                <span className='new-quiz-information-text-6 ml-16'>Summary</span>
              </div>
            </div>
            {isExpand && (
              <QuizSummary
                questionTypes={questionTypeList?.data}
                questions={[]}
              />
            )}
          </div>
        ) : null}
    </div>
  );
}
