/* eslint-disable @typescript-eslint/no-invalid-void-type, @typescript-eslint/no-unsafe-call */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'store/ApiCall/baseQuery';
import type {
  QuestionResType,
  AllQuestionResType,
  AllQuestionFiltersType,
} from 'utils/types';
import { addQueryParam } from 'utils/helper';

export const questionsApi = createApi({
  reducerPath: 'questionsApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getQuestion: builder.mutation<QuestionResType[], string>({
      query: (id) => ({
        url: `/api/question/${id}`,
        method: 'GET',
      }),
    }),
    getAllQuestions: builder.query<AllQuestionResType, void>({
      query: () => ({
        url: '/api/question/all',
        method: 'GET',
      }),
    }),
    getAllQs: builder.mutation<AllQuestionResType, { offset: number; limit: number }>({
      query: ({ offset, limit }) => ({
        url: `/api/question/all?offset=${offset}&limit=${limit}`,
        method: 'GET',
      }),
    }),
    getAllQsWithFilters: builder.mutation<AllQuestionResType, AllQuestionFiltersType>({
      query: ({
        statement, concept, difficulty, offset, limit,
      }: AllQuestionFiltersType) => {
        const route = { base: '/api/question/all' };
        // eslint-disable-next-line no-restricted-syntax, guard-for-in
        for (const i in { statement, concept, difficulty }) {
          route.base = addQueryParam(route.base, i, { statement, concept, difficulty }[i]);
        }

        if (!route.base.includes('?')) {
          route.base += `?offset=${offset}&limit=${limit}`;
        } else {
          route.base += `&offset=${offset}&limit=${limit}`;
        }

        return {
          url: route.base,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useGetQuestionMutation,
  useGetAllQuestionsQuery,
  useGetAllQsMutation,
  useGetAllQsWithFiltersMutation,
} = questionsApi;
