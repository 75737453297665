import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import Logo from 'assets/svgs/logo.svg';
import { checkAuthenticated, getDefaultPage } from 'utils/helper';
import type { UserType } from 'utils/types';
import 'styles/components/pages/landingPage.scss';

export default function LandingPage(): JSX.Element {
  const navigate = useNavigate();

  React.useEffect(() => {
    const userObj = window.localStorage.getItem('user');
    if (userObj) {
      const user: UserType = JSON.parse(userObj);
      if (checkAuthenticated()) {
        navigate(getDefaultPage(user.role_id.name), { replace: true });
      }
    }
  }, []);

  return (
    <div className='landingPageRoot'>
      <nav>
        <div className='logoBox'>
          <img src={Logo} alt='logo' loading='lazy' className='startupLogo' />
        </div>
        <div>
          <ButtonComponent
            type='contained'
            text='Login'
            className='signInButton'
            onClick={(): void => { navigate('/signin'); }}
          />
        </div>
      </nav>
      {/* <div className='contentBox'> */}
      {/*   <div className='linkBox'> */}
      {/*     <div className='signUpContainer'> */}
      {/*       <div> */}
      {/*         <ButtonComponent */}
      {/*           type='contained' */}
      {/*           text='Sign up as Student' */}
      {/*           className='studentSignupBtn' */}
      {/*           onClick={(): void => { navigate('/signup/student'); }} */}
      {/*         /> */}
      {/*       </div> */}
      {/*       <div> */}
      {/*         <ButtonComponent */}
      {/*           type='contained' */}
      {/*           text='Sign up as Teacher' */}
      {/*           className='teacherSignupBtn' */}
      {/*           onClick={(): void => { navigate('/signup/teacher'); }} */}
      {/*         /> */}
      {/*       </div> */}

      {/*     </div> */}
      {/*     <div className='signInLink'> */}
      {/*       <p>Already Have an account?</p> */}
      {/*       <ButtonComponent */}
      {/*         type='contained' */}
      {/*         text='Sign In' */}
      {/*         className='w-100 startupSignupBtn' */}
      {/*         onClick={(): void => { navigate('/signin'); }} */}
      {/*       /> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* </div> */}
      <footer>
        <div className='copyRightsText'>copyright 2022 mathstarter.co.nz</div>
      </footer>
    </div>
  );
}
