import React from 'react';
import type { SVGProps } from 'utils/types';

export default function PlusIcon(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='11' height='10' viewBox='0 0 11 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M5.5 0C5.76522 0 6.01957 0.105357 6.20711 0.292893C6.39464 0.48043 6.5 0.734784 6.5 1V4H9.5C9.76522 4 10.0196 4.10536 10.2071 4.29289C10.3946 4.48043 10.5 4.73478 10.5 5C10.5 5.26522 10.3946 5.51957 10.2071 5.70711C10.0196 5.89464 9.76522 6 9.5 6H6.5V9C6.5 9.26522 6.39464 9.51957 6.20711 9.70711C6.01957 9.89464 5.76522 10 5.5 10C5.23478 10 4.98043 9.89464 4.79289 9.70711C4.60536 9.51957 4.5 9.26522 4.5 9V6H1.5C1.23478 6 0.98043 5.89464 0.792893 5.70711C0.605357 5.51957 0.5 5.26522 0.5 5C0.5 4.73478 0.605357 4.48043 0.792893 4.29289C0.98043 4.10536 1.23478 4 1.5 4H4.5V1C4.5 0.734784 4.60536 0.48043 4.79289 0.292893C4.98043 0.105357 5.23478 0 5.5 0Z' fill='currentColor' />
    </svg>
  );
}
