import React from 'react';
import type { SVGProps } from 'utils/types';

export default function PieChart(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='16' height='16' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'>
      <path d='M2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8H8V0C5.87827 0 3.84344 0.842855 2.34315 2.34315Z' />
      <path d='M13.6569 2.34313C12.6462 1.33246 11.3836 0.610478 10 0.252014V6.00001H15.748C15.3895 4.61639 14.6676 3.35381 13.6569 2.34313Z' />
    </svg>
  );
}
