import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import type { SvgIconComponent } from '@mui/icons-material';
import clsx from 'clsx';

interface InputFieldProps {
  variant: 'filled' | 'outlined' | 'standard';
  text?: string;
  type: string;
  name: string;
  value: string;
  readonly?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDownHandler?: (e: React.KeyboardEvent) => void;
  className?: string;
  iconOnClickHandler?: () => void;
  Icon?: SvgIconComponent;
  iconPosition?: 'end' | 'start';
  error?: boolean | string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  containerClassName?: string;
  required?: boolean;
  autoComplete?: string;
  autoFocus?: boolean;
  placeholder?: string;
  fontColor?: string;
}

export default function InputField(props: InputFieldProps): JSX.Element {
  const {
    name, text = '', variant, type, className = '',
    value, onChange, iconOnClickHandler, Icon,
    iconPosition = 'end', error, onBlur, label = '',
    containerClassName, autoComplete = 'on', autoFocus = false, required = true, onKeyDownHandler,
    placeholder = '', fontColor, readonly,
  } = props;

  return (
    <div className={containerClassName}>
      {label !== '' && <div className='label-style-normal'>{label}</div>}
      <TextField
        required={required}
        name={name}
        type={type}
        label={text}
        variant={variant}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        aria-readonly={readonly}
        className={clsx('input-field', className)}
        error={error !== undefined && error !== ''}
        helperText={error}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        onKeyDown={onKeyDownHandler}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& > fieldset': {
              borderColor: '#D3D5DC',
            },
            '& > input': {
              color: fontColor,
            },
          },
        }}
        InputProps={{
          endAdornment:
            Icon !== undefined && (
              <InputAdornment
                position={iconPosition}
                onClick={iconOnClickHandler}
                style={{ cursor: 'pointer' }}
              >
                <Icon />
              </InputAdornment>
            ),
        }}
      />
    </div>
  );
}
