import React from 'react';

export default function DuplicateIcon(): JSX.Element {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M1.4478 1.44755C1.94789 0.947455 2.62617 0.666504 3.33341 0.666504H11.3334C12.0407 0.666504 12.7189 0.947455 13.219 1.44755C13.7191 1.94765 14.0001 2.62593 14.0001 3.33317H3.33341V13.9998C2.62617 13.9998 1.94789 13.7189 1.4478 13.2188C0.947699 12.7187 0.666748 12.0404 0.666748 11.3332V3.33317C0.666748 2.62593 0.947699 1.94765 1.4478 1.44755Z' fill='#868C9F' />
      <path d='M6.78113 6.78089C6.28103 7.28098 6.00008 7.95926 6.00008 8.6665V16.6665C6.00008 17.3737 6.28103 18.052 6.78113 18.5521C7.28123 19.0522 7.9595 19.3332 8.66675 19.3332H16.6667C17.374 19.3332 18.0523 19.0522 18.5524 18.5521C19.0525 18.052 19.3334 17.3737 19.3334 16.6665V8.6665C19.3334 7.95926 19.0525 7.28098 18.5524 6.78089C18.0523 6.28079 17.374 5.99984 16.6667 5.99984H8.66675C7.9595 5.99984 7.28123 6.28079 6.78113 6.78089Z' fill='#868C9F' />
    </svg>
  );
}
