import type { ReactNode } from 'react';
import React from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';

interface ImageUploadButtonProps {
  type: 'contained' | 'outlined' | 'text' | undefined;
  text: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  size?: 'large' | 'medium' | 'small' | undefined;
  startIcon?: ReactNode;
}

export default function ImageUploadButton(props: ImageUploadButtonProps): JSX.Element {
  const {
    type, text, className = '', disabled, onClick,
    size = 'large', startIcon,
  } = props;
  return (
    <Button
      size={size}
      variant={type}
      className={clsx('btn', size, type === 'contained' ? 'primary-button' : 'secondary-button', className)}
      disabled={disabled}
      startIcon={startIcon}
      component='label'
    >
      {text}
      <input accept='image/*' hidden type='file' onChange={onClick} />
    </Button>
  );
}
