/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import MQStaticField from 'components/shared/atoms/MathQuill/MQStaticField';
import clsx from 'clsx';
import { checkLatexBrackets } from 'utils/helper';
import type { EditorInput } from 'utils/types';
import { useSnackbar } from 'notistack';
import CopyIcon from 'assets/svgs/copyIcon.svg';

interface EquationQuestionProps {
  index?: number;
  body: any ;
  answer: string;
  questionImage?: string;
  orderNum: number;
  title: string;
  hideIndex?: boolean;
  questionCode?: string;
  viewMode?: boolean;
}
export default function EquationQuestion(props: EquationQuestionProps): JSX.Element {
  const {
    index, body, answer, questionImage, orderNum, title, hideIndex, questionCode, viewMode,
  } = props;
  const [answerState] = useState(JSON.parse(answer));
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = (code: string | undefined): void => {
    if (code) {
      navigator.clipboard.writeText(code);
      enqueueSnackbar('Question code copied to clipboard.', { variant: 'success' });
    }
  };

  if (viewMode) {
    return (
      <div className='question-container-view-mode'>
        <div className='equation-question-header-view-mode'>
          {!hideIndex && <div className='question-id'>{index ?? orderNum}</div>}
          <div className='question-code'>
            <div className='question-code-box'>
              {questionCode}
            </div>
            <button
              type='button'
              onClick={(): void => { copyToClipBoard(questionCode); }}
              className='copy-to-clipboard-btn'
            >
              <img className='copy-icon' src={CopyIcon} alt='copy to clipboard' />
            </button>
          </div>
        </div>
        <div className='equation-question'>
          <div className='equation-question-box'>
            <div className='equation-question-left-box-2'>
              <div className='question-id visibility-hidden'>{orderNum}</div>
              <div className='equation-question-component-list'>
                <div className='equation-question-text'>
                  {title}
                </div>
                {typeof body !== 'string' && body.length > 0 && body.map((item: EditorInput, idx: number) => {
                  if (item.type === 1) {
                    let filterAnswer = answerState.find((ans: any) => ans.componentIndex === idx);
                    if (!filterAnswer) {
                      filterAnswer = [];
                    }
                    return (
                      <MQStaticField
                        key={item.id}
                        latex={item.state}
                        answers={filterAnswer.answer}
                        className={clsx('equation-question-component-list-item', checkLatexBrackets(item.state) && 'extra-class')}
                        readOnly
                        isAdminStaticField
                      />
                    );
                  }
                  if (item.type === 2) {
                    return (<div key={item.id} className='equation-question-component-list-item equation-question-text'>{item.state}</div>);
                  }
                  if (item.type === 4) {
                    return (
                      <div key={item.id} className='equation-question-component-list-item equation-answer-text'>
                        {item.state}
                        <span>{` ${item.unit}`}</span>
                      </div>
                    );
                  }
                  return (<br key={item.id} />);
                })}

              </div>
            </div>
          </div>
          {questionImage && (
            <div
              style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/${questionImage})` }}
              className='equation-question-right-box'
            />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className='equation-question'>
      <div className='equation-question-box'>
        <div className='equation-question-left-box-1'>
          {!hideIndex && <div className='question-id'>{index ?? orderNum}</div>}
          <div className='equation-question-text'>{title}</div>
        </div>
        <div className='equation-question-left-box-2'>
          <div className='question-id visibility-hidden'>{orderNum}</div>
          <div className='equation-question-component-list'>
            {typeof body !== 'string' && body.length > 0 && body.map((item: EditorInput, idx: number) => {
              if (item.type === 1) {
                let filterAnswer = answerState.find((ans: any) => ans.componentIndex === idx);
                if (!filterAnswer) {
                  filterAnswer = [];
                }
                return (
                  <MQStaticField
                    key={item.id}
                    latex={item.state}
                    answers={filterAnswer.answer}
                    className={clsx('equation-question-component-list-item', checkLatexBrackets(item.state) && 'extra-class')}
                    readOnly
                    isAdminStaticField
                  />
                );
              }
              if (item.type === 2) {
                return (<div key={item.id} className='equation-question-component-list-item equation-question-text'>{item.state}</div>);
              }
              if (item.type === 4) {
                return (
                  <div key={item.id} className='equation-question-component-list-item equation-answer-text'>
                    {item.state}
                    <span>{` ${item.unit}`}</span>
                  </div>
                );
              }
              return (<br key={item.id} />);
            })}

          </div>
        </div>
      </div>
      {/* TODO: move the image url to backend */}
      {questionImage && (
        <div
          style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_QUESTION_IMAGE_PATH}/${questionImage})` }}
          className={clsx('equation-question-right-box', questionImage && 'expand-image-container')}
        />
      )}
    </div>
  );
}
