import React from 'react';

import ArrowDownIcon from 'assets/svgs/ts/ArrowDownIcon';
import ArrowUpIcon from 'assets/svgs/ts/ArrowUpIcon';
import BuildingIcon from 'assets/svgs/ts/BuildingIcon';
import Profile from 'assets/svgs/ts/Profile';
import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import ImprovedStateComponent from 'components/shared/atoms/ImprovedStateComponent';
import IconicText from 'components/shared/atoms/Text/IconicText';
import TextWithLabel from 'components/shared/atoms/TextWithLabel';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import type { StudentReportSummaryData } from 'utils/types';
import moment from 'moment';

interface SummaryType {
  data?: StudentReportSummaryData;
}

export default function Summary(props: SummaryType): JSX.Element {
  const { data } = props;
  return (
    <BasicCardContainer>
      <div className='student-attempt-detail-report-page-summary'>
        <div className='quiz-class-overview-page-text-1'>Summary</div>
        <div className='student-attempt-detail-report-page-summary-box-2'>
          <IconicText
            Icon={<Profile />}
            text={data?.user.student_name ?? ''}
            textClassName='student-attempt-detail-report-page-summary-box-2-text-1'
          />
          <IconicText
            Icon={<BuildingIcon />}
            text={data?.user.school_name ?? ''}
            textClassName='student-attempt-detail-report-page-summary-box-2-text-2'
          />
        </div>
        <div className='student-attempt-detail-report-page-summary-box-3'>
          <TextWithLabel
            label='Correct Answers'
            labelClassName='quiz-class-overview-page-label'
            text={data?.quiz_stats.correct_answers}
            textClassName='quiz-class-overview-page-text-2'
            className='student-attempt-detail-report-page-summary-box-3-item'
          />
          <TextWithLabel
            label='Wrong Answers'
            labelClassName='quiz-class-overview-page-label'
            text={data?.quiz_stats.wrong_answers}
            textClassName='quiz-class-overview-page-text-2'
            className='student-attempt-detail-report-page-summary-box-3-item'
          />
          <TextWithLabel
            label='Hints Taken'
            labelClassName='quiz-class-overview-page-label'
            text={data?.quiz_stats.hints_taken}
            textClassName='quiz-class-overview-page-text-2'
            className='student-attempt-detail-report-page-summary-box-3-item'
          />
          <TextWithLabel
            label='Total Time'
            labelClassName='quiz-class-overview-page-label'
            text={data ? moment.utc(data.quiz_stats.seconds_taken * 1000).format('HH:mm:ss') : ''}
            textClassName='quiz-class-overview-page-text-2'
            className='student-attempt-detail-report-page-summary-box-3-item'
          />
        </div>
        <div className='student-attempt-detail-report-page-summary-box-4'>
          <div className='quiz-class-overview-page-label'>Performance</div>
          <div className='student-attempt-detail-report-page-summary-box-4-progress'>
            <CircularProgressbar
              value={data?.quiz_stats.performance ?? 0}
              strokeWidth={9}
              text={`${data?.quiz_stats.performance.toFixed(1)}%`}
            />
          </div>
        </div>
        <div className='student-attempt-detail-report-page-summary-box-5'>
          <div className='quiz-class-overview-page-label'>Key Stats</div>
          <ImprovedStateComponent
            labelClassName='quiz-class-overview-page-label'
            textClassName='quiz-class-overview-page-text-2'
            label='Increase in performance compared to the last test'
            text={data?.key_stats.performace_improvement.toFixed(1)}
            Icon={data && data.key_stats.performace_improvement > 0 ? <ArrowUpIcon className='fill-green size-16' /> : <ArrowDownIcon className='fill-red size-16' />}

          />
          <ImprovedStateComponent
            labelClassName='quiz-class-overview-page-label'
            textClassName='quiz-class-overview-page-text-2'
            label='Decrease in total hints taken compared to the last test'
            text={data?.key_stats.hints_taken_improvement.toFixed(1)}
            Icon={!(data && data.key_stats.hints_taken_improvement > 0) ? <ArrowUpIcon className='fill-red size-16' /> : <ArrowDownIcon className='fill-green size-16' />}
            className='border-top'
          />
        </div>
      </div>
    </BasicCardContainer>
  );
}
