import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#3C57F0',
    },
    secondary: {
      main: '#353C55',
    },
    success: {
      main: '#47D16C',
      light: '#BBFBD0',
    },
    error: {
      main: '#F76659',
      light: '#FED6CD',
    },
    warning: {
      main: '#F8C51B',
      light: '#FEF99F',
    },
  },
  typography: {
    fontFamily: ['Poppins'].join(','),
  },
});

export default theme;
