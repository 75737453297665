import React from 'react';
import type { SVGProps } from 'utils/types';

export default function BookIcon(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='16' height='12' viewBox='0 0 16 12' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7 0.804009C5.90971 0.273168 4.71265 -0.0018148 3.5 9.01321e-06C2.245 9.01321e-06 1.057 0.290009 0 0.804009V10.804C1.0903 10.2732 2.28736 9.99822 3.5 10C5.169 10 6.718 10.51 8 11.385C9.32608 10.4802 10.8947 9.99747 12.5 10C13.755 10 14.943 10.29 16 10.804V0.804009C14.9097 0.273168 13.7126 -0.0018148 12.5 9.01321e-06C11.245 9.01321e-06 10.057 0.290009 9 0.804009V8.00001C9 8.26523 8.89464 8.51958 8.70711 8.70712C8.51957 8.89465 8.26522 9.00001 8 9.00001C7.73478 9.00001 7.48043 8.89465 7.29289 8.70712C7.10536 8.51958 7 8.26523 7 8.00001V0.804009Z' />
    </svg>
  );
}
