/* eslint-disable @typescript-eslint/no-invalid-void-type */
import axios from 'axios';
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'store/ApiCall/baseQuery';
import type {
  CreateTeacherAccResponse,
  TeacherCredentials,
  CreateStudentAccResponse,
  StudentCredentials,
  AuthCheckResponse,
  ForgotPasswordResponse,
  ResetPasswordResponse,
  ResetPasswordPayload,
  StudentQuizCodeCredentials,
  CreateQuizCodeAccResponse,
  CheckSignUpEmailResponse,
  SignUpEmail,
} from 'utils/types';
import type { AxiosResponse } from 'axios';
import type { LoginError } from 'components/pages/SignInPage';

export const signinAPICall = async (user: LoginError): Promise<AxiosResponse> => axios({
  url: `${process.env.REACT_APP_BACKEND_URL}/api/login`,
  method: 'post',
  data: user,
});

export const verifyEmailAPICall = async (token: string): Promise<AxiosResponse> => axios({
  url: `${process.env.REACT_APP_BACKEND_URL}/api/user/verify/${token}`,
  method: 'get',
});
export const verifyQuizCodeAPICall = async (token: string, quizCode: string): Promise<AxiosResponse> => axios({
  url: `${process.env.REACT_APP_BACKEND_URL}/api/quiz/verify/${token}?quizCode=${quizCode}`,
  method: 'get',
});
export const signoutAPICall = async (): Promise<AxiosResponse> => axios({
  url: `${process.env.REACT_APP_BACKEND_URL}/api/logout`,
  method: 'POST',
});

export const authenticationApi = createApi({
  reducerPath: 'authenticationApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    signupTeacher: builder.mutation<CreateTeacherAccResponse, TeacherCredentials>({
      query: (body) => ({
        url: '/api/signup/teacher',
        method: 'POST',
        body,
      }),
    }),
    signupStudent: builder.mutation<CreateStudentAccResponse, StudentCredentials>({
      query: (body) => ({
        url: '/api/signup/student',
        method: 'POST',
        body,
      }),
    }),
    signUpCheckEmailExisting: builder.mutation<CheckSignUpEmailResponse, SignUpEmail>({
      query: (body) => ({
        url: '/api/user/check-existing',
        method: 'POST',
        body,
      }),
    }),
    signUpWithQuizCode: builder.mutation<CreateQuizCodeAccResponse, StudentQuizCodeCredentials>({
      query: (body) => ({
        url: '/api/signup/withquizcode',
        method: 'POST',
        body,
      }),
    }),
    authenticationCheck: builder.query<AuthCheckResponse, void>({
      query: () => ({
        url: '/api/user-info',
        method: 'GET',
      }),
    }),
    forgotPassword: builder.mutation<ForgotPasswordResponse, { email: string }>({
      query: (body) => ({
        url: 'api/forget-password',
        method: 'POST',
        body,
      }),
    }),
    resetPassword: builder.mutation<ResetPasswordResponse, ResetPasswordPayload>({
      query: (body) => ({
        url: '/api/reset-password',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useSignupTeacherMutation,
  useSignupStudentMutation,
  useSignUpWithQuizCodeMutation,
  useSignUpCheckEmailExistingMutation,
  useAuthenticationCheckQuery,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authenticationApi;
