import React from 'react';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';

interface MCQTextFieldProps {
  onChangeHandler: (value: string) => void;
  state: string;
  className?: string;
}

function MCQTextField(props: MCQTextFieldProps): JSX.Element {
  const {
    onChangeHandler, state, className,
  } = props;

  return (

    <TextField
      required
      label='Text'
      variant='outlined'
      value={state}
      onChange={(e): void => { onChangeHandler(e.target.value); }}
      className={clsx('input-field input-field-text', className)}
      onClick={(e): void => { e.stopPropagation(); }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& > fieldset': {
            borderColor: '#D3D5DC',
          },
        },
      }}
    />
  );
}

export default MCQTextField;
