import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import clsx from 'clsx';
import { ListItemText } from '@mui/material';

interface SelectEquationToolProps {
  options: string[];
  label?: string;
  containerClassName?: string;
  size?: string;
  onChange?: (val: string, event?: SelectChangeEvent<string[]>) => void;
}

export default function SelectEquationTool(props: SelectEquationToolProps): JSX.Element {
  const {
    options, label, containerClassName, size, onChange,
  } = props;
  const [state, setState] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof state>): void => {
    const { target: { value } } = event;
    const val = typeof value === 'string' ? value.split(',') : value;
    setState(val);
    if (onChange) onChange(val[0]);
  };

  return (
    <div className={clsx(containerClassName)}>
      {label !== undefined && <div className='label-style-normal'>{label}</div> }
      <FormControl className='select-field-form-control'>
        <Select
          className={clsx('select-field-tool', size)}
          displayEmpty
          value={state.length > 0 ? state : [options[0]]}
          onClick={(e): void => { e.stopPropagation(); }}
          onChange={handleChange}
          renderValue={(selected): string => {
            if (selected.length === 0) {
              return '';
            }
            return selected.join(', ');
          }}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
            >
              <ListItemText>
                {name}
              </ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
