import React from 'react';

import Navbar from 'components/layout/Navbar';
import 'styles/components/layout/navbarWrapper.scss';

interface NavbarWrapperProps {
  children: JSX.Element;
  showSaveQuizBtn?: boolean;
  btnLoading?: boolean;
  name: JSX.Element | string;
}

export default function NavbarWrapper(props: NavbarWrapperProps): JSX.Element {
  const {
    children, showSaveQuizBtn = false, btnLoading = false, name,
  } = props;
  return (
    <>
      <Navbar name={name} showSaveQuizBtn={showSaveQuizBtn} btnLoading={btnLoading} />
      <div className='mt-56'>
        {children}
      </div>

    </>
  );
}
