/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table } from 'components/shared/molecules/Table';
import { preprocessClassTeacherReports, classTeacherReportColumns } from 'utils/tableDataPreprocessing';
import { useClassTeacherListQuery } from 'store/ApiCall/classesApiCall';
import { IClassTeacherPreprocessing, IUser } from 'utils/types';
import CircularLoader from '../Loader';
import TeacherAccessAdd from './TeacherAccessAdd';

export default function TeacherAccess({ classId, schoolId }: { classId: string | undefined; schoolId: string | undefined }): JSX.Element {
  const [reports, setReports] = useState<IClassTeacherPreprocessing[]>([]);
  const [existing, setExisting] = useState<string[]>([])
  const navigate = useNavigate();

  const { data: dataReport, isLoading } = useClassTeacherListQuery({ class_id: classId! }, { skip: classId === undefined });

  useEffect(() => {
    setReports(preprocessClassTeacherReports(dataReport ? dataReport.data : [], classId ?? ''));
    if (dataReport) {
      const arr: string[] = dataReport?.data.map((teacher: IUser) => teacher._id);
      console.log('existing', arr)
      if (arr.length > 0) {
        setExisting(arr);
      }
    }
  }, [dataReport]);

  useEffect(() => {
    console.log(existing);
  }, [existing]);

  //  const goToDetailPage = (quizId: string): void => {
  //    navigate(`quiz/${quizId}/report-summary`);
  //  };

  return (
    <div className='tableContainer reportsTable' style={{ padding: 0, margin: 0 }}>
      <div className='tableInner'>
        <div style={{  display: 'inline-flex', verticalAlign: 'middle'}}>
          <h4 className='text-black mt-2' style={{ color: '#000000' }}>Delegated access</h4>
          <div style={{ marginTop: 15, marginLeft: 20 }}>
            <TeacherAccessAdd class_id={classId!} school_id={schoolId!} existing={existing}  />
          </div>
        </div>
        {isLoading ? <CircularLoader /> : [dataReport?.data.length > 0 ? <Table data={reports} columns={classTeacherReportColumns} /> : (
          <div className='noRequestBlock text-center'>
            <span>No teachers found</span>
          </div>
        )]}
      </div>
    </div>
  );
}
