/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';

import CorrectTickIcon from 'assets/svgs/ts/CorrectTickIcon';
import HintBulb from 'assets/svgs/ts/HintBulb';
import clsx from 'clsx';
import MQStaticField from 'components/shared/atoms/MathQuill/MQStaticField';
import type {
  StudentReportSummaryQuestionsData, EditorInput, EquationAnswerType, SingleAnswerType,
} from 'utils/types';
import FalseTickIcon from 'assets/svgs/ts/FalseTickIcon';

interface EquationSummaryQuestionProps {
  data: StudentReportSummaryQuestionsData;
  noBorder: boolean;
}

export default function EquationSummaryQuestion(props: EquationSummaryQuestionProps): JSX.Element {
  const { data, noBorder } = props;

  const answerArrHanlder = (arr: EquationAnswerType[], index: number): SingleAnswerType[] => {
    const answerArr = arr;
    const filterAnswer = answerArr.find((ans) => ans.componentIndex === index);
    if (filterAnswer) return filterAnswer.answer;
    return [];
  };
  return (
    <div className={clsx('equation-summary-question', noBorder && 'no-border')}>
      <div className='left-box'>
        <div className='left-box-row-1'>
          <div className='left-box-row-1-order-number mr-16'>{data.order_num}</div>
          <div className='left-box-row-1-text-box'>
            <div className='left-box-row-1-statement'>{data.statement}</div>
          </div>
        </div>
        <div className='left-box-row-2'>
          <div className='left-box-row-2-answer-state mr-16'>
            <div className='left-box-row-2-icon'>
              {data.is_correct ? <CorrectTickIcon /> : <FalseTickIcon />}
            </div>
          </div>
          <div className='left-box-row-2-answer mr-16'>
            <div className='left-box-row-1-body'>
              {typeof data.body !== 'string' && data.body.map((input: EditorInput, index: number) => {
                if (input.type === 1) {
                  const ans: EquationAnswerType[] = JSON.parse(data.submitted_answer);
                  return (
                    <MQStaticField
                      key={input.id}
                      latex={input.state}
                      answers={answerArrHanlder(ans, index)}
                      className={clsx('student-equation-single-component-2')}
                      isStudentStaticField
                    />
                  );
                }
                if (input.type === 2) {
                  return (<div key={input.id} className='left-box-row-1-statement d-inline-block mr-12'>{input.state}</div>);
                }
                if (input.type === 4) {
                  const ans: EquationAnswerType[] = JSON.parse(data.submitted_answer);
                  const answer = ans.find((ansObj) => ansObj.id === input.id);
                  return (
                    <div key={input.id} className='left-box-row-1-body-item type-4 mr-12 d-inline-block'>
                      {answer?.answer}
                      <span>{` ${input.unit}`}</span>
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </div>
          {data.hint_taken && <HintBulb className='ml-24' />}
        </div>
      </div>
      {data.image_url
    && (
      <div className='right-box'>
        <div
          className='right-box-image'
          style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.image_url})` }}
        />
      </div>
    ) }
    </div>
  );
}
