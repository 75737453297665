/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import { useGetSchoolsListManagementQuery } from 'store/ApiCall/managementApiCall';
import NavbarWrapper from 'components/layout/NavbarWrapper';
import { Table } from 'components/shared/molecules/Table';
import { preprocessSchoolTableData, SchoolColumnsData } from 'utils/tableDataPreprocessing';
import Loader from 'components/shared/molecules/Loader';
import type { SchoolTablePreprocessData } from 'utils/types';
import 'styles/components/pages/schoolListPage.scss';

export default function SchoolListPage(): JSX.Element {
  const [tableData, setTableData] = React.useState<SchoolTablePreprocessData[]>();
  const { data, refetch } = useGetSchoolsListManagementQuery();

  React.useEffect(() => {
    if (data) {
      setTableData(preprocessSchoolTableData(data.data));
    }
  }, [data]);

  React.useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavbarWrapper name='Schools'>
      <div className='schoolListPageRoot'>
        {tableData
          ? !_.isEmpty(tableData)
            ? (
              <div className='tableContainer'>
                <div className='tableInner'>
                  <Table data={tableData} columns={SchoolColumnsData} />
                </div>
              </div>
            )
            : (
              <div className='noDataAvailable'>
                <h2>No school found!</h2>
              </div>
            )
          : (<Loader />)}
      </div>
    </NavbarWrapper>
  );
}
