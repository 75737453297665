import React from 'react';

import EditIcon from 'assets/svgs/ts/EditIcon';
import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';

interface BasicInformationCardProps {
  quizCode?: string;
  quizName?: string;
  nameOfClass: string;
  questionCount?: number;
  createdBy: string;
  editQuizHandler: () => void;
  className?: string;
}

export default function BasicInformationCard(props: BasicInformationCardProps): JSX.Element {
  const {
    quizCode, quizName, nameOfClass, questionCount, createdBy, editQuizHandler, className,
  } = props;
  return (
    <BasicCardContainer className={className}>
      <div className='quiz-detail-page-info-card'>
        <div className='quiz-detail-page-info-card-header'>
          <div className='label-style-bold'>Basic information</div>
          <ButtonComponent
            className='toolbar-btns light-text'
            type='outlined'
            text='Edit Quiz'
            size='small'
            startIcon={<EditIcon className='fill-mono-dull-light' />}
            onClick={editQuizHandler}
          />
        </div>
        <div className='quiz-detail-page-info-card-box-container'>
          <div className='quiz-detail-page-info-card-box'>
            <div className='label-style-normal-4'>Quiz Code</div>
            <div className='label-style-bold quiz-detail-page-info-card-title'>{quizCode}</div>
          </div>
          <div className='quiz-detail-page-info-card-box'>
            <div className='label-style-normal-4'>Quiz Name</div>
            <div className='label-style-bold quiz-detail-page-info-card-title'>{quizName}</div>
          </div>
          <div className='quiz-detail-page-info-card-box'>
            <div className='label-style-normal-4'>Year</div>
            <div className='label-style-bold quiz-detail-page-info-card-title'>{nameOfClass}</div>
          </div>
          <div className='quiz-detail-page-info-card-box'>
            <div className='label-style-normal-4'>Questions</div>
            <div className='label-style-bold quiz-detail-page-info-card-title'>{questionCount}</div>
          </div>
          <div className='quiz-detail-page-info-card-box'>
            <div className='label-style-normal-4'>Created By</div>
            <div className='label-style-bold quiz-detail-page-info-card-title'>{createdBy}</div>
          </div>
        </div>
      </div>
    </BasicCardContainer>
  );
}
