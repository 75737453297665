/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ChangeEvent } from 'react';
import React, { useState, forwardRef, useEffect } from 'react';
import QuestionIcon from 'assets/svgs/ts/QuestionIcon';
import HintIcon from 'assets/svgs/ts/HintIcon';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import SelectField from 'components/shared/atoms/SelectField/SelectField';
import ArrowUpIcon from 'assets/svgs/ts/ArrowUpIcon';
import ArrowDownIcon from 'assets/svgs/ts/ArrowDownIcon';
import DuplicateIcon from 'assets/svgs/ts/DuplicateIcon';
import DeleteIcon from 'assets/svgs/ts/DeleteIcon';
import type { Options, Question, EditorInput } from 'utils/types';
import ImageIcon from 'assets/svgs/ts/ImageIcon';
import InformationIcon from 'assets/svgs/ts/InformationIcon';
import MCQIcon from 'assets/svgs/ts/MCQIcon';
import ImageUploadButton from 'components/shared/atoms/button/ImageUploadButton';
import CloseIcon from '@mui/icons-material/Close';
import InputField from 'components/shared/atoms/inputField/InputField';
import MCQChoiceContainer from 'components/shared/molecules/CreateNewQuiz/MCQChoiceContainer';
import TagSelectorTextField from 'components/shared/atoms/inputField/TagSelectorTextField';
import clsx from 'clsx';
import EditorBox from 'components/shared/atoms/ReactMathQuill/EditorBox';
import TextAreaComponent from 'components/shared/atoms/TextAreaComponent';
import { returnQuestionTypeName } from 'utils/helper';

interface QuestionContainerProps {
  index: number;
  data: Question;
  addChoiceHandler: (questionIndex: number) => void;
  removeChoiceHandler: (questionIndex: number, choiceId: string) => void;
  onDelete: (questionIndex: number, _id?: string) => void;
  imageHandler: (e: React.ChangeEvent<HTMLInputElement>, type: string, questionIndex: number, choiceId?: string) => void;
  deleteQuestionImage: (questionIndex: number) => void;
  deleteHintImage: (questionIndex: number) => void;
  questionNameHandler: (questionIndex: number, value: string, fieldName: string) => void;
  questionTypeHandler: (questionIndex: number, type: string) => void;
  questionTagHandler: (questionIndex: number, name: string, value: string) => void;
  onBlurHandler: (e: React.FocusEvent<HTMLInputElement>, question?: Question) => void;
  moveUpHandler: (questionIndex: number) => void;
  moveDownHandler: (questionIndex: number) => void;
  duplicateHandler: (questionIndex: number) => void;
  totalQuestion: number;
  questionStatementError?: string;
  choiceError?: string;
  equationBodyError?: string;
  choiceStateHandler: (questionIndex: number, value: string, choiceId: string) => void;
  selectAnswer: (choice: string, questionIndex: number) => void;
  gettingRef: (el: HTMLDivElement, index: number) => void;
  QuestionTypeArr: Options[];
  choiceTypeHandler: (questionIndex: number, choiceId: string, type: string) => void;
  equationIsUpdateHandler: (questionIndex: number, body: EditorInput[]) => void;
  answerFieldHandler: (questionIndex: number, body: EditorInput[], answer: string) => void;
  questionTabHandler: (questionIndex: number, tab: string) => void;
  editSolvableHandler: (questionIndex: number) => void;
}

function QuestionContainer(props: QuestionContainerProps, ref: any): JSX.Element {
  const {
    index, data, addChoiceHandler, onDelete, removeChoiceHandler,
    imageHandler, deleteQuestionImage, questionNameHandler, questionTypeHandler,
    questionTagHandler, onBlurHandler, moveUpHandler, moveDownHandler, duplicateHandler, totalQuestion,
    questionStatementError, selectAnswer, choiceError, gettingRef, deleteHintImage, choiceStateHandler,
    QuestionTypeArr, choiceTypeHandler, equationIsUpdateHandler, equationBodyError, answerFieldHandler,
    questionTabHandler, editSolvableHandler,
  } = props;

  const [componentsList, setComponentList] = useState<any>([]);

  useEffect(() => {
    if (data.body.length === 0 || data.body === 'string') return;
    setComponentList(data.body);
  }, []);

  const deleteChoice = (choiceId: string): void => {
    removeChoiceHandler(index, choiceId);
  };
  const questionImageHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    imageHandler(e, 'question-image', index);
  };
  const hintImageHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    imageHandler(e, 'hint-image', index);
  };
  const choiceImageHandler = (e: React.ChangeEvent<HTMLInputElement>, choiceId: string): void => {
    imageHandler(e, 'choice-image', index, choiceId);
  };
  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value, name } = e.target;
    questionNameHandler(index, value, name);
  };
  const hintBodyHandler = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    const { name, value } = e.target;
    questionNameHandler(index, value, name);
  };
  const tagSelectorHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.currentTarget;
    questionTagHandler(index, name, value);
  };
  const onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    onBlurHandler(e, data);
  };
  const selectAnswerHandler = (choice: string): void => {
    selectAnswer(choice, index);
  };
  const onChoiceTextChange = (value: string, choiceId: string): void => {
    choiceStateHandler(index, value, choiceId);
  };
  const onChoiceTypeChange = (choiceId: string, type: string): void => {
    choiceTypeHandler(index, choiceId, type);
  };

  const tabHandler = (name: string): void => {
    questionTabHandler(index, name);
  };

  const typeHandler = (type: string): void => {
    questionTypeHandler(index, type);
    if (type === 'Multiple Choice') setComponentList([]);
  };

  const isEquationType = returnQuestionTypeName(QuestionTypeArr, data.type_id) === 'Equation Type';
  const isQuestionTab = !data.active_tab ? true : data.active_tab === 'question';

  return (
    <div ref={(el: HTMLDivElement): void => { gettingRef(el, index); }} id={`${index}`} className='basic-question-container mt-16'>
      <div className='question-header'>
        <div className='question-id'>{index + 1}</div>
        <ButtonComponent
          startIcon={<QuestionIcon className={!isQuestionTab ? 'fill-mono-dull-light' : 'fill-white'} />}
          size='small'
          className={clsx('h-40 mx-24 font-inter', !isQuestionTab && 'disabled')}
          type={isQuestionTab ? 'contained' : 'outlined'}
          text='Question'
          onClick={(): void => { tabHandler('question'); }}
        />
        <ButtonComponent
          startIcon={<HintIcon className={isQuestionTab ? 'fill-mono-dull-light' : 'fill-white'} />}
          size='small'
          className={clsx('h-40 font-inter', isQuestionTab && 'disabled')}
          type={!isQuestionTab ? 'contained' : 'outlined'}
          text='Hint'
          onClick={(): void => { tabHandler('hint'); }}
        />
        <SelectField
          state={returnQuestionTypeName(QuestionTypeArr, data.type_id)}
          onChange={(type: string): void => { typeHandler(type); }}
          options={QuestionTypeArr}
          containerClassName='ml-auto iconic-select-field'
          size='small'
        />
      </div>
      {isQuestionTab
        ? (
          <div className='question-body'>
            <InputField
              name='statement'
              type='text'
              variant='outlined'
              label='Question statement'
              value={data.statement}
              onChange={onNameChange}
              onBlur={onBlur}
              error={questionStatementError}
            />
            {data.image === null && data.image_url === ''
              ? (
                <ImageUploadButton
                  className='w-100 mt-16'
                  type='outlined'
                  text='Add Question Image'
                  startIcon={<ImageIcon className='fill-blue-2' />}
                  onClick={questionImageHandler}
                />

              )
              : (
                <div className='question-image-container mt-16' style={{ backgroundImage: `url(${data.image_url ? `${process.env.REACT_APP_BACKEND_URL}${data.image_url}` : `${data.image?.image_extension},${data.image?.file_content}`})` }}>
                  <div className='mcq-choice-state-close-icon' role='presentation' onClick={(): void => { deleteQuestionImage(index); }}>
                    <CloseIcon />
                  </div>
                </div>
              )}
            {isEquationType
              ? (
                <div className='label-style-normal mt-24'>
                  Question body
                  <span className='error-span'>{equationBodyError}</span>
                </div>
              )
              : (
                <div className='label-style-normal mt-24'>
                  Choices
                  <span className='error-span'>{choiceError}</span>
                </div>
              ) }
            <div className='options-container'>
              <InformationIcon />
              <p className='label-style-normal'>
                {isEquationType ? 'Type your question below, to write an equation please use the toolbar with related question number.'
                  : 'Add your choices below, you can add image, equation, or text into each choice. Select the check icon for the correct choice.'}
              </p>
            </div>
            {isEquationType
              ? (
                <EditorBox
                  ref={ref}
                  className='mt-24'
                  componentsList={componentsList}
                  answer={data.answer === '' ? data.answer : JSON.parse(data.answer)}
                  onEditorBoxChange={(body: EditorInput[]): void => { equationIsUpdateHandler(index, body); }}
                  onAnswerFieldChange={(body: EditorInput[], answer: string): void => { answerFieldHandler(index, body, answer); }}
                  setComponentList={setComponentList}
                  editSolvableHandler={(): void => { editSolvableHandler(index); }}
                />
              )
              : (
                <div className='options-container-choice'>
                  {typeof data.body !== 'string' && data.body.map((choice: any, i: number) => (
                    <MCQChoiceContainer
                      key={choice.key}
                      index={i}
                      data={choice}
                      deleteChoice={deleteChoice}
                      choiceImageHandler={choiceImageHandler}
                      answer={data.answer}
                      selectAnswerHandler={selectAnswerHandler}
                      onChoiceTextChange={onChoiceTextChange}
                      onChoiceTypeChange={onChoiceTypeChange}
                    />
                  ))}
                  {data.body.length === 4 ? null
                    : (
                      <div className='ml-50'>
                        <ButtonComponent
                          className='w-100'
                          type='outlined'
                          text='Add Choice'
                          startIcon={<MCQIcon />}
                          onClick={(): void => { addChoiceHandler(index); }}
                        />
                      </div>
                    )}
                </div>
              )}
          </div>
        )
        : (
          <div className='question-body'>
            <InputField
              name='hint_title'
              type='text'
              variant='outlined'
              label='Hint Title'
              className='hint-text-input'
              placeholder='Choose this hint wisely!'
              value={data.hint_title}
              onChange={onNameChange}
              required={false}
            />
            {data.hint_image === null && data.hint_image_url === ''
              ? (
                <ImageUploadButton
                  className='w-100 mt-16'
                  type='outlined'
                  text='Add Hint Image'
                  startIcon={<ImageIcon className='fill-blue-2' />}
                  onClick={hintImageHandler}
                />
              )
              : (
                <div className='question-image-container mt-16' style={{ backgroundImage: `url(${data.hint_image_url ? `${process.env.REACT_APP_BACKEND_URL}${data.hint_image_url}` : `${data.hint_image?.image_extension},${data.hint_image?.file_content}`})` }}>
                  <div className='mcq-choice-state-close-icon' role='presentation' onClick={(): void => { deleteHintImage(index); }}>
                    <CloseIcon />
                  </div>
                </div>
              )}
            <div className='label-style-normal mt-24'>Hint body</div>
            <div className='options-container'>
              <InformationIcon />
              <p className='label-style-normal'>
                Type your hint below.
              </p>
            </div>
            <TextAreaComponent name='hint_body' className='mt-24' state={data.hint_body} onChange={hintBodyHandler} />
          </div>
        )}
      <div className='question-footer'>
        <div className='question-footer-left-side'>
          <TagSelectorTextField
            name='concept'
            label='Concept'
            containerClassName='mr-20'
            onChange={tagSelectorHandler}
            value={data.concept}
            iconPosition='start'
            iconOnClickHandler={(): void => { questionTagHandler(index, 'concept', ''); }}
          />
          <TagSelectorTextField
            name='difficulty'
            label='Difficulty'
            onChange={tagSelectorHandler}
            value={data.difficulty}
            iconPosition='start'
            iconOnClickHandler={(): void => { questionTagHandler(index, 'difficulty', ''); }}
          />
        </div>
        <div className='question-footer-right-side'>
          <div className={clsx('arrow-up-icon mr-30', index === 0 && 'disabled')} role='presentation' onClick={(): void => { moveUpHandler(index); }}>
            <ArrowUpIcon className='fill-mono-dull-light' />
          </div>
          <div className={clsx('arrow-down-icon mr-30', index === totalQuestion && 'disabled')} role='presentation' onClick={(): void => { moveDownHandler(index); }}>
            <ArrowDownIcon className='fill-mono-dull-light' />
          </div>
          <div className='duplicate-icon mr-30' role='presentation' onClick={(): void => { duplicateHandler(index); }}>
            <DuplicateIcon />
          </div>
          <div className='delete-icon' role='presentation' onClick={(): void => { onDelete(index, data._id); }}>
            <DeleteIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

export default forwardRef(QuestionContainer);
