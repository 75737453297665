import type { AnyAction, Reducer } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import combineReducers from 'store/combineReducer';
import { quizzesApi } from 'store/ApiCall/quizzesApiCall';
import { schoolApi } from './ApiCall/schoolApiCall';
import { authenticationApi } from './ApiCall/authApiCalls';
import { studentApi } from './ApiCall/studentApiCalls';
import { classesApi } from './ApiCall/classesApiCall';
import { manageApi } from './ApiCall/managementApiCall';
import { reportApi } from './ApiCall/reportApiCalls';
import { questionsApi } from './ApiCall/questionApiCall';

const rootReducer: Reducer = (state: ReturnType<typeof combineReducers>, action: AnyAction) => {
  if (action.type === 'auth-slice/signoutToStore') {
    state = {} as ReturnType<typeof combineReducers>;
  }
  return combineReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
  }).concat(
    quizzesApi.middleware,
    schoolApi.middleware,
    authenticationApi.middleware,
    studentApi.middleware,
    classesApi.middleware,
    manageApi.middleware,
    reportApi.middleware,
    questionsApi.middleware,
  ),
});

export default store;

// TODO: disabled eslint for now
// eslint-disable-next-line @typescript-eslint/no-type-alias
export type RootState = ReturnType<typeof store.getState>;
// eslint-disable-next-line @typescript-eslint/no-type-alias
export type AppDispatch = typeof store.dispatch;
