import React from 'react';

import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import TextWithLabel from 'components/shared/atoms/TextWithLabel';
import ImprovedStateComponent from 'components/shared/atoms/ImprovedStateComponent';
import ArrowUpIcon from 'assets/svgs/ts/ArrowUpIcon';
import ArrowDownIcon from 'assets/svgs/ts/ArrowDownIcon';
import ReportSummaryChart from 'components/shared/molecules/AdminQuizSummary/ReportSummaryChart';
import { SingleStudentSummaryColumn, SingleStudentSummaryData } from 'utils/tableDataPreprocessing';
import { Table } from 'components/shared/molecules/Table';
import type { StudentReportsSummary } from 'utils/types';
import { useNavigate } from 'react-router-dom';
import { returnGraphData2, secondsToTime } from 'utils/helper';
import StudentBasicInfoCard from './StudentBasicInfoCard';

export default function StudentReportSummary({ summaryData }: { summaryData: StudentReportsSummary | undefined }): JSX.Element {
  const navigate = useNavigate();

  const goToStudentAttemptDetailPage = (quizId?: string): void => {
    navigate(`quiz/${quizId}`);
  };

  return (
    <div className='quiz-class-overview-page'>
      <div className='quiz-class-overview-page-left-box report-summary'>
        <StudentBasicInfoCard
          attempted_quizzes={summaryData?.basic_info.attempted_quizzes ?? 0}
          student_name={summaryData?.basic_info.student_name ?? ''}
          year={summaryData?.basic_info.year}
        />
        <div className='quiz-summary-table-container'>
          <div className='quiz-summary-table-inner'>
            <div className='label-style-bold quiz-summary-table-title'>Attempts</div>
            {
              summaryData && summaryData.attempts.length > 0
                ? (
                  <Table
                    data={SingleStudentSummaryData(summaryData.attempts)}
                    columns={SingleStudentSummaryColumn}
                    onClick={(id: string): void => { goToStudentAttemptDetailPage(id); }}
                  />
                )
                : <h5>No record found!</h5>
            }
          </div>
        </div>
      </div>
      <div className='quiz-class-overview-page-right-box'>
        <BasicCardContainer className='quiz-class-overview-page-next-quiz-box'>
          <div className='quiz-class-overview-page-text-1'>Report Insights</div>
          {
            summaryData?.attempts.length !== 0
              ? (
                <>
                  <div className='quiz-summary-chart'>
                    <div className='quiz-class-overview-page-label'>Scores</div>
                    <ReportSummaryChart
                      verticleLabel='Quizzes'
                      data={returnGraphData2(summaryData?.attempts ?? [])}
                    />
                  </div>
                  <div className='quiz-class-overview-page-information-box-0'>
                    <div className='quiz-class-overview-page-label'>Improvement Stats of the Class</div>
                    <ImprovedStateComponent
                      labelClassName='quiz-class-overview-page-label'
                      textClassName='quiz-class-overview-page-text-2'
                      label='Increase in performance compared to previous quiz'
                      text={summaryData?.insights.last_quiz_improvement_stats.performance.toFixed(2)}
                      Icon={summaryData && summaryData.insights.last_quiz_improvement_stats.performance > 0 ? <ArrowUpIcon className='fill-green size-16' /> : <ArrowDownIcon className='fill-red size-16' />}
                    />
                    <ImprovedStateComponent
                      labelClassName='quiz-class-overview-page-label'
                      textClassName='quiz-class-overview-page-text-2'
                      label='Decrease in total hints taken compared to previous quiz'
                      text={summaryData?.insights.last_quiz_improvement_stats.hints_taken.toFixed(2)}
                      Icon={summaryData && !(summaryData.insights.last_quiz_improvement_stats.hints_taken > 0) ? <ArrowUpIcon className='fill-red size-16' /> : <ArrowDownIcon className='fill-green size-16' />}
                      className='border-top'
                    />
                  </div>
                  <div className='quiz-class-overview-page-information-box-1'>
                    <TextWithLabel
                      label='Avg. Solve Time'
                      labelClassName='quiz-class-overview-page-label'
                      text={summaryData?.insights.avg_solve_time_seconds ? secondsToTime(summaryData.insights.avg_solve_time_seconds) : ''}
                      textClassName='quiz-class-overview-page-text-2'
                    />
                    <TextWithLabel
                      label='Avg. Hints Taken'
                      labelClassName='quiz-class-overview-page-label'
                      text={parseFloat(`${summaryData?.insights.avg_hints_taken}`).toFixed()}
                      textClassName='quiz-class-overview-page-text-2'
                    />
                  </div>
                </>
              )
              : <h5>No report found!</h5>
          }
        </BasicCardContainer>
      </div>
    </div>
  );
}
