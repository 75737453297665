/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import React, {
  useEffect, useRef, useImperativeHandle, forwardRef, useState,
} from 'react';
import { addStyles } from 'react-mathquill';
import { RGBToHex } from 'utils/helper';
import type { AnswerState, FractionState, SingleAnswerType } from 'utils/types';
import { useSnackbar } from 'notistack';
import MathQuillModal from './MathQuillModal';
import SolvableFractionModal from './SolvableFractionModal';

import type { EditableMathFieldProps, MathField } from './types';

declare global {
  interface Window {
    MathQuill: any;
  }
}

addStyles();

const EditableMathField = forwardRef((props: EditableMathFieldProps, ref) => {
  const {
    latex,
    onChange,
    setFocused,
    config,
    mathquillDidMount,
    answerArr,
    editSolvableHandler,
    updateEditorBoxIndex,
    ...otherProps
  } = props;
  // MathQuill fire 2 edit events on startup.
  const ignoreEditEvents = useRef(2);
  const MQ = useRef<any>(null);
  const mathField = useRef<MathField>();
  const wrapperElement = useRef<any>(null);
  const { enqueueSnackbar } = useSnackbar();

  // This is required to prevent state closure over the onChange function
  const onChangeRef = useRef(onChange);

  const [open, setOpen] = useState<{ state: string; id: number }>({ state: '', id: 0 });
  const [answerState, setAnswerState] = useState<AnswerState>({
    answer: '',
    label: '',
    color: '#353c55',
  });
  const [fractionState, setFractionState] = useState<FractionState>(
    {
      numeratorAnswer: '',
      numeratorLabel: '',
      numeratorColor: '#353c55',
      denominatorAnswer: '',
      denominatorLabel: '',
      denominatorColor: '#353c55',
    },
  );

  const setFractionHandler = (fieldName: string, value: string): void => {
    setFractionState({
      ...fractionState,
      [fieldName]: value,
    });
  };

  const onCloseFractionModal = (): void => {
    setFractionState({
      numeratorAnswer: '',
      numeratorLabel: '',
      numeratorColor: '#353c55',
      denominatorAnswer: '',
      denominatorLabel: '',
      denominatorColor: '#353c55',
    });
    setOpen({ ...open, state: '' });
  };

  const onClose = (): void => {
    setAnswerState({
      answer: '',
      label: '',
      color: '#353c55',
    });
    setOpen({ ...open, state: '' });
  };
  const setAnswerHandler = (fieldName: string, value: string): void => {
    setAnswerState({
      ...answerState,
      [fieldName]: value,
    });
  };

  const solvableAnswerValidation = (): string => {
    const currLatex = mathField.current?.latex();
    if (currLatex && currLatex.search('embed{solvableFraction}') > -1) {
      return 'You can\'t add Solvable Fraction and Solvable Answer in one field';
    }
    return '';
  };

  const solvableFractionValidation = (): string => {
    const currLatex = mathField.current?.latex();
    if (currLatex) {
      if (currLatex.search('embed{solvableFraction}') > -1) {
        return 'You can\'t add more than one Solvable Fraction in one field';
      }
      if (currLatex.search('embed{answer}') > -1) {
        return 'You can\'t add Solvable Fraction and Solvable Answer in one field';
      }
    }
    return '';
  };

  useImperativeHandle(ref, () => ({
    type: 1,
    addAnswerField(id: number): void {
      const validationError = solvableAnswerValidation();
      if (validationError) {
        enqueueSnackbar(validationError, { variant: 'error' });
      } else {
        setOpen({ state: 'answer', id });
      }
    },
    addFractionAnswerField(id: number): void {
      const validationError = solvableFractionValidation();
      if (validationError) {
        enqueueSnackbar(validationError, { variant: 'error' });
      } else {
        setOpen({ state: 'fraction', id });
      }
    },
    getAnswers(): any {
      const solvalbeFractionContainer = wrapperElement.current.querySelectorAll('span.solvable-fraction-container');
      if ([...solvalbeFractionContainer].length > 0) {
        const answerList = [...solvalbeFractionContainer].map((el: HTMLElement) => {
          const numeratorFieldAnswer = el.querySelector('.mq-numerator > .answer-container > .mq-answer');
          const denumeratorFieldAnswer = el.querySelector('.mq-denominator > .answer-container > .mq-answer');
          return {
            id: el.getAttribute('answer-id'),
            numerator: numeratorFieldAnswer?.innerHTML.trim() ?? '',
            denominator: denumeratorFieldAnswer?.innerHTML.trim() ?? '',
            is_solvable_fraction: true,
          };
        });

        return answerList;
      }
      const answerFields = wrapperElement.current.querySelectorAll('span.answer-container');
      const answerList = [...answerFields].map((el: HTMLElement) => {
        const answerValue = el.querySelector('span.mq-answer');
        return {
          id: el.getAttribute('answer-id'), value: answerValue?.innerHTML.trim() ?? '',
        };
      });
      return answerList;
    },
    addCommand(cmd: string): void {
      mathField.current?.write(cmd);
    },

  }));

  const editAnswerHandler = (id: number): void => {
    let label = '';
    let color = '#353c55';
    const answerFieldContainer = wrapperElement.current.querySelector(`span[answer-id="${id}"]`);
    const answerField = answerFieldContainer?.querySelector('.mq-answer');
    const labelField = answerFieldContainer?.querySelector('.mq-label');
    if (labelField) {
      const labelColor = labelField.getAttribute('style')?.split(':')[1];
      if (labelColor) {
        color = labelColor;
      }
      label = labelField.innerHTML;
    }
    if (answerField) setAnswerState({ answer: answerField.innerHTML, label, color });
    setOpen({ state: 'answer', id });
  };

  const editSolvableFractionHandler = (id: number): void => {
    let numeratorLabel = '';
    let numeratorColor = '#353c55';
    let denominatorLabel = '';
    let denominatorColor = '#353c55';
    const solvalbeFractionContainer = wrapperElement.current.querySelector(`span[answer-id="${id}"]`);
    const numeratorFieldAnswer = solvalbeFractionContainer.querySelector('.mq-numerator > .answer-container > .mq-answer');
    const denumeratorFieldAnswer = solvalbeFractionContainer.querySelector('.mq-denominator > .answer-container > .mq-answer');
    const numeratorFieldLabel = solvalbeFractionContainer.querySelector('.mq-numerator > .answer-container > .mq-label');
    if (numeratorFieldLabel) {
      const labelColor = numeratorFieldLabel.getAttribute('style')?.split(':')[1];
      if (labelColor) {
        numeratorColor = labelColor;
      }
      numeratorLabel = numeratorFieldLabel.innerHTML;
    }
    const denominatorFieldLabel = solvalbeFractionContainer.querySelector('.mq-denominator > .answer-container > .mq-label');
    if (denominatorFieldLabel) {
      const labelColor = denominatorFieldLabel.getAttribute('style')?.split(':')[1];
      if (labelColor) {
        denominatorColor = labelColor;
      }
      denominatorLabel = denominatorFieldLabel.innerHTML;
    }
    if (numeratorFieldAnswer && denumeratorFieldAnswer) {
      setFractionState({
        numeratorAnswer: numeratorFieldAnswer.innerHTML,
        numeratorLabel,
        numeratorColor,
        denominatorAnswer: denumeratorFieldAnswer.innerHTML,
        denominatorLabel,
        denominatorColor,
      });
    }
    setOpen({ state: 'fraction', id });
  };

  const onSaveAnswer = (): void => {
    let answerFieldContainer = wrapperElement.current.querySelector(`[answer-id="${open.id}"]`);
    // check if answer field is exist or not
    if (answerFieldContainer === null) {
      mathField.current?.write(`\\embed{answer}[${open.id}-${answerState.label}-${answerState.color.substring(1)}]`);
      wrapperElement.current.querySelector(`[answer-id="${open.id}"]`)?.addEventListener('click', () => {
        editAnswerHandler(open.id);
      });
    }
    answerFieldContainer = wrapperElement.current.querySelector(`[answer-id="${open.id}"]`);
    const answerField = answerFieldContainer.querySelector('.mq-answer');
    answerField.innerHTML = answerState.answer;
    const answerLabelField = answerFieldContainer.querySelector('.mq-label');
    if (answerLabelField !== null) {
      const currLatex = mathField.current?.latex();
      const allAnswerArr: string[] = [];
      const allAnswerField = currLatex?.split('\\embed{answer}[');
      allAnswerField?.shift();
      allAnswerField?.forEach((ans) => {
        const id = ans.split('-')[0];
        if (Number(id) === open.id) {
          allAnswerArr.push(answerState.answer);
        } else {
          const singleAnswer = wrapperElement.current.querySelector(`[answer-id="${id}"] > .mq-answer`);
          allAnswerArr.push(singleAnswer.innerHTML);
        }
      });
      let newLatex = currLatex?.replace(
        `\\embed{answer}[${open.id}--]`,
        `\\embed{answer}[${open.id}-${answerState.label}-${answerState.color.substring(1)}]`,
      );
      if (newLatex === currLatex) {
        newLatex = currLatex?.replace(
          `\\embed{answer}[${open.id}-${answerLabelField.innerHTML}-${RGBToHex(answerLabelField.style.color)}]`,
          `\\embed{answer}[${open.id}-${answerState.label}-${answerState.color.substring(1)}]`,
        );
      }
      if (newLatex) {
        mathField.current?.latex(newLatex);
        // get all values of answer id and place the innerHTML
        allAnswerField?.forEach((ans, i) => {
          const id = ans.split('-')[0];
          const singleAnswerField = wrapperElement.current.querySelector(`[answer-id="${id}"] > .mq-answer`);
          if (singleAnswerField) {
            singleAnswerField.innerHTML = allAnswerArr[i];
            wrapperElement.current.querySelector(`[answer-id="${id}"]`)?.addEventListener('click', () => {
              editAnswerHandler(Number(id));
            });
          }
        });
      }
      answerLabelField.innerHTML = answerState.label;
      answerLabelField.style.color = answerState.color;
    }
    updateEditorBoxIndex();
    editSolvableHandler();
    setAnswerState({
      answer: '',
      label: '',
      color: '#353c55',
    });
    setOpen({ ...open, state: '' });
  };

  const onSaveFractionAnswer = (): void => {
    let solvalbeFractionContainer = wrapperElement.current.querySelector(`[answer-id="${open.id}"]`);
    // check if solvable fraction field is exist or not
    if (solvalbeFractionContainer === null) {
      mathField.current?.write(`\\embed{solvableFraction}[${open.id}-${fractionState.numeratorLabel}-${fractionState.numeratorColor.substring(1)}-${fractionState.denominatorLabel}-${fractionState.denominatorColor.substring(1)}]`);
      wrapperElement.current.querySelector(`[answer-id="${open.id}"]`)?.addEventListener('click', () => {
        editSolvableFractionHandler(open.id);
      });
    }
    solvalbeFractionContainer = wrapperElement.current.querySelector(`[answer-id="${open.id}"]`);
    const numeratorField = solvalbeFractionContainer.querySelector('.mq-numerator > .answer-container > .mq-answer');
    const denumeratorField = solvalbeFractionContainer.querySelector('.mq-denominator > .answer-container > .mq-answer');
    numeratorField.innerHTML = fractionState.numeratorAnswer;
    denumeratorField.innerHTML = fractionState.denominatorAnswer;
    const numeratorLabel = solvalbeFractionContainer.querySelector('.mq-numerator > .answer-container > .mq-label');
    const denominatorLabel = solvalbeFractionContainer.querySelector('.mq-denominator > .answer-container > .mq-label');
    if (numeratorLabel !== null || denominatorLabel !== null) {
      const currLatex = mathField.current?.latex();
      let newLatex = currLatex?.replace(
        `\\embed{solvableFraction}[${open.id}--${fractionState.numeratorColor.substring(1)}--${fractionState.denominatorColor.substring(1)}]`,
        `\\embed{solvableFraction}[${open.id}-${fractionState.numeratorLabel}-${fractionState.numeratorColor.substring(1)}-${fractionState.denominatorLabel}-${fractionState.denominatorColor.substring(1)}]`,
      );
      if (newLatex === currLatex) {
        newLatex = currLatex?.replace(
          `\\embed{solvableFraction}[${open.id}-${numeratorLabel.innerHTML}-${RGBToHex(numeratorLabel.style.color)}-${denominatorLabel.innerHTML}-${RGBToHex(denominatorLabel.style.color)}]`,
          `\\embed{solvableFraction}[${open.id}-${fractionState.numeratorLabel}-${fractionState.numeratorColor.substring(1)}-${fractionState.denominatorLabel}-${fractionState.denominatorColor.substring(1)}]`,
        );
      }
      if (newLatex) {
        mathField.current?.latex(newLatex);
        solvalbeFractionContainer = wrapperElement.current.querySelector(`[answer-id="${open.id}"]`);
        const numeratorField2 = solvalbeFractionContainer.querySelector('.mq-numerator > .answer-container > .mq-answer');
        const denumeratorField2 = solvalbeFractionContainer.querySelector('.mq-denominator > .answer-container > .mq-answer');
        const numeratorLabel2 = solvalbeFractionContainer.querySelector('.mq-numerator > .answer-container > .mq-label');
        const denominatorLabel2 = solvalbeFractionContainer.querySelector('.mq-denominator > .answer-container > .mq-label');
        numeratorField2.innerHTML = fractionState.numeratorAnswer;
        denumeratorField2.innerHTML = fractionState.denominatorAnswer;
        numeratorLabel2.innerHTML = fractionState.numeratorLabel;
        numeratorLabel2.style.color = fractionState.numeratorColor;
        denominatorLabel2.innerHTML = fractionState.denominatorLabel;
        denominatorLabel2.style.color = fractionState.denominatorColor;
        wrapperElement.current.querySelector(`[answer-id="${open.id}"]`)?.addEventListener('click', () => {
          editSolvableFractionHandler(open.id);
        });
      }
    }
    updateEditorBoxIndex();
    editSolvableHandler();
    onCloseFractionModal();
  };

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    if (!window.MathQuill) return;
    MQ.current = window.MathQuill.getInterface(2);
    MQ.current?.registerEmbed('answer', (data: any) => {
      const splitData = data.split('-');
      return {
        htmlString: `<span class="answer-container" answer-id="${splitData[0]}">
                     <span style="color:#${splitData[2]}"
                      class="mq-label ${splitData[1] === '' && 'd-none'}">${splitData[1]}</span>
                     <span class="mq-answer"></span>
                     </span>`,
        text: (): string => '',
        latex: (): string => `\\embed{answer}[${data}]`,
      };
    });
    MQ.current?.registerEmbed('solvableFraction', (data: any) => {
      const splitData = data.split('-');
      return {
        htmlString: `<span class="mq-fraction solvable-fraction-container" answer-id="${splitData[0]}">
                     <span class="mq-numerator">
                     <span class="answer-container" answer-id="${splitData[0]}">
                     <span style="color:#${splitData[2]}"
                      class="mq-label ${splitData[1] === '' && 'd-none'}">${splitData[1]}</span>
                     <span class="mq-answer"></span>
                     </span>     
                     </span>
                     <span class="mq-denominator">
                     <span class="answer-container" answer-id="${splitData[0]}">
                     <span style="color:#${splitData[4]}"
                      class="mq-label ${splitData[3] === '' && 'd-none'}">${splitData[3]}</span>
                     <span class="mq-answer"></span>
                     </span>
                     </span>
                     </span>`,
        text: (): string => '',
        latex: (): string => `\\embed{solvableFraction}[${data}]`,
      };
    });
  }, []);

  // Setup MathQuill on the wrapperElement
  useEffect(() => {
    if (!MQ.current || !wrapperElement.current) return;

    let combinedConfig: any = {
      restrictMismatchedBrackets: true,
      handlers: {},
    };

    if (config) {
      combinedConfig = {
        ...combinedConfig,
        ...config,
      };
    }

    if (!combinedConfig.handlers) return;
    const configEditHandler = combinedConfig.handlers.edit;
    combinedConfig.handlers.edit = (field: any): void => {
      if (configEditHandler) configEditHandler(field);

      if (ignoreEditEvents.current > 0) {
        ignoreEditEvents.current -= 1;
      } else if (onChangeRef.current) {
        onChangeRef.current(field);
      }
    };

    mathField.current = MQ.current.MathField(
      wrapperElement.current,
      combinedConfig,
    );
    mathField.current?.latex(latex ?? '');

    if (mathquillDidMount) mathquillDidMount(mathField.current);
  }, [wrapperElement]);

  useEffect(() => {
    if (mathField.current && mathField.current.latex() !== latex) {
      mathField.current.latex(latex ?? '');
    }
  }, [latex]);

  const addEditListner = (ans: SingleAnswerType): void => {
    const state = {
      answer: '',
      label: '',
      color: '',
    };
    const answerFieldContainer = wrapperElement.current.querySelector(`span[answer-id="${ans.id}"]`);
    const answerField2 = answerFieldContainer.querySelector('.mq-answer');
    const answerLabelField = answerFieldContainer.querySelector('.mq-label');
    if (answerField2) {
      state.answer = answerField2.innerHTML;
    }
    if (answerLabelField) {
      const color = answerLabelField.getAttribute('style')?.split(':')[1];
      state.label = answerLabelField.innerHTML;
      state.color = color;
    }
    setAnswerState({ ...state });
    setOpen({ state: 'answer', id: Number(ans.id) });
  };

  useEffect(() => {
    if (answerArr && typeof answerArr !== 'string') {
      answerArr.forEach((ans) => {
        if (ans.is_solvable_fraction) {
          const solvalbeFractionContainer = wrapperElement.current.querySelector(`span[answer-id="${ans.id}"]`);
          const numeratorField = solvalbeFractionContainer.querySelector('.mq-numerator > .answer-container > .mq-answer');
          const denumeratorField = solvalbeFractionContainer.querySelector('.mq-denominator > .answer-container > .mq-answer');
          numeratorField.innerHTML = ans.numerator;
          denumeratorField.innerHTML = ans.denominator;
          solvalbeFractionContainer.addEventListener('click', () => {
            editSolvableFractionHandler(open.id);
          });
          updateEditorBoxIndex(Number(ans.id));
        } else {
          const answerFieldContainer = wrapperElement.current.querySelector(`span[answer-id="${ans.id}"]`);
          const answerField = answerFieldContainer.querySelector('.mq-answer');
          if (answerField) {
            answerField.innerHTML = ans.value;
            answerFieldContainer.addEventListener('click', () => {
              addEditListner(ans);
            });
            updateEditorBoxIndex(Number(ans.id));
          }
        }
      });
    }
  }, []);

  return (
    <>
      <span
        {...otherProps}
        ref={wrapperElement}
        onFocus={((): void => { if (setFocused !== undefined) setFocused(true); })}
        onBlur={((): void => { if (setFocused !== undefined) setFocused(false); })}
      />
      <MathQuillModal
        isOpen={open.state === 'answer'}
        answerState={answerState}
        onCloseHandler={onClose}
        setAnswerHandler={setAnswerHandler}
        onSaveAnswer={onSaveAnswer}
      />
      <SolvableFractionModal
        isOpen={open.state === 'fraction'}
        answerState={fractionState}
        onCloseHandler={onCloseFractionModal}
        setAnswerHandler={setFractionHandler}
        onSaveAnswer={onSaveFractionAnswer}
      />
    </>
  );
});

export default EditableMathField;
