/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import { isNil } from 'lodash';
import type {
  ClassYearData, Options, QuestionTypeData, IImage,
  ReportChartDataType, SingleQuizChartSummaryDataType,
  IStudentAttempts, ReportChartDataType2, Question,
} from 'utils/types';

export const removeSpecialCharacters = (str: string): string => str.replace(/[^a-zA-Z ]/g, ' ');
export const returnQuestionOptionNumber = (index: number): string => {
  if (index === 0) return 'A';
  if (index === 1) return 'B';
  if (index === 2) return 'C';
  if (index === 3) return 'D';
  return '';
};
export const returnQuestionOptionIndex = (key?: string): number => {
  if (key === undefined) return -1;
  if (key === 'A') return 0;
  if (key === 'B') return 1;
  if (key === 'C') return 2;
  if (key === 'D') return 3;
  return -1;
};
export const sizeInMB = (sizeInBytes: number): number | string => (sizeInBytes / (1024 * 1024)).toFixed(2);
export const returnAnswerArr = (latex: string): string[] | string => {
  const arr = latex.split('\\embed{');
  if (arr.length === 1) return '';
  const answerArr: string[] = [];
  arr.forEach((item, i) => {
    if (i === 0) return;
    answerArr.push(arr[i].split('}')[0]);
  });

  return answerArr;
};

export const checkSingularWord = (singularWord: string, pluralWord: string, count?: number): string => {
  if (isNil(count)) return '';
  if (count > 1) return pluralWord;
  return singularWord;
};

export const returnQuestionTypeName = (QuestionTypeArr: Options[], id: string): string => {
  const filteredType = QuestionTypeArr.find((type) => type._id === id);
  if (filteredType) {
    return filteredType.value;
  }
  return '';
};

export const returnQuizClassName = (QuizClassList?: ClassYearData[], id?: string): string => {
  if (QuizClassList && id) {
    const filteredType = QuizClassList.find((type) => type._id === id);
    if (filteredType) {
      return filteredType.name;
    }
  }
  return '';
};

export const returnQuestionType = (id: string, QuestionTypeArr?: QuestionTypeData[]): string => {
  if (!QuestionTypeArr) return '';
  const filteredType = QuestionTypeArr.find((type) => type._id === id);
  if (filteredType) {
    return filteredType.name;
  }
  return '';
};

export const checkEmptySpaces = (value: string): boolean => /^\s/.test(value);

export const checkAlphaNumericValidation = (value: string): boolean => /[^-+ a-zA-Z0-9\s]/.test(value);

export const checkSolvableAnswerValidation = (value: string): boolean => /[^-+.: a-zA-Z0-9\s]/.test(value);

export const numberValidation = (value: string): boolean => /^[0-9]+$/.test(value);

export const checkLatexBrackets = (latex: string): boolean => {
  if (latex.search('left') !== -1 && latex.search('frac') !== -1) return true;
  return false;
};

export function RGBToHex(rgb: string): string {
  if (rgb === '') return '';
  // Choose correct separator
  const sep = rgb.includes(',') ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  const rgbSplit = rgb.substr(4).split(')')[0].split(sep);

  let r = (+rgbSplit[0]).toString(16);
  let g = (+rgbSplit[1]).toString(16);
  let b = (+rgbSplit[2]).toString(16);

  if (r.length === 1) { r = `0${r}`; }
  if (g.length === 1) { g = `0${g}`; }
  if (b.length === 1) { b = `0${b}`; }

  return `${r}${g}${b}`;
}

export function getDefaultPage(userRole: string): string {
  const defaultRoutes = {
    admin: '/quizzes',
    teacher: '/my-classes',
    student: '/home',
  };

  if (userRole === 'admin') {
    return defaultRoutes.admin;
  }

  if (userRole === 'teacher') {
    return defaultRoutes.teacher;
  }

  return defaultRoutes.student;
}

export function getRole(role: string): string {
  if (['admin', 'teacher'].includes(role)) return role;
  return '';
}

export function checkAuthenticated(): boolean {
  const userObj = window.localStorage.getItem('user');
  if (userObj) {
    const appUser = JSON.parse(userObj);
    const role: string = appUser.role_id.name;
    if (['admin', 'teacher'].includes(role)) return true;
  }
  return false;
}

const returnFirstBarData = (data: SingleQuizChartSummaryDataType[], endingIndex: number): number => {
  let sum = 0;
  const slicedData = data.slice(0, endingIndex);
  slicedData.forEach((item) => {
    sum += item.student_count;
  });
  return sum;
};

const returnFirstBarStudentData = (data: SingleQuizChartSummaryDataType[], endingIndex: number): string => {
  let studenNameArr: string[] = [];
  let ans = '';
  const slicedData = data.slice(0, endingIndex);
  slicedData.forEach((item) => {
    studenNameArr = [...studenNameArr, ...item.student_names];
  });
  studenNameArr.forEach((name, i) => {
    if (i === 0) {
      ans += `${name}${i !== studenNameArr.length - 1 ? ', ' : ''}`;
    } else if (i % 3 === 0) {
      ans += `<br /> ${name}${i !== studenNameArr.length - 1 ? ', ' : ''}`;
    } else {
      ans += `${name}${i !== studenNameArr.length - 1 ? ', ' : ''}`;
    }
  });
  return ans;
};

export function returnGraphData(data?: SingleQuizChartSummaryDataType[]): ReportChartDataType {
  if (data === undefined || data.length === 0) return { horizontalAxis: [], verticleAxis: [], NameArr: [] };
  const gap = 2;
  let firstGap = 3;
  const horizontalAxis: string[] = [];
  const verticleAxis: number[] = [];
  const studentNameArr: string[] = [];
  let slicedData = [];
  // For the first bar (when number of questions are equal or less than 10)
  if (data.length <= 11) {
    if (data.length < 3) {
      verticleAxis.push(returnFirstBarData(data, data.length + 1));
      studentNameArr.push(returnFirstBarStudentData(data, data.length + 1));
      if (data.length === 1) {
        horizontalAxis.push('0');
      } else {
        horizontalAxis.push(`0-${data.length - 1}`);
      }
    } else {
      verticleAxis.push(returnFirstBarData(data, 3));
      studentNameArr.push(returnFirstBarStudentData(data, 3));
      horizontalAxis.push('0-2');
    }
  }
  // For the first bar (when number of questions are more than 10)
  if (data.length >= 12) {
    firstGap = 5;
    verticleAxis.push(returnFirstBarData(data, 5));
    studentNameArr.push(returnFirstBarStudentData(data, 5));
    horizontalAxis.push('0-4');
  }

  for (let a = firstGap; a < data.length; a += 2) {
    const startingPoint = a;
    let sum = 0;
    slicedData = [];
    slicedData = data.slice(startingPoint, startingPoint + gap);
    if (startingPoint === (data.length - 1)) {
      horizontalAxis.push(`${startingPoint}`);
    } else {
      horizontalAxis.push(`${startingPoint}-${startingPoint + 1}`);
    }
    slicedData.forEach((item) => {
      sum += item.student_count;
    });
    verticleAxis.push(sum);
    studentNameArr.push(returnFirstBarStudentData(slicedData, slicedData.length + 1));
  }
  return { horizontalAxis, verticleAxis, NameArr: studentNameArr };
}

export function returnGraphData2(attemptData: IStudentAttempts[]): ReportChartDataType2 {
  const horizontalAxis: string[] = [];
  const verticleAxis: number[] = [];
  for (let a = 0; a < 5; a++) {
    const quizzes: string[] = [];
    attemptData.forEach((data) => {
      if (a === 0) {
        if (data.performance >= 0 && data.performance <= 20) {
          quizzes.push(data.quiz_name);
        }
      } else if (data.performance >= (a * 20 + 1) && data.performance <= (a * 20 + 20)) {
        quizzes.push(data.quiz_name);
      }
    });
    if (a === 0) {
      horizontalAxis.push('0-20%');
    } else {
      horizontalAxis.push(`${a * 20 + 1}-${a * 20 + 20}%`);
    }
    verticleAxis.push(quizzes.length);
  }
  return {
    horizontalAxis,
    verticleAxis,
  };
}

export function returnPreviousAlphabet(answer: string): string {
  const arr = ['A', 'B', 'C', 'D'];
  const index = arr.findIndex((ans) => ans === answer);
  if (index !== -1) {
    return arr[index - 1];
  }
  return '';
}

export const imageUrlToFile = async (imageUrl: string, type: string): Promise<File> => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return new File([blob], `image.${type}`, { type: blob.type });
};

export const convertFileToImage = async (file: File): Promise<IImage | null> => new Promise((resolve) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (): void => {
    if (typeof reader.result === 'string') {
      const fileContent = reader.result.split(',');
      const fileExtension = file.name.split('.').pop();
      const fileName = file.name;
      const fileSize = sizeInMB(file.size);
      const image = {
        file_content: fileContent[1],
        file_extension: fileExtension,
        file_path: fileName,
        file_type: 2,
        file_size: fileSize,
        image_extension: fileContent[0],
      };
      resolve(image);
    }
    resolve(null);
  };
});

export const progresscolorDecideOnAvg = (avg: number): 'error' | 'info' | 'inherit' | 'primary' | 'secondary' | 'success' | 'warning' | undefined => {
  if (avg <= 33) {
    return 'error';
  } if (avg > 33 && avg < 50) {
    return 'warning';
  } if (avg >= 50) {
    return 'success';
  }
  return 'error';
};

export const returnImageType = (imageUrl: string): string => imageUrl.split('.')[1];

export const secondsToMinutes = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const restSeconds = seconds % 60;
  return `${minutes}m ${restSeconds}s`;
};

export const textTrimmer = (text: string, length: number): string => {
  const textLength = text.length;
  const isLengthExceeded = textLength > length;
  return `${text.substring(0, length)}${isLengthExceeded ? '...' : ''}`;
};
export function secondsToTime(secs: number): string {
  const hours = Math.floor(secs / (60 * 60));
  const divisorForMinutes = secs % (60 * 60);
  const minutes = Math.floor(divisorForMinutes / 60);
  const divisorForSeconds = divisorForMinutes % 60;
  const seconds = Math.round(divisorForSeconds);
  if (hours) {
    return `${hours}h ${minutes}m ${seconds}s`;
  }
  return `${minutes}m ${seconds}s`;
}

// Check if hint is added into the question or not
export const isHintAddedToQuestion = (question: Question): boolean => {
  if (question.hint_title || question.hint_image_url || question.hint_body) return true;
  return false;
};

export const convertIfSolvable = (answer: string): string => {
  if (answer.includes('is_solvable_fraction')) {
    try {
      let submittedAnswerObject = JSON.parse(String(answer));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
      submittedAnswerObject = submittedAnswerObject.map((answerObj: { answer: any[] }) => {
        const answers = answerObj.answer.map((a: { is_solvable_fraction?: boolean; numerator?: number | string; denominator?: number | string }) => {
          if (a.is_solvable_fraction !== undefined && a.is_solvable_fraction && a.numerator !== undefined && a.denominator !== undefined && Number(a.denominator) !== 0) {
            const value = (Number(a.numerator) / Number(a.denominator)).toFixed(2);
            delete a.numerator;
            delete a.denominator;
            delete a.is_solvable_fraction;
            return { ...a, value };
          }
          return { ...a };
        });

        return {
          ...answerObj,
          answer: answers,
        };
      });

      return JSON.stringify(submittedAnswerObject);
    } catch {
      // eslint-disable-next-line no-console
      console.error('Failed to parse answer');
    }
    return '';
  }
  return answer;
};

export const checkHintAvailable = (
  hintTitle: string | undefined,
  hintImageUrl: string | undefined,
  hint_body: string | undefined,
  hint_image: IImage | null | undefined,
): boolean => {
  if (hintTitle || hintImageUrl || hint_body || hint_image) {
    return true;
  }
  return false;
};

export const addQueryParam = (baseUrl: string, queryParamName: string | undefined, value: string | undefined): string => {
  if (!value) return baseUrl;
  if (!baseUrl.includes('?')) {
    return `${baseUrl}?${queryParamName}=${value}`;
  }
  return `${baseUrl}&${queryParamName}=${value}`;
};
