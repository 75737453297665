/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';

import CorrectTickIcon from 'assets/svgs/ts/CorrectTickIcon';
import FalseTickIcon from 'assets/svgs/ts/FalseTickIcon';
import HintBulb from 'assets/svgs/ts/HintBulb';
import HintBulbOff from 'assets/svgs/ts/HintBulbOff';
import clsx from 'clsx';
import type { StudentReportSummaryQuestionsData } from 'utils/types';
import MQStaticField from 'components/shared/atoms/MathQuill/MQStaticField';

interface McqQuestionProps {
  data: StudentReportSummaryQuestionsData;
  noBorder: boolean;
}

export default function McqQuestion(props: McqQuestionProps): JSX.Element {
  const {
    data, noBorder,
  } = props;

  const returnChoiceImageUrl = (): JSX.Element => {
    const filterChoice = data.body.find((choice: any) => choice.key === data.submitted_answer);
    if (filterChoice.type === 'image') {
      return (
        <div className='quiz-response-row-2'>
          <div className='question-bg-image' style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${filterChoice.image_url})` }} />
        </div>
      );
    }
    if (filterChoice.type === 'equation') {
      return (
        <MQStaticField
          latex={filterChoice.state}
          answers={[]}
          className={clsx('admin-student-summary')}
          isStudentStaticField
        />
      );
    }
    return (
      <div className='question-text'>
        {filterChoice.state}
      </div>
    );
  };

  return (
    <div className={clsx('quiz-response-mcq-question', noBorder && 'no-border')}>
      <div className='quiz-response-row-1'>
        <div className='quiz-response-box-1'>
          <div className='question-order'>
            {data.order_num}
            .
          </div>
          <div className='question-text'>
            {data.statement}
          </div>
        </div>
        <div className='quiz-response-box-2'>{data.is_correct ? <CorrectTickIcon className='size-16' /> : <FalseTickIcon className='size-16' />}</div>
        <div className='quiz-response-box-3'>{data.hint_taken ? <HintBulb className='size-16' /> : <HintBulbOff className='size-16 fill-secondary-light' />}</div>
      </div>
      {data.image_url
      && (
        <div className='quiz-response-row-2'>
          <div className='question-bg-image' style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.image_url})` }} />
        </div>
      )}
      <div className='quiz-response-body'>
        <div className='quiz-response-body-row-1 question-text mb-16'>
          Submitted Answer:
        </div>
        <div className='quiz-response-body-row-2'>
          <div
            className={clsx('choice-id active mr-20')}
          >
            {data.answer}
          </div>
          {returnChoiceImageUrl()}
        </div>
      </div>
    </div>
  );
}
