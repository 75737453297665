/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import CorrectTickIcon from 'assets/svgs/ts/CorrectTickIcon';
import RedCloseIcon from 'assets/svgs/ts/RedCrossIcon';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import { useApproveTeacherMutation, useDeclineTeacherMutation } from 'store/ApiCall/managementApiCall';
import type { TeachersRequestResponse } from 'utils/types';

export default function TeacherActionsComponent({ teacherId }: { teacherId: string }): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [approveTeacher] = useApproveTeacherMutation();
  const [declineTeacher] = useDeclineTeacherMutation();

  const approveTeacherHandler = async (): Promise<void> => {
    try {
      const response = await approveTeacher(teacherId).unwrap();
      if (response.success) {
        enqueueSnackbar(response.message, { variant: 'success' });
      }
    } catch (err: any) {
      const errorData: TeachersRequestResponse = err.data;
      enqueueSnackbar(errorData.message, { variant: 'error' });
    }
  };

  const declineTeacherHandler = async (): Promise<void> => {
    try {
      const response = await declineTeacher(teacherId).unwrap();
      if (response.success) {
        enqueueSnackbar(response.message, { variant: 'success' });
      }
    } catch (err: any) {
      const errorData: TeachersRequestResponse = err.data;
      enqueueSnackbar(errorData.message, { variant: 'error' });
    }
  };

  return (
    <div className={clsx('pendingTeacherCell', 'pendingTeacherActionsCell')}>
      <ButtonComponent type='outlined' text='Approve' size='small' startIcon={<CorrectTickIcon />} className='approveBtn' onClick={approveTeacherHandler} />
      <ButtonComponent type='outlined' text={<span><RedCloseIcon /></span>} size='small' className='declineBtn' onClick={declineTeacherHandler} />
    </div>
  );
}
