/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, {
  useRef, useImperativeHandle, forwardRef,
} from 'react';

import clsx from 'clsx';
import MQStaticField from 'components/shared/atoms/MathQuill/MQStaticField';

import type { EditorInput, EquationAnswerType, Question } from 'utils/types';

interface EquationQuestionProps {
  data: Question | null;
}

function EquationQuestion(props: EquationQuestionProps, ref: any): JSX.Element {
  const { data } = props;
  const staticEqRef = useRef<any[]>([]);

  const settingRef = (equationRef: any, index: number): void => {
    if (equationRef === null) return;
    staticEqRef.current[index] = equationRef;
  };

  useImperativeHandle(ref, () => ({
    getAnswer(): string {
      if (data?.body.length > 0) {
        const answerArr: EquationAnswerType[] = [];
        data?.body.forEach((comp: EditorInput, index: number) => {
          if (comp.type === 1) {
            const answerList = staticEqRef.current[index]?.getInputAnswers();
            if (answerList && answerList.length > 0) {
              answerArr.push({
                id: comp.id, componentIndex: index, type: comp.type, answer: answerList,
              });
            }
          } else if (comp.type === 4) {
            const textAnswer = staticEqRef.current[index]?.value;
            answerArr.push({
              id: comp.id, componentIndex: index, type: comp.type, answer: textAnswer,
            });
          }
        });
        if (answerArr.length === 0) return '';
        return JSON.stringify(answerArr);
      }
      return '';
    },
    setAnswer(): string {
      return '';
    },
  }));

  return (
    <div className='present-equation-question'>
      <div className='present-quiz-title'>{data?.statement}</div>
      <div className='present-equation-question-body'>
        {data?.image_url
        && (
          <div className='present-equation-question-left-box'>
            <div
              className='present-equation-question-left-box-image'
              style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.image_url})` }}
            />
          </div>
        )}
        <div className={clsx('present-equation-question-right-box', data?.image_url && 'horizontal-center')}>
          <div className='present-equation-question-right-box-list'>
            {data?.body && data.body.length > 0 && data.body.map((item: EditorInput, index: number) => {
              if (item.type === 1) {
                return (
                  <MQStaticField
                    key={item.id}
                    latex={item.state}
                    answers={[]}
                    ref={(el: any): void => { settingRef(el, index); }}
                    className={clsx('student-equation-single-component')}
                  />
                );
              }
              if (item.type === 2) {
                return (<div key={item.id} className='student-equation-single-component'>{item.state}</div>);
              }
              if (item.type === 4) {
                return (
                  <div key={item.id} className={clsx('student-answer-field', 'student-equation-single-component')}>
                    <input
                      ref={(el: any): void => { settingRef(el, index); }}
                    />
                    <span>{` ${item.unit}`}</span>
                  </div>
                );
              }
              return (<div className='student-equation-single-component-new-line' key={item.id} />);
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(forwardRef(EquationQuestion));
