/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';

import TextWithLabel from 'components/shared/atoms/TextWithLabel';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import type { EditorInput, HardestQuestionsType } from 'utils/types';
import clsx from 'clsx';
import MQStaticField from 'components/shared/atoms/MathQuill/MQStaticField';
import { checkLatexBrackets } from 'utils/helper';
import 'react-circular-progressbar/dist/styles.css';

interface DetailedHardestQuestionProps {
  question: HardestQuestionsType;
  type: string;
}
export default function DetailedHardestQuestion(props: DetailedHardestQuestionProps): JSX.Element {
  const { question, type } = props;

  return (
    <div className='detail-hardest-question'>
      <div className='detail-hardest-question-row-1'>
        <div className='detail-hardest-question-row-1-title-box'>
          <div className='question-id mr-12'>{question.order_number}</div>
          <div className='mcq-question-text'>{question.statement}</div>
        </div>
        {question.concept
        && (
          <div className='detail-hardest-question-row-1-tag-box'>
            <div className='multi-text-label-body-tag'>
              {question.concept}
            </div>
          </div>
        ) }
      </div>
      <div className='detail-hardest-question-row-2'>
        {question.image_url
          ? (
            <div className='detail-hardest-question-row-2-image-container'>
              <div style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${question.image_url})` }} className='detail-hardest-question-row-2-image' />
            </div>
          )
          : type === 'Equation Type' && (
            <div className='equation-question-component-list'>
              {typeof question.body !== 'string' && question.body.length > 0 && question.body.map((item: EditorInput) => {
                if (item.type === 1) {
                  return (
                    <MQStaticField
                      key={item.id}
                      latex={item.state}
                      answers={[]}
                      className={clsx('equation-question-component-list-item hardest-question-equation', checkLatexBrackets(item.state) && 'extra-class')}
                      readOnly
                      isAdminStaticField
                    />
                  );
                }
                if (item.type === 2) {
                  return (<div key={item.id} className='equation-question-component-list-item equation-question-text'>{item.state}</div>);
                }
                if (item.type === 4) {
                  return (
                    <div key={item.id} className='equation-question-component-list-item equation-answer-text'>
                      {item.state}
                      <span>{` ${item.unit}`}</span>
                    </div>
                  );
                }
                return (<br key={item.id} />);
              })}

            </div>
          )}
      </div>
      <div className='detail-hardest-question-row-3'>
        <TextWithLabel
          label='Hints Taken'
          labelClassName='quiz-class-overview-page-label'
          text={`${question.hintsTaken.toFixed(0)}%`}
          textClassName='quiz-class-overview-page-text-2'
        />
        <TextWithLabel
          label='Correct Answers'
          labelClassName='quiz-class-overview-page-label'
          text={`${question.correctAnswers.toFixed(0)}%`}
          textClassName='quiz-class-overview-page-text-2'
        />
        <div className='detail-hardest-question-donut-chart'>
          <CircularProgressbar
            className='hardest-question-chart'
            value={100 - Number(question.correctAnswers.toFixed(0))}
            styles={buildStyles({
              pathColor: '#F64754',
              trailColor: '#19CB9E',
              pathTransitionDuration: 1,
              textColor: '#353C55',
            })}
            strokeWidth={12}
            text={`${100 - Number(question.correctAnswers.toFixed(0))}%`}
          />
        </div>
      </div>
    </div>
  );
}
