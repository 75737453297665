/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import NavbarWrapper from 'components/layout/NavbarWrapper';
import BasicInformationCard from 'components/shared/molecules/QuizDetailPage/BasicInformationCard';
import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import LayoutContainer from 'components/layout/LayoutContainer';
import QuizDetailHeader from 'components/shared/molecules/QuizDetailPage/QuizDetailHeader';
import { useGetClassYearListQuery, useGetQuizDetailQuery, useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';
import { getUserDetail } from 'utils/localStorage';
import { returnQuestionType, returnQuizClassName } from 'utils/helper';
import McqQuestion from 'components/shared/molecules/QuizDetailPage/McqQuestion';
import type { Question } from 'utils/types';
import HintContainer from 'components/shared/molecules/QuizDetailPage/HintContainer';
import EquationQuestion from 'components/shared/molecules/QuizDetailPage/EquationQuestion';

export default function QuizDetailPage({ role }: { role?: string | undefined }): JSX.Element | null {
  const { id } = useParams();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState<string>('question');
  const [hintData, setHintData] = useState<Question[]>([]);

  // get user from localstorage
  const user = getUserDetail();

  // ClassYear Api
  const { data: classYearList, isLoading: isGettingClassYearList } = useGetClassYearListQuery();

  // Question type Api
  const { data: questionTypeList, isLoading: isGettingQuestionTypeList } = useGetQuestionTypeListQuery();

  // API call for get quiz by id
  const { data: quizData, isLoading: isGettingQuiz, error } = useGetQuizDetailQuery(id);

  const editQuizHandler = (): void => {
    navigate(`/quiz-builder/edit/${id}`);
  };

  const activeTabHanlder = (tab: string): void => {
    if (quizData?.data) {
      const hintList = quizData.data.questions.filter((question) => question.hint_title || question.hint_image_url || question.hint_body);
      setHintData(hintList);
    }
    setActiveTab(tab);
  };

  // replace with loader
  if (isGettingClassYearList || isGettingQuestionTypeList || isGettingQuiz) return null;

  if (error) {
    navigate('/quizzes');
  }

  return (
    <NavbarWrapper name={((): JSX.Element => (
      <span className='navbarTitle'>
        <div
          className='linkElement'
          role='presentation'
          onClick={(): void => { navigate(-1); }}
        >
          All Quizzes
        </div>
        <span className='seperator'>{'>'}</span>
        <span className='currentElement'>
          {quizData?.data.name}
        </span>
      </span>
    ))()}
    >
      <LayoutContainer>
        <div className='quiz-detail-page'>
          <BasicInformationCard
            className='mb-16'
            quizCode={quizData?.data.code}
            quizName={quizData?.data.name}
            nameOfClass={returnQuizClassName(classYearList?.data, quizData?.data.class_year_id)}
            questionCount={quizData?.data.questions.length}
            createdBy={`${user?.first_name} ${user?.last_name}`}
            editQuizHandler={editQuizHandler}
          />
          <BasicCardContainer className='px-0'>
            <div className='quiz-detail-page-questions'>
              <QuizDetailHeader quizId={id} activeTab={activeTab} setActiveTab={(tab: string): void => { activeTabHanlder(tab); }} />
              {activeTab === 'question'
                ? (
                  <div className='quiz-detail-page-questions-body'>
                    {quizData?.data && quizData.data.questions.length > 0 ? quizData.data.questions.map((question, i) => {
                      const questionType = returnQuestionType(question.type_id, questionTypeList?.data);
                      if (questionType === 'Multiple Choice') {
                        return (
                          <BasicCardContainer key={question._id} className='no-shadow mb-16'>
                            <McqQuestion
                              index={i + 1}
                              data={question}
                              viewMode={!!role && role === 'admin'}
                            />
                          </BasicCardContainer>
                        );
                      }

                      return (
                        <BasicCardContainer key={question._id} className='no-shadow mb-16'>
                          <EquationQuestion
                            body={question.body}
                            answer={question.answer}
                            questionImage={question.image_url}
                            orderNum={question.order_num}
                            title={question.statement}
                            questionCode={question._id}
                            viewMode={role === 'admin'}
                          />
                        </BasicCardContainer>
                      );
                    })
                      : (<span>No question is added!</span>)}
                  </div>
                )
                : (
                  <div className='quiz-detail-page-hint-body'>
                    { hintData.length === 0
                      ? <span>No hint is added!</span>
                      : hintData.map((hint) => (
                        <BasicCardContainer key={hint._id} className='mb-16'>
                          <HintContainer data={hint} />
                        </BasicCardContainer>
                      ))}
                  </div>
                ) }
            </div>
          </BasicCardContainer>
        </div>
      </LayoutContainer>
    </NavbarWrapper>
  );
}
