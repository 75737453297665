import React from 'react';
import clsx from 'clsx';
import 'styles/components/shared/molecules/Card/main.scss';
import { textTrimmer } from 'utils/helper';

interface CardDataProps {
  preTitle?: JSX.Element | number | string | null | undefined;
  title: string;
  subHeading?: JSX.Element | number | string | undefined;
  detail?: JSX.Element | number | string | undefined;
  styles?: React.CSSProperties | string | undefined;
  gutter?: boolean;
  gutterLeft?: boolean;
  gutterRight?: boolean;
  gutterTop?: boolean;
  gutterBottom?: boolean;
  code?: string;
}

function BasicCard({
  preTitle,
  title,
  subHeading,
  detail,
  styles,
  gutter,
  gutterBottom,
  gutterTop,
  gutterLeft,
  gutterRight,
  code,
}: CardDataProps): JSX.Element {
  return (
    <div className={clsx('cardRoot', gutter && 'basicCardGutter', gutterTop && 'cardGutterTop', gutterBottom && 'cardGutterBottom', gutterLeft && 'cardGutterLeft', gutterRight && 'cardGutterRight', styles)}>
      <div>{preTitle}</div>
      <h4 className='cardHeading'>{textTrimmer(title, 26)}</h4>
      <p>{subHeading}</p>
      <p>{detail}</p>
      <div className='cardSubSection'>
        <p className='cardSubText'>Please share this code with students</p>
        <h3>{code ?? 'Not available'}</h3>
      </div>
    </div>
  );
}

export default BasicCard;
