/* eslint-disable no-nested-ternary */
import React from 'react';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import ImageIcon from 'assets/svgs/ts/ImageIcon';
import EquationIcon from 'assets/svgs/ts/EquationIcon';
import type { Choice } from 'utils/types';
import CloseIcon from '@mui/icons-material/Close';
import ImageUploadButton from 'components/shared/atoms/button/ImageUploadButton';
import { returnQuestionOptionNumber } from 'utils/helper';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import clsx from 'clsx';
import MCQTextField from 'components/shared/atoms/inputField/MCQTextField';
import MCQEquationField from 'components/shared/atoms/inputField/MCQEquationField';
import CheckMarkIcon from 'assets/svgs/ts/CheckMarkIcon';

interface MCQChoiceContainerProps {
  data: Choice;
  index: number;
  deleteChoice: (choiceId: string) => void;
  choiceImageHandler: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
  answer?: string;
  selectAnswerHandler: (choice: string) => void;
  onChoiceTextChange: (value: string, choiceId: string) => void;
  onChoiceTypeChange: (choiceId: string, type: string) => void;
}

export default function MCQChoiceContainer(props: MCQChoiceContainerProps): JSX.Element {
  const {
    index, data, deleteChoice, choiceImageHandler, answer, selectAnswerHandler, onChoiceTypeChange,
    onChoiceTextChange,
  } = props;

  const choiceHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    choiceImageHandler(e, data.key);
  };
  const deleteChoiceHandler = (e: { stopPropagation: () => void }): void => {
    const { key } = data;
    e.stopPropagation();
    deleteChoice(key);
  };
  const selectAnswer = (): void => {
    const { key } = data;
    if (data.type !== '') {
      selectAnswerHandler(key);
    }
  };
  const onTextChange = (value: string): void => {
    onChoiceTextChange(value, data.key);
  };

  const choiceTypeHandler = (type: string): void => {
    onChoiceTypeChange(data.key, type);
  };

  return (
    <div className='mcq-choice-container'>
      <div className='choice-id'>{returnQuestionOptionNumber(index)}</div>
      <div role='presentation' onClick={selectAnswer} className={clsx('mcq-choice-state', data.type, data.type === '' && 'dont-show-selector-ring', answer === data.key && 'active')}>
        {data.type === ''
          ? (
            <div className='before-state-selection'>
              <ImageUploadButton
                startIcon={<ImageIcon className='fill-mono-dull-light' />}
                size='small'
                type='outlined'
                text='Add Image'
                onClick={choiceHandler}
                className='w-150'
              />
              <ButtonComponent
                startIcon={<TextFieldsIcon className='fill-mono-dull-light' />}
                size='small'
                type='outlined'
                text='Add Text Field'
                className='w-150 my-10'
                onClick={(): void => { choiceTypeHandler('text'); }}
              />
              <ButtonComponent
                startIcon={<EquationIcon className='fill-mono-dull-light' />}
                size='small'
                type='outlined'
                text='Add Equation'
                className='w-150'
                onClick={(): void => { choiceTypeHandler('equation'); }}
              />
            </div>
          )
          : data.type === 'text'
            ? (
              <div className='mt-35 w-100'>
                <MCQTextField state={data.state} onChangeHandler={onTextChange} />
              </div>
            )

            : data.type === 'equation'
              ? (
                <div className='mt-35 w-100'>
                  <MCQEquationField initLatex={data.state} onChangeHandler={onTextChange} />
                </div>
              )
              : (
                <div
                  className='choice-image-container'
                  style={{ backgroundImage: `url(${data.image_url ? `${process.env.REACT_APP_BACKEND_URL}${data.image_url}` : `${data.image?.image_extension},${data.image?.file_content}`})` }}
                />
              )}
        {answer === data.key
         && (
           <div className='mcq-choice-state-check-mark'>
             <CheckMarkIcon />
           </div>
         )}
        <div className='mcq-choice-state-close-icon' role='presentation' onClick={deleteChoiceHandler}>
          <CloseIcon />
        </div>
      </div>

    </div>
  );
}
