/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useRef } from 'react';
import Grid from '@mui/material/Grid';

import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import PasswordInputField from 'components/shared/atoms/inputField/PasswordInputField';
import InputField from 'components/shared/atoms/inputField/InputField';
import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import SelectField from 'components/shared/atoms/SelectField/SelectField';
import MQStaticField from 'components/shared/atoms/MathQuill/MQStaticField';

export default function TempComponents(): JSX.Element {
  const EditorBoxRef = useRef<any>(null);
  const staticEqRef = useRef<any>(null);
  // const latex = '1+\\embed{answer}[0-rise-f300f3]\\cdot \\embed{answer}[1-fall-0033f3]';
  const latex1 = '1+\\embed{answer}[0--]\\cdot \\embed{answer}[1--]';
  // const latex2 = '1+\\embed{answer}[0]\\cdot \\embed{answer}[1]';
  const fractionLatex = '\\left[\\left(\\left\\{\\frac{\\embed{answer}[3--]}{\\embed{answer}[4--]}\\times\\frac{\\embed{answer}[5--]}{\\embed{answer}[6--]}\\right\\}\\right)\\right]';
  const answers = [{ id: 5, answer: 5 }, { id: 6, answer: 8 }, { id: 3, answer: 8 }, { id: 4, answer: 8 }];

  return (
    <Grid container spacing={4} style={{ padding: '20px' }}>
      <Grid item lg={4}>
        <MQStaticField isAdminStaticField latex={fractionLatex} answers={answers} readOnly />
      </Grid>
      <Grid item lg={4}>
        <MQStaticField ref={staticEqRef} latex={latex1} answers={[]} />
      </Grid>
      <Grid item lg={4}>
        <ButtonComponent type='contained' text='Primary Button' />
        <ButtonComponent type='contained' text='Logout' />
      </Grid>
      <Grid item lg={5}>
        <PasswordInputField name='password' variant='outlined' text='Type you password' value='' />
      </Grid>
      <Grid item lg={8} />
      <Grid
        item
        xs={4}
        style={{
          background: '#3c57f0', margin: '16px', padding: '16px', borderRadius: '10px',
        }}
      >
        <ButtonComponent type='outlined' text='Secondary Button' />
      </Grid>
      <Grid item lg={4}>
        <InputField name='email' type='email' variant='outlined' text='Type you email' value='' />
      </Grid>
      <Grid item lg={12}>
        <ButtonComponent
          className='mx-10'
          type='contained'
          text='Get Static Answers'
          onClick={(): void => { console.log(staticEqRef.current.getInputAnswers()); }}
        />
        <ButtonComponent className='equation-field-editor' type='contained' text='Insert Equation' onClick={(): void => { EditorBoxRef.current.inserInputHandler(1); }} />
        <ButtonComponent className='input-field-editor mx-10' type='contained' text='Insert Input' onClick={(): void => { EditorBoxRef.current.inserInputHandler(2); }} />
        <ButtonComponent type='contained' text='Insert Line break' onClick={(): void => { EditorBoxRef.current.inserInputHandler(3); }} />
        <ButtonComponent type='contained' text='Insert Field with unit' onClick={(): void => { EditorBoxRef.current.inserInputHandler(4, 'cm'); }} />
        <ButtonComponent
          className='mx-10'
          type='contained'
          text='Add solvable'
          onClick={(): void => { EditorBoxRef.current.addAnswerField(false); }}
        />
        <ButtonComponent
          className='mx-10'
          type='contained'
          text='Get Answers'
          onClick={(): void => { EditorBoxRef.current.getAnswerField(); }}
        />
        <ButtonComponent
          className='mx-10'
          type='contained'
          text='+'
          onClick={(): void => { EditorBoxRef.current.commandFromToolbar('+'); }}
        />
      </Grid>
      <Grid item lg={8} />
      <Grid item lg={4}>
        <SelectField state='9th' options={[{ value: '9th' }, { value: '8th' }, { value: '7th' }]} label='Class' />
      </Grid>
      <Grid item lg={4}>
        <InputField name='quiz-name' type='text' variant='outlined' label='Quiz Name' value='' />
      </Grid>
      <Grid item lg={6}>
        <BasicCardContainer>
          <div className='label-style-bold'>Basic information</div>
          <InputField name='quiz-name' type='text' variant='outlined' label='Quiz Name' value='' containerClassName='mt-24' />
        </BasicCardContainer>
      </Grid>
    </Grid>
  );
}
