import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Table } from 'components/shared/molecules/Table';
import { preprocessReports, teacherQuizReportColumns } from 'utils/tableDataPreprocessing';
import type { IQuizReportsPreprocessing } from 'utils/types';
import { useReportsByClassQuery } from 'store/ApiCall/reportApiCalls';
import CircularLoader from '../Loader';

export default function QuizReportsByClass({ classId }: { classId: string | undefined }): JSX.Element {
  const [reports, setReports] = useState<IQuizReportsPreprocessing[]>([]);
  const navigate = useNavigate();

  const { data: dataReport, isLoading } = useReportsByClassQuery({ classId }, { skip: classId === undefined });

  useEffect(() => {
    setReports(preprocessReports(dataReport ? dataReport.data : []));
  }, [dataReport]);

  const goToDetailPage = (quizId: string): void => {
    navigate(`quiz/${quizId}/report-summary`);
  };

  return (
    <div className='tableContainer reportsTable' style={{ padding: 0, margin: 0 }}>
      <div className='tableInner'>
        {isLoading ? <CircularLoader /> : [reports.length > 0 ? <Table data={reports} columns={teacherQuizReportColumns} onClick={(id): void => { goToDetailPage(id); }} /> : (
          <div className='noRequestBlock text-center'>
            <span>No reports found</span>
          </div>
        )]}
      </div>
    </div>
  );
}
