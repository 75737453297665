import type { SliceCaseReducers } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { IUser, StoreActionPayload } from 'utils/types';

export interface IAuthSlice {
  user: IUser | null;
  token: string | null;
}
export interface ReducersType {
  signinToStore: (state: IAuthSlice, { payload }: { payload: IAuthSlice }) => IAuthSlice;
  signoutToStore: (state: IAuthSlice) => IAuthSlice;
}

const initialState = {
  user: null,
  token: null,
};

const authSlice = createSlice<IAuthSlice, SliceCaseReducers<IAuthSlice>>({
  initialState,
  name: 'auth-slice',
  reducers: {
    signinToStore: (state, action: StoreActionPayload<IUser | null>) => ({ ...state, user: action.payload }),

    signoutToStore: () => {
      // Note: This is left intentionally empty.
      // Clearing redux state happens in store.ts.
    },
  },
});

export const { signinToStore, signoutToStore } = authSlice.actions;

export default authSlice.reducer;
