import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import NavbarWrapper from 'components/layout/NavbarWrapper';
import Summary from 'components/shared/molecules/StudentAttemptDetailReport/Summary';
import QuizResponse from 'components/shared/molecules/StudentAttemptDetailReport/QuizResponse';
import { useStudentQuizReportQuery } from 'store/ApiCall/reportApiCalls';
import CircularLoader from 'components/shared/molecules/Loader';
import { useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';
import { useGeTeacherClassByIdQuery } from 'store/ApiCall/classesApiCall';

export default function StudentAttemptDetailReport(): JSX.Element {
  const navigate = useNavigate();
  const { quizId, studentId, classId } = useParams();

  // API call for student quiz summary
  const { data: studentSummary, isLoading } = useStudentQuizReportQuery({ quizId, studentId });
  // Get Question type
  const { data: questionTypeList, isLoading: isGettingQuestionTypes } = useGetQuestionTypeListQuery();
  // API call for class information
  const { data, isLoading: isGettingClassInformation } = useGeTeacherClassByIdQuery(classId, { skip: classId === undefined });

  if (isLoading || isGettingQuestionTypes || isGettingClassInformation) return <CircularLoader />;

  return (
    <NavbarWrapper name={((): JSX.Element => (
      <span className='navbarTitle'>
        <div
          className='linkElement'
          role='presentation'
          onClick={(): void => { navigate(classId ? -3 : -2); }}
        >
          {classId ? 'My Classes' : 'Reports'}
        </div>
        <span className='seperator'>{'>'}</span>
        <div
          className='linkElement'
          role='presentation'
          onClick={(): void => { navigate(classId ? -2 : -1); }}
        >
          {classId ? data?.data.class.name : studentSummary?.data.quiz.name}
        </div>
        <span className='seperator'>{'>'}</span>
        {classId
          ? (
            <>
              <div
                className='linkElement'
                role='presentation'
                onClick={(): void => { navigate(-1); }}
              >
                {studentSummary?.data.quiz.name}
              </div>
              <span className='seperator'>{'>'}</span>
              <span className='currentElement'>
                {studentSummary?.data.summary.user.student_name}
              </span>
            </>
          )
          : (
            <span className='currentElement'>
              {studentSummary?.data.summary.user.student_name}
            </span>
          )}
      </span>
    ))()}
    >
      <div className='student-attempt-detail-report-page'>
        <div className='student-attempt-detail-report-page-left-box'>
          <QuizResponse
            data={studentSummary?.data.questions}
            questionType={questionTypeList?.data}
          />
        </div>
        <div className='student-attempt-detail-report-page-right-box'>
          <Summary
            data={studentSummary?.data.summary}
          />
        </div>
      </div>
    </NavbarWrapper>
  );
}
