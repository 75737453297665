/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'store/ApiCall/baseQuery';
import type {
  CreatQuizResponse,
  QuestionState,
  ClassYearResponse,
  QuestionTypeResponse,
  GetQuizByIdResponse,
  GetAllClassQuizList,
  ClassQuizListType,
  ReorderClassQuizListType,
  ReorderDataType,
  DeleteApiResponse,
} from 'utils/types';

export const quizzesApi = createApi({
  reducerPath: 'quizzesApi',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['quizDetail', 'quizzesList', 'deleteQuiz'],
  endpoints: (builder) => ({
    createQuiz: builder.mutation<CreatQuizResponse, QuestionState>({
      query: (body) => ({
        url: '/api/quiz/save',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['quizDetail', 'quizzesList'],
    }),
    getQuizById: builder.mutation<GetQuizByIdResponse, string | void>({
      query: (quizId) => ({
        url: `/api/quiz/get/${quizId}`,
        method: 'POST',
      }),
    }),
    getClassYearList: builder.query<ClassYearResponse, void>({
      query: () => ({
        url: '/api/class/years',
        method: 'GET',
      }),
    }),
    getQuestionTypeList: builder.query<QuestionTypeResponse, void>({
      query: () => ({
        url: '/api/question/types',
        method: 'GET',
      }),
    }),
    getAllClassQuizList: builder.query<GetAllClassQuizList, void>({
      query: () => ({
        url: '/api/class/class-years',
        method: 'GET',
      }),
      providesTags: ['quizzesList'],
    }),
    getClassQuizList: builder.query<ClassQuizListType, string>({
      query: (id) => ({
        url: `/api/quiz/list/${id}`,
        method: 'GET',
      }),
      providesTags: ['quizzesList'],
    }),
    reorderQuizList: builder.mutation<ReorderClassQuizListType, ReorderDataType>({
      query: (body) => ({
        url: 'api/quiz/reorder',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['quizzesList'],
    }),
    getQuizDetail: builder.query<GetQuizByIdResponse, string | void>({
      query: (quizId) => ({
        url: `/api/quiz/get/${quizId}`,
        method: 'POST',
      }),
      providesTags: ['quizDetail'],
    }),
    // TODO: update type and method
    duplicateQuiz: builder.mutation<GetQuizByIdResponse, string | void>({
      query: (quizId) => ({
        url: `/api/quiz/duplicate/${quizId}`,
        method: 'GET',
      }),
      invalidatesTags: ['quizzesList'],
    }),
    deleteQuiz: builder.mutation<DeleteApiResponse, string | void>({
      query: (quizId) => ({
        url: `/api/quiz/delete/${quizId}`,
        method: 'GET',
      }),
      invalidatesTags: ['quizzesList'],
    }),
  }),
});

export const {
  useCreateQuizMutation,
  useGetClassYearListQuery,
  useGetQuestionTypeListQuery,
  useGetQuizByIdMutation,
  useGetAllClassQuizListQuery,
  useGetClassQuizListQuery,
  useReorderQuizListMutation,
  useGetQuizDetailQuery,
  useDuplicateQuizMutation,
  useDeleteQuizMutation,
} = quizzesApi;
