/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Grid,
  MenuItem,
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import { useAddClassMutation, useGetAllClassQuery } from 'store/ApiCall/classesApiCall';
import CrossIcon from 'assets/svgs/ts/CrossIcon';
import type { FailureResponseType } from 'utils/types';
import 'styles/components/shared/molecules/AddClassModal/main.scss';

interface ModalProps {
  isOpen: boolean;
  onCloseHandler: () => void;
}

const FormValidation = yup.object({
  className: yup
    .string()
    .min(3, 'Class Name cannot be less then 3 characters')
    .max(50, 'Class Name cannot be more then 50 characters')
    .matches(/^(?!\s+$).*/, 'Class Name cannot contain only blank spaces')
    .required('Class Name is required'),
  classYear: yup
    .string()
    .required('Class Year is required'),
});

export default function AddClassModal({
  isOpen,
  onCloseHandler,
}: ModalProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [addClass, { isLoading: addClassLoader }] = useAddClassMutation();
  const { data } = useGetAllClassQuery();

  const formik = useFormik({
    initialValues: {
      className: '',
      classYear: '',
    },
    validationSchema: FormValidation,
    onSubmit: (body, { resetForm }): void => {
      if (!body.className || !body.classYear) return;
      addClass({
        name: body.className.trim(),
        class_year_id: body.classYear,
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar('Class added successfully!', { variant: 'success' });
          onCloseHandler();
          resetForm();
        })
        .catch((err: FailureResponseType) => {
          enqueueSnackbar(err.data.message || 'Failed to add the class! Please refresh or try again later.', { variant: 'error' });
          onCloseHandler();
        });
    },
  });

  const modalCloseHandler = (): void => {
    onCloseHandler();
    formik.resetForm();
  };

  return (
    <Dialog open={isOpen} className='addClassDialogue'>
      <DialogActions className='addClassModalAction'>
        <button type='button' onClick={modalCloseHandler} className='closeBtn'>
          <span><CrossIcon /></span>
        </button>
      </DialogActions>

      <DialogContent className='modalTextBox'>
        <h2>Class Details</h2>
        <p>Enter your class details</p>
      </DialogContent>
      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={4} className='textfieldContainer'>
            <Grid item sm={12} className='addClassTextfield'>
              <TextField
                fullWidth
                variant='outlined'
                id='className'
                type='className'
                name='className'
                label='Class Name'
                value={formik.values.className}
                onChange={formik.handleChange}
                error={formik.touched.className && Boolean(formik.errors.className)}
                helperText={formik.touched.className && formik.errors.className}
                className='mb-22 addClassField'
              />
            </Grid>
            <Grid item sm={12} className='addClassTextfield'>
              <TextField
                fullWidth
                select
                variant='outlined'
                id='classYear'
                type='classYear'
                name='classYear'
                label='Class Year'
                value={formik.values.classYear}
                onChange={formik.handleChange}
                error={formik.touched.classYear && Boolean(formik.errors.classYear)}
                helperText={formik.touched.classYear && formik.errors.classYear}
                className='mb-22 addClassField'
              >
                {data ? data.data.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {`Year ${option.year}`}
                  </MenuItem>
                )) : null }
              </TextField>
            </Grid>
            <Grid item sm={12} className='buttonGridBox'>
              <ButtonComponent
                size='medium'
                className='w-82px addClassSubmitBtn'
                type='contained'
                text='Add Class'
                loading={addClassLoader}
                onClick={formik.handleSubmit}
                progressLoaderSize={20}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
