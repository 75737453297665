import clsx from 'clsx';
import React from 'react';

interface IconicTextProps {
  Icon: JSX.Element;
  text: string;
  className?: string;
  textClassName?: string;
  onClick?: () => void;
}
export default function IconicText(props: IconicTextProps): JSX.Element {
  const {
    Icon, text, className, textClassName, onClick,
  } = props;
  return (
    <div
      className={clsx('iconic-text', className)}
      role='presentation'
      onClick={onClick}
    >
      {Icon}
      <div className={clsx('iconic-text-box', textClassName)}>
        {text}
      </div>
    </div>
  );
}
