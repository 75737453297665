/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import NavbarWrapper from 'components/layout/NavbarWrapper';
import TabPanel from 'components/shared/atoms/TabPanel';
import TabButton from 'components/shared/atoms/button/TabButton';
import PieChart from 'assets/svgs/ts/PieChart';
import PresentationIcon from 'assets/svgs/ts/PresentationIcon';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import 'styles/components/pages/teacherClass.scss';
import ReportSummary from 'components/shared/molecules/AdminQuizSummary/ReportSummary';
import { useAdminReportSummaryQuery } from 'store/ApiCall/reportApiCalls';
import Loader from 'components/shared/molecules/Loader';
import { useGeTeacherClassByIdQuery } from 'store/ApiCall/classesApiCall';
import AggregatedResponses from 'components/pages/aggregatedResponsePage';
import TeacherAggregateResponse from 'components/pages/aggregatedResponsePage/TeacherAggregateResponse';

export default function QuizSummary({ role }: { role: string }): JSX.Element {
  const navigate = useNavigate();
  const { quizId = '', classId: classIdParam } = useParams();

  // Get the search parameters
  const [searchParams] = useSearchParams();

  // Access individual query parameters
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const classId = classIdParam || (searchParams.get('classId') ? searchParams.get('classId') : undefined);

  const { data: summaryData, isLoading, refetch } = useAdminReportSummaryQuery({ quizId, classId });
  // API call for class information
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  const { data, isLoading: isGettingClassInformation } = useGeTeacherClassByIdQuery(classId, { skip: classId === undefined });

  const [tabValue, setTabValue] = React.useState(1);

  const handleChange = (newValue: number): void => {
    setTabValue(newValue);
  };

  if (isLoading || isGettingClassInformation) {
    return <Loader />;
  }

  return (
    <div className='classPageRoot'>
      <NavbarWrapper name={((): JSX.Element => {
        if (role === 'admin') {
          return (
            <span className='navbarTitle'>
              <div className='linkElement' onClick={(): void => { navigate(-1); }}>
                Reports
              </div>
              {classId
                ? (
                  <>
                    <span className='seperator'>{'>'}</span>
                    <div>
                      {data?.data.class.name}
                    </div>
                  </>
                )
                : (
                  <>
                    <span className='seperator'>{'>'}</span>
                    <span>
                      {summaryData?.data.basic_info.quiz_name}
                    </span>
                  </>
                )}
            </span>
          );
        }
        return (
          <span className='navbarTitle'>
            <div className='linkElement' onClick={(): void => { navigate(-2); }}>
              {classId ? 'My Classes' : 'Reports'}
            </div>
            <span className='seperator'>{'>'}</span>
            {classId
              ? (
                <>
                  <div className='linkElement' onClick={(): void => { navigate(-1); }}>
                    {data?.data.class.name}
                  </div>
                  <span className='seperator'>{'>'}</span>
                  <span className='currentElement'>
                    {summaryData?.data.basic_info.quiz_name}
                  </span>
                </>
              )
              : (
                <span className='currentElement'>
                  {summaryData?.data.basic_info.quiz_name}
                </span>
              )}
          </span>
        );
      })()}
      >
        <div className='pageContent'>
          <div className='tabBar'>
            <Tabs onChange={(event, newValue: number): void => { handleChange(newValue); }} value={tabValue}>
              <Tab
                label={<TabButton icon={<PieChart className='fill-current-color' />} label='Report Summary' />}
                value={1}
                disableRipple
                className='muiTab'
              />
              <Tab
                label={<TabButton icon={<PresentationIcon className='fill-current-color' />} label='Aggregated Responses' />}
                value={2}
                disableRipple
                className='muiTab'
              />
            </Tabs>
          </div>
          <div className='pageContent-single-tab'>
            <TabPanel value={tabValue} index={1}>
              <ReportSummary
                refetch={refetch}
                summaryData={summaryData?.data}
              />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              {role === 'admin' ? <AggregatedResponses /> : <TeacherAggregateResponse /> }
            </TabPanel>
          </div>
        </div>
      </NavbarWrapper>
    </div>
  );
}
