import React from 'react';

export default function PresentActionIcon(): JSX.Element {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M8 16.4375C10.1217 16.4375 12.1566 15.5946 13.6569 14.0944C15.1571 12.5941 16 10.5592 16 8.4375C16 6.31577 15.1571 4.28094 13.6569 2.78065C12.1566 1.28035 10.1217 0.4375 8 0.4375C5.87827 0.4375 3.84344 1.28035 2.34315 2.78065C0.842855 4.28094 0 6.31577 0 8.4375C0 10.5592 0.842855 12.5941 2.34315 14.0944C3.84344 15.5946 5.87827 16.4375 8 16.4375ZM7.555 5.6055C7.4044 5.50502 7.22935 5.44731 7.04852 5.43853C6.86769 5.42974 6.68786 5.47022 6.52823 5.55563C6.3686 5.64104 6.23516 5.76818 6.14213 5.92349C6.04909 6.0788 5.99997 6.25646 6 6.4375V10.4375C5.99997 10.6185 6.04909 10.7962 6.14213 10.9515C6.23516 11.1068 6.3686 11.234 6.52823 11.3194C6.68786 11.4048 6.86769 11.4453 7.04852 11.4365C7.22935 11.4277 7.4044 11.37 7.555 11.2695L10.555 9.2695C10.692 9.17818 10.8043 9.05446 10.8819 8.90932C10.9596 8.76418 11.0002 8.60211 11.0002 8.4375C11.0002 8.27289 10.9596 8.11082 10.8819 7.96568C10.8043 7.82054 10.692 7.69682 10.555 7.6055L7.555 5.6055Z' fill='#868C9F' />
    </svg>

  );
}
