import React from 'react';
import {
  Dialog, DialogContent, DialogActions,
} from '@mui/material';
import clsx from 'clsx';
import InputField from 'components/shared/atoms/inputField/InputField';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import type { AnswerState } from 'utils/types';
import { HexColorPicker } from 'react-colorful';
import { checkSolvableAnswerValidation, checkEmptySpaces } from 'utils/helper';

interface MathQuillModalProps {
  isOpen: boolean;
  answerState: AnswerState;
  setAnswerHandler: (fieldName: string, value: string) => void;
  onCloseHandler: () => void;
  onSaveAnswer: () => void;
}

export default function MathQuillModal(props: MathQuillModalProps): JSX.Element {
  const {
    isOpen, answerState, onCloseHandler, setAnswerHandler, onSaveAnswer,
  } = props;

  const answerStateHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (checkEmptySpaces(value) || checkSolvableAnswerValidation(value)) return;
    setAnswerHandler(name, value);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && answerState.answer !== '') {
      onSaveAnswer();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent>
        <div className='math-quill-modal'>
          <div className='math-quill-modal-left-box'>
            <InputField
              autoComplete='off'
              label='Label'
              variant='outlined'
              type='text'
              name='label'
              className='mb-33'
              value={answerState.label}
              autoFocus
              onKeyDownHandler={onKeyDownHandler}
              onChange={answerStateHandler}
              fontColor={answerState.color}
            />
            <InputField
              autoComplete='off'
              label='Answer'
              variant='outlined'
              type='text'
              name='answer'
              className='mb-33'
              value={answerState.answer}
              onKeyDownHandler={onKeyDownHandler}
              onChange={answerStateHandler}
            />
          </div>
          <div className={clsx('math-quill-modal-right-box', answerState.label === '' && 'disabled')}>
            <HexColorPicker color={answerState.color} onChange={(color: string): void => { setAnswerHandler('color', color); }} />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          size='small'
          className='w-82px'
          type='contained'
          text='Save'
          disabled={answerState.answer === ''}
          onClick={onSaveAnswer}
        />
        <ButtonComponent
          size='small'
          className='w-82px bg-red'
          type='contained'
          text='Cancel'
          onClick={onCloseHandler}
        />
      </DialogActions>
    </Dialog>
  );
}
