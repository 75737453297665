/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { IUser, type ClassTeacherList, type FailureResponseType, type IClassTeacherPreprocessing } from 'utils/types';
import { useClassTeacherAddMutation, useClassTeacherDeleteMutation, useSchoolTeachersListQuery } from 'store/ApiCall/classesApiCall';
import {
  Dialog, DialogActions, DialogContent, Grid, IconButton, TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteActionIcon from 'assets/svgs/delete.svg';
import CrossIcon from 'assets/svgs/ts/CrossIcon';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import PlusIcon from 'assets/svgs/ts/PlusIcon';
import { useNavigate } from 'react-router-dom';
import { preprocessClassTeacherReports } from 'utils/tableDataPreprocessing';
import SelectField from 'components/shared/atoms/SelectField/SelectField';
import AutoCompleteField from 'components/shared/atoms/SelectField/AutoCompleteField';


interface ClassTeacher {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
}

interface Props {
  school_id: string;
  class_id: string;
  existing: string[];
}

export default function TeacherAccessAdd({ school_id, class_id, existing }: Props): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState<any>();
  const [teacher, setTeacher] = useState<any>('')
  const [openAddModal, setOpenAddModal] = React.useState<boolean>(false);
  const [addClassTeacher, { isLoading: addClassTeacherLoader }] = useClassTeacherAddMutation();
  const { data: dataReport, isLoading } = useSchoolTeachersListQuery({ class_id, school_id }, { skip: class_id === undefined });
  console.log(existing);
  const handleAdd = async () => {
    if (teacher.value && teacher.value !== '') {
      await addClassTeacher({
        teacher_id: teacher.value,
        school_id,
        class_id
      }).then(() => {
        enqueueSnackbar('Teacher added to class');
        setOpenAddModal(false)
      }).catch ((err: FailureResponseType) => {
        enqueueSnackbar('Unable to add teacher to class.');
        setOpenAddModal(false);
      })
    } else {
      enqueueSnackbar('Please select a teacher to add.');
    }
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')!));
  }, []);

  return (
    <div>
        <div>

          <div className={clsx('tableCell', 'actionsCell')}>
            <div className='actionCellInner'>
              <IconButton
                type='button'
                className='presentActionBtn'
                onClick={(): void => {
                  setOpenAddModal(true);
                }}
              >
                <PlusIcon />
              </IconButton>
            </div>
          </div>

          <Dialog open={openAddModal} className='addClassDialogue'>
            <DialogActions className='addClassModalAction'>
              <button
                type='button'
                onClick={(): void => {
                  setOpenAddModal(false);
                }}
                className='closeBtn'
              >
                <span><CrossIcon /></span>
              </button>
            </DialogActions>

            <DialogContent className='modalTextBox'>
              <h2>
                Add teacher from class
              </h2>
              <p style={{ fontSize: 14 }}>
                Please select a school teacher from the list you would like to add to the class.
              </p>
          </DialogContent>
          <DialogContent>
            <AutoCompleteField
              data={dataReport?.data.filter((teacher: ClassTeacher) => teacher._id !== user._id).filter((teacher: ClassTeacher) => !existing.includes(teacher._id)).map((teacher: ClassTeacher) => {
                return { label: teacher.first_name + ' ' + teacher.last_name + ' (' + teacher.email + ')', value: (teacher._id) }
              })}
              state={''}
              onChange={(e, a) => setTeacher(a!)}
              label='Add'
              placeholder=' '
            />
          </DialogContent>
            <DialogContent>
              <Grid container rowSpacing={4} className='textfieldContainer'>
                <Grid item sm={12} className='buttonGridBox'>
                  <ButtonComponent
                    size='medium'
                    className='w-82px addClassSubmitBtn'
                    type='contained'
                    text='Add'
                    loading={addClassTeacherLoader}
                    onClick={handleAdd}
                    progressLoaderSize={20}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
    </div>
  );
}
