import React, { useState } from 'react';

import { TextField, InputAdornment } from '@mui/material';
import clsx from 'clsx';
import CrossIcon from 'assets/svgs/ts/CrossIcon';
import PlusIcon from 'assets/svgs/ts/PlusIcon';

interface TagSelectorTextFieldProps {
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  name: string;
  className?: string;
  iconPosition: 'end' | 'start';
  iconOnClickHandler: () => void;
  containerClassName?: string;
}

export default function TagSelectorTextField(props: TagSelectorTextFieldProps): JSX.Element {
  const [focused, setFocused] = useState<boolean>(false);
  const {
    label, onChange, value, name, className, iconPosition, iconOnClickHandler, containerClassName,
  } = props;
  const onKeyDownHandler = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter') {
      (document.activeElement as HTMLElement).blur();
    }
  };
  const isSelected = value.length > 0 || focused;
  return (
    <div className={clsx('tag-selector-text-field', containerClassName)}>
      <div className='tag-selector-label label-style-normal mb-5'>{label}</div>
      <div className={clsx('tag-selector-text-field-box', isSelected && 'selected')}>
        <TextField
          name={name}
          type='text'
          variant='outlined'
          value={value}
          onChange={onChange}
          className={clsx(className)}
          placeholder={!isSelected ? 'Add tag' : undefined}
          onFocus={(): void => { setFocused(true); }}
          onBlur={(): void => { setFocused(false); }}
          autoComplete='off'
          onKeyDown={onKeyDownHandler}
          InputProps={{
            endAdornment:
  <InputAdornment
    position={iconPosition}
    onClick={iconOnClickHandler}
    style={{ cursor: 'pointer' }}
  >
    {isSelected ? <CrossIcon /> : <PlusIcon className='fill-mono-dull-light' />}
  </InputAdornment>,

          }}
        />
      </div>
    </div>
  );
}
