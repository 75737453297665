import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import type { Question } from 'utils/types';
import clsx from 'clsx';
import CircularLoader from '../Loader';

interface HintModalProps {
  data: Question | null;
  isOpen: boolean;
  onCloseHandler: () => void;
  loading?: boolean;
  showOnIncorrect?: boolean;
}

export default function HintModal(props: HintModalProps): JSX.Element {
  const {
    isOpen, onCloseHandler, data, loading, showOnIncorrect = false,
  } = props;

  return (
    <Dialog open={isOpen} className='hint-modal'>
      <DialogContent className={loading ? 'd-center' : ''}>
        {loading
          ? (<CircularLoader fullHeight />)
          : (
            <div className='contentContainer'>
              <div className='hint-modal-header'>

                <div className='hint-modal-title'>
                  {showOnIncorrect ? <span className='hint-modal-title-h2'>Your answer is incorrect. Try the hint.</span> : null}
                  <br />
                  <span className='hint-modal-title-h3'>
                    {data?.hint_title
                    && `${data.hint_title} 💡`}
                  </span>

                </div>
                <ButtonComponent
                  size='large'
                  className='bg-blue some-extra-padding h-48'
                  type='contained'
                  text='Got it'
                  onClick={onCloseHandler}
                />
              </div>

              <div className='hint-modal-body'>
                {data?.hint_body
                  && <div className={clsx('hint-modal-body-para', !data.hint_image_url && 'center')}>{data.hint_body}</div>}
                {data?.hint_image_url && (
                  <div className='hint-modal-body-image-container'>
                    <div
                      className='hint-modal-body-image'
                      style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.hint_image_url})` }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
      </DialogContent>
    </Dialog>
  );
}
