import React, {
  useImperativeHandle, forwardRef, useEffect, useState, useRef,
} from 'react';
import type { EditorInput } from 'utils/types';
import DeleteIcon from 'assets/svgs/delete.svg';
import clsx from 'clsx';
import { checkEmptySpaces, numberValidation } from 'utils/helper';

interface EditorTextFieldProps {
  onChangeHandler: (id: string, value: string) => void;
  onDelete?: (id: string) => void;
  component: EditorInput;
  setFocused?: (isFocused: boolean) => void;
  type: number;
}

const EditorTextField = forwardRef< unknown, EditorTextFieldProps>((props, ref): JSX.Element => {
  const {
    onChangeHandler, onDelete, component: {
      id, state, unit,
    }, setFocused, type,
  } = props;
  const [initialValue, setInitialValue] = useState<string>('');
  const EditorTextFieldRef = useRef<HTMLSpanElement | null>(null);

  useImperativeHandle(ref, () => ({
    type,
    getAnswers(): string {
      if (EditorTextFieldRef.current) {
        return EditorTextFieldRef.current.innerHTML;
      }
      return '';
    },

  }));

  useEffect(() => {
    if (state !== '') setInitialValue(state);
  }, []);

  const inputHandler = (val: string): void => {
    if (EditorTextFieldRef.current) {
      if (checkEmptySpaces(val)) {
        EditorTextFieldRef.current.innerHTML = '';
        return;
      }
      if (!numberValidation(val) && type === 4) {
        EditorTextFieldRef.current.innerHTML = val.slice(0, -1);
        // set the cursor at the end of string
        EditorTextFieldRef.current.focus();
        document.execCommand('selectAll', false, undefined);
        document.getSelection()?.collapseToEnd();
      }
      onChangeHandler(id, val);
    }
  };

  return (
    <div className={clsx('editor-input-field', type === 4 && 'type-4')}>
      <span
        contentEditable
        ref={EditorTextFieldRef}
        onInput={
          (event): void => {
            inputHandler(event.currentTarget.innerText);
          }
        }
        role='presentation'
        onKeyDown={(e): void => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        onFocus={(): void => { if (setFocused !== undefined) setFocused(true); }}
        onBlur={(): void => { if (setFocused !== undefined) setFocused(false); }}
        suppressContentEditableWarning

      >
        {initialValue}
      </span>
      {type === 4 && unit && <div className='type-4-unit-box'>{unit}</div>}
      {onDelete && <img src={DeleteIcon} alt='delete icon' role='presentation' onClick={(): void => { onDelete(id); }} />}
      <div className='label'>{type === 2 ? 'Text' : 'Answer'}</div>
    </div>
  );
});

export default EditorTextField;
