import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import InputField from 'components/shared/atoms/inputField/InputField';
import Logo from 'assets/svgs/logo.svg';
import { REQUIRED_FIELD } from 'utils/constant';
import { useSnackbar } from 'notistack';
import { useForgotPasswordMutation } from 'store/ApiCall/authApiCalls';

export interface ForgotPasswordError {
  email?: string;
}

function ForgotPassword(): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // Forgot password api handler
  const [forgotPasswordHandler, { isLoading }] = useForgotPasswordMutation();

  const [email, setEmail] = useState<string>('');

  const [fieldErrors, setFieldErrors] = useState<ForgotPasswordError>({});

  const emailValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.currentTarget.checkValidity()) {
        delete fieldErrors.email;
      } else {
        setFieldErrors({ ...fieldErrors, email: 'Please enter a valid email address.' });
      }
    } else {
      setFieldErrors({ ...fieldErrors, email: REQUIRED_FIELD });
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setEmail(value);
    if (name === 'email') { emailValidation(e); }
  };

  const isValidState = (): ForgotPasswordError => {
    let fieldError = {};
    if (email === '') {
      fieldError = { ...fieldError, email: REQUIRED_FIELD };
    }
    return fieldError;
  };

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const errorValidation = { ...isValidState() };
    if (Object.keys(errorValidation).length > 0) {
      setFieldErrors(errorValidation);
      return;
    }
    if (Object.keys(fieldErrors).length === 0) {
      try {
        const response = await forgotPasswordHandler({ email }).unwrap();
        if (response.success) {
          enqueueSnackbar(response.message, { variant: 'success' });
          navigate('/signin');
        }
      } catch (err) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    }
  };

  return (
    <form className='sign-in-page' onSubmit={onSubmitHandler}>
      <div className='left-box'>
        <div className='top-part'>
          <img className='logo' src={Logo} alt='app-log' />
          <div className='signin-textbox' />
        </div>
        <div className='bottom-part'>
          <h4>Remember your password?</h4>
          <div className='btn-box'>
            <ButtonComponent size='large' type='outlined' text='Sign in' onClick={(): void => { navigate('/signin'); }} />
          </div>
          <h6>
            copyright
            {' '}
            {new Date().getFullYear()}
            {' '}
            mathstarter.co.nz
          </h6>
        </div>
      </div>
      <div className='right-box'>
        <div className='sign-in-container'>
          <h1>Reset your password</h1>
          <h3 className='mb-40'>Please enter your school&apos;s email address</h3>
          <InputField
            variant='outlined'
            text='School&apos;s email address'
            type='email'
            name='email'
            className='mb-33'
            value={email}
            onChange={onChangeHandler}
            error={fieldErrors.email}

          />
          <ButtonComponent
            type='contained'
            text='Submit'
            className='w-100 white-loader'
            isSubmitBtn
            loading={isLoading}
          />
        </div>
      </div>
    </form>
  );
}

export default ForgotPassword;
