import React, { useState } from 'react';
import type { AxiosResponse, AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import InputField from 'components/shared/atoms/inputField/InputField';
import Logo from 'assets/svgs/sidebarLogoNewWhite.svg';
import PasswordInputField from 'components/shared/atoms/inputField/PasswordInputField';
import {
  signinAPICall,
  useSignUpCheckEmailExistingMutation,
  useSignUpWithQuizCodeMutation,
  verifyEmailAPICall,
} from 'store/ApiCall/authApiCalls';
import { signinToStore } from 'store/reducer/authSlice';
import { getDefaultPage, checkAuthenticated } from 'utils/helper';
import type { IUser, APIResponse, UserType } from 'utils/types';
import {Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import CloseIcon from 'assets/svgs/ts/CloseIcon';

const REQUIRED_FIELD = 'This field is required.';
const PASSWORD_MIN_LEN = 8;

export interface Email {
  address: string;
}
export interface Login {
  password: string;
}

export interface Register {
  first_name: string;
  last_name: string;
  quiz_code: string;
  password: string;
  confirm_password: string;
}

export interface EmailError {
  email?: string;
}

export interface LoginError {
  email?: string;
  password?: string;
}

export interface RegisterError {
  email?: string;
  quiz_code?: string;
  first_name?: string;
  last_name?: string;
  password?: string;
  confirm_password?: string;
}

export interface ILoginTypes {
  user: IUser;
  token: string;
}

function SignInPage(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState<string | null>(null);
  const [title, setTitle] = useState<string>('Sign in');
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [isRegister, setIsRegister] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [openIsVerifiedModal, setOpenIsVerifiedModal] = useState<boolean>(false);
  const [emailFieldError, setEmailFieldError] = useState<EmailError>({});
  const [loginFieldErrors, setLoginFieldErrors] = useState<LoginError>({});
  const [registerFieldErrors, setRegisterFieldErrors] = useState<RegisterError>({});
  const [email, setEmail] = useState<Email>({ address: '' });
  const [login, setLogin] = useState<Login>({ password: '' });
  const [register, setRegister] = useState<Register>({
    first_name: '',
    last_name: '',
    quiz_code: '',
    password: '',
    confirm_password: '',
  });
  const [signUpWithQuizCode, { isLoading: signUpWithQuizCodeCallLoader }] = useSignUpWithQuizCodeMutation();
  const [signUpCheckEmailExisting, { isLoading: signUpCheckEmailExistingCallLoader }] = useSignUpCheckEmailExistingMutation();

  React.useEffect(() => {
    const userObj = window.localStorage.getItem('user');
    if (userObj) {
      const user: UserType = JSON.parse(userObj);
      if (checkAuthenticated()) {
        navigate(getDefaultPage(user.role_id.name), { replace: true });
      }
    }
    // read query params
    const urlParams = new URLSearchParams(window.location.search);
    const paramToken = urlParams.get('token');
    setToken(paramToken);
  }, []);

  React.useEffect(() => {
    if (typeof token === 'string') {
      verifyEmailAPICall(token).then((res: AxiosResponse<APIResponse<null, null>>) => {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        navigate('/signin', { replace: true });
      }).catch((err: AxiosError<APIResponse<null, undefined>>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
        navigate('/signin', { replace: true });
      });
    }
  }, [token]);

  // ----------- Email ----------- //
  const checkExistingEmail = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setLoading(true);
    if (!email.address) {
      setIsLogin(false);
      setIsRegister(false);
      setTitle('Sign in');
    } else {
      const response = await signUpCheckEmailExisting({ email: email.address }).unwrap();
      if (response.success) {
        setIsLogin(true);
        setIsRegister(false);
        setTitle('Sign in');
      } else {
        setIsLogin(false);
        setIsRegister(true);
        setTitle('Register');
      }
    }
    setLoading(false);
  };

  const emailValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.currentTarget.checkValidity()) {
        delete emailFieldError.email;
      } else {
        setEmailFieldError({ ...emailFieldError, email: 'Please enter a valid email address.' });
      }
    } else {
      setEmailFieldError({ ...emailFieldError, email: REQUIRED_FIELD });
    }
  };

  const onEmailChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (value !== email.address) {
      setIsLogin(false);
      setIsRegister(false);
      setTitle('Sign in');
    }
    setEmail({ ...email, [name]: value });
    if (name === 'email') { emailValidation(e); }
  };

  // ----------- Login ----------- //
  const isLoginValidState = (): LoginError => {
    let loginFieldError = {};
    if (login.password === '') {
      loginFieldError = { ...loginFieldError, password: REQUIRED_FIELD };
    }
    return loginFieldError;
  };

  const loginPasswordValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (!value) {
      setLoginFieldErrors({ ...loginFieldErrors, password: REQUIRED_FIELD });
    } else if (value.length < 8) {
      setLoginFieldErrors({ ...loginFieldErrors, password: `Password length is less then ${PASSWORD_MIN_LEN} characters` });
    } else {
      delete loginFieldErrors.password;
    }
  };

  const onLoginChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
    if (name === 'password') { loginPasswordValidation(e); }
  };

  const onLoginSubmitHandler = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    const errorValidation = { ...isLoginValidState() };
    if (Object.keys(errorValidation).length > 0) {
      setLoginFieldErrors(errorValidation);
      return;
    }
    if (Object.keys(loginFieldErrors).length === 0) {
      // api calling
      setLoading(true);
      signinAPICall({ ...login, email: email.address }).then((res: AxiosResponse<APIResponse<ILoginTypes, undefined>>): void => {
        // TODO: we have call api for getting user on refresh and save user to redux.
        console.log(res);
        if (!res.data.data.user.is_verified && res.data.data.user.role_id.name === 'teacher') {
          setOpenIsVerifiedModal(true);
          setLoading(false);
        } else {
          window.localStorage.setItem('user', JSON.stringify(res.data.data.user));
          window.localStorage.setItem('token', res.data.data.token);
          dispatch(signinToStore(res.data.data.user));
          navigate(getDefaultPage(res.data.data.user.role_id.name), { replace: true });
          setLoading(false);
          navigate(getDefaultPage(res.data.data.user.role_id.name));
        }
      }).catch((err: AxiosError<APIResponse<null, LoginError>>) => {
        // handle error from backend
        if (err.response?.data.fieldErrors) {
          setLoginFieldErrors(err.response.data.fieldErrors);
        }
        if (err.response && !err.response.data.success && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, { variant: 'error' });
        }
        setLoading(false);
      });
    }
  };

  // ----------- Register ----------- //
  const isRegisterValidState = (): LoginError => {
    let registerFieldError = {};
    if (register.quiz_code === '') {
      registerFieldError = { ...registerFieldError, quiz_code: REQUIRED_FIELD };
    }
    if (register.first_name === '') {
      registerFieldError = { ...registerFieldError, fifirst_namerstName: REQUIRED_FIELD };
    }
    if (register.last_name === '') {
      registerFieldError = { ...registerFieldError, last_name: REQUIRED_FIELD };
    }
    if (register.password === '') {
      registerFieldError = { ...registerFieldError, password: REQUIRED_FIELD };
    }
    if (register.confirm_password === '') {
      registerFieldError = { ...registerFieldError, confirm_password: REQUIRED_FIELD };
    }
    return registerFieldError;
  };

  const registerQuizCodeValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.currentTarget.checkValidity()) {
        delete registerFieldErrors.password;
      } else {
        setRegisterFieldErrors({ ...registerFieldErrors, quiz_code: 'Please enter a valid quiz code.' });
      }
    } else {
      setRegisterFieldErrors({ ...registerFieldErrors, quiz_code: REQUIRED_FIELD });
    }
  };

  const registerFirstnameValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.currentTarget.checkValidity()) {
        delete registerFieldErrors.first_name;
      } else {
        setRegisterFieldErrors({ ...registerFieldErrors, first_name: 'Please enter a valid first name.' });
      }
    } else {
      setRegisterFieldErrors({ ...registerFieldErrors, first_name: REQUIRED_FIELD });
    }
  };

  const registerLastnameValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.currentTarget.checkValidity()) {
        delete registerFieldErrors.last_name;
      } else {
        setRegisterFieldErrors({ ...registerFieldErrors, last_name: 'Please enter a valid last name.' });
      }
    } else {
      setRegisterFieldErrors({ ...registerFieldErrors, last_name: REQUIRED_FIELD });
    }
  };

  const registerPasswordValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.target.checkValidity()) {
        delete registerFieldErrors.password;
      } else {
        setRegisterFieldErrors({ ...registerFieldErrors, password: 'Please enter a valid password.' });
      }
    } else if (value.length < 8) {
      setRegisterFieldErrors({ ...registerFieldErrors, password: `Password length is less then ${PASSWORD_MIN_LEN} characters` });
    } else {
      setRegisterFieldErrors({ ...registerFieldErrors, password: REQUIRED_FIELD });
    }
  };

  const registerConfirmPasswordValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.target.checkValidity()) {
        delete registerFieldErrors.confirm_password;
      } else {
        setRegisterFieldErrors({ ...registerFieldErrors, password: 'Please confirm your password.' });
      }
    } else if (value !== registerFieldErrors.password) {
      setRegisterFieldErrors({ ...registerFieldErrors, confirm_password: 'Password does not match.' });
    } else {
      setRegisterFieldErrors({ ...registerFieldErrors, confirm_password: REQUIRED_FIELD });
    }
  };

  const onRegisterChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setRegister({ ...register, [name]: value });
    if (name === 'quiz_code') { registerQuizCodeValidation(e); }
    if (name === 'first_name') { registerFirstnameValidation(e); }
    if (name === 'last_name') { registerLastnameValidation(e); }
    if (name === 'password') { registerPasswordValidation(e); }
    if (name === 'confirm_password') { registerConfirmPasswordValidation(e); }
  };

  const onRegisterHandler = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const errorValidation = { ...isRegisterValidState() };
    if (Object.keys(errorValidation).length > 0) {
      setLoginFieldErrors(errorValidation);
    }
    if (Object.keys(registerFieldErrors).length === 0) {
      setLoading(true);
      const response = await signUpWithQuizCode({ ...register, email: email.address }).unwrap();
      // TODO: we have call api for getting user on refresh and save user to redux.
      if (response.success) {
        const { token: newToken } = response.data;
        const roleIdName: string = response.data.user.role_id.name;
        window.localStorage.setItem('user', JSON.stringify(response.data.user));
        window.localStorage.setItem('token', newToken);
        dispatch(signinToStore(response.data.user));
        navigate(getDefaultPage(roleIdName), { replace: true });
        navigate(getDefaultPage(roleIdName));
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
      setLoading(false);
    }
  };

  return (
    // eslint-disable-next-line no-nested-ternary
    <div>
      <form className='sign-in-page' onSubmit={isLogin ? onLoginSubmitHandler : isRegister ? onRegisterHandler : checkExistingEmail}>
        <div className='left-box'>
          <div className='top-part'>
            <img className='logo' src={Logo} alt='app-log' />
            <div className='signin-textbox'>
              <h2>
                Designed specifically for New Zealand teachers to assist in the development of students basic knowledge.
              </h2>
              <h2>
                MathStarter covers the entire New Zealand curriculum in each session.
              </h2>
              <h2>
                Through design MathStarter simplifies maths concepts allowing all students to achieve.
              </h2>
            </div>
          </div>
          <div className='bottom-part'>
            {/* <h4>Don&apos;t have an account?</h4> */}
            <h4>Are you a teacher?</h4>
            <div className='btn-box'>
              <ButtonComponent size='large' type='outlined' text='Register as a Teacher' onClick={(): void => { navigate('/signup/teacher'); }} />
            </div>
            <h6>
              copyright
              {' '}
              {new Date().getFullYear()}
              {' '}
              mathstarter.co.nz
            </h6>
          </div>
        </div>
        <div className='right-box'>
          <div className='sign-in-container'>
            <h1>{title}</h1>
            <h3 className='mb-40'>{ `Please enter your ${isRegister ? 'information' : 'school email'}` }</h3>
            <InputField
              variant='outlined'
              text='Email address'
              type='email'
              name='address'
              className='mb-33'
              value={email.address}
              onChange={onEmailChangeHandler}
              error={emailFieldError.email}
            />
            {isLogin && (
              <PasswordInputField
                variant='outlined'
                text='Password'
                name='password'
                className='mb-40'
                value={login.password}
                onChange={onLoginChangeHandler}
                error={loginFieldErrors.password}
              />
            )}
            {isRegister && (
              <div>
                <InputField
                  variant='outlined'
                  text='Class Code'
                  type='text'
                  name='quiz_code'
                  className='mb-24 mt-35'
                  value={register.quiz_code}
                  onChange={onRegisterChangeHandler}
                  error={registerFieldErrors.quiz_code}
                />
                <Grid container sm={12} spacing={1} marginBottom={4}>
                  <Grid item sm={6}>
                    <InputField
                      variant='outlined'
                      type='text'
                      name='first_name'
                      text='First Name'
                      value={register.first_name}
                      onChange={onRegisterChangeHandler}
                      error={registerFieldErrors.first_name}
                      className='mb-22'
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <InputField
                      variant='outlined'
                      type='text'
                      name='last_name'
                      text='Last Name'
                      value={register.last_name}
                      onChange={onRegisterChangeHandler}
                      error={registerFieldErrors.last_name}
                      className='mb-22'
                    />
                  </Grid>
                </Grid>
                <PasswordInputField
                  variant='outlined'
                  text='Password'
                  name='password'
                  className='mb-40'
                  value={register.password}
                  onChange={onRegisterChangeHandler}
                  error={registerFieldErrors.password}
                />
                <PasswordInputField
                  variant='outlined'
                  text='Confirm Password'
                  name='confirm_password'
                  className='mb-40'
                  value={register.confirm_password}
                  onChange={onRegisterChangeHandler}
                  error={registerFieldErrors.confirm_password}
                />
              </div>
            )}

            <div className='btn-box'>
              <ButtonComponent
                type='contained'
                text={isLogin ? 'Log in' : isRegister ? 'Register' : 'Next'}
                className='mr-16 white-loader'
                isSubmitBtn
                loading={loading}
              />
              {/*  <ButtonComponent */}
              {/*    type='text' */}
              {/*    text='Join as a guest' */}
              {/*    className='white-loader' */}
              {/*    onClick={(): void => { navigate('/signupwithcode'); }} */}
              {/*    loading={loading} */}
              {/*  /> */}
            </div>
            {!isRegister && login.password !== '' && (
              <h5
                className='cursor-pointer'
                role='presentation'
                onClick={(): void => { navigate('/forgot-password'); }}
              >
                Forgot Password?
              </h5>
            )}
          </div>
        </div>
      </form>
      <Dialog open={openIsVerifiedModal} className='start-quiz-modal'>
        <DialogContent className='start-quiz-modal-information-container'>
          <div className='start-quiz-modal-information-container-header mb-10'>
            <div className='quiz-class-overview-page-text-2'>Thank you for creating an account.</div>
            <CloseIcon onClick={(): void => { setOpenIsVerifiedModal(false); }} />
          </div>
          <div className='quiz-class-overview-page-label-2 mb-10'>Please verify your email address to log in.</div>
        </DialogContent>
        <DialogActions>
          <div className='start-quiz-modal-footer'>
            <ButtonComponent
              text='Close'
              size='medium'
              className='bg-blue'
              type='contained'
              onClick={(): void => { setOpenIsVerifiedModal(false); }}
            />
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SignInPage;
