import React, { useState } from 'react';
import type { AxiosResponse, AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import InputField from 'components/shared/atoms/inputField/InputField';
import Logo from 'assets/svgs/logo.svg';
import PasswordInputField from 'components/shared/atoms/inputField/PasswordInputField';
import {
  verifyEmailAPICall,
  useSignUpWithQuizCodeMutation,
  useSignUpCheckEmailExistingMutation,
  signinAPICall,
} from 'store/ApiCall/authApiCalls';
import { signinToStore } from 'store/reducer/authSlice';
import { getDefaultPage, checkAuthenticated } from 'utils/helper';
import type { IUser, APIResponse, UserType } from 'utils/types';
import { Grid } from '@mui/material';

const REQUIRED_FIELD = 'This field is required.';
const PASSWORD_MIN_LEN = 8;

export interface LoginError {
  quiz_code?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  confirm_password?: string;
}

export interface ILoginTypes {
  user: IUser;
  token: string;
}

function SignupWithCode(): JSX.Element {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [token, setToken] = useState<string | null>(null);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
  const [isSignUp, setIsSignUp] = useState<boolean>(false);
  const [isSignIn, setIsSignIn] = useState<boolean>(false);
  const [signUpWithQuizCode, { isLoading: signUpWithQuizCodeCallLoader }] = useSignUpWithQuizCodeMutation();
  const [signUpCheckEmailExisting, { isLoading: signUpCheckEmailExistingCallLoader }] = useSignUpCheckEmailExistingMutation();

  React.useEffect(() => {
    const userObj = window.localStorage.getItem('user');
    if (userObj) {
      const user: UserType = JSON.parse(userObj);
      if (checkAuthenticated()) {
        navigate(getDefaultPage(user.role_id.name), { replace: true });
      }
    }
    // read query params
    const urlParams = new URLSearchParams(window.location.search);
    const paramToken = urlParams.get('token');
    setToken(paramToken);
  }, []);

  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState<{
    quiz_code: string;
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    confirm_password: string;
  }>({
    quiz_code: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    confirm_password: '',
  });
  const [fieldErrors, setFieldErrors] = useState<LoginError>({});

  React.useEffect(() => {
    if (typeof token === 'string') {
      verifyEmailAPICall(token).then((res: AxiosResponse<APIResponse<null, null>>) => {
        enqueueSnackbar(res.data.message, { variant: 'success' });
        navigate('/signin', { replace: true });
      }).catch((err: AxiosError<APIResponse<null, undefined>>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
        navigate('/signin', { replace: true });
      });
    }
  }, [token]);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const checkExistingEmail = async () => {
    if (!user.email) {
      setIsReadOnly(false);
      setIsSignUp(false);
      setIsSignIn(false);
    } else {
      const { email } = user;
      const response = await signUpCheckEmailExisting({ email }).unwrap();
      if (response.success) {
        setIsReadOnly(true);
        setIsSignUp(false);
        setIsSignIn(true);
      } else {
        setIsReadOnly(true);
        setIsSignIn(false);
        setIsSignUp(true);
      }
    }
    return null;
  };
  const quizCodeValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.currentTarget.checkValidity()) {
        delete fieldErrors.quiz_code;
      } else {
        setFieldErrors({ ...fieldErrors, quiz_code: 'Please enter a valid quiz code.' });
      }
    } else {
      setFieldErrors({ ...fieldErrors, quiz_code: REQUIRED_FIELD });
    }
  };

  const firstnameValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.currentTarget.checkValidity()) {
        delete fieldErrors.first_name;
      } else {
        setFieldErrors({ ...fieldErrors, first_name: 'Please enter a valid first name.' });
      }
    } else {
      setFieldErrors({ ...fieldErrors, first_name: REQUIRED_FIELD });
    }
  };

  const lastnameValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.currentTarget.checkValidity()) {
        delete fieldErrors.last_name;
      } else {
        setFieldErrors({ ...fieldErrors, last_name: 'Please enter a valid last name.' });
      }
    } else {
      setFieldErrors({ ...fieldErrors, last_name: REQUIRED_FIELD });
    }
  };

  const emailValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.currentTarget.checkValidity()) {
        delete fieldErrors.email;
      } else {
        setFieldErrors({ ...fieldErrors, email: 'Please enter a valid email address.' });
      }
    } else {
      setFieldErrors({ ...fieldErrors, email: REQUIRED_FIELD });
    }
  };

  const passwordValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.target.checkValidity()) {
        delete fieldErrors.password;
      } else {
        setFieldErrors({ ...fieldErrors, password: 'Please enter a valid password.' });
      }
    } else if (value.length < 8) {
      setFieldErrors({ ...fieldErrors, password: `Password length is less then ${PASSWORD_MIN_LEN} characters` });
    } else {
      setFieldErrors({ ...fieldErrors, password: REQUIRED_FIELD });
    }
  };

  const confirmPasswordValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (value) {
      if (event.target.checkValidity()) {
        delete fieldErrors.confirm_password;
      } else {
        setFieldErrors({ ...fieldErrors, password: 'Please confirm your password.' });
      }
    } else if (value !== user.password) {
      setFieldErrors({ ...fieldErrors, confirm_password: 'Password does not match.' });
    } else {
      setFieldErrors({ ...fieldErrors, confirm_password: REQUIRED_FIELD });
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
    if (name === 'quiz_code') { quizCodeValidation(e); }
    if (name === 'first_name') { firstnameValidation(e); }
    if (name === 'last_name') { lastnameValidation(e); }
    if (name === 'email') { emailValidation(e); }
    if (name === 'password') { passwordValidation(e); }
    if (name === 'confirm_password') { confirmPasswordValidation(e); }
  };

  const isValidState = (): LoginError => {
    let fieldError = {};
    if (user.quiz_code === '') {
      fieldError = { ...fieldError, quiz_code: REQUIRED_FIELD };
    }
    if (user.first_name === '') {
      fieldError = { ...fieldError, first_name: REQUIRED_FIELD };
    }
    if (user.last_name === '') {
      fieldError = { ...fieldError, last_name: REQUIRED_FIELD };
    }
    if (user.email === '') {
      fieldError = { ...fieldError, email: REQUIRED_FIELD };
    }
    if (user.password === '') {
      fieldError = { ...fieldError, password: REQUIRED_FIELD };
    }
    if (user.confirm_password === '') {
      fieldError = { ...fieldError, confirm_password: REQUIRED_FIELD };
    }
    return fieldError;
  };

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>): Promise<undefined> => {
    e.preventDefault();
    const errorValidation = { ...isValidState() };
    if (Object.keys(errorValidation).length > 0) {
      setFieldErrors(errorValidation);
      return;
    }
    if (Object.keys(fieldErrors).length === 0) {
      const response = await signUpWithQuizCode(user).unwrap();
      // TODO: we have call api for getting user on refresh and save user to redux.
      if (response.success) {
        const { token: newToken } = response.data;
        const roleIdName: string = response.data.user.role_id.name;
        window.localStorage.setItem('user', JSON.stringify(response.data.user));
        window.localStorage.setItem('token', newToken);
        dispatch(signinToStore(response.data.user));
        navigate(getDefaultPage(roleIdName), { replace: true });
        navigate(getDefaultPage(roleIdName));
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
  };
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onSignInHandler = () => {
    signinAPICall({ email: user.email, password: user.password }).then((res: AxiosResponse<APIResponse<ILoginTypes, undefined>>): void => {
      // TODO: we have call api for getting user on refresh and save user to redux.
      window.localStorage.setItem('user', JSON.stringify(res.data.data.user));
      window.localStorage.setItem('token', res.data.data.token);
      dispatch(signinToStore(res.data.data.user));
      navigate(getDefaultPage(res.data.data.user.role_id.name), { replace: true });
      navigate(getDefaultPage(res.data.data.user.role_id.name));
    }).catch((err: AxiosError<APIResponse<null, LoginError>>) => {
      // handle error from backend
      if (err.response?.data.fieldErrors) {
        setFieldErrors(err.response.data.fieldErrors);
      }
      if (err.response && !err.response.data.success && err.response.data.message) {
        enqueueSnackbar(err.response.data.message, { variant: 'error' });
      }
    });
  };
  return (
    <form className='sign-in-page' onSubmit={onSubmitHandler}>
      <div className='left-box'>
        <div className='top-part'>
          <img className='logo' src={Logo} alt='app-log' />
          <div className='signin-textbox'>
            <h2>
              Designed specifically for New Zealand teachers to assist in the development of students basic knowledge.
            </h2>
            <h2>
              MathStarter covers the entire New Zealand curriculum in each session.
            </h2>
            <h2>
              Through design MathStarter simplifies maths concepts allowing all students to achieve.
            </h2>
          </div>
        </div>
      </div>
      <div className='right-box'>
        <div className='sign-in-container'>
          <h1 className='mb-20'>
            { !isSignIn && !isSignUp ? 'Please enter your email address' : isSignIn ? 'Sign in' : 'Join as a guest' }
          </h1>
          <InputField
            variant='outlined'
            text='Email'
            type='email'
            name='email'
            className='mb-33'
            value={user.email}
            readonly={isReadOnly}
            onChange={onChangeHandler}
            error={fieldErrors.email}
          />
          {!isSignIn && !isSignUp && (
            <div className='btn-box'>
              <ButtonComponent
                type='contained'
                text='Next'
                className='mr-16 white-loader'
                onClick={() => { checkExistingEmail(); }}
                loading={signUpCheckEmailExistingCallLoader}
              />
            </div>
          )}
          <div className='existing-user-container' hidden={!isSignIn}>
            <PasswordInputField
              variant='outlined'
              text='Password'
              name='password'
              className='mb-40'
              value={user.password}
              onChange={onChangeHandler}
              error={fieldErrors.password}
            />
            <div className='btn-box'>
              <ButtonComponent
                type='contained'
                text='Sign In'
                className='mr-16 white-loader'
                onClick={() => { onSignInHandler(); }}
              />
            </div>
          </div>
          <div className='new-user-container' hidden={!isSignUp}>
            <InputField
              variant='outlined'
              text='Class Code'
              type='text'
              name='quiz_code'
              className='mb-24 mt-35'
              value={user.quiz_code}
              onChange={onChangeHandler}
              error={fieldErrors.quiz_code}
            />
            <Grid container sm={12} spacing={1} marginBottom={4}>
              <Grid item sm={6}>
                <InputField
                  variant='outlined'
                  type='text'
                  name='first_name'
                  text='First Name'
                  value={user.first_name}
                  onChange={onChangeHandler}
                  error={fieldErrors.first_name}
                  className='mb-22'
                />
              </Grid>
              <Grid item sm={6}>
                <InputField
                  variant='outlined'
                  type='text'
                  name='last_name'
                  text='Last Name'
                  value={user.last_name}
                  onChange={onChangeHandler}
                  error={fieldErrors.last_name}
                  className='mb-22'
                />
              </Grid>
            </Grid>
            <PasswordInputField
              variant='outlined'
              text='Password'
              name='password'
              className='mb-40'
              value={user.password}
              onChange={onChangeHandler}
              error={fieldErrors.password}

            />
            <PasswordInputField
              variant='outlined'
              text='Confirm Password'
              name='confirm_password'
              className='mb-40'
              value={user.confirm_password}
              onChange={onChangeHandler}
              error={fieldErrors.confirm_password}
            />
            <div className='btn-box'>
              <ButtonComponent
                type='contained'
                text='Sign Up'
                className='mr-16 white-loader'
                isSubmitBtn
                loading={signUpWithQuizCodeCallLoader}
              />
            </div>
          </div>
          {isSignIn && (
            <h5
              className='cursor-pointer'
              role='presentation'
              onClick={(): void => { navigate('/forgot-password'); }}
            >
              Forgot Password?
            </h5>
          )}
        </div>
      </div>
    </form>
  );
}

export default SignupWithCode;
