import React from 'react';
import type { SVGProps } from 'utils/types';

export default function ArrowRight(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0.293 9.707C0.105529 9.51947 0.000213623 9.26516 0.000213623 9C0.000213623 8.73483 0.105529 8.48052 0.293 8.293L3.586 5L0.293 1.707C0.110842 1.51839 0.0100473 1.26579 0.0123258 1.00359C0.0146042 0.741397 0.119773 0.490585 0.305182 0.305177C0.49059 0.119769 0.741402 0.0145995 1.0036 0.0123211C1.2658 0.0100427 1.5184 0.110838 1.707 0.292996L5.707 4.293C5.89447 4.48052 5.99979 4.73483 5.99979 5C5.99979 5.26516 5.89447 5.51947 5.707 5.707L1.707 9.707C1.51947 9.89447 1.26516 9.99978 1 9.99978C0.734836 9.99978 0.480528 9.89447 0.293 9.707Z' fill='#868C9F' />
    </svg>
  );
}
