import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

interface CircularLoaderProps {
  fullHeight?: boolean;
}

export default function CircularLoader(props: CircularLoaderProps): JSX.Element {
  const { fullHeight } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: fullHeight ? '100%' : '80vh',
      }}
    >
      <CircularProgress />
    </Box>
  );
}
