/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import CrossIcon from 'assets/svgs/ts/CrossIcon';
import { useDeleteQuizMutation } from 'store/ApiCall/quizzesApiCall';
import 'styles/components/shared/molecules/ConfirmationModal/main.scss';

interface ModalProps {
  isOpen: boolean;
  id: string;
  quizTitle?: string;
  quizAttempts?: number;
  onCloseHandler: (e: React.SyntheticEvent) => void;
}

export default function ConfirmationModal({
  isOpen,
  id: quizId,
  quizTitle,
  quizAttempts,
  onCloseHandler,
}: ModalProps): JSX.Element {
  const [deleteQuiz] = useDeleteQuizMutation();
  const { enqueueSnackbar } = useSnackbar();

  // ? delete action handler
  const deleteActionHandler = async (event: React.SyntheticEvent, qid: string): Promise<void> => {
    event.stopPropagation();
    try {
      const response = await deleteQuiz(`${qid}?confirm=true`).unwrap();

      if (response.success) {
        enqueueSnackbar(response.message, { variant: 'success' });
        onCloseHandler(event);
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
      onCloseHandler(event);
    }
  };

  return (
    <Dialog open={isOpen} className='confirmationModal'>
      <DialogActions className='confirmationModalAction'>
        <button type='button' onClick={(e: React.SyntheticEvent): void => { e.stopPropagation(); onCloseHandler(e); }} className='cModalCloseBtn'>
          <span><CrossIcon /></span>
        </button>
      </DialogActions>

      <DialogContent className='cModalTextBox'>
        <h4>{`Deleting Quiz: ${quizTitle}`}</h4>
        {quizAttempts
          ? (
            <p>
              <span className='warningSpan'>Warning</span>
              : This action will delete
              {' '}
              {quizAttempts}
              {' '}
              quiz attempt(s).
            </p>
          )
          : null}
      </DialogContent>
      <DialogContent className='cModalBtnBox'>
        <ButtonComponent type='outlined' text='Cancel' size='small' className='cModalCancelBtn' onClick={(e: React.SyntheticEvent): void => { e.stopPropagation(); onCloseHandler(e); }} />
        <ButtonComponent type='outlined' text='Delete Anyway' size='small' className='cModalDeleteBtn' onClick={(e: React.SyntheticEvent): void => { deleteActionHandler(e, quizId); }} />
      </DialogContent>
    </Dialog>
  );
}
