import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import Logo from 'assets/svgs/logo.svg';
import { REQUIRED_FIELD } from 'utils/constant';
import PasswordInputField from 'components/shared/atoms/inputField/PasswordInputField';
import { useSnackbar } from 'notistack';
import { useResetPasswordMutation } from 'store/ApiCall/authApiCalls';

export interface ResetPasswordError {
  password?: string;
  resetPassword?: string;
}

const PASSWORD_MIN_LEN = 8;

function ResetPassword(): JSX.Element {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const { enqueueSnackbar } = useSnackbar();

  const [state, setState] = useState<{ password: string;resetPassword: string }>({
    password: '',
    resetPassword: '',
  });

  const [fieldErrors, setFieldErrors] = useState<ResetPasswordError>({});

  // API handler for reset password
  const [resetPasswordHanlder, { isLoading }] = useResetPasswordMutation();

  const passwordValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (!value) {
      setFieldErrors({ ...fieldErrors, password: REQUIRED_FIELD });
    } else if (value.length < 8) {
      setFieldErrors({ ...fieldErrors, password: `Password length is less then ${PASSWORD_MIN_LEN} characters` });
    } else {
      delete fieldErrors.password;
    }
  };

  const resetPasswordValidation = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (!value) {
      setFieldErrors({ ...fieldErrors, resetPassword: REQUIRED_FIELD });
    } else if (value !== state.password) {
      setFieldErrors({ ...fieldErrors, resetPassword: 'Passwords do not match' });
    } else {
      delete fieldErrors.resetPassword;
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
    if (name === 'password') { passwordValidation(e); }
    if (name === 'resetPassword') { resetPasswordValidation(e); }
  };

  const isValidState = (): ResetPasswordError => {
    let fieldError = {};
    if (state.password === '') {
      fieldError = { ...fieldError, password: REQUIRED_FIELD };
    }
    if (state.resetPassword === '') {
      fieldError = { ...fieldError, resetPassword: REQUIRED_FIELD };
    }
    return fieldError;
  };

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    const errorValidation = { ...isValidState() };
    if (Object.keys(errorValidation).length > 0) {
      setFieldErrors(errorValidation);
      return;
    }
    if (Object.keys(fieldErrors).length === 0) {
      if (!token) {
        enqueueSnackbar('Invalid Reset Password link', { variant: 'error' });
        navigate('/signin');
        return;
      }
      const body = {
        password: state.password,
        confirm_password: state.resetPassword,
        token,
      };
      try {
        const response = await resetPasswordHanlder(body).unwrap();
        if (response.success) {
          enqueueSnackbar(response.message, { variant: 'success' });
          navigate('/signin');
        }
      } catch (err) {
        enqueueSnackbar('Password reset token expired!', { variant: 'error' });
      }
    }
  };

  return (
    <form className='sign-in-page' onSubmit={onSubmitHandler}>
      <div className='left-box'>
        <div className='top-part'>
          <img className='logo' src={Logo} alt='app-log' />
          <div className='signin-textbox' />
        </div>
        <div className='bottom-part'>
          <h4>Remember your password?</h4>
          <div className='btn-box'>
            <ButtonComponent size='large' type='outlined' text='Sign in' onClick={(): void => { navigate('/signin'); }} />
          </div>
          <h6>
            copyright
            {' '}
            {new Date().getFullYear()}
            {' '}
            mathstarter.co.nz
          </h6>
        </div>
      </div>
      <div className='right-box'>
        <div className='sign-in-container'>
          <h1>Please set a new password for your account</h1>
          <h3 className='mb-40'>Please set a new password for your account</h3>
          <PasswordInputField
            variant='outlined'
            text='Set Password'
            name='password'
            className='mb-40'
            value={state.password}
            onChange={onChangeHandler}
            error={fieldErrors.password}

          />
          <PasswordInputField
            variant='outlined'
            text='Retype Password'
            name='resetPassword'
            className='mb-40'
            value={state.resetPassword}
            onChange={onChangeHandler}
            error={fieldErrors.resetPassword}
          />
          <ButtonComponent
            type='contained'
            text='Submit'
            className='w-100 white-loader'
            isSubmitBtn
            loading={isLoading}
          />
        </div>
      </div>
    </form>
  );
}

export default ResetPassword;
