/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useRef, forwardRef, useImperativeHandle,
} from 'react';

import EditorComponent from 'components/shared/atoms/ReactMathQuill/EditorComponent';
import type { EditorInput, EquationAnswerType } from 'utils/types';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';
import { checkEmptySpaces } from 'utils/helper';

interface EditorBoxProps {
  className?: string;
  componentsList: any;
  answer: EquationAnswerType[];
  onEditorBoxChange: (boyd: EditorInput[]) => void;
  onAnswerFieldChange: (componentList: any, answer: string) => void;
  setComponentList: (arr: any) => void;
  editSolvableHandler: () => void;
}

function EditorBox(props: EditorBoxProps, ref: any): JSX.Element {
  const {
    className, answer, onEditorBoxChange,
    componentsList, setComponentList, onAnswerFieldChange, editSolvableHandler,
  } = props;

  const equationInputRef = useRef<any>([]);
  const focusedComponent = useRef<any>(null);
  const EditorBoxInputIndex = useRef<number>(0);

  const updateEditorBoxIndex = (id?: number): void => {
    if (id || id === 0) {
      if (id <= EditorBoxInputIndex.current) return;

      EditorBoxInputIndex.current = id;
    }
    EditorBoxInputIndex.current += 1;
  };

  const getAnswerHandler = (componentsListArr: EditorInput[]): string => {
    const answerArr: EquationAnswerType[] = [];
    componentsListArr.forEach((comp: any, i: number) => {
      if (comp.type === 1) {
        const answerList = equationInputRef.current[i]?.getAnswers();
        if (answerList && answerList.length > 0) {
          answerArr.push({
            id: comp.id, componentIndex: i, type: comp.type, answer: answerList,
          });
        }
      } else if (comp.type === 4) {
        const textAnswer = equationInputRef.current[i]?.getAnswers();
        answerArr.push({
          id: comp.id, componentIndex: i, type: comp.type, answer: textAnswer,
        });
      }
    });
    if (answerArr.length === 0) return '';
    return JSON.stringify(answerArr);
  };

  const updateAnswerIndex = (componentArr: any, answerArr: EquationAnswerType[]): any => {
    const filterAnswerArr: EquationAnswerType[] = [];
    if (answerArr.length > 0) {
      // remove the answer of deleted input fields
      answerArr.forEach((ans) => {
        const findAnswer = componentArr.findIndex((comp: any) => comp.id === ans.id);
        if (findAnswer !== -1) {
          filterAnswerArr.push(ans);
        }
      });
      // updated the componentIndex of answers
      filterAnswerArr.forEach((singleAnswer) => {
        const filterInputIndex = componentArr.findIndex((comp: any) => comp.id === singleAnswer.id);
        if (filterInputIndex !== -1) {
          singleAnswer.componentIndex = filterInputIndex;
        }
      });
      if (filterAnswerArr.length === 0) return '';
      return JSON.stringify(filterAnswerArr);
    }
    return '';
  };

  useImperativeHandle(ref, () => ({
    inserInputHandler(type: number, unit: string): EditorInput[] {
      const newComponentList = [...componentsList, {
        id: uuid(),
        state: '',
        type,
        unit,
        ...(type === 1 || type === 4) && { index: EditorBoxInputIndex.current },
      }];
      if (type === 4) {
        updateEditorBoxIndex();
      }
      setComponentList(newComponentList);
      return newComponentList;
    },
    addAnswerField(): void {
      if (focusedComponent.current !== null) {
        if (focusedComponent.current.type === 1) {
          focusedComponent.current.addAnswerField(EditorBoxInputIndex.current);
        }
      }
    },
    addFractionAnswerField(): void {
      if (focusedComponent.current !== null) {
        if (focusedComponent.current.type === 1) {
          focusedComponent.current.addFractionAnswerField(EditorBoxInputIndex.current);
        }
      }
    },
    getAnswerField(): string {
      if (componentsList.length === 0) return '';
      const answerArr = getAnswerHandler(componentsList);
      return answerArr;
    },
    commandFromToolbar(cmd: string): void {
      if (focusedComponent.current !== null) {
        if (focusedComponent.current.type === 1) focusedComponent.current.addCommand(cmd);
      }
    },
    getEquationList(): any {
      return componentsList;
    },
    setEquationList(list: EditorInput[]): void {
      setComponentList(list);
    },
  }));

  const onDelete = (id: string, index: number): void => {
    const componentArr = [...componentsList];
    const component = componentArr[index];
    const filteredComponents = componentArr.filter((el: any) => el.id !== id);
    // set the delete component ref to null
    if (component.type === 1 || component.type === 4) {
      equationInputRef.current[index] = null;
    }
    // set is_update true and updated the answer
    const updatedAnswerIndexArr = updateAnswerIndex(filteredComponents, answer);
    onAnswerFieldChange(filteredComponents, updatedAnswerIndexArr);
    focusedComponent.current = null;
    setComponentList([...filteredComponents]);
  };

  const onChange = (id: string, value: string): void => {
    // validation for spaces
    if (checkEmptySpaces(value)) return;
    const newCompList = [...componentsList];
    const componentIndex = newCompList.findIndex((el) => el.id === id);
    newCompList.splice(componentIndex, 1, { ...newCompList[componentIndex], state: value });
    // reset the answer field when equation field is update
    const componentType = newCompList[componentIndex].type;
    if (componentType === 1 || componentType === 4) {
      const answer = getAnswerHandler(newCompList);
      onAnswerFieldChange(newCompList, answer);
    } else {
      onEditorBoxChange(newCompList);
    }
    setComponentList([...newCompList]);
  };

  const setFocusedRef = (refFocusedComponent: any, index: number): void => {
    if (refFocusedComponent === null) {
      return;
    }
    focusedComponent.current = refFocusedComponent.current;
    if (refFocusedComponent.current.type === 1) {
      equationInputRef.current[index] = refFocusedComponent.current;
    }
  };

  const setEquationFieldRef = (refEquationField: any, index: number): void => {
    if (refEquationField && (refEquationField.current.type === 1 || refEquationField.current.type === 4)) {
      equationInputRef.current[index] = refEquationField.current;
    }
  };

  return (
    <div className={clsx('card-container', className)}>
      {componentsList && componentsList.length > 0 && componentsList.map((c: any, i: number) => (
        <EditorComponent
          key={c.id}
          index={i}
          component={c}
          onChangeHandler={onChange}
          onDelete={onDelete}
          setFocusedRef={setFocusedRef}
          answer={answer}
          setEquationFieldRef={setEquationFieldRef}
          editSolvableHandler={editSolvableHandler}
          updateEditorBoxIndex={updateEditorBoxIndex}
        />
      ))}
    </div>
  );
}

export default forwardRef(EditorBox);
