import React from 'react';
import type { SVGProps } from 'utils/types';

export default function CorrectTickIcon(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M11.0007 21.6666C13.8296 21.6666 16.5427 20.5428 18.5431 18.5425C20.5435 16.5421 21.6673 13.829 21.6673 11C21.6673 8.171 20.5435 5.4579 18.5431 3.45751C16.5427 1.45712 13.8296 0.333313 11.0007 0.333313C8.17167 0.333313 5.45857 1.45712 3.45818 3.45751C1.45779 5.4579 0.333984 8.171 0.333984 11C0.333984 13.829 1.45779 16.5421 3.45818 18.5425C5.45857 20.5428 8.17167 21.6666 11.0007 21.6666ZM15.9433 9.27598C16.1862 9.02451 16.3206 8.68771 16.3175 8.33811C16.3145 7.98852 16.1743 7.6541 15.9271 7.40689C15.6799 7.15968 15.3454 7.01945 14.9959 7.01641C14.6463 7.01338 14.3095 7.14777 14.058 7.39065L9.66732 11.7813L7.94332 10.0573C7.69185 9.81444 7.35504 9.68004 7.00545 9.68308C6.65585 9.68612 6.32144 9.82634 6.07423 10.0736C5.82702 10.3208 5.68679 10.6552 5.68375 11.0048C5.68071 11.3544 5.81511 11.6912 6.05798 11.9426L8.72465 14.6093C8.97469 14.8593 9.31376 14.9997 9.66732 14.9997C10.0209 14.9997 10.3599 14.8593 10.61 14.6093L15.9433 9.27598Z' fill='#47D16C' />
    </svg>
  );
}
