/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import NavbarWrapper from 'components/layout/NavbarWrapper';
import { Table } from 'components/shared/molecules/Table';
import type {
  ClassYearData, IAdminQuizReportsData, IAdminQuizReportsPreprocessing,
  SchoolData, SchoolClassType, SchoolListData,
} from 'utils/types';
import { preprocessAdminReports, QuizReportsColumns } from 'utils/tableDataPreprocessing';
import 'styles/components/pages/reportsListPage.scss';
import 'styles/components/shared/molecules/AdminReportSummary/reportSummaryTable.scss';
import {
  RangeFilter, SearchFilter, YearFilter, SchoolClassFilter,
} from 'components/shared/atoms/QuizReportsFilters';
import type { Range } from 'react-date-range';
import {
  useReportsByYearMutation,
  useReportsFilterByClassMutation,
} from 'store/ApiCall/reportApiCalls';
import moment from 'moment';
import CircularLoader from 'components/shared/molecules/Loader';
import { useGetClassYearListQuery } from 'store/ApiCall/quizzesApiCall';
import { useGetSchoolListQuery, useGetSchoolClassListQuery } from 'store/ApiCall/schoolApiCall';

export default function Reports(): JSX.Element {
  const [reports, setReports] = useState<IAdminQuizReportsPreprocessing[]>([]);
  const [reportsDataByYear, setReportsDataByYear] = useState<IAdminQuizReportsData[]>([]);
  const [reportsDataByClass, setReportsDataByClass] = useState<IAdminQuizReportsData[]>([]);
  const [range, setRange] = useState<Range>({});
  const [search, setSearch] = useState('');
  const [school, setSchool] = useState<SchoolData | null>(null);
  const [schoolClass, setSchoolClass] = useState<SchoolListData | null>(null);
  const [schoolList, setSchoolList] = useState<SchoolListData[] | [] | null>(null);
  const [classList, setClassList] = useState<SchoolClassType[] | [] | null>(null);

  const [year, setYear] = useState<ClassYearData | null>(null);
  const [data, setData] = useState<IAdminQuizReportsData[]>([]);
  const [skip, setSkip] = React.useState(true);

  const [reportsByYear] = useReportsByYearMutation();
  const [reportsFilterByClass] = useReportsFilterByClassMutation();

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const { data: classData } = useGetSchoolClassListQuery(school?._id || '', { skip });

  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const validateDateFilter = (anyDate: Date | undefined): string | undefined => {
    if (anyDate) {
      return moment(anyDate).format('YYYY-MM-DD');
    }
    return undefined;
  };

  const { data: classYearList, isLoading: yearsLoading } = useGetClassYearListQuery();

  const { data: allSchoolListData } = useGetSchoolListQuery();

  useEffect(() => {
    if (classYearList?.data && classYearList.data.length) {
      setYear(classYearList.data[0]);
      reportsByYear({
        classYearId: classYearList.data[0]?._id, startDate: validateDateFilter(range.startDate), endDate: validateDateFilter(range.endDate),
      // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
      }).then(({ data }: any) => {
        if (data?.data) {
          setReportsDataByYear(data.data);
        }
      });
    }
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classYearList]);

  useEffect(() => {
    if (!search) {
      if (schoolClass) {
        setData(reportsDataByClass);
        setLoading(false);
        return;
      }

      setData(reportsDataByYear);
      setLoading(false);
    }

    if (schoolClass) {
      const filteredData = reportsDataByClass.length ? data.filter((singleReport) => singleReport.quiz_name.toLowerCase().includes(search.toLowerCase())) : [];
      setData(filteredData);
      setLoading(false);
      return;
    }

    const filteredData: IAdminQuizReportsData[] | [] = reportsDataByYear.length
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      ? reportsDataByYear.filter((singleReport: IAdminQuizReportsData) => singleReport.quiz_name.toLowerCase().includes(search.toLowerCase())) : [];
    setData(filteredData);
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, reportsDataByYear, reportsDataByClass]);

  useEffect(() => {
    if (range.startDate && range.endDate) {
      if (year) {
        reportsByYear({
          classYearId: year._id, startDate: validateDateFilter(range.startDate), endDate: validateDateFilter(range.endDate),
        // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
        }).then(({ data }: any): void => {
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (data.data) {
            setReportsDataByYear(data.data);
          }
        });

        return;
      }

      if (schoolClass) {
        reportsFilterByClass({ classId: schoolClass._id, startDate: validateDateFilter(range.startDate), endDate: validateDateFilter(range.endDate) })
        // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
          .then(({ data }: any) => {
            if (data?.data) {
              setReportsDataByClass(data.data);
            }
          });
      }
    } else {
      if (schoolClass) {
        reportsFilterByClass({ classId: schoolClass._id, startDate: validateDateFilter(range.startDate), endDate: validateDateFilter(range.endDate) })
        // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
          .then(({ data }: any) => {
            if (data?.data) {
              setReportsDataByYear(data.data);
            }
          });
        return;
      }

      reportsByYear({
        classYearId: year?._id,
      // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
      }).then(({ data }: any) => {
        if (data?.data) {
          setReportsDataByYear(data.data);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.startDate, range.endDate]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredData: any = reportsDataByYear.length ? reportsDataByYear.filter((singleReport) => singleReport.quiz_name.toLowerCase().includes(search.toLowerCase())) : [];
    setData(filteredData);
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsDataByYear]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const filteredData: any = reportsDataByClass.length ? reportsDataByClass.filter((singleReport) => singleReport.quiz_name.toLowerCase().includes(search.toLowerCase())) : [];
    setData(filteredData);
    setLoading(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsDataByClass]);

  useEffect(() => {
    setReports(preprocessAdminReports(data));
  }, [data]);

  useEffect(() => {
    if (allSchoolListData) setSchoolList(allSchoolListData.data);
  }, [allSchoolListData]);

  useEffect(() => {
    if (classData?.data) setClassList(classData.data);
  }, [classData]);

  const resetDate = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation();
    setRange({ startDate: undefined, endDate: undefined });
  };

  const resetSearch = (): void => {
    setSearch('');
  };

  if (yearsLoading || loading) {
    return (
      <NavbarWrapper name='Reports'>
        <CircularLoader />
      </NavbarWrapper>
    );
  }

  const goToDetailPage = (id: string): void => {
    if (schoolClass?._id) {
      navigate(`/quiz/${id}/report-summary?classId=${schoolClass._id}`);
      return;
    }
    navigate(`/quiz/${id}/report-summary`);
  };

  const classYearHandler = (classYear: ClassYearData | null): void => {
    if (classYear) {
      setYear(classYear);
      setSchool(null);
      setSchoolClass(null);
      setSkip(true);
      reportsByYear({
        classYearId: classYear._id, startDate: validateDateFilter(range.startDate), endDate: validateDateFilter(range.endDate),
      // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
      }).then(({ data }: any) => {
        if (data?.data) {
          setReportsDataByYear(data.data);
        }
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const schoolClassHandler = (schoolName?: SchoolData | null, schoolClassName?: SchoolListData | null | undefined): void => {
    if (school == null && schoolName) setYear(null);

    if (school && schoolClassName) {
      setSchoolClass(schoolClassName);
      // reportsFilterByClass
      reportsFilterByClass({ classId: schoolClassName._id, startDate: validateDateFilter(range.startDate), endDate: validateDateFilter(range.endDate) })
        // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
        .then(({ data }: any) => {
          if (data?.data) {
            setReportsDataByClass(data.data);
          }
        });
      return;
    }

    if (schoolName) {
      setSchool(schoolName);
      setSkip(false);
      if (year) setYear(null);
    }
  };

  return (
    <NavbarWrapper name='Reports'>
      <div className='reports-container'>
        <div className='reports-filters'>
          <span> Filter by: </span>
          <YearFilter {...{
            year, classYearList, handler: classYearHandler,
          }}
          />
          <SchoolClassFilter {...{
            school, schoolList, schoolClass, schoolClassList: classList, handler: schoolClassHandler,
          }}
          />
          <RangeFilter {...{ range, setRange }} onClick={(e): void => { resetDate(e); }} />
          <SearchFilter {...{ search, setSearch }} onClick={resetSearch} />
        </div>

        <div className='tableContainer reportsTable'>
          <div className='tableInner'>
            {!reports.length ? (
              <div className='noRequestBlock text-center'>
                <span>No reports found</span>
              </div>
            ) : (
              <Table data={reports} columns={QuizReportsColumns} onClick={(id): void => { goToDetailPage(id); }} />
            )}
          </div>
        </div>
      </div>

    </NavbarWrapper>
  );
}
