import type { ChangeEvent } from 'react';
import React from 'react';

interface TextAreaProps {
  state?: string;
  className?: string;
  name: string;
  onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}
export default function TextAreaComponent(props: TextAreaProps): JSX.Element {
  const {
    state, className, name, onChange,
  } = props;
  return (
    <textarea name={name} className={className} onChange={onChange} value={state} />
  );
}
