import React, { useRef, useEffect } from 'react';
import EditorTextField from 'components/shared/atoms/ReactMathQuill/EditorTextField';
import EditorNewLine from 'components/shared/atoms/ReactMathQuill/EditorNewLine';
import EditorEquationField from 'components/shared/atoms/ReactMathQuill/EditorEquationField';
import type { EditorInput, EquationAnswerType } from 'utils/types';
import { addStyles } from 'react-mathquill';

addStyles();

interface EditorComponentsListProps {
  component: EditorInput;
  onChangeHandler: (id: string, value: string) => void;
  onDelete: (id: string, index: number) => void;
  setFocusedRef?: (ref: unknown, index: number) => void;
  setEquationFieldRef: (ref: unknown, index: number) => void;
  index: number;
  answer?: EquationAnswerType[] | string;
  editSolvableHandler: () => void;
  updateEditorBoxIndex: (id?: number) => void;
}

export default function EditorComponent(props: EditorComponentsListProps): JSX.Element | null {
  const {
    component, onChangeHandler, onDelete, setFocusedRef,
    index, answer, setEquationFieldRef, updateEditorBoxIndex, editSolvableHandler,
  } = props;
  const { type } = component;

  const focusedRef = useRef<typeof EditorEquationField | typeof EditorTextField>();

  const setFocused = (isFocused: boolean): void => {
    if (setFocusedRef === undefined) return;

    if (isFocused) {
      setFocusedRef(focusedRef, index);
      return;
    }
    setFocusedRef(null, index);
  };

  useEffect(() => {
    if ((answer && focusedRef.current) || (type === 4 && focusedRef.current)) {
      setEquationFieldRef(focusedRef, index);
    }
  }, []);

  const componentDeleteHandler = (id: string): void => {
    onDelete(id, index);
  };

  if (type === 1) {
    return (
      <EditorEquationField
        componentRef={focusedRef}
        component={component}
        onChangeHandler={onChangeHandler}
        onDelete={componentDeleteHandler}
        setFocused={setFocused}
        editSolvableHandler={editSolvableHandler}
        updateEditorBoxIndex={updateEditorBoxIndex}
        answer={typeof answer !== 'string' ? answer?.find((ans) => ans.componentIndex === index)?.answer : undefined}
      />
    );
  }
  if (type === 2 || type === 4) {
    return (
      <EditorTextField
        type={type}
        ref={focusedRef}
        component={component}
        onChangeHandler={onChangeHandler}
        onDelete={componentDeleteHandler}
        setFocused={setFocused}
      />
    );
  }
  if (type === 3) {
    return (
      <EditorNewLine component={component} onDelete={componentDeleteHandler} />
    );
  }
  return null;
}
