export const Operators = [{ text: '+', cmd: '+' }, { text: '-', cmd: '-' }, { text: '×', cmd: '×' }, { text: '÷', cmd: '\\frac{ }{ }' }, { text: '^', cmd: '^{ }' }, { text: '=', cmd: '=' }, { text: '°', cmd: '^{°}' }];
export const ImageSize = ['Small', 'Medium', 'Large'];
export const Units = ['cm', 'cm²', 'cm³', 'm', 'm²', 'm³', 'mm', 'mls', 'kg', 'lr', 'lb'];
export const REQUIRED_FIELD = 'This field is required.';
export const SELECT_CHOICE_FIELD = 'Please select an answer';
export const MIN_CHOICE = 'Please create atleast 2 choices';
export const CHOICE_HAVE_EMPTY_STATE = 'Please fill all the choices';
export const AT_LEAST_ONE_EQUATION_OR_ANSWER_FIELD = 'Please add atleast one equation or answer field';
export const AT_LEAST_ONE_SOLVABLE_FIELD = 'Please add atleast one solvable field';
export const BLANK_SOLVABLE_FIELD = 'Please dont leave solvable field empty';
export const BLANK_FIELD = 'Please dont leave any field empty';
export const BLANK_ANSWER_FIELD = 'Please dont leave answer field empty';
export const ALLOWED_ATTEMPTS = 2;
