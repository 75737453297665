import React from 'react';
import { useNavigate } from 'react-router-dom';
import NewLogo from 'assets/svgs/ts/NewLogo';
import { adminSidebarData } from 'utils/sidebarConfig';

export default function SideBar(): JSX.Element {
  const navigate = useNavigate();
  const onClickHandler = (link: string): void => {
    if (link !== '') {
      navigate(link);
    }
  };

  return (
    <div className='sidebar-main-wrapper'>
      <div className='sidebar-wrapper'>
        <div className='logo-block'>
          <NewLogo />
        </div>
        <div className='options-block'>
          {adminSidebarData.map((section) => {
            if (!section.hide) {
              return (
                <div key={section.sectionId} className='sidebar-section'>
                  <p>
                    {' '}
                    {section.sectionName}
                    {' '}
                  </p>
                  {section.tabs.map((tab) => {
                    if (!tab.hide) {
                      return (
                        <div className='tab-heading'>
                          <div key={tab.id} className='side-bar-option'>
                            <div className='icon-side'>
                              <tab.icon />
                            </div>
                            <div className='text-side'>
                              <span role='presentation' onClick={(): void => { onClickHandler(tab.route); }}>
                                {' '}
                                {tab.heading}
                                {' '}
                              </span>
                              <p>
                                {' '}
                                {tab.subheading}
                                {' '}
                              </p>
                              <div className='sub-menu'>
                                {tab.subMenu?.map((menu) => {
                                  if (!menu.hide) {
                                    return (
                                      <span role='presentation' onClick={(): void => { onClickHandler(menu.route); }}>
                                        {'• '}
                                        {menu.heading}
                                        {' '}
                                      </span>
                                    );
                                  }

                                  return null;
                                })}
                              </div>
                            </div>
                          </div>
                          { tab.actionButton && (
                            <div key={tab.actionButton.id} className='action-button-icon' role='presentation' onClick={(): void => { onClickHandler(tab.actionButton.route); }}>
                              <tab.actionButton.icon />
                            </div>
                          )}
                        </div>
                      );
                    }
                    return null;
                  })}

                  {section.sectionDivider
                    ? (
                      <div className='divider'>
                        <div className='line' />
                      </div>
                    ) : null }

                </div>
              );
            }

            return null;
          })}
        </div>

      </div>

    </div>
  );
}
