/* eslint @typescript-eslint/no-explicit-any: 1 */
import { sortingFns } from '@tanstack/react-table';
import type {
  FilterFn,
  SortingFn,
} from '@tanstack/react-table';
import type { RankingInfo } from '@tanstack/match-sorter-utils';
import {
  rankItem,
  compareItems,
} from '@tanstack/match-sorter-utils';

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value: number | string, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value.toString());

  addMeta({
    itemRank,
  });

  return itemRank.passed;
};

// Todo: update SortingFb arg type
const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  dir = compareItems(
    rowA.columnFiltersMeta[columnId].itemRank,
    rowB.columnFiltersMeta[columnId].itemRank,
  );

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

export { fuzzyFilter, fuzzySort };
