/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { Table } from 'components/shared/molecules/Table';
import Loader from 'components/shared/molecules/Loader';
import { useGetAllStudentsQuery } from 'store/ApiCall/classesApiCall';
import {
  preprocessStudentTableData,
  StudentColumnsData,
} from 'utils/tableDataPreprocessing';
import type { StudentTablePreprocessData } from 'utils/types';
import 'styles/components/shared/molecules/StudentListTable/main.scss';

export default function StudentListTable(): JSX.Element {
  const { classId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [tableData, setTableData] = React.useState<StudentTablePreprocessData[]>();
  const navigate = useNavigate();

  const { data, error, isLoading } = useGetAllStudentsQuery(`${classId}`);

  React.useEffect(() => {
    if (data?.data) {
      setTableData(preprocessStudentTableData(data.data));
    }
  }, [data]);

  if (error) {
    enqueueSnackbar('Something went wrong. Please try again Later!', { variant: 'error' });
  }

  if (isLoading) {
    return <Loader />;
  }

  const goToDetailPage = (id: string): void => {
    navigate(`/student/${id}/report-summary`);
  };

  if (tableData && !_.isEmpty(tableData)) {
    return (
      <div className='tableContainer studentListTable'>
        <div className='tableInner'>
          <Table data={tableData} columns={StudentColumnsData} onClick={(id): void => { goToDetailPage(id); }} />
        </div>
      </div>
    );
  }

  return (
    <div className='noDataAvailable'>
      <h2>No data available!</h2>
    </div>
  );
}
