/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import type { ClassTeacherList, FailureResponseType } from 'utils/types';
import { useClassTeacherDeleteMutation } from 'store/ApiCall/classesApiCall';
import {
  Dialog, DialogActions, DialogContent, Grid, IconButton, TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import DeleteActionIcon from 'assets/svgs/delete.svg';
import CrossIcon from 'assets/svgs/ts/CrossIcon';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';

interface Props {
  teacher_id: string;
  class_id: string;
}

export default function TeacherAccessAction({ teacher_id, class_id }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState<any>();
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [classTeacherDelete, { isLoading: deleteClassTeacherLoader }] = useClassTeacherDeleteMutation();
  console.log(teacher_id, class_id);
  const handleDelete = async () => {
    await classTeacherDelete({
      teacher_id,
      class_id
    }).then(() => {
      enqueueSnackbar('Teacher removed from class successfully!', { variant: 'success' });
      setOpenDeleteModal(false);
    }).catch((err) => {
      enqueueSnackbar(err.data.message || 'Failed to remove teacher from class! Please refresh or try again later.', { variant: 'error' });
      setOpenDeleteModal(false);
    })
  };

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user')!));
  }, []);

  useEffect(() => {
    console.log(user)
  }, [user])

  return (
    <div>
      {teacher_id && (teacher_id !== user?._id) && (
        <div>

          <div className={clsx('tableCell', 'actionsCell')}>
            <div className='actionCellInner'>
              <IconButton
                type='button'
                className='presentActionBtn'
                onClick={(): void => {
                  setOpenDeleteModal(true);
                }}
              >
                <img src={DeleteActionIcon} alt='delete' />
              </IconButton>
            </div>
          </div>

          <Dialog open={openDeleteModal} className='addClassDialogue'>
            <DialogActions className='addClassModalAction'>
              <button
                type='button'
                onClick={(): void => {
                  setOpenDeleteModal(false);
                }}
                className='closeBtn'
              >
                <span><CrossIcon /></span>
              </button>
            </DialogActions>

            <DialogContent className='modalTextBox'>
              <h2>
                Remove teacher from class
              </h2>
              <p style={{ fontSize: 14 }}>
                Are you sure you want to proceed?
              </p>
            </DialogContent>
            <DialogContent>
              <Grid container rowSpacing={4} className='textfieldContainer'>
                <Grid item sm={12} className='buttonGridBox'>
                  <ButtonComponent
                    size='medium'
                    className='w-82px addClassSubmitBtn'
                    type='contained'
                    text='Remove'
                    loading={deleteClassTeacherLoader}
                    onClick={handleDelete}
                    progressLoaderSize={20}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  );
}
