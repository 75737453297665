/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react';
import clsx from 'clsx';
import { useFormik } from 'formik';
import type { FailureResponseType, StudentList } from 'utils/types';
import { useTeacherStudentDeleteMutation, useTeacherStudentUpdateMutation } from 'store/ApiCall/studentApiCalls';
import {
  Dialog, DialogActions, DialogContent, Grid, IconButton, TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import EditActionIcon from 'assets/svgs/edit.svg';
import DeleteActionIcon from 'assets/svgs/delete.svg';
import CrossIcon from 'assets/svgs/ts/CrossIcon';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';

interface Student {
  student: StudentList;
}

export default function TeacherStudentActionsComponent({ student }: Student): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [openUpdateModal, setOpenUpdateModal] = React.useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [teacherStudentUpdate, { isLoading: updateStudentLoader }] = useTeacherStudentUpdateMutation();
  const [teacherStudentDelete, { isLoading: deleteStudentLoader }] = useTeacherStudentDeleteMutation();

  const formik = useFormik({
    initialValues: {
      student_id: student.user_id._id,
      email: student.user_id.email,
    },
    onSubmit: (body, { resetForm }): void => {
      if (!body.student_id || !body.email) return;
      teacherStudentUpdate({
        student_id: body.student_id,
        email: body.email,
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar('Student updated successfully!', { variant: 'success' });
          setOpenUpdateModal(false);
          resetForm();
        })
        .catch((err: FailureResponseType) => {
          enqueueSnackbar(err.data.message || 'Failed to update student! Please refresh or try again later.', { variant: 'error' });
          setOpenUpdateModal(false);
        });
    },
  });

  const handleDelete = (): void => {
    teacherStudentDelete({
      student_id: student.user_id._id,
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar('Student updated successfully!', { variant: 'success' });
        setOpenDeleteModal(false);
      })
      .catch((err: FailureResponseType) => {
        enqueueSnackbar(err.data.message || 'Failed to delete student! Please refresh or try again later.', { variant: 'error' });
        setOpenDeleteModal(false);
      });
  };

  return (
    <div>
      {student._id && (
        <div>

          <div className={clsx('tableCell', 'actionsCell')}>
            <div className='actionCellInner'>
              <IconButton
                type='button'
                className='presentActionBtn'
                onClick={(): void => {
                  setOpenUpdateModal(true);
                }}
              >
                <img src={EditActionIcon} alt='edit' />
              </IconButton>
              <IconButton
                type='button'
                className='presentActionBtn'
                onClick={(): void => {
                  setOpenDeleteModal(true);
                }}
              >
                <img src={DeleteActionIcon} alt='delete' />
              </IconButton>
            </div>
          </div>

          <Dialog open={openUpdateModal} className='addClassDialogue'>
            <DialogActions className='addClassModalAction'>
              <button
                type='button'
                onClick={(): void => {
                  setOpenUpdateModal(false);
                }}
                className='closeBtn'
              >
                <span><CrossIcon /></span>
              </button>
            </DialogActions>

            <DialogContent className='modalTextBox'>
              <h2>Update student</h2>
              <p>
                Please enter new email address
              </p>
            </DialogContent>
            <DialogContent>
              <form onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={4} className='textfieldContainer'>
                  <Grid item sm={12} className='addClassTextfield'>
                    <TextField
                      fullWidth
                      variant='outlined'
                      id='email'
                      type='email'
                      name='email'
                      label='Email'
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      className='mb-22 addClassField'
                    />
                  </Grid>
                  <Grid item sm={12} className='buttonGridBox'>
                    <ButtonComponent
                      size='medium'
                      className='w-82px addClassSubmitBtn'
                      type='contained'
                      text='Update'
                      loading={updateStudentLoader}
                      onClick={formik.handleSubmit}
                      progressLoaderSize={20}
                    />
                  </Grid>
                </Grid>
              </form>
            </DialogContent>
          </Dialog>
          <Dialog open={openDeleteModal} className='addClassDialogue'>
            <DialogActions className='addClassModalAction'>
              <button
                type='button'
                onClick={(): void => {
                  setOpenDeleteModal(false);
                }}
                className='closeBtn'
              >
                <span><CrossIcon /></span>
              </button>
            </DialogActions>

            <DialogContent className='modalTextBox'>
              <h2>
                Delete student account of
                {' '}
                {student.user_id.first_name}
                ?
              </h2>
              <p style={{ fontSize: 14 }}>
                Deleting this account will erase all data associated to it. Are you sure you want to proceed?
              </p>
            </DialogContent>
            <DialogContent>
              <Grid container rowSpacing={4} className='textfieldContainer'>
                <Grid item sm={12} className='buttonGridBox'>
                  <ButtonComponent
                    size='medium'
                    className='w-82px addClassSubmitBtn'
                    type='contained'
                    text='Delete'
                    loading={deleteStudentLoader}
                    onClick={handleDelete}
                    progressLoaderSize={20}
                  />
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </div>
      )}
    </div>
  );
}
