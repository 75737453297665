import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import NavbarWrapper from 'components/layout/NavbarWrapper';
import Summary from 'components/shared/molecules/StudentAttemptDetailReport/Summary';
import QuizResponse from 'components/shared/molecules/StudentAttemptDetailReport/QuizResponse';
import { useStudentQuizReportQuery, useStudentReportSummaryQuery } from 'store/ApiCall/reportApiCalls';
import CircularLoader from 'components/shared/molecules/Loader';
import { useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';

export default function StudentAttemptQuizDetailReport(): JSX.Element {
  const navigate = useNavigate();
  const { quizId, studentId = '' } = useParams();

  const { data: summaryData, isLoading } = useStudentReportSummaryQuery({ studentId });
  // API call for student quiz summary
  const { data: studentSummary, isLoading: isGettingStudentSummary } = useStudentQuizReportQuery({ quizId, studentId });
  // Get Question type
  const { data: questionTypeList, isLoading: isGettingQuestionTypes } = useGetQuestionTypeListQuery();

  if (isLoading || isGettingQuestionTypes || isGettingStudentSummary) return <CircularLoader />;

  return (
    <NavbarWrapper name={((): JSX.Element => (
      <span className='navbarTitle'>
        <div
          className='linkElement'
          role='presentation'
          onClick={(): void => { navigate(-3); }}
        >
          Students
        </div>
        <span className='seperator'>{'>'}</span>
        <div
          className='linkElement'
          role='presentation'
          onClick={(): void => { navigate(-2); }}
        >
          {summaryData?.data.basic_info.year.name}
        </div>
        <span className='seperator'>{'>'}</span>
        <div
          className='linkElement'
          role='presentation'
          onClick={(): void => { navigate(-1); }}
        >
          {summaryData?.data.basic_info.student_name}
        </div>
        <span className='seperator'>{'>'}</span>
        <span className='currentElement'>
          {studentSummary?.data.quiz.name}
        </span>
      </span>
    ))()}
    >
      <div className='student-attempt-detail-report-page'>
        <div className='student-attempt-detail-report-page-left-box'>
          <QuizResponse
            data={studentSummary?.data.questions}
            questionType={questionTypeList?.data}
          />
        </div>
        <div className='student-attempt-detail-report-page-right-box'>
          <Summary
            data={studentSummary?.data.summary}
          />
        </div>
      </div>
    </NavbarWrapper>
  );
}
