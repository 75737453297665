import React from 'react';
import type { SVGProps } from 'utils/types';

export default function PresentationIcon(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='17' height='16' viewBox='0 0 17 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M1.5 0C1.23478 0 0.98043 0.105357 0.792893 0.292893C0.605357 0.48043 0.5 0.734784 0.5 1C0.5 1.26522 0.605357 1.51957 0.792893 1.70711C0.98043 1.89464 1.23478 2 1.5 2V10C1.5 10.5304 1.71071 11.0391 2.08579 11.4142C2.46086 11.7893 2.96957 12 3.5 12H6.086L4.793 13.293C4.69749 13.3852 4.62131 13.4956 4.5689 13.6176C4.51649 13.7396 4.4889 13.8708 4.48775 14.0036C4.4866 14.1364 4.5119 14.2681 4.56218 14.391C4.61246 14.5138 4.68671 14.6255 4.7806 14.7194C4.8745 14.8133 4.98615 14.8875 5.10905 14.9378C5.23194 14.9881 5.36362 15.0134 5.4964 15.0123C5.62918 15.0111 5.7604 14.9835 5.8824 14.9311C6.00441 14.8787 6.11475 14.8025 6.207 14.707L8.5 12.414L10.793 14.707C10.9816 14.8892 11.2342 14.99 11.4964 14.9877C11.7586 14.9854 12.0094 14.8802 12.1948 14.6948C12.3802 14.5094 12.4854 14.2586 12.4877 13.9964C12.49 13.7342 12.3892 13.4816 12.207 13.293L10.914 12H13.5C14.0304 12 14.5391 11.7893 14.9142 11.4142C15.2893 11.0391 15.5 10.5304 15.5 10V2C15.7652 2 16.0196 1.89464 16.2071 1.70711C16.3946 1.51957 16.5 1.26522 16.5 1C16.5 0.734784 16.3946 0.48043 16.2071 0.292893C16.0196 0.105357 15.7652 0 15.5 0H1.5ZM13.207 4.707C13.3892 4.5184 13.49 4.2658 13.4877 4.0036C13.4854 3.7414 13.3802 3.49059 13.1948 3.30518C13.0094 3.11977 12.7586 3.0146 12.4964 3.01233C12.2342 3.01005 11.9816 3.11084 11.793 3.293L8.5 6.586L7.207 5.293C7.01947 5.10553 6.76516 5.00021 6.5 5.00021C6.23484 5.00021 5.98053 5.10553 5.793 5.293L3.793 7.293C3.69749 7.38525 3.62131 7.49559 3.5689 7.6176C3.51649 7.7396 3.4889 7.87082 3.48775 8.0036C3.4866 8.13638 3.5119 8.26806 3.56218 8.39095C3.61246 8.51385 3.68671 8.6255 3.7806 8.71939C3.8745 8.81329 3.98615 8.88754 4.10905 8.93782C4.23194 8.9881 4.36362 9.0134 4.4964 9.01225C4.62918 9.0111 4.7604 8.98351 4.8824 8.9311C5.00441 8.87869 5.11475 8.80251 5.207 8.707L6.5 7.414L7.793 8.707C7.98053 8.89447 8.23484 8.99979 8.5 8.99979C8.76516 8.99979 9.01947 8.89447 9.207 8.707L13.207 4.707Z' />
    </svg>
  );
}
