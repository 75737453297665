/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import ArrowDown from 'assets/svgs/ts/ArrowDown';
import BookIcon from 'assets/svgs/ts/BookIcon';
import CalenderIcon from 'assets/svgs/ts/CalenderIcon';
import SearchIcon from 'assets/svgs/ts/SearchIcon';
import React from 'react';
import clsx from 'clsx';
import {
  Popover, Autocomplete, Typography, TextField,
} from '@mui/material';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import moment from 'moment';
import type { Range } from 'react-date-range';
import type {
  ClassYearData, ClassYearResponse, SchoolData, SchoolClassType, SchoolListData,
} from 'utils/types';
import CrossIcon from 'assets/svgs/ts/CrossIcon';
import DateRangePicker from '../DateRangePicker';

export function RangeFilter({ range, setRange, onClick }: { range: Range; setRange: (range: Range) => void; onClick: (e: React.MouseEvent<HTMLDivElement>) => void }): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
  const rangeButtonDisplay = (): string => {
    const from = range.startDate !== undefined ? moment(range.startDate).format('MMM DD, YYYY') : '';
    const to = range.endDate !== undefined ? moment(range.endDate).format('MMM DD, YYYY') : '';
    const buttonText = !from && !to ? 'All Time' : `${from} - ${to}`;
    return buttonText;
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <div className='filter-btn-wrapper date-picker' aria-hidden='true' onClick={handleClick}>
        <div className='left-side'>
          <CalenderIcon />
          <span>
            {' '}
            {rangeButtonDisplay()}
            {' '}
          </span>
        </div>
        {range.startDate !== undefined
          ? (
            <div className='right-side' role='presentation' onClick={(e): void => { onClick(e); }}>
              <CrossIcon />
            </div>
          )
          : (
            <div className='right-arrow-side'>
              <ArrowDown />
            </div>
          )}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DateRangePicker range={range} setRange={setRange} />
      </Popover>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function SchoolClassFilter({
  school,
  schoolList,
  schoolClass,
  schoolClassList,
  handler,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
}: {
  school: any;
  schoolList: SchoolData[] | [] | null | undefined;
  schoolClass: SchoolListData | null | undefined;
  schoolClassList: SchoolClassType[] | [] | null | undefined;
  handler: (schoolName?: SchoolData | null, schoolClassName?: SchoolListData | null | undefined) => void;
}): JSX.Element {
  const [anchorClassEl, setAnchorClassEl] = React.useState<HTMLDivElement | null>(null);
  const [currentSchool, setCurrentSchool] = React.useState<SchoolData | null>(null);

  const handleClose = (): void => {
    setAnchorClassEl(null);
  };

  const handleSchoolClick = (s: SchoolData): void => {
    handler(s);
    setCurrentSchool(s);
    handleClose();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSchoolClass = (c: SchoolListData): void => {
    handler(currentSchool, c);
    handleClose();
  };

  const handleClassClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    if (!school) return;
    setAnchorClassEl(event.currentTarget);
  };

  const openClass = Boolean(anchorClassEl);
  const cid = openClass && !schoolClass ? 'simple-popover' : undefined;

  return (
    <div className='classBasedFilters'>
      <div className='schoolFilter'>
        <div className='filter-btn-wrapper' aria-hidden='true'>
          <BookIcon className='fill-blue-2' />
          <Autocomplete
            className='school-autocomplete-input'
            disablePortal
            value={school}
            onChange={(e: React.SyntheticEvent, newVal) => { handleSchoolClick(newVal); }}
            options={schoolList?.length ? schoolList : []}
            getOptionLabel={(option: SchoolData) => option.name}
            disableClearable
            sx={{ width: '100%' }}
            popupIcon={<ArrowDown />}
            renderInput={(params): JSX.Element => (
              <TextField
                {...params}
                label=''
                placeholder='Select School'
                value={school}
                className='school-autocomplete-textfield'
              />
            )}
          />
        </div>
      </div>

      <div className={clsx('classFilter', !school && 'disableField')}>
        <div className='filter-btn-wrapper' aria-hidden='true' onClick={handleClassClick}>
          <div className='left-side'>
            <BookIcon className={school ? 'fill-blue-2' : 'fill-mono-dull-light'} />
            <span className='class-field-text-area'>
              {schoolClass?.name ? schoolClass.name : 'Select class'}
            </span>
          </div>
          <div className='right-arrow-side'>
            <ArrowDown />
          </div>
        </div>
        <Popover
          id={cid}
          open={openClass}
          anchorEl={anchorClassEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          {schoolClassList?.length ? schoolClassList.map((c: SchoolClassType) => (
            <Typography sx={{ p: 2 }} style={{ cursor: 'pointer' }} onClick={(): void => { handleSchoolClass(c); }}>
              {c.name}
            </Typography>
          ))
            : (
              <Typography sx={{ p: 2 }} style={{ cursor: 'pointer' }} onClick={(): void => { handleClose(); }}>
                --- No class ---
              </Typography>
            )}
        </Popover>
      </div>
    </div>
  );
}

export function YearFilter({
  year,
  classYearList,
  handler,
}: { year: ClassYearData | null;
  classYearList: ClassYearResponse | undefined;
  handler: (classYear: ClassYearData | null) => void ;
}): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (singleYear: ClassYearData): void => {
    handler(singleYear);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <div className='filter-btn-wrapper' aria-hidden='true' onClick={handleClick}>
        <div className='left-side'>
          <BookIcon className='fill-blue-2' />
          <span className='year-field-text-area'>
            {!year ? 'Select Year' : `Year ${year.year}`}
          </span>
        </div>
        <div className='right-arrow-side'>
          <ArrowDown />
        </div>
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {classYearList?.data.map((singleYear) => (
          <Typography sx={{ p: 2 }} style={{ cursor: 'pointer' }} onClick={(): void => { handleOptionSelect(singleYear); }}>
            Year
            {' '}
            {singleYear.year}
          </Typography>
        ))}

      </Popover>
    </>
  );
}

export function SearchFilter({
  search, setSearch, onClick, placeholder = 'Search quiz name',
}: { search: string; placeholder?: string; setSearch: (search: string) => void; onClick: () => void }): JSX.Element {
  return (
    <div className='filter-btn-wrapper'>
      <div className='left-side'>
        <SearchIcon />
        <input value={search} onChange={(e): void => { setSearch(e.target.value); }} placeholder={placeholder} />
      </div>
      <div className='right-side' role='presentation' onClick={onClick}>
        <CrossIcon />
      </div>
    </div>
  );
}

export function ResetFilter({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <div className='filter-btn-wrapper reset-button' aria-hidden='true' onClick={onClick}>
      <div className='left-side'>
        <CrossIcon />
      </div>
    </div>
  );
}
