/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';

import {
  Table, TableBody, TableCell, TableHead, TableRow,
} from '@mui/material';
import type { AdminAggregatedReportQuestionResponseType } from 'utils/types';
import FalseTickIcon from 'assets/svgs/ts/FalseTickIcon';
import CorrectTickIcon from 'assets/svgs/ts/CorrectTickIcon';
import HintBulbOff from 'assets/svgs/ts/HintBulbOff';
import HintBulb from 'assets/svgs/ts/HintBulb';

const tabsList = [
  {
    id: 1,
    heading: 'All',
    value: 'all',
  },
  {
    id: 2,
    heading: 'Correct',
    value: 'correct',
  },
  {
    id: 3,
    heading: 'Wrong',
    value: 'wrong',
  },
];

interface ResponsesListProps {
  data: AdminAggregatedReportQuestionResponseType[];
  questionType: string;
}

export default function ResponsesList(props: ResponsesListProps): JSX.Element {
  const { data, questionType } = props;

  const [active, setActive] = useState(1);
  const [activeList, setActiveList] = useState(data);

  const filterHandler = (id: number): void => {
    if (id === 1) {
      setActiveList(data);
    } else if (id === 2) {
      const filterData = data.filter((item) => item.is_correct);
      setActiveList(filterData);
    } else {
      const filterData = data.filter((item) => !item.is_correct);
      setActiveList(filterData);
    }
    setActive(id);
  };

  const concateAnswerHandler = (totalAnswer: string, singleAnswer: string): string => {
    if (singleAnswer === '') return totalAnswer;
    if (totalAnswer === '') {
      totalAnswer = singleAnswer;
    } else {
      totalAnswer = `${totalAnswer}, ${singleAnswer}`;
    }
    return totalAnswer;
  };

  const returnStudentAnswer = (submittedAns: string): string => {
    const submittedAnsArr: any[] = JSON.parse(submittedAns);
    let showAnswer = '';
    submittedAnsArr.forEach((answerContainer) => {
      if (answerContainer.type === 1) {
        answerContainer.answer.forEach((singleAnswer: any) => {
          if (singleAnswer.is_solvable_fraction) {
            showAnswer = concateAnswerHandler(showAnswer, singleAnswer.numerator);
            showAnswer = concateAnswerHandler(showAnswer, singleAnswer.denominator);
          } else {
            showAnswer = concateAnswerHandler(showAnswer, singleAnswer.value);
          }
        });
      } else {
        showAnswer = concateAnswerHandler(showAnswer, answerContainer.answer);
      }
    });
    return showAnswer;
  };

  return (
    <div className='response-list-wrapper'>
      <div className='response-header'>
        <span className='single-question-label'> Response </span>
        <div className='tabs'>
          {tabsList.map((singleTab) => (
            <div key={singleTab.id} className={`single-tab ${active === singleTab.id && 'active-tab'} `} aria-hidden='true' onClick={(): void => { filterHandler(singleTab.id); }}>
              {singleTab.heading}
            </div>
          ))}
        </div>
      </div>
      <Table className='response-table'>
        <TableHead className='response-table-head'>
          <TableCell>Student Name</TableCell>
          <TableCell>Hint</TableCell>
          <TableCell>Answer</TableCell>
        </TableHead>
        <TableBody className='response-table-body'>
          {activeList.length > 0 ? activeList.map((student) => (
            <TableRow key={student.user_id}>
              <TableCell className='named-cell'>
                {student.is_correct ? <CorrectTickIcon className='size-16' /> : <FalseTickIcon className='size-16' />}
                <span>{student.student_name}</span>
              </TableCell>
              <TableCell className='hint-cell'>
                {student.hint_taken ? <HintBulb className='size-16' /> : <HintBulbOff className='size-16 fill-secondary-light' />}
              </TableCell>
              <TableCell>
                {questionType === 'Equation Type' ? returnStudentAnswer(student.submitted_answer) : student.submitted_answer }
              </TableCell>
            </TableRow>
          ))
            : <div className='equation-question-text'>No student found!</div>}

        </TableBody>
      </Table>
    </div>
  );
}
