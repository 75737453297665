/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useGetAllTeacherStudentsManagementQuery } from 'store/ApiCall/studentApiCalls';
import NavbarWrapper from 'components/layout/NavbarWrapper';
import { Table } from 'components/shared/molecules/Table';
import { preprocessTeacherStudentList, TeacherStudentListColumn } from 'utils/tableDataPreprocessing';
import type { TeacherStudentListPreprocessing } from 'utils/types';
import 'styles/components/pages/studentListPage.scss';
import { SearchFilter } from 'components/shared/atoms/QuizReportsFilters';
import CircularLoader from 'components/shared/molecules/Loader';

export default function StudentListPage(): JSX.Element {
  const [tableData, setTableData] = React.useState<TeacherStudentListPreprocessing[]>();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const { data, refetch } = useGetAllTeacherStudentsManagementQuery();
  React.useEffect(() => {
    if (data?.data) {
      setTableData(preprocessTeacherStudentList(data.data.filter((singleReport) => singleReport.user_id.first_name)));
    }
  }, [data]);

  useEffect(() => {
    const filteredData = data?.data ? data.data.filter((singleReport) => {
      const firstName: string = singleReport.user_id.first_name.toLowerCase();
      const lastName: string = singleReport.user_id.last_name.toLowerCase();
      const fullName = firstName + ' ' + lastName;
      const schoolName: string = singleReport.school_id.name.toLowerCase();
      const className: string = singleReport.class_id.name.toLowerCase();
      return firstName && (
        className.includes(search.toLowerCase())
        || schoolName.includes(search.toLowerCase())
        || firstName.toLowerCase().includes(search.toLowerCase())
        || lastName.toLowerCase().includes(search.toLowerCase())
        || fullName.toLowerCase().includes(search.toLowerCase())
      );
    }) : [];
    setTableData(preprocessTeacherStudentList(filteredData));
    setLoading(false);
  }, [search]);

  const resetSearch = (): void => {
    setSearch('');
  };

  React.useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <NavbarWrapper name='Reports'>
        <CircularLoader />
      </NavbarWrapper>
    );
  }

  return (
    <NavbarWrapper name='Students'>
      <div className='reports-container'>
        <div className='reports-filters'>
          {/* <RangeFilter {...{ range, setRange }} onClick={(e): void => { resetDate(e); }} /> */}
          <SearchFilter {...{ search, setSearch }} placeholder='Search student' onClick={resetSearch} />
        </div>
        <div className='studentListPageRoot'>

          {tableData
            ? !_.isEmpty(tableData) ? (
              <div className='tableContainer'>
                <div className='tableInner'>
                  <Table data={tableData} columns={TeacherStudentListColumn} enableSorting />
                </div>
              </div>
            )
              : (
                <div className='noDataAvailable'>
                  <h2>No students found!</h2>
                </div>
              )
            : (<CircularLoader />)}
        </div>
      </div>
    </NavbarWrapper>
  );
}
