/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from 'react';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import Logo from 'assets/svgs/sidebarLogoNewWhite.svg';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { useGetSchoolListQuery, useGetSchoolClassListQuery } from 'store/ApiCall/schoolApiCall';
import { useSignupStudentMutation } from 'store/ApiCall/authApiCalls';
import Grid from '@mui/material/Grid';
import {
  TextField,
  MenuItem,
  InputAdornment,
  Autocomplete,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getDefaultPage, checkAuthenticated } from 'utils/helper';
import type { UserType, FailureResponseType } from 'utils/types';
import 'styles/components/pages/signup.scss';

const SignUpFormValidationSchema = yup.object({
  firstName: yup
    .string()
    .max(50, 'Class Name cannot be more then 50 characters')
    .matches(/^(?!\s+$).*/, 'First Name cannot contain only blank spaces')
    .required('First Name is required'),
  lastName: yup
    .string()
    .max(50, 'Class Name cannot be more then 50 characters')
    .matches(/^(?!\s+$).*/, 'Last Name cannot contain only blank spaces')
    .required('Last Name is required'),
  class: yup
    .string()
    .required('Select class'),
  schoolEmail: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .max(100, 'Password should be of maximum 16 characters length')
    .matches(/^(?!\s+$).*/, 'Password cannot contain only blank spaces')
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required('Please enter your password again'),
});

function StudentSignUp(): JSX.Element {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    const userObj = window.localStorage.getItem('user');
    if (userObj) {
      const user: UserType = JSON.parse(userObj);
      if (checkAuthenticated()) {
        navigate(getDefaultPage(user.role_id.name), { replace: true });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cPasswordVisible, setCPasswordVisible] = useState(false);
  const [school, setSchool] = React.useState<{ label: string; id: string }>({ label: '', id: '' });
  const [schoolError, setSchoolError] = React.useState<string>('');
  const [skip, setSkip] = React.useState(true);
  const { data, error, isLoading } = useGetSchoolListQuery();
  const classData = useGetSchoolClassListQuery(school.id, { skip });

  const [signupStudent, { isLoading: signUpCallLoader }] = useSignupStudentMutation();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      class: '',
      schoolEmail: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: SignUpFormValidationSchema,
    onSubmit: (body): void => {
      if (schoolError) return;
      signupStudent({
        first_name: body.firstName,
        last_name: body.lastName,
        email: body.schoolEmail,
        password: body.password,
        confirm_password: body.confirmPassword,
        school_id: school.id,
        class_id: body.class,
      })
        .unwrap()
        .then(() => {
          enqueueSnackbar('Please open your email and verify your account.', { variant: 'success' });
          navigate('/signin');
        })
        .catch((err: FailureResponseType) => {
          enqueueSnackbar(err.data.message, { variant: 'error' });
        });
    },
  });

  const onSchoolChangeHandler = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: any): void => {
    if (newValue) {
      setSchool(newValue);
      setSchoolError('');
      setSkip(false);
    } else {
      setSchool({ label: '', id: '' });

      setSchoolError('Please select your school');
    }
  };

  return (
    <div className='sign-in-page'>
      <div className='left-box'>
        <div className='top-part'>
          <img className='logo' src={Logo} alt='app-log' />
          <div className='signup-text-box' />
        </div>
        <div className='bottom-part'>
          <h4>Already have an account?</h4>
          <div className='btn-box'>
            <ButtonComponent size='large' type='outlined' text='Sign In' className='sign-in-btn' onClick={(): void => { navigate('/signin'); }} />
          </div>
          <h6>
            copyright
            {' '}
            {new Date().getFullYear()}
            {' '}
            mathstarter.co.nz
          </h6>
        </div>
      </div>
      <div className='right-box sign-up-page'>
        <div className='sign-in-container sign-up-page'>
          <h1>Create Account</h1>
          <h3 className='mb-22'>Please enter your details to create the account</h3>
          <form onSubmit={formik.handleSubmit}>
            <Grid container>
              {/* name fields */}
              <Grid container item sm={12} spacing={1}>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    id='firstName'
                    type='firstName'
                    name='firstName'
                    label='First Name'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    className='mb-22'
                    helperText={formik.touched.firstName && formik.errors.firstName}
                  />
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    id='lastName'
                    type='lastName'
                    name='lastName'
                    label='Last Name'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                    className='mb-22'
                  />
                </Grid>
              </Grid>
              <Grid container item sm={12} columnSpacing={1}>
                {/* school field (drop down) */}
                <Grid item sm={8} sx={{ height: 'fit-content' }}>
                  <Autocomplete
                    className='mb-22 autocomplete-input'
                    disablePortal
                    value={school}
                    onChange={(e: any, newVal) => { onSchoolChangeHandler(e, newVal); }}
                    options={data ? data.data.map((i) => ({ label: i.name, id: i._id })) : []}
                    getOptionLabel={(option: any) => option.label}
                    disableClearable
                    sx={{ width: '100%' }}
                    disabled={!!error || !!isLoading}
                    renderInput={(params): JSX.Element => (
                      <TextField
                        {...params}
                        label='School'
                        value={school}
                        error={Boolean(schoolError)}
                        helperText={schoolError}
                      />
                    )}
                  />

                </Grid>
                {/* class field (drop down) */}
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    id='class'
                    select
                    type='class'
                    name='class'
                    label='Class'
                    value={formik.values.class}
                    onChange={formik.handleChange}
                    error={formik.touched.class && Boolean(formik.errors.class)}
                    className='mb-22'
                    sx={{ width: '100%' }}
                    helperText={formik.touched.class && formik.errors.class}
                    // disabled={!school}
                    disabled={skip}
                  >
                    {classData.data && classData.data.data.length > 0 ? classData.data.data.map((item) => (
                      <MenuItem key={item._id} value={item._id}>
                        <span className='menu-item'>{item.name}</span>
                      </MenuItem>
                    )) : (<span className='no-data-item'>No class available!</span>)}
                  </TextField>
                </Grid>
              </Grid>
              {/* school Email field */}
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='schoolEmail'
                  type='schoolEmail'
                  name='schoolEmail'
                  label='School Email'
                  className='mb-22'
                  value={formik.values.schoolEmail}
                  onChange={formik.handleChange}
                  error={formik.touched.schoolEmail && Boolean(formik.errors.schoolEmail)}
                  helperText={formik.touched.schoolEmail && formik.errors.schoolEmail}
                />
              </Grid>
              {/* password field */}
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='password'
                  name='password'
                  label='Password'
                  type={passwordVisible ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  className='mb-22'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ cursor: 'pointer' }} position='end' onClick={(): void => { setPasswordVisible((prev) => !prev); }}>
                        {passwordVisible ? <Visibility /> : <VisibilityOff />}
                      </InputAdornment>
                    ),
                  }}
                  error={formik.touched.password && Boolean(formik.errors.password)}
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              {/* confirm password field */}
              <Grid item sm={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  id='confirmPassword'
                  name='confirmPassword'
                  type={cPasswordVisible ? 'text' : 'password'}
                  label='Confirm Password'
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  className='mb-22'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment sx={{ cursor: 'pointer' }} position='end' onClick={(): void => { setCPasswordVisible((prev) => !prev); }}>
                        {cPasswordVisible ? <Visibility /> : <VisibilityOff />}
                      </InputAdornment>
                    ),
                  }}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                />
              </Grid>

              {/* button container */}
              <Grid item sm={12}>
                <ButtonComponent
                  type='contained'
                  text='Create Account'
                  className='w-100'
                  loading={signUpCallLoader}
                  isSubmitBtn
                />
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  );
}

export default StudentSignUp;
