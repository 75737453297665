/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import type { QuestionResType } from 'utils/types';
import EquationQuestion from 'components/shared/molecules/QuizDetailPage/EquationQuestion';
import clsx from 'clsx';
import CopyIcon from 'assets/svgs/copyIcon.svg';
import { useSnackbar } from 'notistack';
import { Chip } from '@mui/material';
import McqChoiceContainer from '../QuizDetailPage/McqChoiceContainer';

interface QuestionCardProps {
  index: number;
  question: QuestionResType;
  questionType: string;
}

export default function QuestionCardComponent({
  index, question, questionType,
}: QuestionCardProps): JSX.Element | null {
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = (code: string): void => {
    navigator.clipboard.writeText(code);
    enqueueSnackbar('Question code copied to clipboard.', { variant: 'success' });
  };

  return (
    <BasicCardContainer key='question._id' className='no-shadow mb-16'>
      <div className='question-container'>
        <div className='question-header'>
          <div className='question-id'>{index + 1}</div>
          <div className='question-code'>
            <div className='question-code-box'>{question._id.toString()}</div>
            <button
              type='button'
              onClick={(): void => { copyToClipBoard(question._id); }}
              className='copy-to-clipboard-btn'
            >
              <img className='copy-icon' src={CopyIcon} alt='copy to clipboard' />
            </button>
          </div>
        </div>
        {questionType.toLowerCase().trim() === 'equation type'
          ? (
            <div className='question-body'>
              <EquationQuestion
                index={index}
                body={JSON.parse(question.body)}
                answer={question.answer}
                questionImage={question.image_url}
                orderNum={question.order_num}
                title={question.statement}
                hideIndex
              />
            </div>
          )
          : (
            <div className='question-body'>
              <div className='mcq-question-box'>
                <div className='mcq-question-left-box-1'>
                  <div className='mcq-question-text'>{question.statement}</div>
                </div>
                {question.image_url
          && (
            <div className='mcq-question-left-box-2'>
              <div
                className='mcq-question-left-box-2-image'
                style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/assets/images/questions/${question.image_url})` }}
              />
            </div>
          )}
              </div>
              <div className='mcq-choices-container'>
                {typeof question.body === 'string'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-call
        && JSON.parse(question.body).map((choice: any) => (
          <McqChoiceContainer key={choice.key} data={choice} answer={question.answer} />
        ))}
              </div>
            </div>
          )}
        {question.hint_title || question.hint_body || question.hint_image_url
          ? (
            <div className='hint-container'>
              <div className='hint-body'>
                <h4>Hint</h4>
                <div className='mcq-hint-box'>
                  {question.hint_title
                          && (
                            <div className='mcq-hint-left-box-1'>
                              <div className='mcq-hint-text'>{question.hint_title}</div>
                            </div>
                          )}
                  <div className={clsx('mcq-hint-left-box-2', question.hint_title)}>
                    <div className='mcq-hint-text'>{question.hint_body}</div>
                  </div>
                </div>
              </div>
              {question.hint_image_url
              && (
                <div
                  className='mcq-question-left-box-2-image image-container hint-img-container'
                  style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/assets/images/questions/${question.hint_image_url})` }}
                />
              )}

            </div>
          )
          : null}

        {question.difficulty || question.concept
          ? (
            <div className='question-tags-container'>
              {question.difficulty
                ? (
                  <div className='tag-container'>
                    <p className='tag-title'>Difficulty</p>
                    <Chip label={question.difficulty} className='tag-btn-chip' />
                  </div>
                ) : null}
              {question.concept
                ? (
                  <div className='tag-container'>
                    <p className='tag-title'>Concept</p>
                    <Chip label={question.concept} className='tag-btn-chip' />
                  </div>
                ) : null}
            </div>
          )
          : null}
      </div>
    </BasicCardContainer>

  );
}
