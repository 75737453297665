/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'store/ApiCall/baseQuery';
import type {
  SchoolListDataType,
  StudentListResponse,
  ApprovedTeachersResponse,
  PendingTeachersResponse,
  TeachersRequestResponse,
} from 'utils/types';

export const manageApi = createApi({
  reducerPath: 'manageApi',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['teachersList'],
  endpoints: (builder) => ({
    getSchoolsListManagement: builder.query<SchoolListDataType, void>({
      query: () => ({
        url: '/api/school/counts',
        method: 'GET',
      }),
    }),
    getAllStudentsManagement: builder.query<StudentListResponse, void>({
      query: () => ({
        url: '/api/student/list',
        method: 'GET',
      }),
    }),
    getAllTeacherStudentsManagement: builder.query<StudentListResponse, void>({
      query: () => ({
        url: '/api/teacher/student/list',
        method: 'GET',
      }),
    }),
    getApprovedTeachersList: builder.query<ApprovedTeachersResponse, void>({
      query: () => ({
        url: '/api/teacher/approved-list',
        method: 'GET',
      }),
      providesTags: ['teachersList'],
    }),
    getPendingTeachersList: builder.query<PendingTeachersResponse, void>({
      query: () => ({
        url: '/api/teacher/pending-list',
        method: 'GET',
      }),
      providesTags: ['teachersList'],
    }),
    approveTeacher: builder.mutation<TeachersRequestResponse, string>({
      query: (teacherId) => ({
        url: `/api/teacher/approve/${teacherId}`,
        method: 'GET',
      }),
      invalidatesTags: ['teachersList'],
    }),
    declineTeacher: builder.mutation<TeachersRequestResponse, string>({
      query: (teacherId) => ({
        url: `/api/teacher/decline/${teacherId}`,
        method: 'GET',
      }),
      invalidatesTags: ['teachersList'],
    }),

  }),
});

export const {
  useGetSchoolsListManagementQuery,
  useGetAllStudentsManagementQuery,
  useGetAllTeacherStudentsManagementQuery,
  useGetApprovedTeachersListQuery,
  useGetPendingTeachersListQuery,
  useApproveTeacherMutation,
  useDeclineTeacherMutation,
} = manageApi;
