/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useSnackbar } from 'notistack';

import NavbarWrapper from 'components/layout/NavbarWrapper';
import BasicCard from 'components/shared/molecules/Card';
import CircularLoader from 'components/shared/molecules/Loader';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import AddClassModal from 'components/shared/molecules/AddClassModal';
import { useGetAllTeacherClassesQuery } from 'store/ApiCall/classesApiCall';
import NothingFoundSVG from 'assets/svgs/nothingFound2.svg';
import PlusIcon from 'assets/svgs/ts/PlusIcon';
import { checkSingularWord } from 'utils/helper';
import type { TeacherClasses } from 'utils/types';
import 'styles/components/pages/teacherClasses.scss';

export default function TeacherClassesPage(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { data, error, isLoading } = useGetAllTeacherClassesQuery();
  const [user, setUser] = useState<any>({});
  const [owned, setOwned] = useState<any>([]);
  const [delegated, setDelegated] = useState<any>([])


  if (error) {
    enqueueSnackbar('Something went wrong! Please refresh or try again later.', { variant: 'error' });
  }

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('user') ?? ''));
  }, [])

  useEffect(() => {
    if (user?._id) {
      const own = data?.data.filter(teacherClass => teacherClass.class.created_by?._id === user?._id);
      if (own && own?.length > 0) {
        setOwned(own);
      }
      const delegate = data?.data.filter(teacherClass => teacherClass.class.created_by?._id !== user?._id);
      if (delegate && delegate?.length > 0) {
        setDelegated(delegate);
      }
    }
  }, [data, user])

  return (
    <NavbarWrapper name='My Classes'>
      <div className='classesPageRoot'>
        {isLoading ? <CircularLoader /> : null}
        {data
          ? !_.isEmpty(data.data)
            ? (
              <div>
                {owned?.length > 0 && (
                  <div className='classesCardContainer'>
                    {owned?.map((teacherClass: TeacherClasses) => (
                      <Link to={`/my-classes/class/${teacherClass.class._id}`} key={teacherClass.class._id}>
                        <BasicCard
                          styles='classesPageCard'
                          preTitle={(
                            <span className='cardPreTitle'>
                              Year
                              {' '}
                              {teacherClass.class.class_year_id.year}
                            </span>
                          )}
                          code={teacherClass.class.code}
                          title={teacherClass.class.name}
                          detail={(
                            <span className='classesCardDetailBox'>
                              <span>{`${teacherClass.numberOfStudents} ${checkSingularWord('Student', 'Students', teacherClass.numberOfStudents)}`}</span>
                              <span>{`${teacherClass.numberofCompletedQuiz} ${checkSingularWord('Completed Quiz', 'Completed Quizzes', teacherClass.numberofCompletedQuiz)}`}</span>
                            </span>
                          )}
                          gutterRight
                          gutterBottom
                        />
                      </Link>
                    ))}
                  </div>
                )}
                {delegated?.length > 0 && (
                  <div>
                    <h4 style={{ color: '#000000'}}>Delegated classes</h4>
                    <div className='classesCardContainer'>
                      {delegated?.map((teacherClass: TeacherClasses) => (
                        <Link to={`/my-classes/class/${teacherClass.class._id}`} key={teacherClass.class._id}>
                          <BasicCard
                            styles='delegatedClassesPageCard'
                            preTitle={(
                              <span className='cardPreTitle'>
                                Year
                                {' '}
                                {teacherClass.class.class_year_id.year}
                              </span>
                            )}
                            code={teacherClass.class.code}
                            title={teacherClass.class.name}
                            detail={(
                              <div>
                                <div style={{ marginBottom: '15px'}}>
                                  <span style={{ fontSize: '14px' }}>Teacher: {teacherClass.class.created_by.first_name + ' ' + teacherClass.class.created_by.last_name}</span>
                                </div>
                                <span className='classesCardDetailBox'>
                                  <span>{`${teacherClass.numberOfStudents} ${checkSingularWord('Student', 'Students', teacherClass.numberOfStudents)}`}</span>
                                  <span>{`${teacherClass.numberofCompletedQuiz} ${checkSingularWord('Completed Quiz', 'Completed Quizzes', teacherClass.numberofCompletedQuiz)}`}</span>
                                </span>
                              </div>
                            )}
                            gutterRight
                            gutterBottom
                          />
                        </Link>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <div className='noClassFound'>
                <img src={NothingFoundSVG} alt='No data found' loading='lazy' />
                <div className='textBox'>
                  <h4>No Class Found</h4>
                  <p>Create your first class to get you going.</p>
                </div>
                <ButtonComponent
                  type='contained'
                  text='Create your First Class'
                  size='medium'
                  className='createFirstClassBtn'
                  startIcon={<PlusIcon />}
                  onClick={(): void => { setIsOpen(!isOpen); }}
                />
                <AddClassModal
                  isOpen={isOpen}
                  onCloseHandler={(): void => { setIsOpen(false); }}
                />
              </div>
            )
          : null }
      </div>
    </NavbarWrapper>
  );
}
