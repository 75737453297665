import React from 'react';
import { Grid } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import 'react-circular-progressbar/dist/styles.css';
import type { AdminAggregatedReportQuestionStatsType } from 'utils/types';

interface StatsSectionProps {
  data: AdminAggregatedReportQuestionStatsType;
}

export default function StatsSection(props: StatsSectionProps): JSX.Element {
  const { data } = props;
  const option = {
    tooltip: {
      trigger: 'item',
      valueFormatter: (value: number) => `${value}%`,
    },
    series: [{
      type: 'pie',
      radius: ['80%', '60%'],
      avoidLabelOverlap: false,
      label: {
        show: false,
        position: 'center',
      },
      markPoint: {
        tooltip: { show: false },
        label: {
          show: true,
          formatter: [`{a|${Number(data.correct_answer_performance.toFixed(1))}%}`, 'Total Correct'].join('\n'),
          color: '#868C9F',
          fontSize: 14,
          align: 'center',
          rich: {
            a: {
              color: '#353c55',
              fontSize: 22,
              fontWeight: 600,
              lineHeight: 35,
            },
          },
        },
        data: [
          {
            name: '',
            value: '',
            symbol: 'circle',
            itemStyle: { color: 'transparent' },
            x: '50%',
            y: '50%',
          },
        ],
      },
      emphasis: {
        scale: false,
      },
      labelLine: {
        show: false,
      },
      color: ['#19CB9E', 'orange', '#F64754'],
      data: [
        { value: Number(data.correct_answers_without_hint.toFixed(1)), name: 'Correct' },
        { value: Number(data.correct_answers_with_forced_hint.toFixed(1)), name: 'Correct with hint' },
        { value: Number(data.wrong_pct.toFixed(1)), name: 'Wrong' },
      ],
    },
    ],
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={4}>
          <div className='counter-block'>
            <p> Correct </p>
            <span>
              {Number(data.correct_answers_without_hint.toFixed(1))}
              %
            </span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='counter-block'>
            <p> Correct with Hint </p>
            <span>
              {Number(data.correct_answers_with_forced_hint.toFixed(1))}
              %
            </span>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className='counter-block'>
            <p> Wrong </p>
            <span>
              {Number(data.wrong_pct.toFixed(1))}
              %
            </span>
          </div>
        </Grid>
      </Grid>
      <Grid container justifyContent='start'>
        <Grid
          item
          xs={12}
          md={12}
        >
          <div className='stat-graph'>
            <ReactECharts
              option={option}
              notMerge
              lazyUpdate
              theme='theme_name'
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
