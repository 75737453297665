import React, { useState } from 'react';

export default function PageNotFound(): JSX.Element {
  const [isAuthRoutesLoaded, setIsAuthRoutesLoaded] = useState(false);
  setTimeout(() => {
    setIsAuthRoutesLoaded(true);
  }, 500);
  return (
    isAuthRoutesLoaded ? (
      <div className='page-not-found'>
        404
        <br />
        Sorry, Page not found
      </div>
    ) : (
      <div className='page-not-found'>
        ...
      </div>
    )

  );
}
