import React from 'react';
import { useNavigate } from 'react-router-dom';

import NewLogo from 'assets/svgs/ts/NewLogo';
import { teacherSideBarData } from 'utils/sidebarConfig';

export default function TeacherSideBar(): JSX.Element {
  const navigate = useNavigate();
  const clickOnSidebar = (link: string): void => {
    navigate(link);
  };
  return (
    <div className='sidebar-main-wrapper'>
      <div className='sidebar-wrapper'>
        <div className='logo-block'>
          <NewLogo />
        </div>
        <div className='options-block'>
          {teacherSideBarData.map((section) => {
            if (!section.hide) {
              return (
                <div key={section.sectionId} className='sidebar-section'>
                  <p>
                    {' '}
                    {section.sectionName}
                    {' '}
                  </p>
                  {section.tabs.map((tab) => {
                    if (!tab.hide) {
                      return (
                        <div className='tab-heading'>
                          <div key={tab.id} className='side-bar-option' role='presentation' onClick={(): void => { clickOnSidebar(tab.route); }}>
                            <div className='icon-side'>
                              <tab.icon />
                            </div>
                            <div className='text-side'>
                              <p>
                                {' '}
                                {tab.heading}
                                {' '}
                              </p>
                              <p>
                                {' '}
                                {tab.subheading}
                                {' '}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })}

                  {section.sectionDivider
                    ? (
                      <div className='divider'>
                        <div className='line' />
                      </div>
                    ) : null }

                </div>
              );
            }

            return null;
          })}
        </div>

      </div>

    </div>
  );
}
