/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';

interface AutoCompleteFieldProps {
  state: string | null;
  data: string[];
  label: string;
  placeholder?: string;
  onChange: (name: string, value: string | null) => void;
}

export default function AutoCompleteField(props: AutoCompleteFieldProps): JSX.Element {
  const {
    data, onChange, label, state, placeholder
  } = props;
  const onChangeHandler = (e: React.SyntheticEvent, value: string | null): void => {
    onChange(label, value);
  };
  return (
    <Autocomplete
      className={clsx('auto-complete-field', state !== null && 'selected')}
      value={state}
      disablePortal
      options={data}
      onChange={onChangeHandler}
      renderInput={(params): JSX.Element => (
        <TextField
          {...params}
          placeholder={placeholder ? placeholder : 'Add tag'}
          className='auto-complete-input-field'
        />
      )}
    />
  );
}
