/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';
import { Dialog, DialogContent } from '@mui/material';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import type {
  Choice, EditorInput, EquationAnswerType, EquationAnswerTypeFour, Question,
} from 'utils/types';
import clsx from 'clsx';
import MQStaticField from 'components/shared/atoms/MathQuill/MQStaticField';
import McqChoiceContainer from './McqChoiceContainer';
import 'styles/components/shared/molecules/QuizDetailPage/ShowAnswerModal.scss';

interface ShowAnswerModalProps {
  data: Question | null;
  isOpen: boolean;
  questionType: string;
  onCloseHandler: () => void;
  inputAnswer: string;
}

export default function ShowAnswerModal(props: ShowAnswerModalProps): JSX.Element | null {
  const {
    isOpen, onCloseHandler, data, questionType, inputAnswer,
  } = props;
  if (inputAnswer === '') return null;
  return (
    <Dialog open={isOpen} className='show-answer-modal'>
      <DialogContent className='content-box'>
        <div className='answer-modal-header'>
          <div className='modal-title'>
            You entered an incorrect answer.
          </div>
          <ButtonComponent
            size='large'
            className='bg-blue some-extra-padding h-48'
            type='contained'
            text='Got it'
            onClick={onCloseHandler}
          />
        </div>
        <div className='answer-modal-body'>
          <div className='input-answer-container'>
            <div className='input-answer-inner'>
              <h4 className='input-answer-heading'>Your Answer</h4>
              {questionType === 'Equation Type'
                ? (
                  <div className='present-equation-question-right-box-list'>
                    { data && data.body.length > 0 && data.body.map((item: EditorInput, index: number) => {
                      if (item.type === 1) {
                        const answerArr: any[] = JSON.parse(inputAnswer);
                        const answer = answerArr.find((ans) => ans.componentIndex === index)?.answer;
                        return (
                          <MQStaticField
                            key={item.id}
                            latex={item.state}
                            answers={answer}
                            className={clsx('student-equation-single-component')}
                            isStudentStaticField
                          />
                        );
                      }
                      if (item.type === 2) {
                        return (<div key={item.id} className='student-equation-single-component'>{item.state}</div>);
                      }
                      if (item.type === 4) {
                        const answerArr: any[] = JSON.parse(inputAnswer);
                        const answer = answerArr.find((ans) => ans.componentIndex === index)?.answer;
                        return (
                          <div key={item.id} className='student-answer-field student-equation-single-component'>
                            <input readOnly value={answer} />
                            <span>{` ${item.unit}`}</span>
                          </div>
                        );
                      }
                      return (<div className='student-equation-single-component-new-line' key={item.id} />);
                    })}
                  </div>
                )
                : (
                  <McqChoiceContainer
                    className='single-present-mcq-question show-right-option'
                    data={data?.body.find((choice: Choice) => choice.key === inputAnswer)}
                    answer={inputAnswer}
                  />
                )}
            </div>
          </div>
          <div className='answer-modal-divider' />
          <div className='correct-answer-container'>
            <div className='correct-answer-inner'>
              <h4 className='correct-answer-heading'>Correct Answer</h4>

              {questionType === 'Equation Type'
                ? (
                  <div className='present-equation-question-right-box-list'>
                    {data && data.body.length > 0 && data.body.map((item: EditorInput, index: number) => {
                      if (item.type === 1) {
                        const answerArr: EquationAnswerType[] = typeof data.answer === 'string' ? JSON.parse(data.answer) : data.answer;
                        const answer = answerArr.find((ans) => ans.componentIndex === index)?.answer;
                        return (
                          <MQStaticField
                            key={item.id}
                            latex={item.state}
                            answers={answer}
                            className={clsx('student-equation-single-component')}
                            isStudentStaticField
                          />
                        );
                      }
                      if (item.type === 2) {
                        return (<div key={item.id} className='student-equation-single-component'>{item.state}</div>);
                      }
                      if (item.type === 4) {
                        const answerArr: EquationAnswerTypeFour[] = typeof data.answer === 'string' ? JSON.parse(data.answer) : data.answer;
                        const answer = answerArr.find((ans) => ans.componentIndex === index)?.answer;
                        return (
                          <div key={item.id} className={clsx('student-answer-field', 'student-equation-single-component')}>
                            <input readOnly value={answer} />
                            <span>{` ${item.unit}`}</span>
                          </div>
                        );
                      }
                      return (<div className='student-equation-single-component-new-line' key={item.id} />);
                    })}
                  </div>
                )
                : (
                  <McqChoiceContainer
                    className='single-present-mcq-question show-right-option'
                    data={data?.body.find((choice: Choice) => choice.key === data.answer)}
                    answer={data?.answer ?? ''}
                  />
                )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
