import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputField from 'components/shared/atoms/inputField/InputField';

interface InputFieldProps {
  variant: 'filled' | 'outlined' | 'standard';
  text: string;
  name: string;
  value: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  error?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export default function PasswordInputField(props: InputFieldProps): JSX.Element {
  const {
    name, text, variant, value, onChange, className, error, onBlur,
  } = props;
  const [isVisibility, setIsVisibility] = useState(false);

  return (
    <div>
      <InputField
        name={name}
        type={isVisibility ? 'text' : 'password'}
        variant={variant}
        text={text}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        Icon={isVisibility ? Visibility : VisibilityOff}
        iconPosition='start'
        iconOnClickHandler={(): void => {
          setIsVisibility((prev) => !prev);
        }}
        error={error}
      />
    </div>
  );
}
