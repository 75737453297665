import React from 'react';
import type { SVGProps } from 'utils/types';

export default function ArrowDownIcon(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M18.9428 11.3908C19.1927 11.6409 19.3332 11.9799 19.3332 12.3335C19.3332 12.687 19.1927 13.0261 18.9428 13.2762L10.9428 21.2762C10.6927 21.5261 10.3537 21.6665 10.0001 21.6665C9.64656 21.6665 9.30748 21.5261 9.05744 21.2762L1.05744 13.2762C0.930095 13.1532 0.828518 13.006 0.75864 12.8434C0.688761 12.6807 0.651979 12.5057 0.650441 12.3287C0.648903 12.1517 0.682638 11.9761 0.749679 11.8122C0.816721 11.6484 0.915725 11.4995 1.04091 11.3743C1.16611 11.2491 1.31497 11.1501 1.47884 11.0831C1.6427 11.016 1.81827 10.9823 1.99531 10.9838C2.17235 10.9854 2.34731 11.0221 2.50998 11.092C2.67265 11.1619 2.81978 11.2635 2.94277 11.3908L8.66677 17.1148V1.66683C8.66677 1.31321 8.80725 0.974069 9.0573 0.72402C9.30735 0.473972 9.64649 0.333496 10.0001 0.333496C10.3537 0.333496 10.6929 0.473972 10.9429 0.72402C11.193 0.974069 11.3334 1.31321 11.3334 1.66683V17.1148L17.0574 11.3908C17.3075 11.1409 17.6466 11.0004 18.0001 11.0004C18.3537 11.0004 18.6927 11.1409 18.9428 11.3908Z' />
    </svg>
  );
}
