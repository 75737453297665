import React from 'react';

export function QuizBuilderIcon(): JSX.Element {
  return (
    <svg width='12' height='16' viewBox='0 0 12 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M4.29289 0.292893C4.48043 0.105357 4.73478 0 5 0H7C7.26522 0 7.51957 0.105357 7.70711 0.292893C7.89464 0.48043 8 0.734784 8 1C8 1.26522 7.89464 1.51957 7.70711 1.70711C7.51957 1.89464 7.26522 2 7 2H5C4.73478 2 4.48043 1.89464 4.29289 1.70711C4.10536 1.51957 4 1.26522 4 1C4 0.734784 4.10536 0.48043 4.29289 0.292893Z' fill='#4094F7' />
      <path fillRule='evenodd' clipRule='evenodd' d='M0.585786 1.58579C0.210713 1.96086 0 2.46957 0 3V14C0 14.5304 0.210713 15.0391 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H10C10.5304 16 11.0391 15.7893 11.4142 15.4142C11.7893 15.0391 12 14.5304 12 14V3C12 2.46957 11.7893 1.96086 11.4142 1.58579C11.0391 1.21071 10.5304 1 10 1C10 1.79565 9.68393 2.55871 9.12132 3.12132C8.55871 3.68393 7.79565 4 7 4H5C4.20435 4 3.44129 3.68393 2.87868 3.12132C2.31607 2.55871 2 1.79565 2 1C1.46957 1 0.960859 1.21071 0.585786 1.58579ZM2.29289 7.29289C2.48043 7.10536 2.73478 7 3 7H3.01C3.27522 7 3.52957 7.10536 3.71711 7.29289C3.90464 7.48043 4.01 7.73478 4.01 8C4.01 8.26522 3.90464 8.51957 3.71711 8.70711C3.52957 8.89464 3.27522 9 3.01 9H3C2.73478 9 2.48043 8.89464 2.29289 8.70711C2.10536 8.51957 2 8.26522 2 8C2 7.73478 2.10536 7.48043 2.29289 7.29289ZM5.29289 7.29289C5.48043 7.10536 5.73478 7 6 7H9C9.26522 7 9.51957 7.10536 9.70711 7.29289C9.89464 7.48043 10 7.73478 10 8C10 8.26522 9.89464 8.51957 9.70711 8.70711C9.51957 8.89464 9.26522 9 9 9H6C5.73478 9 5.48043 8.89464 5.29289 8.70711C5.10536 8.51957 5 8.26522 5 8C5 7.73478 5.10536 7.48043 5.29289 7.29289ZM2.29289 11.2929C2.48043 11.1054 2.73478 11 3 11H3.01C3.27522 11 3.52957 11.1054 3.71711 11.2929C3.90464 11.4804 4.01 11.7348 4.01 12C4.01 12.2652 3.90464 12.5196 3.71711 12.7071C3.52957 12.8946 3.27522 13 3.01 13H3C2.73478 13 2.48043 12.8946 2.29289 12.7071C2.10536 12.5196 2 12.2652 2 12C2 11.7348 2.10536 11.4804 2.29289 11.2929ZM5.29289 11.2929C5.48043 11.1054 5.73478 11 6 11H9C9.26522 11 9.51957 11.1054 9.70711 11.2929C9.89464 11.4804 10 11.7348 10 12C10 12.2652 9.89464 12.5196 9.70711 12.7071C9.51957 12.8946 9.26522 13 9 13H6C5.73478 13 5.48043 12.8946 5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929Z' fill='#4094F7' />
    </svg>
  );
}
