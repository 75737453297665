import type { IUser } from 'utils/types';

export function getUserDetail(): IUser | null {
  try {
    const user = localStorage.getItem('user');
    return JSON.parse(user ?? '');
  } catch (ex) {
    return null;
  }
}
