import React from 'react';
import type { SVGProps } from 'utils/types';

export default function DoubleDownArrow(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M12.7076 0.792787C12.895 0.980314 13.0004 1.23462 13.0004 1.49979C13.0004 1.76495 12.895 2.01926 12.7076 2.20679L7.70757 7.20679C7.52004 7.39426 7.26573 7.49957 7.00057 7.49957C6.7354 7.49957 6.4811 7.39426 6.29357 7.20679L1.29357 2.20679C1.11141 2.01818 1.01062 1.76558 1.0129 1.50339C1.01517 1.24119 1.12034 0.990376 1.30575 0.804968C1.49116 0.61956 1.74197 0.514391 2.00417 0.512112C2.26636 0.509834 2.51897 0.610629 2.70757 0.792787L7.00057 5.08579L11.2936 0.792787C11.4811 0.605316 11.7354 0.5 12.0006 0.5C12.2657 0.5 12.52 0.605316 12.7076 0.792787ZM12.7076 6.79279C12.895 6.98031 13.0004 7.23462 13.0004 7.49979C13.0004 7.76495 12.895 8.01926 12.7076 8.20679L7.70757 13.2068C7.52004 13.3943 7.26573 13.4996 7.00057 13.4996C6.7354 13.4996 6.4811 13.3943 6.29357 13.2068L1.29357 8.20679C1.19806 8.11454 1.12188 8.00419 1.06947 7.88219C1.01706 7.76019 0.989473 7.62897 0.988319 7.49619C0.987165 7.36341 1.01247 7.23173 1.06275 7.10883C1.11303 6.98594 1.18728 6.87428 1.28117 6.78039C1.37507 6.6865 1.48672 6.61225 1.60962 6.56196C1.73251 6.51168 1.86419 6.48638 1.99697 6.48754C2.12975 6.48869 2.26097 6.51628 2.38297 6.56868C2.50498 6.62109 2.61532 6.69728 2.70757 6.79279L7.00057 11.0858L11.2936 6.79279C11.4811 6.60532 11.7354 6.5 12.0006 6.5C12.2657 6.5 12.52 6.60532 12.7076 6.79279Z' fill='#1A61FF' />
    </svg>
  );
}
