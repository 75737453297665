import { combineReducers } from 'redux';
import auth from 'store/reducer/authSlice';
import { quizzesApi } from './ApiCall/quizzesApiCall';
import { schoolApi } from './ApiCall/schoolApiCall';
import { authenticationApi } from './ApiCall/authApiCalls';
import { studentApi } from './ApiCall/studentApiCalls';
import { classesApi } from './ApiCall/classesApiCall';
import { manageApi } from './ApiCall/managementApiCall';
import { reportApi } from './ApiCall/reportApiCalls';
import { questionsApi } from './ApiCall/questionApiCall';

export default combineReducers({
  auth,
  [quizzesApi.reducerPath]: quizzesApi.reducer,
  [schoolApi.reducerPath]: schoolApi.reducer,
  [authenticationApi.reducerPath]: authenticationApi.reducer,
  [studentApi.reducerPath]: studentApi.reducer,
  [classesApi.reducerPath]: classesApi.reducer,
  [manageApi.reducerPath]: manageApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [questionsApi.reducerPath]: questionsApi.reducer,
});
