import NavbarWrapper from 'components/layout/NavbarWrapper';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { removeSpecialCharacters } from 'utils/helper';

export default function Dashboard(): JSX.Element {
  let { pathname } = useLocation();
  pathname = pathname === '/' ? 'dashboard' : pathname;
  return (
    <NavbarWrapper name='Dashboard'>
      <div className='dashboard-body-block'>
        <h2>{removeSpecialCharacters(pathname)}</h2>
      </div>
    </NavbarWrapper>
  );
}
