import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import CircularLoader from 'components/shared/molecules/Loader';
import { useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';
import { useTeacherAggregatedReportQuery } from 'store/ApiCall/reportApiCalls';
import { returnQuestionType } from 'utils/helper';
import SingleQuestion from './SingleQuestion';

export default function TeacherAggregatedResponses(): JSX.Element {
  const { quizId = '', classId = '' } = useParams();

  // Question type Api
  const { data: questionTypeList, isLoading: isGettingQuestionTypeList } = useGetQuestionTypeListQuery();
  const { data: aggregateResponse, isLoading, refetch } = useTeacherAggregatedReportQuery({ quizId, classId });

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading || isGettingQuestionTypeList) return <CircularLoader />;

  return (
    <div className='aggregation-responses'>
      {aggregateResponse && Object.keys(aggregateResponse.data).map((key) => {
        const data = aggregateResponse.data[Number(key)];
        return (
          <SingleQuestion
            key={data.question.order_num}
            data={data}
            questionType={returnQuestionType(data.question.type_id, questionTypeList?.data)}
          />
        );
      })}
    </div>
  );
}
