/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem, IconButton } from '@mui/material';
import ConfirmationModal from 'components/shared/molecules/ConfirmationModal';
import { useDuplicateQuizMutation, useDeleteQuizMutation } from 'store/ApiCall/quizzesApiCall';
import PresentActionIcon from 'assets/svgs/presentAction.svg';
import EditActionIcon from 'assets/svgs/edit.svg';
import ReportActionIcon from 'assets/svgs/report.svg';
import ActionsIcon from 'assets/svgs/actionsIcon.svg';
import { useSnackbar } from 'notistack';

export default function ActionsComponent({ quizId, quizTitle }: { quizId: string; quizTitle: string }): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [affectedqQuizAttempts, setAffectedQuizAttempts] = React.useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const [duplicateQuiz] = useDuplicateQuizMutation();
  const [deleteQuiz] = useDeleteQuizMutation();

  const handleClose = (event: React.SyntheticEvent): void => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  // ? 1st btn event handler
  const presentActionHandler = (event: React.SyntheticEvent, id: string): void => {
    event.stopPropagation();
    navigate(`/quiz/${id}/present`);
  };

  // ? detail action handler
  const detailActionHandler = (event: React.SyntheticEvent, id: string): void => {
    event.stopPropagation();
    navigate(`/quiz-detail/${id}`);
    setAnchorEl(null);
  };

  // ? edit action handler
  const editActionHandler = (event: React.SyntheticEvent, id: string): void => {
    event.stopPropagation();
    navigate(`/quiz-builder/edit/${id}`);
    setAnchorEl(null);
  };

  const reportViewActionHandler = (event: React.SyntheticEvent, id: string): void => {
    event.stopPropagation();
    navigate(`/quiz/${id}/report-summary`);
    setAnchorEl(null);
  };

  // ? duplicate action handler
  const duplicateActionHandler = async (event: React.SyntheticEvent, id: string): Promise<void> => {
    event.stopPropagation();
    setAnchorEl(null);

    try {
      const { success, message } = await duplicateQuiz(id).unwrap();
      if (success) {
        enqueueSnackbar(message, { variant: 'success' });
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // ? delete action handler
  const deleteActionHandler = async (event: React.SyntheticEvent, id: string): Promise<void> => {
    event.stopPropagation();
    setAnchorEl(null);

    try {
      const response = await deleteQuiz(id).unwrap();

      if (response.success) {
        if (response.data?.warning) {
          setAffectedQuizAttempts(response.data.affectedAttemptRecords || 0);
          setIsModalOpen(true);
        } else {
          enqueueSnackbar(response.message, { variant: 'success' });
        }
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // ? 2nd btn event handler
  const allActionsHandler = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className={clsx('tableCell', 'actionsCell')}>
      <div className='actionCellInner'>
        <IconButton type='button' className='presentActionBtn' onClick={(event): void => { presentActionHandler(event, quizId); }}><img src={PresentActionIcon} alt='present' /></IconButton>
        <IconButton type='button' className='presentActionBtn' onClick={(event): void => { editActionHandler(event, quizId); }}><img src={EditActionIcon} alt='edit' /></IconButton>
        <IconButton type='button' className='presentActionBtn' onClick={(event): void => { reportViewActionHandler(event, quizId); }}><img src={ReportActionIcon} alt='reports' /></IconButton>
        <IconButton type='button' className='allActionsBtn' onClick={(event): void => { allActionsHandler(event); }}><img src={ActionsIcon} alt='actions' /></IconButton>
        <div className='actionsPopover'>
          <Menu
            id='basic-menu'
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={(event): void => { detailActionHandler(event, quizId); }}><span className='actionsMenu'>Detail</span></MenuItem>
            {/* <MenuItem onClick={(event): void => { editActionHandler(event, quizId); }}><span className='actionsMenu'>Edit</span></MenuItem> */}
            <MenuItem onClick={(event): void => { duplicateActionHandler(event, quizId); }}><span className='actionsMenu'>Duplicate</span></MenuItem>
            <MenuItem onClick={(event): void => { deleteActionHandler(event, quizId); }}><span className='actionsMenu'>Delete</span></MenuItem>
          </Menu>

        </div>
      </div>
      <ConfirmationModal
        isOpen={isModalOpen}
        id={quizId}
        quizTitle={quizTitle}
        quizAttempts={affectedqQuizAttempts}
        onCloseHandler={(e: React.SyntheticEvent): void => { e.stopPropagation(); setIsModalOpen(false); }}
      />
    </div>
  );
}
