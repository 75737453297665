import React from 'react';
import type { SVGProps } from 'utils/types';

export default function PresentIcon(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13.0268 6.27805C14.3244 7.05404 14.3244 8.94596 13.0268 9.72195L3.00337 15.7163C1.678 16.5089 -6.55151e-08 15.5468 0 13.9943L5.0592e-07 2.00567C5.71435e-07 0.453175 1.678 -0.508889 3.00337 0.28372L13.0268 6.27805Z' fill='#1A61FF' />
    </svg>
  );
}
