import type { RefObject } from 'react';
import React, { useState, useEffect } from 'react';
import type { EditorInput, SingleAnswerType } from 'utils/types';
import DeleteIcon from 'assets/svgs/delete.svg';
import { addStyles } from 'react-mathquill';

import type { MathField } from 'components/shared/atoms/MathQuill/types';
import EditableMathField from 'components/shared/atoms/MathQuill/MQEquationField';

interface EditorEquationFieldProps {
  componentRef?: RefObject<unknown>;
  onChangeHandler: (id: string, value: string) => void;
  onDelete: (id: string) => void;
  component: EditorInput;
  setFocused?: (isFocused: boolean) => void;
  answer?: SingleAnswerType[] | string;
  editSolvableHandler: () => void;
  updateEditorBoxIndex: (id?: number) => void;
}

addStyles();

export default function EditorEquationField(props: EditorEquationFieldProps): JSX.Element {
  const {
    onChangeHandler, onDelete, component: { id, state },
    componentRef, setFocused, answer, updateEditorBoxIndex, editSolvableHandler,
  } = props;
  const [latex, setLatex] = useState<string>(state);

  const MqbracketHandler = (): void => {
    const rootBlocks = Array.from(document.querySelectorAll('.mq-root-block'));
    rootBlocks.forEach((el) => {
      const bracket = Array.from(el.querySelectorAll('.mq-non-leaf'));
      if (bracket.length > 0) {
        el.className = 'mq-root-block mq-root-block-styles';
      } else {
        el.className = 'mq-root-block';
      }
    });
  };

  function handleChange(field: MathField): void {
    const fieldElement: HTMLElement = field.el();
    MqbracketHandler();
    // If root node then update
    if (fieldElement.classList.contains('mq-math-mode')) {
      onChangeHandler(id, field.latex());
      setLatex(field.latex());
    }
  }

  useEffect(() => {
    MqbracketHandler();
  }, []);
  return (
    <div className='math-equation-field'>
      <EditableMathField
        ref={componentRef}
        latex={latex}
        onChange={(field: MathField): void => { handleChange(field); }}
        setFocused={setFocused}
        answerArr={answer}
        editSolvableHandler={editSolvableHandler}
        updateEditorBoxIndex={updateEditorBoxIndex}
      />
      <img src={DeleteIcon} className='ml-8' alt='delete icon' role='presentation' onClick={(): void => { onDelete(id); }} />
      <div className='label'>Equation</div>
    </div>
  );
}
