/* eslint-disable react/no-array-index-key */
import clsx from 'clsx';
import React from 'react';

interface MultiTextLableProps {
  title?: string;
  label: string;
  tagArr?: string[];
  className?: string;
}

export default function MultiTextLable(props: MultiTextLableProps): JSX.Element {
  const {
    label, title, tagArr, className,
  } = props;

  return (
    <div className={clsx('multi-text-label', className)}>
      <div className='multi-text-label-container'>
        {label}
      </div>
      <div className='multi-text-label-body'>
        {title
          ? (
            <>
              <div className='multi-text-label-body-title'>{title}</div>
              <div className='multi-text-label-body-tags'>
                {tagArr?.map((tag, i) => {
                  if (tag) {
                    return (
                      <div key={i} className='multi-text-label-body-tag'>
                        {tag}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </>
          )
          : (
            <div className='multi-text-label-body-title'>No Quiz Available</div>
          )}

      </div>
    </div>
  );
}
