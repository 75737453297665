import React, { useState, useEffect } from 'react';

import Logo from 'assets/svgs/sidebarLogoNewWhite.svg';
import { getUserDetail } from 'utils/localStorage';
import HintAdded from 'assets/svgs/ts/HintAdded';
import HintModal from 'components/shared/molecules/QuizDetailPage/HintModal';
import Dropdown from 'components/shared/molecules/Dropdown/Dropdown';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Logout from 'assets/svgs/ts/Logout';
import type {
  APIResponse, DropdownType, Question,
} from 'utils/types';
import { useNavigate, useParams } from 'react-router-dom';
import { signoutAPICall } from 'store/ApiCall/authApiCalls';
import { signoutToStore } from 'store/reducer/authSlice';
import { useDispatch } from 'react-redux';
import type { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useGetQuizDetailQuery, useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';
import { CircularProgress } from '@mui/material';
import NoHint from 'assets/svgs/ts/NoHint';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import { checkSingularWord, returnQuestionType } from 'utils/helper';
import EquationQuestion from 'components/shared/molecules/AdminQuizPresent/EquationQuestion';
import MCQQuestion from 'components/shared/molecules/AdminQuizPresent/MCQQuestion';

// Dropdown data
const DropdownData: DropdownType[] = [
  { id: 0, name: 'Go Back', icon: <ArrowCircleLeftIcon className='quiz-present-back-icon' /> },
  { id: 1, name: 'Logout', icon: <Logout /> },
];

export default function AdminQuizPresent(): JSX.Element {
  const [showHint, setShowHint] = useState<boolean>(false);
  const [activeQuestion, setActiveQuestion] = useState<Question | null>(null);

  // get user from localstorage
  const user = getUserDetail();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // Question type Api
  const { data: questionTypeList, isLoading: isGettingQuestionTypes } = useGetQuestionTypeListQuery();
  // API call to get quiz
  const { data: quizData, isLoading: isGettingQuiz, error } = useGetQuizDetailQuery(id);

  useEffect(() => {
    if (quizData && quizData.data.questions.length > 0) {
      setActiveQuestion(quizData.data.questions[0]);
    }
  }, [quizData]);

  // Dropdown handler
  const onClickHandler = (data: DropdownType): void => {
    if (data.name === 'Go Back') {
      navigate(-1);
    } else {
      signoutAPICall().then(() => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        dispatch(signoutToStore(null));
      }).catch((err: AxiosError<APIResponse<null, undefined>>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
      });
    }
  };

  // Check if hint is added into the question or not
  const isHintAddedToQuestion = (question: Question): boolean => {
    if (question.hint_title || question.hint_image_url || question.hint_body) return true;
    return false;
  };

  // move to next question
  const onSubmitHandler = (): void => {
    if (quizData && activeQuestion) {
      if (activeQuestion.order_num === quizData.data.questions.length) {
        navigate(-1);
        return;
      }
      setActiveQuestion(quizData.data.questions[activeQuestion.order_num]);
    }
  };

  // Show Loader during API call
  if (isGettingQuiz || isGettingQuestionTypes) {
    return (
      <div className='quiz-present-page d-center'>
        <CircularProgress className='bg-white' />
      </div>
    );
  }

  if (error) {
    navigate(`/quiz-detail/${id}`);
  }

  const questionCount = quizData?.data.questions.length;

  return (
    <div className='quiz-present-page'>
      <div className='quiz-present-page-header'>
        <div className='quiz-present-page-header-row-1 mb-16'>
          <img className='logo' src={Logo} alt='app-log' />
          <div className='number-of-questions'>{`${quizData?.data.name} - ${questionCount} ${checkSingularWord('Question', 'Questions', questionCount)}`}</div>
          <div className='quiz-present-page-header-row-1-profile'>
            <div className='quiz-present-page-header-row-1-profile-text'>Logged in as</div>
            <Dropdown
              title={`${user?.first_name} ${user?.last_name}`}
              data={DropdownData}
              onClickHandler={onClickHandler}
            />

          </div>
        </div>
        <div className='quiz-present-page-header-row-2 mb-16'>
          {quizData?.data.questions.map((question) => (
            <div key={question._id} className='progressive-bar-component' />
          ))}
        </div>
        <div className='quiz-present-page-header-row-3'>
          <div className='quiz-present-page-header-row-3-text'>{`Question ${activeQuestion?.order_num}`}</div>
          {activeQuestion && isHintAddedToQuestion(activeQuestion)
            ? <HintAdded className='cursor-pointer' onClick={(): void => { setShowHint(true); }} />
            : <NoHint />}
        </div>

      </div>
      <div className='quiz-present-page-body'>
        <div className='quiz-present-page-body-question-container'>
          {returnQuestionType(activeQuestion?.type_id ?? '', questionTypeList?.data) === 'Equation Type'
            ? <EquationQuestion data={activeQuestion} />
            : <MCQQuestion data={activeQuestion} />}
        </div>
        <div className='quiz-present-page-body-footer'>
          <ButtonComponent
            size='large'
            className='bg-blue extra-padding'
            type='contained'
            text={questionCount === activeQuestion?.order_num ? 'End Presentation' : 'Submit'}
            onClick={onSubmitHandler}
          />
        </div>
      </div>
      <HintModal
        data={activeQuestion}
        isOpen={showHint}
        onCloseHandler={(): void => { setShowHint(false); }}
      />
    </div>
  );
}
