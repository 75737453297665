import React from 'react';

interface LayoutContainerProps {
  children?: JSX.Element;
}

export default function LayoutContainer(props: LayoutContainerProps): JSX.Element {
  const { children } = props;
  return (
    <div className='layout-container'>{children}</div>
  );
}
