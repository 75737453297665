/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { Avatar, LinearProgress, Tooltip } from '@mui/material';
import { property, uniq } from 'lodash';
import type {
  IQuizReportsData,
  ClassQuizData,
  TableDataType,
  StudentListDataType,
  StudentTablePreprocessData,
  SchoolDataType,
  SchoolTablePreprocessData,
  StudentList,
  StudentListPreprocessing,
  SingleQuizSummaryDataType,
  PendingTeachersPreprocessing,
  PendingTeachersType,
  ApprovedTeachersType,
  ApprovedTeachersPreprocessing,
  ApprovedTeacherClasses,
  IQuizReportsPreprocessing,
  IAdminQuizReportsData,
  IAdminQuizReportsPreprocessing,
  IAttempts,
  IStudentAttempts,
  SingleStudentSummaryDataType,
  TeacherStudentListPreprocessing,
  IClassTeacherPreprocessing,
  IClassTeacher,
} from 'utils/types';
import 'styles/components/shared/molecules/TablePreprocessing/main.scss';
import { progresscolorDecideOnAvg, secondsToTime } from './helper';
import TeacherStudentActionsComponent from "../components/shared/molecules/TeacherAction";
import TeacherAccessAction from 'components/shared/molecules/ClassSettings/TeacherAccessAction';

export const checkStatus = (val: string): string => {
  // Add more conditions and related css class names if needed
  if (val.toLowerCase() === 'published') return 'publishedStatus';
  if (val.toLowerCase() === 'canceled') return 'canceledStatus';
  return 'defaultStatus';
};

export const statusComponent = (status: string): JSX.Element => (
  <span className='tableCell'>
    <div className={clsx('statusCircle', checkStatus(status))} />
    <span>{status}</span>
  </span>
);

export const authorComponent = (fName: string, lName: string): JSX.Element => {
  const fullName = fName.concat(' ', lName);
  return (
    <span className={clsx('tableCell', 'nameCell')}>
      <div className='avatar'><Avatar className='author-avatar' sx={{ width: '24px', height: '24px', marginRight: '8px' }} /></div>
      {fullName}
    </span>
  );
};

export const preprocessQuizTableData = (allQuiz: ClassQuizData[], ActionsComponent: React.ElementType): TableDataType[] => {
  const tableData: TableDataType[] = [];
  allQuiz.forEach((item: ClassQuizData): void => {
    tableData.push({
      quizId: item._id,
      order: <span className='tableCell'>{item.order_number}</span>,
      code: <span className={clsx('tableCell')}>{item.code}</span>,
      quizName: <span className={clsx('tableCell', 'quizNameCell')}>{item.name}</span>,
      // eslint-disable-next-line no-nested-ternary
      progress: <Tooltip title={`${Math.ceil(item.performance?.avg_performance)}%`} placement='top' arrow><span className='studentTableCell studentTableNameCell progressbar-cell'><LinearProgress variant='determinate' color={progresscolorDecideOnAvg(item.performance?.avg_performance)} value={item.performance?.avg_performance ?? 0} /></span></Tooltip>,
      //      createdBy: authorComponent(`${item.create_by.first_name}`, `${item.create_by.last_name}`),
      createdDate: <span className='tableCell'>{moment.utc(item.createdAt).local().format('MMMM D, YYYY')}</span>,
      actions: <ActionsComponent quizId={item._id} quizTitle={item.name} />,
    });
  });

  return tableData;
};

export const columnsData = [
  { property: 'order', name: 'Order' },
  { property: 'code', name: 'Code' },
  { property: 'quizName', name: 'Quiz Name' },
  { property: 'progress', name: 'Performance' },
  //  { property: 'createdBy', name: 'Created By' },
  { property: 'createdDate', name: 'Created Date' },
  { property: 'actions', name: 'Actions' },
];

// ? Student List Table Preprocessing
export const StudentColumnsData = [
  { property: 'name', name: 'Name' },
  { property: 'createdAt', name: 'Register Date' },
  { property: 'lastLogin', name: 'Last Login' },
  { property: 'avg_performance', name: 'Avg.Performance' },
  { property: 'attemptedQuizCount', name: 'Attempted Quizzes' },
];

export const preprocessStudentTableData = (data: StudentListDataType[]): StudentTablePreprocessData[] => {
  const tableData: StudentTablePreprocessData[] = [];

  data.forEach((item: StudentListDataType): void => {
    tableData.push({
      name: <span className={clsx('studentTableCell student-normal', 'studentTableNameCell')}>{`${item.student.first_name} ${item.student.last_name}`}</span>,
      createdAt: <span className='studentTableCell registerDate'>{moment.utc(item.student.createdAt).local().format('MMMM D, YYYY')}</span>,
      lastLogin: <span className='studentTableCell lastLogin'>{item.student.last_login ? moment.utc(item.student.last_login).local().format('MMMM D, YYYY') : ''}</span>,
      attemptedQuizCount: <span className='studentTableCell lastLogin'>{item.attempted_quiz_count}</span>,
      avg_performance: <Tooltip title={`${Math.ceil(item.avg_performance)}%`} placement='top' arrow><span className='studentTableCell studentTableNameCell progressbar-cell'><LinearProgress variant='determinate' color={progresscolorDecideOnAvg(item.avg_performance)} value={item.avg_performance} /></span></Tooltip>,
      id: item.student._id,
    });
  });

  return tableData;
};

// ? School List Table Preprocessing
export const SchoolColumnsData = [
  { property: 'schoolName', name: 'School Name' },
  { property: 'totalStudents', name: 'No. of Students' },
  { property: 'totalTeachers', name: 'No. of Teachers' },
  { property: 'registerDate', name: 'Register Date' },
];

export const preprocessSchoolTableData = (data: SchoolDataType[]): SchoolTablePreprocessData[] => {
  const tableData: SchoolTablePreprocessData[] = [];

  data.forEach((item: SchoolDataType): void => {
    tableData.push({
      schoolName: <span className={clsx('schoolTableCell', 'schoolTableNameCell')}>{item.school.name}</span>,
      totalStudents: <span className='schoolTableCell'>{item.number_of_student}</span>,
      totalTeachers: <span className='schoolTableCell'>{item.number_of_teacher}</span>,
      registerDate: <span className='schoolTableCell'>{moment.utc(item.school.createdAt).local().format('MMMM D, YYYY')}</span>,
    });
  });

  return tableData;
};

// ? Student List Table >> Management Section - preprocessing
export const StudentListColumn = [
  { property: 'studentName', name: 'Student Name' },
  { property: 'class', name: 'Class Year' },
  { property: 'school', name: 'School' },
  { property: 'registerDate', name: 'Register Date' },
];

export const preprocessStudentList = (data: StudentList[]): StudentListPreprocessing[] => {
  const tableData: StudentListPreprocessing[] = [];

  if (data && data.length > 0) {
    data.forEach((item: StudentList): void => {
      tableData.push({
        studentName: <span className={clsx('studentListCell', 'studentListNameCell')}>{`${item.user_id.first_name} ${item.user_id.last_name}`}</span>,
        class: <span className='studentListCell'>{item.class_id.class_year_id.year}</span>,
        school: <span className='studentListCell'>{item.school_id.name}</span>,
        registerDate: <span className='studentListCell'>{moment.utc(item.createdAt).local().format('MMMM D, YYYY')}</span>,
      });
    });
  }

  return tableData;
};

export const TeacherStudentListColumn = [
  { property: 'studentName', name: 'Student Name' },
  { property: 'email', name: 'Email' },
  { property: 'class', name: 'Class Year' },
  { property: 'school', name: 'School' },
  { property: 'action', name: 'Action' },
];

export const preprocessTeacherStudentList = (data: StudentList[]): TeacherStudentListPreprocessing[] => {
  const tableData: TeacherStudentListPreprocessing[] = [];

  if (data && data.length > 0) {
    data.forEach((item: StudentList): void => {
      tableData.push({
        studentName: <span className={clsx('studentListCell', 'studentListNameCell')}>{`${item.user_id.first_name} ${item.user_id.last_name}`}</span>,
        email: <span className={clsx('studentListCell', 'studentListNameCell')}>{`${item.user_id.email}`}</span>,
        class: <span className='studentListCell'>{item.class_id.class_year_id.year}</span>,
        school: <span className='studentListCell'>{item.school_id.name}</span>,
        action: <TeacherStudentActionsComponent student={item} />,
      });
    });
  }

  return tableData
};

// ? Teacher List Table >> Management Section - preprocessing
export const PendingTeachersColumn = [
  { property: 'teacherName', name: 'Teacher Name' },
  { property: 'school', name: 'School' },
  { property: 'email', name: 'Email Address' },
  { property: 'registerDate', name: 'Register date' },
  { property: 'actions', name: 'Actions' },
];

export const preprocessPendingTeachers = (data: PendingTeachersType[], TeacherActionsComponent: ({ teacherId }: { teacherId: string }) => JSX.Element): PendingTeachersPreprocessing[] => {
  const tableData: PendingTeachersPreprocessing[] = [];

  data.forEach((item: PendingTeachersType): void => {
    tableData.push({
      teacherName: <span className={clsx('pendingTeacherCell', 'pendingTeacherNameCell')}>{`${item.teacher.first_name} ${item.teacher.last_name}`}</span>,
      school: <span className='pendingTeacherCell'>{item.school.name}</span>,
      email: <span className='pendingTeacherCell'>{item.teacher.email}</span>,
      registerDate: <span className='pendingTeacherCell'>{moment.utc(item.teacher.createdAt).local().format('MMMM D, YYYY')}</span>,
      actions: <TeacherActionsComponent teacherId={item.teacher._id} />,
    });
  });

  return tableData;
};

export const ApprovedTeachersColumn = [
  { property: 'teacherName', name: 'Teacher Name' },
  { property: 'school', name: 'School' },
  { property: 'email', name: 'Email' },
  { property: 'classes', name: 'Class Years' },
  { property: 'registerDate', name: 'Register Date' },
];

export const preprocessApprovedTeachers = (data: ApprovedTeachersType[]): ApprovedTeachersPreprocessing[] => {
  const tableData: ApprovedTeachersPreprocessing[] = [];

  data.forEach((item: ApprovedTeachersType): void => {
    const classesList: string[] = [];
    item.classes.forEach((c: ApprovedTeacherClasses) => {
      if (c.class_year_id.year) {
        classesList.push(c.class_year_id.year.toString().trim());
      }
    });

    tableData.push({
      teacherName: <span className={clsx('approvedTeacherCell', 'approvedTeacherNameCell')}>{`${item.teacher.first_name} ${item.teacher.last_name}`}</span>,
      school: <span className='approvedTeacherCell'>{item.school.name}</span>,
      email: <span className='approvedTeacherCell'>{item.teacher.email}</span>,
      classes: <span className={clsx('approvedTeacherCell', 'approvedTeacherClassesCell')}>{uniq(classesList).join(', ')}</span>,
      registerDate: <span className='approvedTeacherCell'>{moment.utc(item.teacher.createdAt).local().format('MMMM D, YYYY')}</span>,
    });
  });

  return tableData;
};

// Single quiz summary
export const SingleQuizSummary = [
  { property: 'studentName', name: 'Student Name' },
  { property: 'performance', name: 'Performance' },
  { property: 'hintsUsed', name: 'Hints used' },
  { property: 'timeTaken', name: 'Time Taken ↑' },
];
export const SingleStudentSummaryColumn = [
  { property: 'quizName', name: 'Quiz' },
  { property: 'performance', name: 'Performance' },
  { property: 'hintsUsed', name: 'Hints used' },
  { property: 'timeTaken', name: 'Time Taken ↑' },
];

export const SingleQuizSummaryData = (attempts: IAttempts[]): SingleQuizSummaryDataType[] => {
  const allAttempts = attempts.map((singleAttempt: IAttempts) => ({
    studentName: singleAttempt.student_name,
    performance: <Tooltip title={`${Math.ceil(singleAttempt.performance)}%`} placement='top' arrow><span className=' studentTableNameCell progressbar-cell-summary'><LinearProgress variant='determinate' color={progresscolorDecideOnAvg(singleAttempt.performance)} value={singleAttempt.performance} /></span></Tooltip>,
    hintsUsed: singleAttempt.hints_taken,
    timeTaken: secondsToTime(singleAttempt.seconds_taken),
    id: singleAttempt.user_id,
  }));
  return allAttempts;
};
export const SingleStudentSummaryData = (attempts: IStudentAttempts[]): SingleStudentSummaryDataType[] => {
  const allAttempts = attempts.map((singleAttempt: IStudentAttempts) => ({
    quizName: singleAttempt.quiz_name,
    performance: <Tooltip title={`${Math.ceil(singleAttempt.performance)}%`} placement='top' arrow><span className='studentTableCell studentTableNameCell progressbar-cell'><LinearProgress variant='determinate' color={progresscolorDecideOnAvg(singleAttempt.performance)} value={singleAttempt.performance} /></span></Tooltip>,
    hintsUsed: singleAttempt.hints_taken,
    timeTaken: secondsToTime(singleAttempt.seconds_taken),
    id: singleAttempt.quiz_id,
  }));
  return allAttempts;
};

// REPORTS TABLE

export const QuizReportsColumns = [
  { property: 'quiz_name', name: 'Quiz name' },
  { property: 'created_on', name: 'Created on' },
  { property: 'total_attempts', name: 'Number of Attempts' },
  { property: 'avg_performance', name: 'Avg. Performance' },
  { property: 'avg_hints_taken', name: 'Avg. Hints' },
];
export const teacherQuizReportColumns = [
  { property: 'quiz_name', name: 'Quiz name' },
  { property: 'created_on', name: 'Created on' },
  { property: 'total_attempts', name: 'Number of Attempts' },
  { property: 'avg_performance', name: 'Avg. Performance' },
  { property: 'avg_hints_taken', name: 'Avg. Hints' },
];

export const classTeacherReportColumns = [
  { property: 'name', name: 'Name' },
  { property: 'email', name: 'Email' },
  { property: 'action', name: '' },
];

export const preprocessReports = (data: IQuizReportsData[]): IQuizReportsPreprocessing[] => {
  const tableData: IQuizReportsPreprocessing[] = [];
  data.forEach((report) => {
    tableData.push({
      quiz_name: <span className={clsx('reportsCell', 'quizNameCell')}>{report.quiz_name}</span>,
      created_on: <span className='reportsCell'>{moment.utc(report.created_on).local().format('MMMM D, YYYY')}</span>,
      total_attempts: <span className='reportsCell'>{report.total_attempts}</span>,
      avg_performance: <Tooltip title={`${parseFloat(report.avg_performance)}%`} placement='top' arrow><span className='studentTableCell studentTableNameCell progressbar-cell'><LinearProgress variant='determinate' color={progresscolorDecideOnAvg(parseFloat(report.avg_performance))} value={parseFloat(report.avg_performance)} /></span></Tooltip>,
      avg_hints_taken: <span className='reportsCell'>{Number(report.avg_hints_taken).toFixed()}</span>,
      id: report.quiz_id,
    });
  });
  return tableData;
};
export const preprocessClassTeacherReports = (data: IClassTeacher[], class_id: string): IClassTeacherPreprocessing[] => {
  const tableData: IClassTeacherPreprocessing[] = [];
  data.forEach((teacher) => {
    tableData.push({
      name: <span className={clsx('reportsCell', 'quizNameCell')}>{teacher.first_name + ' ' + teacher.last_name}</span>,
      email: <span className='reportsCell'>{teacher.email}</span>,
      action: <TeacherAccessAction teacher_id={teacher._id} class_id={class_id} />
    });
  });
  return tableData;
};
export const preprocessAdminReports = (data: IAdminQuizReportsData[]): IAdminQuizReportsPreprocessing[] => {
  const tableData: IAdminQuizReportsPreprocessing[] = [];

  data.forEach((report) => {
    tableData.push({
      quiz_name: <span className={clsx('reportsCell', 'quizNameCell')}>{report.quiz_name}</span>,
      created_on: <span className='reportsCell'>{moment.utc(report.created_on).local().format('MMMM D, YYYY')}</span>,
      total_attempts: <span className='reportsCell'>{report.total_attempts}</span>,
      avg_performance: <Tooltip title={`${parseFloat(report.avg_performance)}%`} placement='top' arrow><span className='studentTableCell studentTableNameCell progressbar-cell'><LinearProgress variant='determinate' color={progresscolorDecideOnAvg(parseFloat(report.avg_performance))} value={parseFloat(report.avg_performance)} /></span></Tooltip>,
      avg_hints_taken: <span className='reportsCell'>{Number(report.avg_hints_taken).toFixed()}</span>,
      id: report.quiz_id,
    });
  });
  return tableData;
};
