import React from 'react';

import type { StudentReportSummaryQuestionsData, QuestionTypeData } from 'utils/types';
import { returnQuestionType } from 'utils/helper';
import EquationSummaryQuestion from '../StudentQuizPage/EquationSummaryQuestion';
import MCQSummaryQuestion from '../StudentQuizPage/MCQSummaryQuestion';

interface QuizSummaryProps {
  questions: StudentReportSummaryQuestionsData[];
  questionTypes?: QuestionTypeData[];
}
export default function QuizSummary(props: QuizSummaryProps): JSX.Element {
  const { questions, questionTypes } = props;
  const questionSorted: StudentReportSummaryQuestionsData[] = [...questions].sort((a, b) => Number(a.is_correct) - Number(b.is_correct));
  return (
    <div className='last-quiz-summary-detail'>
      {questionSorted.map((question) => {
        const questionType = returnQuestionType(question.type_id, questionTypes);
        if (questionType === 'Multiple Choice') {
          return (
            <MCQSummaryQuestion
              key={question._id}
              data={question}
              noBorder={question.order_num === questions.length}
            />
          );
        }
        return (
          <EquationSummaryQuestion
            key={question._id}
            data={question}
            noBorder={question.order_num === questions.length}
          />
        );
      })}
    </div>
  );
}
