import React from 'react';
import {
  Avatar, Badge, IconButton, Popover, Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import type { AxiosError } from 'axios';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

import type { APIResponse } from 'utils/types';
import SaveIcon from 'assets/svgs/save.svg';
import FeedbackIcon from 'assets/svgs/feedback.svg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import { signoutAPICall } from 'store/ApiCall/authApiCalls';
import { signoutToStore } from 'store/reducer/authSlice';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import AddClassModal from 'components/shared/molecules/AddClassModal';
import RoundPlusIcon from 'assets/svgs/ts/RoundPlusIcon';
import clsx from 'clsx';

interface NavbarProps {
  showSaveQuizBtn: boolean;
  btnLoading: boolean;
  name?: JSX.Element | string;
}

export default function Navbar(props: NavbarProps): JSX.Element {
  const navigate = useNavigate();
  const { showSaveQuizBtn, btnLoading, name } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isVerified, setIsVerified] = React.useState<boolean>(false);
  const [isTeacher, setIsTeacher] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleLogout = (): void => {
    signoutAPICall().then(() => {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('user');
      dispatch(signoutToStore(null));
    }).catch((err: AxiosError<APIResponse<null, undefined>>) => {
      enqueueSnackbar(err.response?.data.message, { variant: 'error' });
    });
  };

  React.useEffect(() => {
    const user = window.localStorage.getItem('user');
    if (user) {
      const userData = JSON.parse(user);
      if (userData.role_id.name === 'teacher') setIsTeacher(true);
      setIsVerified(userData.is_verified as boolean);
    }
  }, []);

  return (
    <div className={clsx('general-navbar', pathname === '/reports' && 'no-shadow')}>
      <div className='navbar-left-side'>
        <h2>{name}</h2>
      </div>
      <div className='navbar-right-side'>
        {/* <div className='mr-30' style={{ fontSize: 14, color: 'red' }}>{!isVerified && 'Your account is not verified.'}</div> */}
        {showSaveQuizBtn && (
          <ButtonComponent
            isSubmitBtn
            startIcon={<img src={SaveIcon} alt='save-icon' />}
            size='small'
            type='contained'
            text='Save Quiz'
            loading={btnLoading}
            loadingIconClass='size-20'
            className='font-inter'
          />
        )}
        <ButtonComponent
          type='contained'
          text='Add new Class'
          size='medium'
          className='addNewClassBtn'
          startIcon={<RoundPlusIcon />}
          onClick={(): void => { setIsOpen((prev) => !prev); }}
        />
        {/* TODO: will use it in future
       <div className='badge-wrapper mx-18'>
        <Badge badgeContent={3} color='error'>
          <img src={BellIcon} alt='notification' />
        </Badge>
      </div> */}
        <div className='avatar-wrapper ml-18'>
          <IconButton onClick={handleClick}>
            <Badge className='avatar-badge' badgeContent={<KeyboardArrowDownIcon fontSize='small' />} color='primary'>
              <Avatar className='navbar-avatar' />
            </Badge>
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Typography className='navbar-popover-option' sx={{ p: 2 }} onClick={(): void => { window.open('https://www.mathstarter.co.nz/feedback.html', '_blank'); }}>
              {' '}
              <span>Submit Feedback </span>
              {' '}
            </Typography>
            <Typography className='navbar-popover-option' sx={{ p: 2 }} onClick={handleLogout}>
              {' '}
              <LogoutIcon />
              {' '}
              <span> Logout </span>
              {' '}
            </Typography>
          </Popover>
        </div>
      </div>

      { isTeacher
        ? (
          <AddClassModal
            isOpen={isOpen}
            onCloseHandler={(): void => { setIsOpen(false); }}
          />
        )
        : null}
    </div>
  );
}
