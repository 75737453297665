import React from 'react';

import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import { returnQuestionType } from 'utils/helper';
import type { QuestionTypeData, StudentReportSummaryQuestionsData } from 'utils/types';
import EquationQuestion from './EquationQuestion';
import McqQuestion from './McqQuestion';

interface QuizResponseType {
  data?: StudentReportSummaryQuestionsData[];
  questionType?: QuestionTypeData[];
}

export default function QuizResponse(props: QuizResponseType): JSX.Element {
  const { data, questionType } = props;
  const sorted: StudentReportSummaryQuestionsData[] | undefined = data ? [...data].sort((a, b) => Number(a.is_correct) - Number(b.is_correct)) : undefined;

  return (
    <BasicCardContainer className='px-0'>
      <div className='student-attempt-detail-report-page-quiz-response'>
        <div className='label-style-bold quiz-summary-table-title px-24'>Quiz response</div>
        <div className='student-attempt-detail-report-page-quiz-response-label-box px-24'>
          <div className='student-attempt-detail-report-page-quiz-response-label-1'>
            <span>#</span>
            Question
          </div>
          <div className='student-attempt-detail-report-page-quiz-response-label-2'>Status</div>
          <div className='student-attempt-detail-report-page-quiz-response-label-3'>Hint</div>
        </div>
        <div className='quiz-response-question-box'>
          {sorted?.map((question) => {
            if (returnQuestionType(question.type_id, questionType) === 'Equation Type') {
              return (
                <EquationQuestion
                  data={question}
                  noBorder={question.order_num === data?.length}
                />
              );
            }
            return (
              <McqQuestion
                data={question}
                noBorder={question.order_num === data?.length}
              />
            );
          })}
        </div>
      </div>
    </BasicCardContainer>
  );
}
