/* eslint-disable @typescript-eslint/naming-convention */
import React from 'react';

import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import type { ClassYearData } from 'utils/types';

interface BasicInformationCardProps {
  attempted_quizzes: number;
  student_name: string;
  year: ClassYearData | undefined;
}

export default function StudentBasicInfoCard(props: BasicInformationCardProps): JSX.Element {
  const {
    attempted_quizzes, student_name, year,
  } = props;

  return (
    <BasicCardContainer>
      <div className='student-card-main-wrapper'>
        <div className='heading'>
          Basic Info
        </div>
        <div className='student-info-section'>
          <p> Student Name </p>
          <span>
            {' '}
            {student_name}
            {' '}
          </span>
        </div>
        <div className='student-year-section'>
          <div className='info-block'>
            <p> Year </p>
            <span>
              {' '}
              {year?.name}
              {' '}
            </span>
          </div>
          <div className='info-block'>
            <p> Attempted Quizzes </p>
            <span>
              {' '}
              {attempted_quizzes}
              {' '}
            </span>
          </div>
        </div>
      </div>
    </BasicCardContainer>
  );
}
