import React from 'react';

export default function TabButton(
  { icon, label }: { icon: JSX.Element; label: string },
): JSX.Element {
  return (
    <span className='tabLabelComp'>
      <span className='tabIcon'>
        {icon}
      </span>
      {' '}
      <span className='tabLabel'>
        {label}
      </span>
    </span>
  );
}
