/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import NavbarWrapper from 'components/layout/NavbarWrapper';
import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import LayoutContainer from 'components/layout/LayoutContainer';
import { SearchFilter } from 'components/shared/atoms/QuizReportsFilters';
import CircularLoader from 'components/shared/molecules/Loader';
import QuestionCardComponent from 'components/shared/molecules/QuestionCard';
import { useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';
import Pagination from 'components/shared/molecules/Pagination';
import { returnQuestionType } from 'utils/helper';
import type {
  QuestionResType,
} from 'utils/types';
import {
  useGetAllQsMutation,
  useGetAllQsWithFiltersMutation,
} from 'store/ApiCall/questionApiCall';
import { useSnackbar } from 'notistack';

export default function QuizLibrary(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchStatement, setSearchStatement] = useState('');
  const [searchConcept, setSearchConcept] = useState('');
  const [searchDifficulty, setSearchDifficulty] = useState('');
  const [activeSearch, setActiveSearch] = useState(false);
  const [questionList, setQuestionList] = useState<QuestionResType[]>();
  const [searchQuestionList, setSearchQuestionList] = useState<QuestionResType[]>();
  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const [paginationParams, setPaginationParams] = useState<{ offset: number; limit: number }>({ offset: 1, limit: Number(process.env.REACT_APP_QUESTION_LIBRARY_PAGE_SIZE || 20) });
  const [pagination, setPagination] = useState<{ start: number; end: number; totalRecords: number; totalPages: number }>({
    start: 1, end: 20, totalRecords: 0, totalPages: 0,
  });

  const { data: questionTypeList } = useGetQuestionTypeListQuery();
  const [getAllQs, { isLoading, error }] = useGetAllQsMutation();
  const [getAllQsWithFilters, { isLoading: loadingSearchResult }] = useGetAllQsWithFiltersMutation();

  useEffect(() => {
    const page = Number(searchParams.get('page'));
    if (page) {
      if (page <= 0) {
        enqueueSnackbar(
          'Page cant be zero or a negative number.',
          // `Page exceeds the total page limit (1 - ${pagination.totalPages})`,
          { variant: 'error' },
        );
        return;
      }
      setPaginationParams({ ...paginationParams, offset: page });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeSearch) {
      return;
    }
    const timer = setTimeout(async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data: allQsData }: any = await getAllQs({
        offset: paginationParams.offset,
        limit: paginationParams.limit,
      });

      if (paginationParams.offset > allQsData.meta.pagination.totalPage || paginationParams.offset < 0) {
        enqueueSnackbar(
          `Page exceeds the total page limit (1 - ${allQsData.meta.pagination.totalPage})`,
          { variant: 'error' },
        );
        return;
      }

      if (allQsData.data.length) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setQuestionList(allQsData.data);
        setPagination({
          start: allQsData.meta.pagination.page,
          end: allQsData.meta.pagination.numberOfRecords,
          totalRecords: allQsData.meta.pagination.totalRecords,
          totalPages: allQsData.meta.pagination.totalPage,
        });
      }
    }, 300);

    // eslint-disable-next-line consistent-return
    return () => { clearInterval(timer); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginationParams.offset, activeSearch]);

  useEffect(() => {
    if (!searchStatement && !searchConcept && !searchDifficulty) {
      setActiveSearch(false);
      return;
    }

    if (!activeSearch) setPaginationParams({ offset: 1, limit: 20 });

    const timer = setTimeout(async () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const { data: allQsData }: any = await getAllQsWithFilters({
        statement: searchStatement,
        concept: searchConcept,
        difficulty: searchDifficulty,
        offset: paginationParams.offset,
        limit: paginationParams.limit,
      });

      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      setSearchQuestionList(allQsData.data);
      setActiveSearch(true);
      setPagination({
        start: allQsData.meta.pagination.page,
        end: allQsData.meta.pagination.numberOfRecords,
        totalRecords: allQsData.meta.pagination.totalRecords,
        totalPages: allQsData.meta.pagination.totalPage,
      });

      return allQsData;
    }, 300);

    // eslint-disable-next-line consistent-return
    return () => { clearInterval(timer); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStatement, searchConcept, searchDifficulty, paginationParams.offset]);

  const paginationHandler = (direction: number): void => {
    // Prev Button
    if (direction === -1 && paginationParams.offset > 1) {
      setPaginationParams({ ...paginationParams, offset: paginationParams.offset - 1 });
      setSearchParams({ page: `${paginationParams.offset - 1}` });
    }

    // Next Button
    if (direction === 1 && paginationParams.offset < pagination.totalPages) {
      setPaginationParams({ ...paginationParams, offset: paginationParams.offset + 1 });
      setSearchParams({ page: `${paginationParams.offset + 1}` });
    }
  };

  const searchStatementHandler = (val: string): void => {
    setSearchStatement(val);
    if (!activeSearch) {
      setPaginationParams({ ...paginationParams, offset: 1 });
    }
  };

  if (error) {
    enqueueSnackbar('Something went wrong.', { variant: 'error' });
  }

  return (
    <NavbarWrapper name={((): JSX.Element => (
      <span className='navbarTitle'>
        <span className='currentElement'>
          Question Library
        </span>
      </span>
    ))()}
    >
      <div className='all-question-inner-container'>
        <LayoutContainer>
          <div className='all-question-content'>
            <div className='all-questions-filters'>
              <span>Filter by: </span>
              <SearchFilter {...{ search: searchStatement, setSearch: searchStatementHandler }} placeholder='Search by title' onClick={(): void => { setSearchStatement(''); }} />
              <SearchFilter {...{ search: searchDifficulty, setSearch: setSearchDifficulty }} placeholder='Search by difficulty' onClick={(): void => { setSearchDifficulty(''); }} />
              <SearchFilter {...{ search: searchConcept, setSearch: setSearchConcept }} placeholder='Search by concept' onClick={(): void => { setSearchConcept(''); }} />
              <Pagination
                offset={paginationParams.offset - 1}
                limit={paginationParams.limit}
                end={pagination.end}
                totalRecords={pagination.totalRecords}
                handler={paginationHandler}
              />
            </div>
            <div className='all-question-card-container'>
              <BasicCardContainer className='px-0'>
                <div className='quiz-detail-page-questions'>
                  {activeSearch
                    ? searchQuestionList?.length
                      ? searchQuestionList.map((question: QuestionResType, idx: number): JSX.Element => {
                        const qType = returnQuestionType(question.type_id, questionTypeList?.data);
                        return (
                          <QuestionCardComponent key={question._id} index={(paginationParams.offset - 1) * paginationParams.limit + idx} question={question} questionType={qType} />
                        );
                      }) : !loadingSearchResult ? (<div>No record found!</div>) : (
                        <div>
                          <CircularLoader />
                        </div>
                      )
                    : questionList?.length ? questionList.map((question: QuestionResType, idx: number): JSX.Element => {
                      const qType = returnQuestionType(question.type_id, questionTypeList?.data);
                      return (
                        <QuestionCardComponent key={question._id} index={(paginationParams.offset - 1) * paginationParams.limit + idx} question={question} questionType={qType} />
                      );
                    })
                      : !isLoading ? (<div>No record found!</div>) : (
                        <div>
                          <CircularLoader />
                        </div>
                      )}
                </div>
              </BasicCardContainer>
            </div>
          </div>
        </LayoutContainer>
      </div>
    </NavbarWrapper>
  );
}
