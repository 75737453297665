import React from 'react';

import clsx from 'clsx';

interface QuizStatisticsBoxProps {
  title: string;
  count?: number;
  className?: string;
}

export default function QuizStatisticsBox(props: QuizStatisticsBoxProps): JSX.Element {
  const { title, count, className } = props;
  return (
    <div className={clsx('last-quiz-states-box', className)}>
      <div className='new-quiz-information-text-1'>{title}</div>
      <div className='new-quiz-information-text-5'>{count}</div>
    </div>
  );
}
