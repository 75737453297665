/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';

import CorrectTickIcon from 'assets/svgs/ts/CorrectTickIcon';
import FalseTickIcon from 'assets/svgs/ts/FalseTickIcon';
import HintBulb from 'assets/svgs/ts/HintBulb';
import HintBulbOff from 'assets/svgs/ts/HintBulbOff';
import clsx from 'clsx';
import type {
  EditorInput, EquationAnswerType, EquationAnswerTypeFour, StudentReportSummaryQuestionsData,
} from 'utils/types';
import MQStaticField from 'components/shared/atoms/MathQuill/MQStaticField';

interface EquationQuestionProps {
  data: StudentReportSummaryQuestionsData;
  noBorder: boolean;
}

export default function EquationQuestion(props: EquationQuestionProps): JSX.Element {
  const {
    data, noBorder,
  } = props;
  return (
    <div className={clsx('quiz-response-equation-question', noBorder && 'no-border')}>
      <div className='quiz-response-row-1'>
        <div className='quiz-response-box-1'>
          <div className='question-order'>
            {data.order_num}
            .
          </div>
          <div className='question-text'>
            {data.statement}
          </div>
        </div>
        <div className='quiz-response-box-2'>{data.is_correct ? <CorrectTickIcon className='size-16' /> : <FalseTickIcon className='size-16' />}</div>
        <div className='quiz-response-box-3'>{data.hint_taken ? <HintBulb className='size-16' /> : <HintBulbOff className='size-16 fill-secondary-light' />}</div>
      </div>
      {data.image_url
      && (
        <div className='quiz-response-row-2'>
          <div className='question-bg-image' style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.image_url})` }} />
        </div>
      )}
      <div className='quiz-response-body'>
        <div className='quiz-response-body-row-1 question-text mb-8'>
          Submitted Answer:
        </div>
        <div className='quiz-response-body-row-2'>
          <div className='question-text'>
            <div>
              {data.body.map((item: EditorInput, index: number) => {
                if (item.type === 1) {
                  const answerArr: EquationAnswerType[] = typeof data.submitted_answer === 'string' ? JSON.parse(data.submitted_answer) : data.submitted_answer;
                  const answer = answerArr.find((ans) => ans.componentIndex === index)?.answer;
                  return (
                    <MQStaticField
                      key={item.id}
                      latex={item.state}
                      answers={answer}
                      className={clsx('admin-student-summary')}
                      isStudentStaticField
                    />
                  );
                }
                if (item.type === 2) {
                  return (<div key={item.id} className='admin-student-summary'>{item.state}</div>);
                }
                if (item.type === 4) {
                  const answerArr: EquationAnswerTypeFour[] = typeof data.submitted_answer === 'string' ? JSON.parse(data.submitted_answer) : data.submitted_answer;
                  const answer = answerArr.find((ans) => ans.componentIndex === index)?.answer;
                  return (
                    <div key={item.id} className={clsx('admin-student-summary-answer-field', 'admin-student-summary')}>
                      <input readOnly value={answer} />
                      <span>{` ${item.unit}`}</span>
                    </div>
                  );
                }
                return (<div className='student-equation-single-component-new-line' key={item.id} />);
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
