/* eslint-disable no-nested-ternary */
import React from 'react';
import _ from 'lodash';
import {
  useGetPendingTeachersListQuery,
  useGetApprovedTeachersListQuery,
} from 'store/ApiCall/managementApiCall';
import NavbarWrapper from 'components/layout/NavbarWrapper';
import { Table } from 'components/shared/molecules/Table';
import {
  preprocessPendingTeachers,
  PendingTeachersColumn,
  preprocessApprovedTeachers,
  ApprovedTeachersColumn,
} from 'utils/tableDataPreprocessing';
import Loader from 'components/shared/molecules/Loader';
import TeacherActionsComponent from 'components/shared/atoms/TeacherActionColumn';
import type {
  PendingTeachersPreprocessing,
  ApprovedTeachersPreprocessing,
} from 'utils/types';
import 'styles/components/pages/teacherListPage.scss';

export default function TeachersListPage(): JSX.Element {
  const [pendingTeachers, setPendingTeachers] = React.useState<PendingTeachersPreprocessing[]>();
  const [approvedTeachers, setApprovedTeachers] = React.useState<ApprovedTeachersPreprocessing[]>();

  const { data: pendingList, refetch: pendingTeacherRefetch } = useGetPendingTeachersListQuery();
  const { data: approvedList, refetch: approvedTeacherRefetch } = useGetApprovedTeachersListQuery();

  React.useEffect(() => {
    if (pendingList) {
      setPendingTeachers(preprocessPendingTeachers(pendingList.data, TeacherActionsComponent));
    }
  }, [pendingList]);

  React.useEffect(() => {
    if (approvedList) {
      setApprovedTeachers(preprocessApprovedTeachers(approvedList.data));
    }
  }, [approvedList]);

  React.useEffect(() => {
    pendingTeacherRefetch();
    approvedTeacherRefetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NavbarWrapper name='Teachers'>
      <div className='teacherListPageRoot'>
        <div className='tableContainer pendingTeachersTable'>
          <div className='tableInner'>
            <h4 className='pendingTableHeading'>Pending Approval</h4>
            {pendingTeachers
              ? !_.isEmpty(pendingTeachers)
                ? <Table data={pendingTeachers} columns={PendingTeachersColumn} />
                : (
                  <div className='noRequestBlock'>
                    <span>No pending approvals</span>
                  </div>
                )
              : (<Loader />)}
          </div>
        </div>
        {approvedTeachers
          ? !_.isEmpty(approvedTeachers)
            ? (
              <div className='tableContainer approvedTable'>
                <div className='tableInner'>
                  <Table data={approvedTeachers} columns={ApprovedTeachersColumn} />
                </div>
              </div>
            )
            : (
              <div className='noTeacherContainer'>
                <div className='noTeacherContainerInner'>
                  <h4>Approved Teacher</h4>
                  <div className='noTeacherMessage'>
                    <h4>No teacher found!</h4>
                  </div>
                </div>

              </div>
            )
          : (<Loader />)}
      </div>
    </NavbarWrapper>
  );
}
