import clsx from 'clsx';
import type { ReactNode } from 'react';
import React from 'react';

interface CardContainerProps {
  children?: ReactNode;
  className?: string;
}

export default function BasicCardContainer(props: CardContainerProps): JSX.Element {
  const { children, className } = props;
  return (
    <div className={clsx('basic-card-container', className)}>{children}</div>
  );
}
