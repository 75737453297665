/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';
import type { Question } from 'utils/types';
import McqChoiceContainer from 'components/shared/molecules/QuizDetailPage/McqChoiceContainer';
import { useSnackbar } from 'notistack';
import CopyIcon from 'assets/svgs/copyIcon.svg';

interface McqQuestionProps {
  index: number;
  data: Question;
  viewMode?: boolean;
}
export default function McqQuestion(props: McqQuestionProps): JSX.Element {
  const { index, data, viewMode } = props;
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = (code: string | undefined): void => {
    if (code) {
      navigator.clipboard.writeText(code);
      enqueueSnackbar('Question code copied to clipboard.', { variant: 'success' });
    }
  };

  if (viewMode) {
    return (
      <div className='mcq-question-view-mode'>
        <div className='mcq-question-header'>
          <div className='title-container'>
            <div className='question-id'>{index}</div>
          </div>
          <div className='question-code'>
            <div className='question-code-box'>
              {data._id}
            </div>
            <button
              type='button'
              onClick={(): void => { copyToClipBoard(data._id); }}
              className='copy-to-clipboard-btn'
            >
              <img className='copy-icon' src={CopyIcon} alt='copy to clipboard' />
            </button>
          </div>
        </div>
        <div className='mcq-question-content'>
          <div>
            <div className='mcq-question-text'>{data.statement}</div>
            <div>
              {data.image_url
          && (
            <div className='mcq-question-left-box-2'>
              <div
                className='mcq-question-left-box-2-image'
                style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.image_url})` }}
              />
            </div>
          )}
            </div>
          </div>
          <div className='mcq-choices-container'>
            {typeof data.body !== 'string'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        && data.body.map((choice: any) => (
          <McqChoiceContainer key={choice.key} data={choice} answer={data.answer} />
        ))}
          </div>
        </div>

      </div>
    );
  }

  return (
    <div className='mcq-question'>
      <div className='mcq-question-box'>
        <div className='mcq-question-left-box-1'>
          <div className='question-id'>{index}</div>
          <div className='mcq-question-text'>{data.statement}</div>
        </div>
        {data.image_url
          && (
            <div className='mcq-question-left-box-2'>
              <div
                className='mcq-question-left-box-2-image'
                style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.image_url})` }}
              />
            </div>
          )}
      </div>
      <div className='mcq-choices-container'>
        {typeof data.body !== 'string'
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        && data.body.map((choice: any) => (
          <McqChoiceContainer key={choice.key} data={choice} answer={data.answer} />
        ))}
      </div>
    </div>
  );
}
