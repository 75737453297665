/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import {
  DragDropContext,
  Droppable,
  Draggable,
} from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import type {
  ColumnFiltersState,
  Row,
  Cell,
} from '@tanstack/react-table';
import type {
  DraggableProvided,
  DropResult,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import clsx from 'clsx';
import { fuzzyFilter } from './utils/filter';
import { mapColumns } from './utils';
import 'styles/components/shared/molecules/Table/main.scss';

interface TableProps<TData extends object> {
  data: TData[];
  columns: { property: string; name: JSX.Element | string }[];
  enableSorting?: boolean;
  draggable?: boolean;
  clickable?: boolean;
  onClick?: (id: string) => void;
  onDragEndHandler?: (result: DropResult, currentQuiz: string) => void;
}

export default function Table({
  columns, data, enableSorting, draggable = false, onDragEndHandler, onClick,
}: TableProps<any>): JSX.Element {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    [],
  );
  const [globalFilter, setGlobalFilter] = React.useState('');
  const [currentQuiz, setCurrentQuiz] = React.useState('');
  const [tableData, setTableData] = React.useState(data);
  const [columnData] = React.useState(mapColumns(columns, draggable));

  React.useEffect(() => {
    setTableData(data);
  }, [data]);

  const table = useReactTable({
    data: tableData,
    columns: columnData,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    enableSorting: enableSorting ?? false,
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  const navigate = useNavigate();

  const onClickHandler = (quiz_id: string): void => {
    navigate(`/quiz-detail/${quiz_id}`);
  };

  const rowClickHandler = (id: string): void => {
    if (onClick) {
      onClick(id);
    }
  };

  if (draggable && onDragEndHandler) {
    return (
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder ? null : (
                    // TODO: look for proper fix
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      {...{
                        className: header.column.getCanSort()
                          ? 'cursor-pointer select-none'
                          : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                      className={clsx('headCell', enableSorting && 'onHoverCursor', header.column.columnDef.id === 'quizName' && 'quizNameCell', header.column.columnDef.id === 'actions' ? 'actionsCellHeader' : '')}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: '',
                        desc: '',
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <DragDropContext onDragEnd={(result: DropResult): void => { onDragEndHandler(result, currentQuiz); }}>
          <Droppable droppableId='table-body'>
            {(provided): JSX.Element => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {table.getCoreRowModel().rows.map((row: Row<any>): JSX.Element => (
                  <Draggable key={row.id} draggableId={row.id.toString()} index={row.index}>
                    {(provided2: DraggableProvided, snapshot: DraggableStateSnapshot): JSX.Element => (
                      <tr className={snapshot.isDragging ? 'snapshot' : ''} key={row.id} ref={provided2.innerRef} {...provided2.draggableProps} onClick={(): void => { onClickHandler(`${row.original.quizId}`); }} onMouseDown={(): void => { setCurrentQuiz(`${row.original.quizId}`); }}>
                        {row.getVisibleCells().map((cell: Cell<any, any>): JSX.Element => (
                          <td key={cell.id} {...(cell.column.id === 'draggable' && provided2.dragHandleProps)}>
                            {cell.getValue()}
                          </td>
                        ))}
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
    );
  }

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                // TODO: look for proper fix
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <div
                    {...{
                      className: header.column.getCanSort()
                        ? 'cursor-pointer select-none'
                        : '',
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                    className={clsx('headCell', enableSorting && 'onHoverCursor', header.column.columnDef.id === 'actions' && 'actionsCellHeader')}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
                    {{
                      asc: '',
                      desc: '',
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getCoreRowModel().rows.map((row: Row<any>): JSX.Element => (
          <tr key={row.id} onClick={(): void => { rowClickHandler(row.original.id); }}>
            {row.getVisibleCells().map((cell: Cell<any, any>): JSX.Element => (
              <td key={cell.id}>
                {cell.getValue()}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
