import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import CircularLoader from 'components/shared/molecules/Loader';
import { useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';
import { useAdminAggregatedReportQuery } from 'store/ApiCall/reportApiCalls';
import { returnQuestionType } from 'utils/helper';
import SingleQuestion from './SingleQuestion';

export default function AggregatedResponses(): JSX.Element {
  const { quizId = '' } = useParams();

  // Get the search parameters
  const [searchParams] = useSearchParams();

  const classId = searchParams.get('classId') ? searchParams.get('classId') : undefined;

  const aggregatedReportParams: { quizId: string; classId?: string | undefined } = { quizId };
  if (classId) {
    aggregatedReportParams.classId = classId;
  }

  // Question type Api
  const { data: questionTypeList, isLoading: isGettingQuestionTypeList } = useGetQuestionTypeListQuery();
  const { data: aggregateResponse, isLoading, refetch } = useAdminAggregatedReportQuery(aggregatedReportParams);

  useEffect(() => {
    refetch();
  });

  if (isLoading || isGettingQuestionTypeList) return <CircularLoader />;

  return (
    <div className='aggregation-responses'>
      {aggregateResponse
      && Object.keys(aggregateResponse.data).length > 0
        ? Object.keys(aggregateResponse.data).map((key) => {
          const data = aggregateResponse.data[Number(key)];
          return (
            <SingleQuestion
              key={data.question.order_num}
              data={data}
              questionType={returnQuestionType(data.question.type_id, questionTypeList?.data)}
            />
          );
        })
        : <div className='empty-box'>No report found!</div>}
    </div>
  );
}
