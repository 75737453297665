import clsx from 'clsx';
import React from 'react';

interface TextWithLabelProps {
  label: string;
  text?: number | string | null;
  labelClassName?: string;
  textClassName?: string;
  className?: string;
}

export default function TextWithLabel(props: TextWithLabelProps): JSX.Element {
  const {
    label, text, labelClassName, textClassName, className,
  } = props;
  return (
    <div className={clsx('text-with-label', className)}>
      <div className={clsx(labelClassName, 'mb-10')}>{label}</div>
      <div className={textClassName}>{text}</div>
    </div>
  );
}
