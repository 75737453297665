import React from 'react';
import type { SVGProps } from 'utils/types';

export default function CrossIcon(props: SVGProps): JSX.Element {
  const { onClick } = props;
  return (
    <svg onClick={onClick} width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M4.99997 6.41397L1.70697 9.70697L0.292969 8.29297L3.58597 4.99997L0.292969 1.70697L1.70697 0.292969L4.99997 3.58597L8.29297 0.292969L9.70697 1.70697L6.41397 4.99997L9.70697 8.29297L8.29297 9.70697L4.99997 6.41397Z' fill='#595E72' />
    </svg>
  );
}
