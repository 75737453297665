/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'store/ApiCall/baseQuery';
import type {
  AvailableQuizResponse, QuestionAttemptResponse,
  StartQuizResponse, HintTakenResponse, SubmitAnswerByStudent, TeacherStudentUpdateResponse, UpdateStudentByTeacher, TeacherStudentDeleteResponse, DeleteStudentByTeacher, StudentListResponse,
} from 'utils/types';

export const studentApi = createApi({
  reducerPath: 'studentApi',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['getAvailableQuiz', 'getAllTeacherStudentsManagement'],
  endpoints: (builder) => ({
    getAvailableQuiz: builder.query<AvailableQuizResponse, void>({
      query: () => ({
        url: 'api/quiz/available',
        method: 'GET',
      }),
      providesTags: ['getAvailableQuiz'],
    }),
    startAvailableQuiz: builder.query<StartQuizResponse, string | void>({
      query: (quizId) => ({
        url: `/api/quiz/start/${quizId}`,
        method: 'GET',
      }),
    }),
    hintTaken: builder.mutation<HintTakenResponse, { questionId: string; forced: boolean }>({
      query: ({ questionId, forced }) => ({
        url: `/api/quiz/question/${questionId}/hint?forced=${forced}`,
        method: 'POST',
      }),
    }),
    questionAttempt: builder.mutation<QuestionAttemptResponse, SubmitAnswerByStudent>({
      query: (body) => ({
        url: '/api/quiz/question/submit',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getAvailableQuiz'],
    }),
    getAllTeacherStudentsManagement: builder.query<StudentListResponse, void>({
      query: () => ({
        url: '/api/teacher/student/list',
        method: 'GET',
      }),
      providesTags: ['getAllTeacherStudentsManagement'],
    }),
    teacherStudentUpdate: builder.mutation<TeacherStudentUpdateResponse, UpdateStudentByTeacher>({
      query: (body) => ({
        url: '/api/teacher/student/update',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getAllTeacherStudentsManagement'],
    }),
    teacherStudentDelete: builder.mutation<TeacherStudentDeleteResponse, DeleteStudentByTeacher>({
      query: (body) => ({
        url: '/api/teacher/student/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getAllTeacherStudentsManagement'],
    }),
  }),
});

export const {
  useGetAvailableQuizQuery,
  useStartAvailableQuizQuery,
  useHintTakenMutation,
  useQuestionAttemptMutation,
  useGetAllTeacherStudentsManagementQuery,
  useTeacherStudentUpdateMutation,
  useTeacherStudentDeleteMutation,
} = studentApi;
