import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import TextWithLabel from 'components/shared/atoms/TextWithLabel';
import MultiTextLable from 'components/shared/atoms/MultiTextLable';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import PlayIcon from 'assets/svgs/ts/PlayIcon';
import PresentationIcon from 'assets/svgs/ts/PresentationIcon';
import {
  useGetNextQuizInformationQuery,
  useQuizStartByTeacherMutation,
} from 'store/ApiCall/classesApiCall';
import StartQuizModal from 'components/shared/molecules/QuizClass/StartQuizModal';
import CircularLoader from 'components/shared/molecules/Loader/index';
import { useSnackbar } from 'notistack';
import type { TeacherClassInfo } from 'utils/types';
import QuizInProgressReport from 'components/shared/molecules/QuizClass/QuizInProgressReport';
import clsx from 'clsx';
import CrossIcon from 'assets/svgs/ts/CrossIcon';
import {
  Dialog, DialogActions, DialogContent, Grid,
} from '@mui/material';

interface QuizClassOverviewProps {
  classInformation?: TeacherClassInfo;
}

export default function QuizClassOverview(props: QuizClassOverviewProps): JSX.Element {
  const { classInformation } = props;
  const [showModal, setShowModal] = useState<boolean>(false);
  const [refetchQuizReport, setRefetchQuizReport] = useState<boolean>(false);
  const [openStartModal, setOpenStartModal] = useState<boolean>(false);

  const { classId } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  // API call for next quiz information
  const { data: classQuizInfomation, isLoading: isGettingClassQuizInformation, error: classQuizInfomationError } = useGetNextQuizInformationQuery(classId);
  // Quiz start api call
  const [quizStartByTeacherHandler, { isLoading: isQuizStarting }] = useQuizStartByTeacherMutation();

  const quizStartByTeacher = async (): Promise<void> => {
    try {
      const body = {
        quiz_id: classQuizInfomation?.data.next_quiz?.quiz._id,
        class_id: classId,
      };
      const response = await quizStartByTeacherHandler(body).unwrap();
      if (response.success) {
        setRefetchQuizReport((prev) => !prev);
        setOpenStartModal(true);
        enqueueSnackbar('Quiz started successfully', { variant: 'success' });
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // Start quiz handler
  const startQuizHandler = async (): Promise<void> => {
    if (classQuizInfomation?.data.started_quiz) {
      setShowModal(true);
    } else {
      await quizStartByTeacher();
    }
  };

  // For present quiz
  const presentQuizHandler = (): void => {
    const nextQuizId = classQuizInfomation?.data.next_quiz?.quiz._id;
    if (nextQuizId) navigate(`/my-classes/class/${classId}/present-quiz/${nextQuizId}`);
  };

  // Handle errors
  if (classQuizInfomationError) navigate('/my-classes');

  if (isGettingClassQuizInformation) return <CircularLoader />;

  return (
    <div className='quiz-class-overview-page'>
      <div className={clsx('quiz-class-overview-page-left-box', !classQuizInfomation?.data.started_quiz && 'min-height-style')}>
        <BasicCardContainer
          className={clsx('quiz-class-overview-page-left-box-container', !classQuizInfomation?.data.started_quiz && 'min-height-style')}
        >
          <QuizInProgressReport
            classCode={classInformation?.class.code}
            quizName={classQuizInfomation?.data.started_quiz?.quiz.name}
            quizId={classQuizInfomation?.data.started_quiz?.quiz._id}
            questionCount={classQuizInfomation?.data.started_quiz?.no_of_questions}
            refetchQuizReport={refetchQuizReport}
          />
        </BasicCardContainer>
      </div>
      <div className='quiz-class-overview-page-right-box'>
        <BasicCardContainer className='quiz-class-overview-page-next-quiz-box'>
          <MultiTextLable
            label='Next Quiz'
            className='mb-18'
            title={classQuizInfomation?.data.next_quiz?.quiz.name ?? 'No next quiz available'}
            tagArr={classQuizInfomation?.data.next_quiz?.concepts ?? []}
          />
          {classQuizInfomation?.data.next_quiz
               && (
                 <div className='quiz-class-overview-page-right-box-btns mb-18'>
                   <ButtonComponent
                     className='mr-12'
                     type='outlined'
                     text='Preview Quiz'
                     size='medium'
                     startIcon={<PresentationIcon className='fill-mono-dull-light' />}
                     onClick={presentQuizHandler}
                   />
                   <ButtonComponent
                     type='contained'
                     text='Start Quiz'
                     size='medium'
                     className='bg-blue'
                     startIcon={<PlayIcon />}
                     onClick={startQuizHandler}
                     loadingIconClass='size-26'
                     loading={isQuizStarting}
                   />
                 </div>
               )}
          {/* TODO: may be will use in future  */}
          {/* {classQuizInfomation?.data.after_that
            ? (
              <MultiTextLable
                label='Quiz after that'
                title={classQuizInfomation.data.after_that.quiz.name}
                tagArr={classQuizInfomation.data.after_that.concepts}
                className='border-top'
              />
            ) : null} */}
        </BasicCardContainer>
        <BasicCardContainer className='quiz-class-overview-page-information-box mb-16'>
          {/* <div className='quiz-class-overview-page-text-1 mb-24'>Class Information</div> */}
          {/* <TextWithLabel */}
          {/*  label='Class Code (Provide this to the students in your class)' */}
          {/*  className='mb-24' */}
          {/*  labelClassName='quiz-class-overview-page-label' */}
          {/*  text={classInformation?.class.code} */}
          {/*  textClassName='quiz-class-overview-page-text-2 underline' */}
          {/* /> */}
          <TextWithLabel
            label=''
            className='mb-24'
            labelClassName='quiz-class-overview-page-label'
            text={classInformation?.class.school_id.name}
            textClassName='quiz-class-overview-page-text-2'
          />
          <div className='quiz-class-overview-page-information-box-1'>
            <TextWithLabel
              label='Year'
              labelClassName='quiz-class-overview-page-label'
              text={classInformation?.class.class_year_id.year}
              textClassName='quiz-class-overview-page-text-2'
            />
            <TextWithLabel
              label='Total Students'
              labelClassName='quiz-class-overview-page-label'
              text={classInformation?.total_student}
              textClassName='quiz-class-overview-page-text-2'
            />
            <TextWithLabel
              label='Total Quizzes'
              labelClassName='quiz-class-overview-page-label'
              text={classInformation?.total_quiz}
              textClassName='quiz-class-overview-page-text-2'
            />
            <TextWithLabel
              label='Completed Quizzes'
              labelClassName='quiz-class-overview-page-label'
              text={classInformation?.total_completed_quiz}
              textClassName='quiz-class-overview-page-text-2'
            />
          </div>
        </BasicCardContainer>
      </div>
      <StartQuizModal
        isOpen={showModal}
        onClose={(): void => { setShowModal(false); }}
        quizStartByTeacher={quizStartByTeacher}
        nextQuiz={classQuizInfomation?.data.started_quiz}
      />
      <Dialog open={openStartModal} className='addClassDialogue'>
        <DialogActions className='addClassModalAction'>
          <button
            type='button'
            onClick={(): void => {
              setOpenStartModal(false);
            }}
            className='closeBtn'
          >
            <span>
              <CrossIcon />
            </span>
          </button>
        </DialogActions>
        <DialogContent className='modalTextBox'>
          <h2>
            Quiz started successfully.
          </h2>
          <p className='mt-10' style={{ fontSize: 14 }}>
            Please share the class code with students:
          </p>
          <p className='mt-24' style={{ fontSize: 22, color: '#000000' }}>
            {classInformation?.class.code}
          </p>
        </DialogContent>
        <DialogContent>
          <Grid container rowSpacing={4} className='textfieldContainer'>
            <Grid item sm={12} className='buttonGridBox'>
              <ButtonComponent
                size='medium'
                className='w-82px addClassSubmitBtn'
                type='contained'
                text='Close'
                onClick={(): void => { setOpenStartModal(false) }}
                progressLoaderSize={20}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}
