import clsx from 'clsx';
import React from 'react';

interface ImprovedStateComponentProps {
  label: string;
  text?: number | string | null;
  labelClassName?: string;
  textClassName?: string;
  className?: string;
  Icon: JSX.Element;
}

export default function ImprovedStateComponent(props: ImprovedStateComponentProps): JSX.Element {
  const {
    label, text, labelClassName, textClassName, className, Icon,
  } = props;

  return (
    <div className={clsx('improved-state-component', className)}>
      <div className='improved-state-component-text-box'>
        <div className={textClassName}>
          {text}
          <sup>%</sup>
        </div>
        {Icon}
      </div>
      <div className={labelClassName}>{label}</div>
    </div>
  );
}
