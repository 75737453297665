import React from 'react';

interface HamburgerIconProps {
  width?: number | string;
  height?: number | string;
}

export function HamBurgerIcon({ width = 14, height = 15 }: HamburgerIconProps): JSX.Element {
  return (
    <svg width={width.toString()} height={height.toString()} viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0 1.4375C0 1.17228 0.105357 0.91793 0.292893 0.730393C0.48043 0.542857 0.734784 0.4375 1 0.4375H13C13.2652 0.4375 13.5196 0.542857 13.7071 0.730393C13.8946 0.91793 14 1.17228 14 1.4375C14 1.70272 13.8946 1.95707 13.7071 2.14461C13.5196 2.33214 13.2652 2.4375 13 2.4375H1C0.734784 2.4375 0.48043 2.33214 0.292893 2.14461C0.105357 1.95707 0 1.70272 0 1.4375ZM0 5.4375C0 5.17228 0.105357 4.91793 0.292893 4.73039C0.48043 4.54286 0.734784 4.4375 1 4.4375H13C13.2652 4.4375 13.5196 4.54286 13.7071 4.73039C13.8946 4.91793 14 5.17228 14 5.4375C14 5.70272 13.8946 5.95707 13.7071 6.14461C13.5196 6.33214 13.2652 6.4375 13 6.4375H1C0.734784 6.4375 0.48043 6.33214 0.292893 6.14461C0.105357 5.95707 0 5.70272 0 5.4375ZM0 9.4375C0 9.17228 0.105357 8.91793 0.292893 8.73039C0.48043 8.54286 0.734784 8.4375 1 8.4375H13C13.2652 8.4375 13.5196 8.54286 13.7071 8.73039C13.8946 8.91793 14 9.17228 14 9.4375C14 9.70272 13.8946 9.95707 13.7071 10.1446C13.5196 10.3321 13.2652 10.4375 13 10.4375H1C0.734784 10.4375 0.48043 10.3321 0.292893 10.1446C0.105357 9.95707 0 9.70272 0 9.4375ZM0 13.4375C0 13.1723 0.105357 12.9179 0.292893 12.7304C0.48043 12.5429 0.734784 12.4375 1 12.4375H13C13.2652 12.4375 13.5196 12.5429 13.7071 12.7304C13.8946 12.9179 14 13.1723 14 13.4375C14 13.7027 13.8946 13.9571 13.7071 14.1446C13.5196 14.3321 13.2652 14.4375 13 14.4375H1C0.734784 14.4375 0.48043 14.3321 0.292893 14.1446C0.105357 13.9571 0 13.7027 0 13.4375Z' fill='#D3D5DC' />
    </svg>
  );
}
