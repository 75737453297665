/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavbarWrapper from 'components/layout/NavbarWrapper';
import 'styles/components/pages/teacherClass.scss';
import TabPanel from 'components/shared/atoms/TabPanel';
import { useStudentReportSummaryQuery } from 'store/ApiCall/reportApiCalls';
import StudentReportSummary from 'components/shared/molecules/AdminQuizSummary/StudentReportSummary';
import Loader from 'components/shared/molecules/Loader';

export default function StudentSummary(): JSX.Element {
  const navigate = useNavigate();
  const { studentId = '' } = useParams();

  const { data: summaryData, isLoading, refetch } = useStudentReportSummaryQuery({ studentId });

  useEffect(() => {
    refetch();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className='classPageRoot'>
      <NavbarWrapper name={((): JSX.Element => (
        <span className='navbarTitle'>
          <div className='linkElement' onClick={(): void => { navigate(-1); }}>
            Students
          </div>
          <span className='seperator'>{'>'}</span>
          <span className='navbarTitle'>
            <div className='linkElement' onClick={(): void => { navigate(-1); }}>
              {summaryData?.data.basic_info.year.name}
            </div>
          </span>
          <span className='seperator'>{'>'}</span>
          <span className='currentElement'>
            {summaryData?.data.basic_info.student_name}
          </span>
        </span>
      ))()}
      >
        <div className='pageContent'>
          <div className='pageContent-single-tab'>
            <TabPanel value={1} index={1}>
              <StudentReportSummary summaryData={summaryData?.data} />
            </TabPanel>
          </div>
        </div>
      </NavbarWrapper>
    </div>
  );
}
