/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { getUserDetail } from 'utils/localStorage';
import HintAdded from 'assets/svgs/ts/HintAdded';
import HintModal from 'components/shared/molecules/QuizDetailPage/HintModal';
import Dropdown from 'components/shared/molecules/Dropdown/Dropdown';
import Logout from 'assets/svgs/ts/Logout';
import type {
  APIResponse, DropdownType, Question, QuestionProgressBarState, StudentReportSummaryQuestionsData, SubmitAnswerByStudent,
} from 'utils/types';
import { signoutAPICall } from 'store/ApiCall/authApiCalls';
import { signoutToStore } from 'store/reducer/authSlice';
import { useDispatch } from 'react-redux';
import type { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';
import { useStartAvailableQuizQuery, useHintTakenMutation, useQuestionAttemptMutation } from 'store/ApiCall/studentApiCalls';
import { CircularProgress } from '@mui/material';
import NoHint from 'assets/svgs/ts/NoHint';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import {
  checkSingularWord, returnQuestionType, isHintAddedToQuestion, convertIfSolvable,
} from 'utils/helper';
import EquationQuestion from 'components/shared/molecules/AdminQuizPresent/EquationQuestion';
import MCQQuestion from 'components/shared/molecules/AdminQuizPresent/MCQQuestion';
import Profile from 'assets/svgs/ts/Profile';
import clsx from 'clsx';
import QuizStatisticsBox from 'components/shared/molecules/StudentHomePage/QuizStatisticsBox';
import moment from 'moment';
import HomeIcon from '@mui/icons-material/Home';
import ShowAnswerModal from 'components/shared/molecules/QuizDetailPage/ShowAnswerModal';
import ArrowDown from 'assets/svgs/ts/ArrowDown';
import ArrowRight from 'assets/svgs/ts/ArrowRight';
import QuizSummary from 'components/shared/molecules/StudentHomePage/QuizSummary';
import { useStudentQuizSummaryMutation } from 'store/ApiCall/reportApiCalls';
import { ALLOWED_ATTEMPTS } from 'utils/constant';
import Logo from 'assets/svgs/sidebarLogoNewWhite.svg';

// Dropdown data
const DropdownData: DropdownType[] = [
  { id: 0, name: 'Profile', icon: <Profile className='quiz-profile-icon' /> },
  { id: 1, name: 'Logout', icon: <Logout /> },
];

export interface IQuote {
  text: string;
  author: string;
}

export default function StudentQuizPage(): JSX.Element {
  const [quote, setQuote] = useState<{ text: string; author: string }>({ text: '', author: '' });
  const [correctAnswer, setCorrectAnswer] = useState<string>();
  const [showOnIncorrect, setShowOnIncorrect] = useState<boolean>(false);
  const [allowedAttempts, setAllowedAttempts] = useState<number>(ALLOWED_ATTEMPTS - 1);
  const [attemptsCount, setAttemptsCount] = useState<number>(0);
  const [showHint, setShowHint] = useState<boolean>(false);
  const [activeQuestion, setActiveQuestion] = useState<Question | null>(null);
  const [questionStartTime, setQuestionStartTime] = useState<number>(0);
  const [quizAttemptTime, setQuizAttemptTime] = useState<Date | null>(null);
  const [progressBarState, setProgressBarState] = useState<QuestionProgressBarState[]>([]);
  const [isQuizCompleted, setIsQuizCompleted] = useState<boolean>(false);
  const [showAnswerModal, setShowAnswerModal] = useState<boolean>(false);
  const [nextQuestionId, setNextQuestionId] = useState<string | null>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [questionLoading, setQuestionLoading] = useState<boolean>(false);
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [summary, setSummary] = useState<StudentReportSummaryQuestionsData[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const activeQuestionRef = useRef<any>(null);

  // get user from localstorage
  const user = getUserDetail();
  const { id } = useParams();
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // check if page is coming from home page or not
  useEffect(() => {
    if (state === null) {
      window.location.href = '/home';
      return;
    }
    navigate(pathname, {});
  }, []);
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getQuote = async () => {
    const request = await fetch('https://type.fit/api/quotes');
    const response = await request.json();
    const quotes: IQuote[] = response.filter((quote: IQuote) => quote.author !== 'type.fit')?.map((quote: IQuote) => ({
      text: quote.text,
      author: quote.author.replace(', type.fit', ''),
    }));
    setQuote({ ...quotes[Math.floor(Math.random() * quotes.length)] });
    console.log(quotes[Math.floor(Math.random() * quotes.length)]);
    return response;
  };

  // disable back button functionality
  useEffect(() => {
    if (pathname.startsWith('/quiz-start/')) {
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', () => {
        window.history.pushState(null, document.title, window.location.href);
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getQuote();
  }, []);

  // Question type Api
  const { data: questionTypeList, isLoading: isGettingQuestionTypes } = useGetQuestionTypeListQuery();
  // API call to get quiz
  const { data: quizData, isLoading: isGettingQuiz, error } = useStartAvailableQuizQuery(id);
  // API for hint taken by student
  const [hintTakenApiCall, { isLoading: isHintTaking }] = useHintTakenMutation();
  // API for submit question
  const [submitAnswer, { isLoading: isSubmittingAnswer }] = useQuestionAttemptMutation();
  // API for student quiz summary
  const [getSummary, { isLoading: isGettingStudentSummary }] = useStudentQuizSummaryMutation();

  const getStudentSummary = async (): Promise<void> => {
    try {
      const response = await getSummary(id).unwrap();
      if (response.success) {
        setSummary(response.data.questions);
        setIsQuizCompleted(true);
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateAllowedAttempts = (question: any): void => {
    if (isHintAddedToQuestion(question)) {
      setAllowedAttempts(ALLOWED_ATTEMPTS - 1);
    } else {
      setAllowedAttempts(0);
    }
  };

  // set next question in Active State
  const setNextQuestionHandler = (questionId: string): void => {
    const filterQuestion = quizData?.data.question_attempts.find((question) => question._id === questionId);
    if (filterQuestion) {
      // Reset the MCQ
      activeQuestionRef.current?.setAnswer();
      setCorrectAnswer(filterQuestion.answer.toLowerCase());
      if (typeof filterQuestion.body === 'string') {
        const currentActiveQuestion = {
          ...filterQuestion,
          body: JSON.parse(filterQuestion.body),
        };
        setActiveQuestion(currentActiveQuestion);
        updateAllowedAttempts(currentActiveQuestion);

        setAttemptsCount(0);
        if (loading) setLoading(false);
        return;
      }
      setActiveQuestion(filterQuestion);
      updateAllowedAttempts(filterQuestion);
      setAttemptsCount(0);
      if (loading) setLoading(false);
    }
  };

  useEffect(() => {
    if (quizData && quizData.data.question_attempts.length > 0) {
      setNextQuestionHandler(quizData.data.next_question_attempt_id);
      const progressStateArr: QuestionProgressBarState[] = [];
      quizData.data.question_attempts.forEach((question) => {
        progressStateArr.push({ questionId: question._id, state: null, hintTaken: false });
      });
      setProgressBarState(progressStateArr);
    }
  }, [quizData]);

  // set timer for the first quiz
  useEffect(
    () => {
      if (!isGettingQuestionTypes && !isGettingQuiz) {
        setQuestionStartTime(new Date().getTime());
        setQuizAttemptTime(new Date());
      }
    },
    [isGettingQuestionTypes, isGettingQuiz],
  );

  // Dropdown handler
  const onClickHandler = (data: DropdownType): void => {
    if (data.name === 'Detail Page') {
      navigate(`/quiz-detail/${id}`);
    } else {
      signoutAPICall().then(() => {
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        dispatch(signoutToStore(null));
      }).catch((err: AxiosError<APIResponse<null, undefined>>) => {
        enqueueSnackbar(err.response?.data.message, { variant: 'error' });
      });
    }
  };

  // update progressBarState for after submitting answer
  const progressBarStateHandler = (isCorrect: boolean, questionId?: string): void => {
    const newProgressBarState = [...progressBarState];
    const index = newProgressBarState.findIndex((item) => item.questionId === questionId);
    if (index !== -1) {
      const currentProgressBar = newProgressBarState[index];
      currentProgressBar.state = isCorrect;
      newProgressBarState[index] = currentProgressBar;
      setProgressBarState(newProgressBarState);
    }
  };

  // hint taken handler
  const hintTakenHandler = (forced = false) => async (): Promise<void> => {
    try {
      setShowOnIncorrect(false);
      setShowHint(true);
      const response = await hintTakenApiCall({ questionId: activeQuestion?._id ?? '', forced }).unwrap();
      if (response.success) {
        const newProgressBarState = [...progressBarState];
        const index = newProgressBarState.findIndex((item) => item.questionId === activeQuestion?._id);
        if (index !== -1) {
          const currentProgressBar = newProgressBarState[index];
          currentProgressBar.hintTaken = true;
          newProgressBarState[index] = currentProgressBar;
          setProgressBarState(newProgressBarState);
        }
      }
    } catch (err) {
      setShowHint(false);
    }
  };

  // move to next question
  const onSubmitHandler = async (): Promise<void> => {
    // validation for MCQ question
    if (returnQuestionType(activeQuestion?.type_id ?? '', questionTypeList?.data) === 'Multiple Choice') {
      if (activeQuestionRef.current?.getAnswer() === '') { enqueueSnackbar('Please select an option!', { variant: 'error' }); return; }
    }

    setAttemptsCount(attemptsCount + 1);

    const questionFinalTime = new Date().getTime();
    const attemptTime = Math.round((questionFinalTime - questionStartTime) / 1000);

    try {
      if (attemptsCount < allowedAttempts && convertIfSolvable(activeQuestionRef.current?.getAnswer().toLowerCase()) !== convertIfSolvable(correctAnswer?.toString() ?? '')) {
        setShowOnIncorrect(true);
        setShowHint(true);
        await hintTakenHandler(true)();
        return;
      }

      if (quizData && activeQuestion) {
        const body: SubmitAnswerByStudent = {
          seconds_taken: attemptTime,
          question_attempt_id: activeQuestion._id ?? '',
          answer: activeQuestionRef.current?.getAnswer() ?? '',
        };

        const response = await submitAnswer(body).unwrap();
        if (response.data.next_question) {
          // set progressBar color on base of isCorrect question
          progressBarStateHandler(response.data.is_correct, activeQuestion._id);
          // if the answer is wrong
          if (!response.data.is_correct) {
            setShowAnswerModal(true);
            setNextQuestionId(response.data.next_question);
            setShowOnIncorrect(false);
            return;
          }
          setNextQuestionHandler(response.data.next_question);
          setShowOnIncorrect(false);
          // set timer for the question
          setQuestionStartTime(new Date().getTime());
        } else {
          // set progressBar color on base of isCorrect question
          progressBarStateHandler(response.data.is_correct, activeQuestion._id);
          // if the answer is wrong
          if (!response.data.is_correct) {
            setShowAnswerModal(true);
            setNextQuestionId(null);
            setShowOnIncorrect(false);
            return;
          }

          setShowOnIncorrect(false);
          await getStudentSummary();
        }
      }
    } catch (err) {
      enqueueSnackbar('Something went wrong', { variant: 'error' });
    }
  };

  // Got it answerModalHandler
  useEffect(() => {
    if (showAnswerModal || nextQuestionId === '') return;
    setQuestionLoading(true);
    // if quiz is completed
    setTimeout(async () => {
      if (nextQuestionId === null) {
        await getStudentSummary();
      } else {
        setNextQuestionHandler(nextQuestionId);
        setQuestionStartTime(new Date().getTime());
      }
      setQuestionLoading(false);
    }, 100);
  }, [showAnswerModal]);

  // return question isCorrect state
  const returnIsCorrectState = (questionState: QuestionProgressBarState): string => {
    if (questionState.state === null) return '';
    if (questionState.state) return 'correct';
    return 'wrong';
  };

  // return question prgressbar state count
  const returnProgressBarStateCount = (state: boolean): number => {
    const filterProgressBarState = progressBarState.filter((item) => item.state === state);
    return filterProgressBarState.length;
  };

  // Show Loader during API call
  if (isGettingQuiz || isGettingQuestionTypes || loading || isGettingStudentSummary) {
    return (
      <div className='quiz-present-page d-center'>
        <CircularProgress className='bg-white' />
      </div>
    );
  }

  if (error || quizData?.success === false) {
    navigate('/home');
  }

  const questionCount = quizData?.data.question_attempts.length;

  return (
    <div className='quiz-present-page'>
      <div className='quiz-present-page-header'>
        <div className='quiz-present-page-header-row-1 mb-16'>
          <img className='logo' src={Logo} alt='app-log' />
          <div className='number-of-questions'>{`${questionCount} ${checkSingularWord('Question', 'Questions', questionCount)}`}</div>
          <div className='quiz-present-page-header-row-1-profile'>
            <div className='quiz-present-page-header-row-1-profile-text'>Logged in as</div>
            <Dropdown
              title={`${user?.first_name} ${user?.last_name}`}
              data={DropdownData}
              onClickHandler={onClickHandler}
            />

          </div>
        </div>
        <div className='quiz-present-page-header-row-2 mb-16'>
          {progressBarState.length > 0 && progressBarState.map((question) => (
            <div
              key={question.questionId}
              className={clsx('progressive-bar-component', returnIsCorrectState(question))}
            />
          ))}
        </div>
        <div className='quiz-present-page-header-row-3'>
          {
            isQuizCompleted
              ? (
                <>
                  <div className='quiz-present-page-header-row-3-left'>
                    <div className='new-quiz-information-text-7'>Completed!</div>
                    <div className='new-quiz-information-text-8'>Here is how you performed.</div>
                  </div>
                  <div className='quiz-present-page-header-row-3-right'>
                    <ButtonComponent
                      type='outlined'
                      text='Back to Home'
                      size='large'
                      className='color-blue'
                      onClick={(): void => { navigate('/home'); }}
                      startIcon={<HomeIcon className='fill-white' />}
                    />
                  </div>

                </>
              )
              : (
                <>
                  <div className='quiz-present-page-header-row-3-text'>{`Question ${activeQuestion?.order_num}`}</div>
                  {activeQuestion && isHintAddedToQuestion(activeQuestion)
                    ? <HintAdded className='cursor-pointer' onClick={hintTakenHandler()} />
                    : <NoHint />}
                </>
              )
          }
        </div>

      </div>
      {isQuizCompleted
        ? (
          <div className='student-quiz-present-page-body mt-15'>
            <div className='last-quiz-info'>
              <div className='left-box'>
                <div className='new-quiz-information-text-1'>Test</div>
                <div className='new-quiz-information-text-2'>{quizData?.data.quiz_attempt.name}</div>
              </div>
              <div className='right-box'>
                <div className='new-quiz-information-text-3'>Attempted on </div>
                <div className='new-quiz-information-text-4'>{moment(quizAttemptTime).format('DD MMM YYYY, hh:mm a')}</div>
              </div>
            </div>
            <div className='last-quiz-states'>
              <QuizStatisticsBox className='border no-padding' title='Total Question' count={questionCount} />
              <QuizStatisticsBox className='border' title='Correct Answers' count={returnProgressBarStateCount(true)} />
              <QuizStatisticsBox title='Incorrect Answers' count={returnProgressBarStateCount(false)} />
            </div>
            <div className='last-summary-container'>
              <div className='last-quiz-summary'>
                <div
                  className='last-quiz-summary-left-btn'
                  role='presentation'
                  onClick={(): void => { setIsExpand((prev) => !prev); }}
                >
                  {isExpand ? <ArrowDown />
                    : <ArrowRight />}
                  <span className='new-quiz-information-text-6 ml-16'>Summary</span>
                </div>
              </div>
              {isExpand && (
                <QuizSummary
                  questionTypes={questionTypeList?.data}
                  questions={summary}
                />
              )}
              <div className='last-quiz-summary mt-35 ml-35'>
                <div className='new-quiz-information-text-2'>
                  {quote.text}
                  <div className='new-quiz-information-text-2'>
                    -
                    {quote.author}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className={clsx('quiz-present-page-body', questionLoading && 'd-center')}>
            {questionLoading ? <CircularProgress />
              : (
                <>
                  <div className='quiz-present-page-body-question-container'>
                    {returnQuestionType(activeQuestion?.type_id ?? '', questionTypeList?.data) === 'Equation Type'
                      ? <EquationQuestion ref={activeQuestionRef} data={activeQuestion} />
                      : <MCQQuestion ref={activeQuestionRef} data={activeQuestion} />}
                  </div>
                  <div className='quiz-present-page-body-footer'>
                    <ButtonComponent
                      size='large'
                      className='bg-blue white-loader'
                      type='contained'
                      text='Submit'
                      onClick={onSubmitHandler}
                      loading={isSubmittingAnswer}
                      progressLoaderSize={30}
                    />
                  </div>
                </>
              )}
          </div>
        )}
      <HintModal
        data={activeQuestion}
        isOpen={showHint}
        onCloseHandler={(): void => {
          setShowHint(false);
        }}
        loading={isHintTaking}
        showOnIncorrect={showOnIncorrect}
      />
      <ShowAnswerModal
        data={activeQuestion}
        inputAnswer={activeQuestionRef.current?.getAnswer() ?? ''}
        isOpen={showAnswerModal}
        onCloseHandler={(): void => { setShowAnswerModal(false); }}
        questionType={returnQuestionType(activeQuestion?.type_id ?? '', questionTypeList?.data)}
      />
    </div>
  );
}
