import type { ReactNode } from 'react';
import React from 'react';

import Button from '@mui/material/Button';
import { CircularProgress, IconButton } from '@mui/material';
import clsx from 'clsx';

interface ButtonComponentProps {
  type: 'contained' | 'icon' | 'outlined' | 'text' | undefined;
  text?: JSX.Element | string;
  icon?: JSX.Element;
  className?: string;
  isSubmitBtn?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClick?: (e?: any) => void;
  size?: 'large' | 'medium' | 'small' | undefined;
  startIcon?: ReactNode;
  loading?: boolean;
  loadingIconClass?: string;
  progressLoaderSize?: number;
}

export default function ButtonComponent(props: ButtonComponentProps): JSX.Element {
  const {
    type, text, icon, className = '', isSubmitBtn = false, disabled, onClick,
    size = 'large', startIcon, loading = false, loadingIconClass, progressLoaderSize = 40,
  } = props;
  return (
    <div>
      { type !== 'icon' && (
        <Button
          size={size}
          variant={type}
          className={clsx('btn', size, type === 'contained' ? 'primary-button' : 'secondary-button', className)}
          type={isSubmitBtn ? 'submit' : 'button'}
          disabled={disabled ?? loading}
          onClick={onClick}
          startIcon={loading ? <CircularProgress size={progressLoaderSize} className={loadingIconClass} color='inherit' /> : startIcon}
        >
          {text}
        </Button>
      )}

      { type === 'icon' && (
        <IconButton
          size={size}
          //          className={clsx('btn', size, type === 'contained' ? 'primary-button' : 'secondary-button', className)}
          type={isSubmitBtn ? 'submit' : 'button'}
          disabled={disabled ?? loading}
          onClick={onClick}
        >
          {icon}
        </IconButton>
      )}
    </div>
  );
}
