import React from 'react';
import {
  Dialog, DialogContent, DialogActions,
} from '@mui/material';
import clsx from 'clsx';
import InputField from 'components/shared/atoms/inputField/InputField';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import type { FractionState } from 'utils/types';
import { HexColorPicker } from 'react-colorful';
import { checkAlphaNumericValidation, checkEmptySpaces } from 'utils/helper';

interface SolvableFractionModalProps {
  isOpen: boolean;
  answerState: FractionState;
  setAnswerHandler: (fieldName: string, value: string) => void;
  onCloseHandler: () => void;
  onSaveAnswer: () => void;
}

export default function SolvableFractionModal(props: SolvableFractionModalProps): JSX.Element {
  const {
    isOpen, answerState, onCloseHandler, setAnswerHandler, onSaveAnswer,
  } = props;

  const answerStateHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (checkEmptySpaces(value) || checkAlphaNumericValidation(value)) return;
    // when denominator is zero
    if (name === 'denominatorAnswer' && value === '0') return;
    setAnswerHandler(name, value);
  };

  const onKeyDownHandler = (e: React.KeyboardEvent): void => {
    if (e.key === 'Enter' && answerState.numeratorAnswer !== '' && answerState.denominatorAnswer !== '') {
      onSaveAnswer();
    }
  };

  return (
    <Dialog className='solvable-fraction-modal' open={isOpen}>
      <DialogContent>
        <div className='math-quill-modal math-quill-modal-2'>
          <h2 className='math-quill-modal-fixed-title'>Add Solvable Fraction</h2>
          <div className='math-quill-modal-box'>
            <div className='math-quill-modal-box-1'>
              <div className='label-style-bold mb-24'>Numerator</div>
              <div className='math-quill-modal-box-1-body'>
                <div className='math-quill-modal-left-box'>
                  <InputField
                    autoComplete='off'
                    label='Label'
                    variant='outlined'
                    type='text'
                    name='numeratorLabel'
                    className='mb-33'
                    value={answerState.numeratorLabel}
                    autoFocus
                    onKeyDownHandler={onKeyDownHandler}
                    onChange={answerStateHandler}
                    fontColor={answerState.numeratorColor}
                  />
                  <InputField
                    autoComplete='off'
                    label='Answer'
                    variant='outlined'
                    type='text'
                    name='numeratorAnswer'
                    className='mb-33'
                    value={answerState.numeratorAnswer}
                    onKeyDownHandler={onKeyDownHandler}
                    onChange={answerStateHandler}
                  />
                </div>
                <div className={clsx('math-quill-modal-right-box', answerState.numeratorLabel === '' && 'disabled')}>
                  <HexColorPicker color={answerState.numeratorColor} onChange={(color: string): void => { setAnswerHandler('numeratorColor', color); }} />
                </div>
              </div>
            </div>
            <div className='math-quill-modal-box-2'>
              <div className='label-style-bold mb-24'>Denominator</div>
              <div className='math-quill-modal-box-2-body'>
                <div className='math-quill-modal-left-box'>
                  <InputField
                    autoComplete='off'
                    label='Label'
                    variant='outlined'
                    type='text'
                    name='denominatorLabel'
                    className='mb-33'
                    value={answerState.denominatorLabel}
                    onKeyDownHandler={onKeyDownHandler}
                    onChange={answerStateHandler}
                    fontColor={answerState.denominatorColor}
                  />
                  <InputField
                    autoComplete='off'
                    label='Answer'
                    variant='outlined'
                    type='text'
                    name='denominatorAnswer'
                    className='mb-33'
                    value={answerState.denominatorAnswer}
                    onKeyDownHandler={onKeyDownHandler}
                    onChange={answerStateHandler}
                  />
                </div>
                <div className={clsx('math-quill-modal-right-box', answerState.denominatorLabel === '' && 'disabled')}>
                  <HexColorPicker color={answerState.denominatorColor} onChange={(color: string): void => { setAnswerHandler('denominatorColor', color); }} />
                </div>
              </div>
            </div>
          </div>

        </div>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          size='small'
          className='w-82px'
          type='contained'
          text='Save'
          disabled={answerState.numeratorAnswer === '' || answerState.denominatorAnswer === ''}
          onClick={onSaveAnswer}
        />
        <ButtonComponent
          size='small'
          className='w-82px bg-red'
          type='contained'
          text='Cancel'
          onClick={onCloseHandler}
        />
      </DialogActions>
    </Dialog>
  );
}
