import React from 'react';
import type { SVGProps } from 'utils/types';

export default function CloseIcon(props: SVGProps): JSX.Element {
  const { className, onClick } = props;
  return (
    <svg className={className} onClick={onClick} width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M8.00191 10.121L3.06241 15.0605L0.941406 12.9395L5.88091 7.99995L0.941406 3.06045L3.06241 0.939453L8.00191 5.87895L12.9414 0.939453L15.0624 3.06045L10.1229 7.99995L15.0624 12.9395L12.9414 15.0605L8.00191 10.121Z' fill='#868C9F' />
    </svg>
  );
}
