import React from 'react';
import type { EditorInput } from 'utils/types';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import CancelIcon from '@mui/icons-material/Cancel';

interface EditorNewLineProps {
  onDelete: (id: string) => void;
  component: EditorInput;
}

export default function EditorNewLine(props: EditorNewLineProps): JSX.Element {
  const { onDelete, component } = props;
  return (
    <>
      <div className='editor-new-line'>
        <SubdirectoryArrowRightIcon />
        <CancelIcon className='close-icon' onClick={(): void => { onDelete(component.id); }} />
      </div>
      <br />
    </>
  );
}
