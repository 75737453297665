import React from 'react';

import { Dialog, DialogContent, DialogActions } from '@mui/material';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import PlayIcon from 'assets/svgs/ts/PlayIcon';
import CloseIcon from 'assets/svgs/ts/CloseIcon';
import type { NextQuizSingleQuestion } from 'utils/types';

interface StartQuizModalProps {
  isOpen: boolean;
  onClose: () => void;
  quizStartByTeacher: () => void;
  nextQuiz?: NextQuizSingleQuestion | null;
}

export default function StartQuizModal(props: StartQuizModalProps): JSX.Element {
  const {
    isOpen, onClose, quizStartByTeacher, nextQuiz,
  } = props;

  return (
    <Dialog open={isOpen} className='start-quiz-modal'>
      <DialogContent className='start-quiz-modal-information-container'>
        <div className='start-quiz-modal-information-container-header mb-10'>
          <div className='quiz-class-overview-page-text-2'>Start Next Quiz?</div>
          <CloseIcon onClick={onClose} />
        </div>
        <div className='quiz-class-overview-page-label-2 mb-10'>There is already a quiz in progress</div>
        <div className='start-quiz-info-box mb-10'>
          <div className='start-quiz-info-box-left mr-16'>
            <div className='quiz-class-overview-page-text-2'>{nextQuiz?.quiz.name}</div>
            <div className='quiz-class-overview-page-text-3'>{nextQuiz?.quiz.code}</div>
            <div className='quiz-class-overview-page-label-3'>{`${nextQuiz?.no_of_questions} Questions`}</div>
          </div>
          <div className='start-quiz-info-box-right'>
            <div className='multi-text-label-body-tag'>In Progress</div>
          </div>
        </div>
        <div className='quiz-class-overview-page-label-2'> Do you want to stop that and start the next quiz?</div>
      </DialogContent>
      <DialogActions>
        <div className='start-quiz-modal-footer'>
          <ButtonComponent
            text='Cancel'
            size='medium'
            type='outlined'
            className='color-blue extra-padding h-38'
            onClick={onClose}
          />
          <ButtonComponent
            text='Start Next Quiz anyway'
            size='medium'
            className='bg-blue'
            type='contained'
            startIcon={<PlayIcon />}
            onClick={(): void => {
              onClose();
              quizStartByTeacher();
            }}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}
