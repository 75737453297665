import React, { useState, useEffect, useRef } from 'react';

import CaretDown from 'assets/svgs/ts/CaretDown';
import clsx from 'clsx';
import type { DropdownType } from 'utils/types';

interface DropdownProps {
  className?: string;
  title: string;
  data: DropdownType[];
  onClickHandler: (data: DropdownType) => void;
}

export default function Dropdown(props: DropdownProps): JSX.Element {
  const {
    className, title, data, onClickHandler,
  } = props;
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const dropdownStateHandler = (): void => {
    setShowDropdown((prev) => !prev);
  };

  return (
    <div className={clsx(className, 'dropdown-container')}>
      <div ref={ref} className='dropdown-title' role='presentation' onClick={dropdownStateHandler}>
        <span>{title}</span>
        <CaretDown />
      </div>
      <div className={clsx('dropdown-list-container', showDropdown && 'visible')}>
        {data.map((item) => (
          <div
            key={item.id}
            className='dropdown-list-item'
            role='presentation'
            onClick={(): void => { onClickHandler(item); }}
          >
            <div className='dropdown-list-item-icon'>{item.icon}</div>
            <div className='dropdown-list-item-text'>{item.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
}
