/* eslint-disable react/no-array-index-key */
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SignInPage from 'components/pages/SignInPage';
import ForgotPassword from 'components/pages/ForgotPassword';
import TempComponent from 'components/layout/TempComponents';
import PageNotFound from 'components/pages/PageNotFound';
import ControlledRoutes from 'utils/ControlledRoutes';
import { StudentSignUp, TeacherSignUp } from 'components/pages/signup';
import LandingPage from 'components/pages/LandingPage';
import routesData from 'components/shared/routes';
import { getRole } from 'utils/helper';
import { useAppSelector } from 'utils/reduxHooks';
import type { UserType } from 'utils/types';
import ResetPassword from 'components/pages/ResetPassword';
import 'styles/App.scss';
import SignupWithCode from 'components/pages/SignupWithCode';

function App(): JSX.Element {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [userType, setUserType] = React.useState<string>('');
  const userAuthData = useAppSelector((state) => state.auth.user);

  React.useEffect(() => {
    const user = window.localStorage.getItem('user');
    if (user) {
      setIsAuthenticated(true);
      const userData: UserType = JSON.parse(user);
      setUserType(getRole(userData.role_id.name));
    }
  }, [userAuthData]);

  return (
    <Router>
      <Routes>
        <Route path='/signin' element={<SignInPage />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/reset-password' element={<ResetPassword />} />
        <Route path='/temp' element={<TempComponent />} />
        <Route path='/signup/student' element={<StudentSignUp />} />
        <Route path='/signup/teacher' element={<TeacherSignUp />} />
        <Route path='/signupwithcode' element={<SignupWithCode />} />
        <Route path='/' element={<LandingPage />} />
        {isAuthenticated
          ? (
            <Route element={<ControlledRoutes userRole={userType.toLowerCase()} />}>
              {routesData(userType.toLowerCase()).map((item, idx) => (<React.Fragment key={idx}>{item}</React.Fragment>))}
            </Route>
          )
          : null}
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
