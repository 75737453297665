/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';

import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import TextWithLabel from 'components/shared/atoms/TextWithLabel';
import ImprovedStateComponent from 'components/shared/atoms/ImprovedStateComponent';
import ArrowUpIcon from 'assets/svgs/ts/ArrowUpIcon';
import ArrowDownIcon from 'assets/svgs/ts/ArrowDownIcon';
import ReportSummaryChart from 'components/shared/molecules/AdminQuizSummary/ReportSummaryChart';
import BasicInfoCard from 'components/shared/molecules/AdminQuizSummary/BasicInfoCard';
import { SingleQuizSummary, SingleQuizSummaryData } from 'utils/tableDataPreprocessing';
import { Table } from 'components/shared/molecules/Table';
import { returnGraphData, secondsToTime } from 'utils/helper';
import type { AdminReportsSummary } from 'utils/types';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

export default function ReportSummary({ summaryData, refetch }: { summaryData: AdminReportsSummary | undefined;refetch: any }): JSX.Element {
  const navigate = useNavigate();
  const [processedTableDate, setProcessedTableData] = useState<any>([]);

  const goToStudentDetailPage = (studentId?: string): void => {
    navigate(`student/${studentId}`);
  };

  useEffect(() => {
    if (summaryData && summaryData.attempts.length > 0) {
      setProcessedTableData(SingleQuizSummaryData(summaryData.attempts));
    }
  }, [summaryData]);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className='quiz-class-overview-page'>
      <div className='quiz-class-overview-page-left-box report-summary'>
        <BasicInfoCard
          classYear={summaryData?.basic_info.class_year.name ?? ''}
          questionCount={summaryData?.basic_info.total_questions ?? 0}
          totalAttempts={summaryData?.attempts.length ?? 0}
          createdOn={summaryData ? moment(summaryData.basic_info.created_at).format('MMMM d, YYYY') : ''}
        />
        <div className='quiz-summary-table-container'>
          <div className='quiz-summary-table-inner'>
            <div className='label-style-bold quiz-summary-table-title'>Attempts</div>
            {
              processedTableDate.length > 0
                ? (
                  <Table
                    data={processedTableDate}
                    columns={SingleQuizSummary}
                    onClick={(id: string): void => { goToStudentDetailPage(id); }}
                  />
                )
                : <h5>No record found!</h5>
            }
          </div>
        </div>
      </div>

      <div className='quiz-class-overview-page-right-box'>
        <BasicCardContainer className='quiz-class-overview-page-next-quiz-box'>
          <div className='quiz-class-overview-page-text-1'>Report Insights</div>
          {
            summaryData?.report_insigths.avg_hints_taken !== null
              ? (
                <>
                  <div className='quiz-summary-chart'>
                    <div className='quiz-class-overview-page-label'>Scores</div>
                    <ReportSummaryChart
                      data={summaryData ? returnGraphData(summaryData.report_insigths.correct_answer_stats) : {
                        horizontalAxis: [],
                        verticleAxis: [],
                        NameArr: [],
                      }}
                      verticleLabel='Students'
                    />
                  </div>
                  <div className='quiz-class-overview-page-information-box-0'>
                    <div className='quiz-class-overview-page-label'>Improvement Stats of the Class</div>
                    <ImprovedStateComponent
                      labelClassName='quiz-class-overview-page-label'
                      textClassName='quiz-class-overview-page-text-2'
                      label='Increase in performance compared to previous quiz'
                      text={summaryData ? parseFloat(`${summaryData.report_insigths.improvement_stats.performance}`).toFixed(2) : ''}
                      Icon={summaryData && summaryData.report_insigths.improvement_stats.performance > 0 ? <ArrowUpIcon className='fill-green size-16' /> : <ArrowDownIcon className='fill-red size-16' />}
                    />
                    <ImprovedStateComponent
                      labelClassName='quiz-class-overview-page-label'
                      textClassName='quiz-class-overview-page-text-2'
                      label='Decrease in total hints taken compared to previous quiz'
                      text={summaryData ? parseFloat(`${summaryData.report_insigths.improvement_stats.hintsTaken}`).toFixed(2) : ''}
                      Icon={summaryData && !(summaryData.report_insigths.improvement_stats.hintsTaken > 0) ? <ArrowUpIcon className='fill-red size-16' /> : <ArrowDownIcon className='fill-green size-16' />}
                      className='border-top'
                    />
                  </div>
                  <div className='quiz-class-overview-page-information-box-1'>
                    <TextWithLabel
                      label='Avg. Solve Time'
                      labelClassName='quiz-class-overview-page-label'
                      text={summaryData?.report_insigths.avg_solve_time ? secondsToTime(summaryData.report_insigths.avg_solve_time) : ''}
                      textClassName='quiz-class-overview-page-text-2'
                    />
                    <TextWithLabel
                      label='Avg. Hints Taken'
                      labelClassName='quiz-class-overview-page-label'
                      text={summaryData ? summaryData.report_insigths.avg_hints_taken.toFixed() : ''}
                      textClassName='quiz-class-overview-page-text-2'
                    />
                    <TextWithLabel
                      label={`Top ${summaryData?.report_insigths.hardest_questions.length} Hardest Questions`}
                      labelClassName='quiz-class-overview-page-label'
                      text={`${summaryData?.report_insigths.hardest_questions.map((q) => `#${q} `)}`}
                      textClassName='quiz-class-overview-page-text-2'
                    />
                  </div>
                </>
              )
              : <h5>No report found!</h5>
          }
        </BasicCardContainer>
      </div>
    </div>
  );
}
