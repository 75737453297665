import { AllQuizIcon } from 'assets/svgs/ts/AllQuizIcon';
import { DashboardIcon } from 'assets/svgs/ts/DashboardIcon';
import { QuizBuilderIcon } from 'assets/svgs/ts/QuizBuilderIcon';
import { QuizBuilderPlusIcon } from 'assets/svgs/ts/QuizBuilderPlusIcon';
import { ReportsIcon } from 'assets/svgs/ts/ReportsIcon';
import { SchoolIcon } from 'assets/svgs/ts/SchoolIcon';
import { SettingsIcon } from 'assets/svgs/ts/SettingsIcon';
import { StudentsIcon } from 'assets/svgs/ts/StudentsIcon';
import { TeachersIcon } from 'assets/svgs/ts/TeachersIcon';
import QuestionListIcon from 'assets/svgs/ts/questionListIcon';

import ClassIcon from 'assets/svgs/ts/ClassIcon';
import { CircleIcon } from 'assets/svgs/ts/CircleIcon';

export const adminSidebarData = [
  {
    sectionId: 912,
    sectionName: 'Quiz',
    tabs: [
      {
        id: '04-all-quizzes',
        name: 'quizzes',
        heading: 'Quizzes',
        subheading: 'Years and reports',
        subMenu: [
          {
            id: '01-year',
            name: 'year-7',
            heading: 'Year 7',
            route: '/quizzes/634fc716fd321bc5337206e2/7th',
            icon: CircleIcon,
            hide: false,
          },
          {
            id: '02-year',
            name: 'year-8',
            heading: 'Year 8',
            route: '/quizzes/634fc725fd321bc5337206e3/8th',
            icon: CircleIcon,
            hide: false,
          },
          {
            id: '03-year',
            name: 'year-9',
            heading: 'Year 9',
            route: '/quizzes/63085f75a0499af96bad30fb/9th',
            icon: CircleIcon,
            hide: false,
          },
          {
            id: '04-year',
            name: 'year-10',
            heading: 'Year 10',
            route: '/quizzes/634fc739fd321bc5337206e4/10th',
            icon: CircleIcon,
            hide: false,
          },
          {
            id: '04-class',
            name: 'reports',
            heading: 'Reports',
            route: '/reports',
            icon: CircleIcon,
            hide: true,
          },
        ],
        route: '/quizzes',
        icon: AllQuizIcon,
        hide: false,
        actionButton: {
          id: '01-create-quiz',
          name: 'create-quiz',
          icon: QuizBuilderPlusIcon,
          route: '/quiz-builder',
          hide: false,
        },
      },
      {
        id: '03-quiz-builder',
        name: 'quiz-builder',
        heading: 'Quiz Builder',
        subheading: 'Create a new quiz',
        route: '/quiz-builder',
        icon: QuizBuilderIcon,
        hide: true,
        actionButton: null,
      },
      {
        id: '02-reports',
        name: 'reports',
        heading: 'Reports',
        subheading: 'Analytics related to quizzes',
        subMenu: null,
        route: '/reports',
        icon: ReportsIcon,
        hide: false,
        actionButton: null,
      },
      {
        id: '05-question-library',
        name: 'question-library',
        heading: 'Question Library',
        subheading: 'Search a new question',
        route: '/question-library',
        icon: QuestionListIcon,
        hide: false,
      },
    ],
    hide: false,
    sectionDivider: true,
  },
  {
    sectionId: 922,
    sectionName: 'Reporting',
    tabs: [
      {
        id: '01-dashboard',
        name: 'dashboard',
        heading: 'Dashboard',
        subheading: 'Outline of key info',
        subMenu: null,
        route: '/dashboard',
        icon: DashboardIcon,
        hide: true,
        actionButton: null,
      },
      {
        id: '02-reports',
        name: 'reports',
        heading: 'Reports',
        subheading: 'Analytics related to quizzes',
        subMenu: null,
        route: '/reports',
        icon: ReportsIcon,
        hide: false,
        actionButton: null,
      },
    ],
    hide: true,
    sectionDivider: false,
  },
  {
    sectionId: 988,
    sectionName: 'Management',
    tabs: [
      {
        id: '05-students',
        name: 'students',
        heading: 'Students',
        subheading: 'Student related info',
        subMenu: null,
        route: '/manage/students',
        icon: StudentsIcon,
        hide: false,
        actionButton: null,
      },
      {
        id: '08-schools',
        name: 'schools',
        heading: 'Schools',
        subheading: 'Add or edit schools',
        subMenu: null,
        route: '/manage/schools',
        icon: SchoolIcon,
        hide: false,
        actionButton: null,
      },
      {
        id: '06-teachers',
        name: 'teachers',
        heading: 'Teachers',
        subheading: 'Manage Teachers',
        subMenu: null,
        route: '/manage/teachers',
        icon: TeachersIcon,
        hide: false,
        actionButton: null,
      },
    ],
    hide: false,
    sectionDivider: false,
  },
  {
    sectionId: 932,
    sectionName: 'General',
    tabs: [
      {
        id: '07-settings',
        name: 'settings',
        heading: 'Settings',
        subheading: 'System configurations',
        subMenu: null,
        route: '',
        icon: SettingsIcon,
        hide: false,
        actionButton: null,
      },
    ],
    hide: true,
    sectionDivider: false,
  },
];

export const teacherSideBarData = [
  {
    sectionId: 2932,
    sectionName: 'Classes',
    tabs: [
      {
        id: '01-classes',
        name: 'My Classes',
        heading: 'My Classes',
        subheading: 'Configure classes',
        subMenu: null,
        route: '/my-classes',
        icon: ClassIcon,
        hide: false,
        actionButton: null,
      },
    ],
    hide: false,
    sectionDivider: false,
  },
  {
    sectionId: 988,
    sectionName: 'Management',
    tabs: [
      {
        id: '05-students',
        name: 'students',
        heading: 'Students',
        subheading: 'Student related info',
        subMenu: null,
        route: '/manage/students',
        icon: StudentsIcon,
        hide: false,
        actionButton: null,
      },
    ],
  },
];
