// import clsx from 'clsx';
import React from 'react';
import type { Question } from 'utils/types';
import { useSnackbar } from 'notistack';
import CopyIcon from 'assets/svgs/copyIcon.svg';

interface HintContainerProps {
  data: Question;
}

export default function HintContainer(props: HintContainerProps): JSX.Element {
  const { data } = props;
  const { enqueueSnackbar } = useSnackbar();

  const copyToClipBoard = (code: string | undefined): void => {
    if (code) {
      navigator.clipboard.writeText(code);
      enqueueSnackbar('Question code copied to clipboard.', { variant: 'success' });
    }
  };

  return (
    <>
      <div className='hint-container-header'>
        <div>
          <div className='question-id'>{data.order_num}</div>
        </div>
        <div className='question-code'>
          <div className='question-code-box'>
            {data._id}
          </div>
          <button
            type='button'
            onClick={(): void => { copyToClipBoard(data._id); }}
            className='copy-to-clipboard-btn'
          >
            <img className='copy-icon' src={CopyIcon} alt='copy to clipboard' />
          </button>
        </div>
      </div>
      <div className='mcq-hint'>
        <div className='mcq-hint-box'>
          {data.hint_title
        && (
          <div className='mcq-hint-left-box-1'>
            <div className='mcq-hint-text'>{data.hint_title}</div>
          </div>
        ) }
          <div className='mcq-hint-left-box-2'>
            {/* <div className={clsx('question-id', data.hint_title && 'd-none')}>{data.order_num}</div> */}
            <div className='mcq-hint-text'>{data.hint_body}</div>
          </div>
        </div>
        <div
          className='mcq-image-container'
          style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.hint_image_url})` }}
        />
      </div>
    </>

  );
}
