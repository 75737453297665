/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, {
  useState, forwardRef, useImperativeHandle,
} from 'react';
import type { Question } from 'utils/types';
import McqChoiceContainer from '../QuizDetailPage/McqChoiceContainer';

interface MCQQuestionProps {
  data: Question | null;
}

function MCQQuestion(props: MCQQuestionProps, ref: any): JSX.Element {
  const { data } = props;
  const [selectedOption, setSelectedOption] = useState<string>('');

  // for return the answer
  useImperativeHandle(ref, () => ({
    getAnswer(): string {
      return selectedOption;
    },
    setAnswer(): void {
      setSelectedOption('');
    },
  }));

  return (
    <div className='present-mcq-question'>
      <div className='present-mcq-question-left-box'>
        <div className='present-quiz-title'>{data?.statement}</div>
        {data?.image_url
          && (
            <div className='present-mcq-question-left-box-image-container'>
              <div
                className='present-mcq-question-left-box-image'
                style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.image_url})` }}
              />
            </div>
          )}
      </div>
      <div className='present-mcq-question-right-box'>
        <div className='present-quiz-title'>Select the correct answer</div>
        <div className='mcq-choices-container present-quiz-page'>
          {data?.body && data.body.length > 0 && data.body.map((choice: any, index: number) => (
            <McqChoiceContainer
              className='single-present-mcq-question'
              key={index}
              data={choice}
              answer={selectedOption}
              onClickHandler={(choiceId: string): void => { setSelectedOption(choiceId); }}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default React.memo(forwardRef(MCQQuestion));
