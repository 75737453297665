import React from 'react';

export default function QuestionListIcon(): JSX.Element {
  return (
    <svg className='questionLibraryIcon' color='yellow' xmlns='http://www.w3.org/2000/svg' version='1.1' width='256' height='256' viewBox='0 0 256 256' xmlSpace='preserve'>
      <defs />
      <g color='yellow' transform='translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)'>
        <path color='yellow' d='M 76.704 90 H 13.296 C 5.964 90 0 84.035 0 76.704 V 13.296 C 0 5.964 5.964 0 13.296 0 h 63.408 C 84.035 0 90 5.964 90 13.296 v 63.408 C 90 84.035 84.035 90 76.704 90 z M 13.296 6 C 9.273 6 6 9.273 6 13.296 v 63.408 C 6 80.728 9.273 84 13.296 84 h 63.408 C 80.728 84 84 80.728 84 76.704 V 13.296 C 84 9.273 80.728 6 76.704 6 H 13.296 z' transform=' matrix(1 0 0 1 0 0) ' strokeLinecap='round' />
        <path color='yellow' d='M 70.086 27.955 H 19.914 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 50.172 c 1.657 0 3 1.343 3 3 S 71.743 27.955 70.086 27.955 z' transform=' matrix(1 0 0 1 0 0) ' strokeLinecap='round' />
        <path color='yellow' d='M 70.086 48 H 19.914 c -1.657 0 -3 -1.343 -3 -3 c 0 -1.657 1.343 -3 3 -3 h 50.172 c 1.657 0 3 1.343 3 3 C 73.086 46.657 71.743 48 70.086 48 z' transform=' matrix(1 0 0 1 0 0) ' strokeLinecap='round' />
        <path color='yellow' d='M 70.086 68.046 H 19.914 c -1.657 0 -3 -1.343 -3 -3 s 1.343 -3 3 -3 h 50.172 c 1.657 0 3 1.343 3 3 S 71.743 68.046 70.086 68.046 z' transform=' matrix(1 0 0 1 0 0) ' strokeLinecap='round' />
      </g>
    </svg>
  );
}
