import React from 'react';
import { Route } from 'react-router-dom';

import Dashboard from 'components/pages/Dashboard';
import CreateNewQuiz from 'components/pages/CreateNewQuiz';
import QuizListPage from 'components/pages/Quizzes';
import QuizDetailPage from 'components/pages/QuizDetailPage';
import ClassQuizList from 'components/pages/QuizList';
import AdminQuizPresent from 'components/pages/AdminQuizPresent';
import TeacherClassesPage from 'components/pages/TeacherClasses';
import StudentHomePage from 'components/pages/StudentHomePage';
import StudentQuizPage from 'components/pages/StudentQuizPage';
import TeacherClassPage from 'components/pages/TeacherClass';
import SchoolListPage from 'components/pages/SchoolListPage';
import StudentListPage from 'components/pages/StudentListPage';
import QuizSummary from 'components/pages/Admin/QuizSummary';
import TeachersListPage from 'components/pages/TeachersListPage';
import StudentAttemptDetailReport from 'components/pages/StudentAttemptDetailReport';
import 'styles/App.scss';
import Reports from 'components/pages/Reports';
import StudentSummary from 'components/pages/Admin/StudentSummary';
import StudentAttemptQuizDetailReport from 'components/pages/Teacher/StudentAttemptQuizDetailReport';
import QuestionLibrary from 'components/pages/QuestionLibrary';
import TeacherStudentListPage from 'components/pages/TeacherStudentListPage';

export default function routesData(userRole: string): JSX.Element[] {
  if (userRole === 'admin') {
    return [
      <Route path='/dashboard' element={<Dashboard />} />,
      <Route path='/reports' element={<Reports />} />,
      <Route path='/quiz-builder' element={<CreateNewQuiz />} />,
      <Route path='/quiz-builder/edit/:id' element={<CreateNewQuiz />} />,
      <Route path='/quiz-detail/:id' element={<QuizDetailPage role={userRole} />} />,
      <Route path='/quizzes' element={<QuizListPage />} />,
      <Route path='/quizzes/:classYearId/:classYearName' element={<ClassQuizList />} />,
      <Route path='/quiz/:id/present' element={<AdminQuizPresent />} />,
      <Route path='/manage/schools' element={<SchoolListPage />} />,
      <Route path='/manage/students' element={<StudentListPage />} />,
      <Route path='/quiz/:quizId/report-summary' element={<QuizSummary role={userRole} />} />,
      <Route path='/quiz/:quizId/report-summary/student/:studentId' element={<StudentAttemptDetailReport />} />,
      <Route path='/manage/teachers' element={<TeachersListPage />} />,
      <Route path='/question-library' element={<QuestionLibrary />} />,
    ];
  }

  if (userRole === 'teacher') {
    return [
      <Route path='/my-classes' element={<TeacherClassesPage />} />,
      <Route path='/my-classes/class/:classId' element={<TeacherClassPage />} />,
      <Route path='/my-classes/class/:classId/present-quiz/:id' element={<AdminQuizPresent />} />,
      <Route path='/student/:studentId/report-summary' element={<StudentSummary />} />,
      <Route path='/student/:studentId/report-summary/quiz/:quizId' element={<StudentAttemptQuizDetailReport />} />,
      <Route path='/manage/students' element={<TeacherStudentListPage />} />,
      <Route path='/my-classes/class/:classId/quiz/:quizId/report-summary/student/:studentId' element={<StudentAttemptDetailReport />} />,
      <Route path='/my-classes/class/:classId/quiz/:quizId/report-summary' element={<QuizSummary role={userRole} />} />,
    ];
  }

  return [
    <Route path='/home' element={<StudentHomePage />} />,
    <Route path='/quiz-start/:id' element={<StudentQuizPage />} />,
  ];
}
