/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useGetAllStudentsManagementQuery } from 'store/ApiCall/managementApiCall';
import NavbarWrapper from 'components/layout/NavbarWrapper';
import { Table } from 'components/shared/molecules/Table';
import { preprocessStudentList, StudentListColumn } from 'utils/tableDataPreprocessing';
import type { StudentListPreprocessing } from 'utils/types';
import 'styles/components/pages/studentListPage.scss';
import { SearchFilter } from 'components/shared/atoms/QuizReportsFilters';
import CircularLoader from 'components/shared/molecules/Loader';

export default function StudentListPage(): JSX.Element {
  const [tableData, setTableData] = React.useState<StudentListPreprocessing[]>();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const { data, refetch } = useGetAllStudentsManagementQuery();
  React.useEffect(() => {
    if (data) {
      setTableData(preprocessStudentList(data.data));
    }
  }, [data]);

  useEffect(() => {
    const filteredData = data ? data.data.filter((singleReport) => singleReport.class_id.name.toLowerCase().includes(search.toLowerCase()) || singleReport.school_id.name.toLowerCase().includes(search.toLowerCase()) || singleReport.user_id.first_name.toLowerCase().includes(search.toLowerCase())) : [];
    setTableData(preprocessStudentList(filteredData));
    setLoading(false);
  }, [search]);

  const resetSearch = (): void => {
    setSearch('');
  };

  React.useEffect(() => {
    refetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (loading) {
    return (
      <NavbarWrapper name='Reports'>
        <CircularLoader />
      </NavbarWrapper>
    );
  }

  return (
    <NavbarWrapper name='Students'>

      <div className='reports-container'>
        <div className='reports-filters'>
          {/* <RangeFilter {...{ range, setRange }} onClick={(e): void => { resetDate(e); }} /> */}
          <SearchFilter {...{ search, setSearch }} onClick={resetSearch} />
        </div>
        <div className='studentListPageRoot'>

          {tableData
            ? !_.isEmpty(tableData) ? (
              <div className='tableContainer'>
                <div className='tableInner'>
                  <Table data={tableData} columns={StudentListColumn} enableSorting />
                </div>
              </div>
            )
              : (
                <div className='noDataAvailable'>
                  <h2>No students found!</h2>
                </div>
              )
            : (<CircularLoader />)}
        </div>
      </div>
    </NavbarWrapper>
  );
}
