/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

import {
  Grid,
} from '@mui/material';
import type { AdminAggregatedReportQuestion, EditorInput } from 'utils/types';
import McqChoiceContainer from 'components/shared/molecules/QuizDetailPage/McqChoiceContainer';
import MQStaticField from 'components/shared/atoms/MathQuill/MQStaticField';
import clsx from 'clsx';
import ResponsesList from 'components/pages/aggregatedResponsePage/ResponsesList';
import StatsSection from 'components/pages/aggregatedResponsePage/StatsSection';
import { checkLatexBrackets } from 'utils/helper';

interface SingleQuestionProps {
  data: AdminAggregatedReportQuestion;
  questionType: string;
}

export default function SingleQuestion(props: SingleQuestionProps): JSX.Element {
  const { data, questionType } = props;

  const returnEquationBody = (showAnswer?: boolean): JSX.Element => typeof data.question.body !== 'string' && data.question.body.length > 0 && data.question.body.map((item: EditorInput, index: number) => {
    if (item.type === 1) {
      let filterAnswer = JSON.parse(data.question.answer).find((ans: any) => ans.componentIndex === index);

      if (!filterAnswer) {
        filterAnswer = [];
      }

      return (
        <MQStaticField
          key={item.id}
          latex={item.state}
          answers={filterAnswer.answer}
          className={clsx('equation-question-component-list-item', checkLatexBrackets(item.state) && 'extra-class')}
          readOnly
          isAdminStaticField
          hideAnswer={!showAnswer}
        />
      );
    }
    if (item.type === 2) {
      return (<div key={item.id} className='equation-question-component-list-item equation-question-text'>{item.state}</div>);
    }
    if (item.type === 4) {
      return (
        <div key={item.id} className='equation-question-component-list-item equation-answer-text-2'>
          {showAnswer ? item.state : ' '}
          <span>{` ${item.unit}`}</span>
        </div>
      );
    }
    return (<br key={item.id} />);
  });

  return (
    <div className='single-question'>
      <div className='single-question-id'>
        Question
        {data.question.order_num}
      </div>

      <div className='question-body'>
        <div className='question-body-statement'>{data.question.statement}</div>
        <Grid container justifyContent='space-between'>
          {data.question.image_url
               && (
                 <Grid item xs={12} md={6}>
                   <div className='image-wrapper'>
                     <div
                       className='question-image'
                       style={{ backgroundImage: `url(${`${process.env.REACT_APP_BACKEND_URL}${data.question.image_url}`})` }}
                     />
                   </div>
                 </Grid>
               )}
          <Grid item xs={12} md={6} className={data.question.image_url && 'right-grid'}>
            {questionType === 'Equation Type'
              ? (
                <div className='question-side'>
                  <div className='single-question-label mb-40'>Question statement</div>
                  <div className='equation-question-component-list mb-33'>
                    {returnEquationBody(false)}
                  </div>
                  <div className='single-question-label mb-24'>Correct answer</div>
                  <div className='equation-question-component-list mb-33'>
                    {returnEquationBody(true)}
                  </div>
                </div>
              )
              : (
                <div className='question-side'>
                  <div className='single-question-label mb-40'>Question choices</div>
                  <div className='mcq-choices-container present-quiz-page mb-40'>
                    {data.question.body.length > 0 && data.question.body.map((choice: any, index: number) => (
                      <McqChoiceContainer
                        className='single-present-mcq-question aggregated-choice no-cursor'
                        key={index}
                        data={choice}
                        answer=''
                      />
                    ))}
                  </div>
                  <div className='single-question-label mb-24'>Correct answer</div>
                  <div
                    className='choice-id active mb-33'
                  >
                    {data.question.answer}
                  </div>
                </div>
              )}
          </Grid>
        </Grid>
      </div>

      <div className='responses-area'>
        <Grid container>
          {/* percentages area */}
          <Grid item xs={12} sm={12} md={6}>
            {/* Stats Data */}
            <StatsSection data={data.stats} />
          </Grid>

          {/* Responses list */}
          <Grid item xs={12} sm={12} md={6}>
            <ResponsesList
              data={data.responses}
              questionType={questionType}
            />
          </Grid>
        </Grid>
      </div>

    </div>
  );
}
