import React from 'react';
import type { SVGProps } from 'utils/types';

export default function DoubleUpArrow(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='13' height='14' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M0.792787 13.2068C0.605316 13.0193 0.5 12.765 0.5 12.4998C0.5 12.2346 0.605316 11.9803 0.792787 11.7928L5.79279 6.79279C5.98031 6.60532 6.23462 6.5 6.49979 6.5C6.76495 6.5 7.01926 6.60532 7.20679 6.79279L12.2068 11.7928C12.3889 11.9814 12.4897 12.234 12.4875 12.4962C12.4852 12.7584 12.38 13.0092 12.1946 13.1946C12.0092 13.38 11.7584 13.4852 11.4962 13.4875C11.234 13.4897 10.9814 13.3889 10.7928 13.2068L6.49979 8.91379L2.20679 13.2068C2.01926 13.3943 1.76495 13.4996 1.49979 13.4996C1.23462 13.4996 0.980314 13.3943 0.792787 13.2068ZM0.792787 7.20679C0.605316 7.01926 0.5 6.76495 0.5 6.49979C0.5 6.23462 0.605316 5.98031 0.792787 5.79279L5.79279 0.792786C5.98031 0.605315 6.23462 0.5 6.49979 0.5C6.76495 0.5 7.01926 0.605315 7.20679 0.792786L12.2068 5.79279C12.3889 5.98139 12.4897 6.23399 12.4875 6.49619C12.4852 6.75838 12.38 7.0092 12.1946 7.1946C12.0092 7.38001 11.7584 7.48518 11.4962 7.48746C11.234 7.48974 10.9814 7.38894 10.7928 7.20679L6.49979 2.91379L2.20679 7.20679C2.01926 7.39426 1.76495 7.49957 1.49979 7.49957C1.23462 7.49957 0.980314 7.39426 0.792787 7.20679Z' fill='#1A61FF' />
    </svg>
  );
}
