/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint @typescript-eslint/no-unsafe-call: 0 */
import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { HamBurgerIcon } from 'assets/svgs/ts/HamburgerIcon';

const columnHelper = createColumnHelper<any>();

export const mapColumns = (col: any, isDraggable = false): any[] => {
  // TODO: map columns without initializing new array
  let newArr: any[] = [];
  if (isDraggable) {
    if (!newArr.length) {
      newArr = [
        columnHelper.accessor('draggable', {
          id: 'draggable',
          header: () => '',
        }),
      ];
    }
    col.forEach((c: any) => {
      newArr = [
        ...newArr,
        columnHelper.accessor(c.property.toString(), {
          id: c.property.toString(),
          header: () => c.name.toString(),
        }),
      ];
    });
    return newArr;
  }

  col.forEach((c: any): any => {
    newArr = [
      ...newArr,
      columnHelper.accessor(c.property.toString(), {
        id: c.property.toString(),
        header: () => c.name.toString(),
      }),
    ];
  });

  return newArr;
};

export const addDraggableColumn = (data: any): any => {
  data.forEach((item: any) => {
    item.draggable = <span className='draggableCell'><HamBurgerIcon /></span>;
    return item;
  });
  return data;
};
