import React from 'react';

import clsx from 'clsx';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

interface HardestQuestionProps {
  questionOrder: number;
  className?: string;
  tagName: string;
  progressValue: number;
}

export default function HardestQuestion(props: HardestQuestionProps): JSX.Element {
  const {
    questionOrder, className, tagName, progressValue,
  } = props;
  return (
    <div className={clsx('hardest-question', className)}>
      <div className='hardest-question-left-box'>
        <div className='question-id hardest-question-id'>{questionOrder}</div>
        {tagName && <div className='hardest-question-tag mt-10'>{tagName}</div>}
      </div>
      <div className='hardest-question-right-box'>
        <CircularProgressbar
          value={progressValue}
          strokeWidth={15}
          text={`${progressValue}%`}
        />
      </div>
    </div>
  );
}
