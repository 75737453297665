import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
  checkSingularWord, returnGraphData, returnQuestionType, secondsToTime,
} from 'utils/helper';
import TextWithLabel from 'components/shared/atoms/TextWithLabel';
import IconicText from 'components/shared/atoms/Text/IconicText';
import DoubleDownArrow from 'assets/svgs/ts/DoubleDownArrow';
import DoubleUpArrow from 'assets/svgs/ts/DoubleUpArrow';
import ReportSummaryChart from 'components/shared/molecules/AdminQuizSummary/ReportSummaryChart';
import HardestQuestion from 'components/shared/molecules/QuizClass/HardestQuestion';
import DetailedHardestQuestion from 'components/shared/molecules/QuizClass/DetailedHardestQuestion';
import { useInProgressQuizReportTeacherQuery } from 'store/ApiCall/reportApiCalls';
import { CircularProgress } from '@mui/material';
import { useGetQuestionTypeListQuery } from 'store/ApiCall/quizzesApiCall';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import PresentActionIcon from 'assets/svgs/ts/PresentActionIcon';

interface QuizInProgressReportProps {
  classCode?: string;
  quizCode?: string;
  quizName?: string;
  quizId?: string;
  questionCount?: number;
  refetchQuizReport: boolean;
}

export default function QuizInProgressReport(props: QuizInProgressReportProps): JSX.Element {
  const {
    classCode, quizCode, quizName, questionCount, quizId, refetchQuizReport,
  } = props;
  const navigate = useNavigate();
  // Question type Api
  const { data: questionTypeList, isLoading: isGettingQuestionTypeList } = useGetQuestionTypeListQuery();

  const [detailQuestion, setDetailQuestion] = useState<boolean>(false);
  const { classId } = useParams();

  const { data: inProgressQuiz, isLoading, refetch } = useInProgressQuizReportTeacherQuery(classId);

  useEffect(() => {
    refetch();
    const refetchReportInterval = setInterval(() => {
      refetch();
    }, Number(process.env.REACT_APP_REFETCH_QUIZ_IN_PROGRESS_REPORT));

    return () => { clearInterval(refetchReportInterval); };
  }, [refetchQuizReport, refetch]);

  // For present quiz
  const presentQuizHandler = (): void => {
    if (quizId) navigate(`/my-classes/class/${classId}/present-quiz/${quizId}`);
  };

  if (isLoading || isGettingQuestionTypeList) {
    return (
      <div className='progress-quiz-report-page d-center flex-1'>
        <CircularProgress />
      </div>
    );
  }

  if (!inProgressQuiz?.data) {
    return (
      <div className='progress-quiz-report-page d-center flex-1'>
        <div className='quiz-class-overview-page-text-2 text-center'>
          {inProgressQuiz?.message}
        </div>
      </div>
    );
  }

  const hardestQuestionCount = inProgressQuiz.data.hardestQuestions?.length ?? 0;

  return (
    <div className='progress-quiz-report-page'>
      <div className='start-quiz-modal progress-quiz-report-box-2'>
        <div className='start-quiz-info-box'>
          <div className='start-quiz-info-box-left'>
            <div className='quiz-class-overview-page-text-1'>
              {quizName}
              {' '}
              <span>is now in progress </span>
            </div>
            <div className='quiz-class-overview-page-label-3'>{`${questionCount} ${checkSingularWord('Question', 'Questions', questionCount)}`}</div>
            <div className='quiz-class-overview-page-text-2 mt-24' style={{ fontSize: 15 }}>
              Share the class code with students:
              {' '}
              <span style={{ fontSize: 19 }}>
                {classCode}
              </span>
            </div>
          </div>
          <div className='start-quiz-info-box-right-side'>
            <ButtonComponent
              type='icon'
              icon={<PresentActionIcon />}
              size='medium'
              className=' mb-8'
              onClick={presentQuizHandler}
            />
            {/* TODO: We have to make this button functional */}
            {/* <ButtonComponent
              type='outlined'
              text='View Complete Report'
              size='medium'
            /> */}
          </div>
        </div>
      </div>
      {inProgressQuiz.data.correctCountWithStudents
      && inProgressQuiz.data.improvementStats
      && (
        <div className='progress-quiz-report-box-3'>
          <div className='quiz-summary-chart'>
            <div className='quiz-class-overview-page-label'>Scores</div>
            <ReportSummaryChart
              verticleLabel='Students'
              data={returnGraphData(inProgressQuiz.data.correctCountWithStudents)}
            />
          </div>
          <div className='progress-quiz-report-box-container'>
            <div className='progress-quiz-report-box-5'>
              <TextWithLabel
                label='Total Attempts'
                labelClassName='quiz-class-overview-page-label'
                text={`${inProgressQuiz.data.summary?.totalAttempts}`}
                textClassName='quiz-class-overview-page-text-2'
              />
              <TextWithLabel
                label='Avg. Solve Time'
                labelClassName='quiz-class-overview-page-label'
                text={inProgressQuiz.data.summary ? secondsToTime(inProgressQuiz.data.summary.avgSolveTimeInSeconds) : ''}
                textClassName='quiz-class-overview-page-text-2'
                className='my-16'
              />
              <TextWithLabel
                label='Avg. Hints Taken'
                labelClassName='quiz-class-overview-page-label'
                text={inProgressQuiz.data.summary?.avgHintsTaken.toFixed()}
                textClassName='quiz-class-overview-page-text-2'
              />
            </div>
          </div>
          {/* <div className='progress-quiz-report-states'>
            <div className='quiz-class-overview-page-label'>Improvement Stats of the Class</div>
            <ImprovedStateComponent
              labelClassName='quiz-class-overview-page-label'
              textClassName='quiz-class-overview-page-text-2'
              label='Increase in performance compared to last quiz'
              text={inProgressQuiz.data.improvementStats.performance.toFixed(1)}
              Icon={inProgressQuiz.data.improvementStats.performance > 0 ? <ArrowUpIcon className='fill-green size-16' /> : <ArrowDownIcon className='fill-red size-16' />}
            />
            <ImprovedStateComponent
              labelClassName='quiz-class-overview-page-label'
              textClassName='quiz-class-overview-page-text-2'
              label='decrease in total hints taken compared to last quiz'
              text={inProgressQuiz.data.improvementStats.hintsTaken.toFixed(1)}
              Icon={!(inProgressQuiz.data.improvementStats.hintsTaken > 0) ? <ArrowUpIcon className='fill-red size-16' /> : <ArrowDownIcon className='fill-green size-16' />}
              className='border-top'
            />
          </div> */}
        </div>
      )}
      {inProgressQuiz.data.hardestQuestions
      && (
        <div className='progress-quiz-report-box-4'>
          <div className='quiz-class-overview-page-label'>{`Top ${inProgressQuiz.data.hardestQuestions.length} Hardest Questions`}</div>
          <div className='progress-quiz-report-box-4-hardest-questions'>
            {inProgressQuiz.data.hardestQuestions.map((question, i) => (
              <HardestQuestion
                key={question.order_number}
                questionOrder={question.order_number}
                className={i < (hardestQuestionCount - 1) ? 'border-right' : ''}
                tagName={question.concept}
                progressValue={100 - Number(question.correctAnswers.toFixed(0))}
              />
            ))}
          </div>
          {detailQuestion
         && (
           <div className='hardest-question-detail-box mt-16'>
             {inProgressQuiz.data.hardestQuestions.map((question) => (
               <DetailedHardestQuestion
                 key={question.order_number}
                 question={question}
                 type={returnQuestionType(question.type_id, questionTypeList?.data)}
               />
             ))}
           </div>
         )}
          <div className='view-details-btn mt-16'>
            <IconicText
              Icon={detailQuestion ? <DoubleUpArrow /> : <DoubleDownArrow />}
              text={detailQuestion ? 'Less Details' : 'View Details'}
              onClick={(): void => { setDetailQuestion((prev) => !prev); }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
