/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useGetAllClassQuizListQuery } from 'store/ApiCall/quizzesApiCall';
import { useSnackbar } from 'notistack';
import NavbarWrapper from 'components/layout/NavbarWrapper';
import BasicCard from 'components/shared/molecules/Card';
import CircularLoader from 'components/shared/molecules/Loader';
import 'styles/components/pages/quizzes.scss';
import type { ClassData } from 'utils/types';

export default function Quizzes(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, error } = useGetAllClassQuizListQuery();

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Something went wrong. Please refresh the page.', { variant: 'error' });
    }
  }, [error, enqueueSnackbar]);

  return (
    <NavbarWrapper name='Quizzes'>
      <>
        {isLoading && <CircularLoader />}
        {!isLoading && data && (
          <div className='root'>
            <h2 className='classYearHeading'>Class Years</h2>
            <div className='cardContainer'>
              {data.data.map(({ year, quiz, students }: ClassData): JSX.Element => (
                <div key={year._id}>
                  <Link to={`/quizzes/${year._id}/${year.name}`}>
                    <BasicCard title={`Year ${year.year}`} subHeading={quiz > 1 ? `${quiz} Quizzes` : `${quiz} Quiz`} detail={`${students} Students`} styles='cardOverride' gutterRight gutterBottom />
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    </NavbarWrapper>
  );
}
