/* eslint-disable @typescript-eslint/no-unsafe-call */
import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CrossIcon from 'assets/svgs/ts/CrossIcon';
import 'styles/components/shared/molecules/AddClassModal/main.scss';

interface ModalProps {
  isOpen: boolean;
  onCloseHandler: () => void;
  title?: string;
  subHeading?: string;
  children?: JSX.Element;
}

export default function Modal({
  isOpen,
  onCloseHandler,
  title,
  subHeading,
  children,
}: ModalProps): JSX.Element {
  return (
    <Dialog open={isOpen} className='addModal'>
      <DialogActions className='addClassModalAction'>
        <button type='button' onClick={onCloseHandler} className='closeBtn'>
          <span><CrossIcon /></span>
        </button>
      </DialogActions>

      <DialogContent className='modalTextBox'>
        <h2>{title}</h2>
        <p>{subHeading}</p>
      </DialogContent>
      <div className='modalChildren'>
        {children}
      </div>
      <DialogContent />
    </Dialog>
  );
}
