/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import InputField from 'components/shared/atoms/inputField/InputField';
import Toolbar from 'components/shared/molecules/CreateNewQuiz/Toolbar';
import QuestionContainer from 'components/shared/molecules/CreateNewQuiz/QuestionContainer';
import {
  Units, REQUIRED_FIELD, SELECT_CHOICE_FIELD, MIN_CHOICE, AT_LEAST_ONE_EQUATION_OR_ANSWER_FIELD, AT_LEAST_ONE_SOLVABLE_FIELD, CHOICE_HAVE_EMPTY_STATE, BLANK_FIELD,
} from 'utils/constant';
import type {
  IImage, QuestionState, Question, QuizErrors, Options, SingleQuestionError, EditorInput, Choice,
} from 'utils/types';
import PlusIcon from 'assets/svgs/ts/PlusIcon';
import {
  checkEmptySpaces,
  returnQuestionOptionIndex, returnQuestionOptionNumber,
  returnQuestionTypeName, sizeInMB, returnPreviousAlphabet, imageUrlToFile, convertFileToImage, returnImageType,
} from 'utils/helper';
import NavbarWrapper from 'components/layout/NavbarWrapper';
import {
  useCreateQuizMutation, useGetClassYearListQuery, useGetQuestionTypeListQuery, useGetQuizByIdMutation,
} from 'store/ApiCall/quizzesApiCall';
import { useGetQuestionMutation } from 'store/ApiCall/questionApiCall';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import { useSnackbar } from 'notistack';
import EquationIcon from 'assets/svgs/ts/EquationIcon';
import MCQIcon from 'assets/svgs/ts/MCQIcon';
import { TextField, MenuItem } from '@mui/material';
import Modal from 'components/shared/molecules/Modal';

export default function CreateNewQuiz(): JSX.Element | null {
  const questionRef = useRef<HTMLDivElement[]>([]);
  const EditorBoxRef = useRef<any[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const { id: quizId } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState<QuestionState>({
    name: '',
    class_year_id: '',
    create_by: '',
    questions: [],
    deleteQuestions: [],
  });
  const [quizCode, setQuizCode] = useState<string>();
  const [equationTypeQuestions, setEquationTypeQuestion] = useState<Options[]>([]);
  const [fieldErrors, setFieldErrors] = useState<QuizErrors>({
    name: '',
    class: '',
    questions: [],
  });
  const [activeEquationQuestion, setActiveEquationQuestion] = useState<Options>({
    value: 'Question #1',
    index: 0,
  });
  const [unit, setUnit] = useState<string>(Units[0]);
  const [classYear, setClassYear] = useState<string>('');
  const [questionType, setQuestionType] = useState<Options[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [addExistingQuestionModal, setAddExistingQuestionModal] = useState<boolean>(false);
  const [existingQuestionOrder, setExistingQuestionOrder] = useState<number>(-1);

  // ClassYear Api
  const { data: classYearList } = useGetClassYearListQuery();
  // Question type Api
  const { data: questionTypeList } = useGetQuestionTypeListQuery();
  // Get Question By ID Api
  const [getQuestion, { isLoading: addQuestionLoader }] = useGetQuestionMutation();

  useEffect(() => {
    if (questionTypeList === undefined) return;
    const { success, data } = questionTypeList;
    if (success) {
      const newQuestionTypesList: Options[] = data.map((opt) => {
        // TODO: make a generic approach for icons
        const Icon = opt.name === 'Equation Type' ? <EquationIcon className='fill-blue-2' /> : <MCQIcon />;
        return { value: opt.name, _id: opt._id, Icon };
      });
      setQuestionType(newQuestionTypesList);
    }
  }, [questionTypeList]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const quizNameValidation = (value: string): void => {
    if (value.length === 0) {
      setFieldErrors({ ...fieldErrors, name: REQUIRED_FIELD });
    } else {
      delete fieldErrors.name;
    }
  };

  const classValidation = (value: string): void => {
    if (value.length === 0) {
      setFieldErrors({ ...fieldErrors, class: REQUIRED_FIELD });
    } else {
      delete fieldErrors.class;
    }
  };

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (checkEmptySpaces(value)) return;
    setState({ ...state, [name]: value });
    if (name === 'name') {
      quizNameValidation(value);
    }
  };

  const quizCodeChangeHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    if (checkEmptySpaces(value)) return;
    if (!value) setQuizCode('');
    setQuizCode(value);
  };

  const basicInformationValidationHandler = (): boolean => {
    classValidation(classYear);
    if (state.name === '' || classYear === '') return true;
    return false;
  };

  const classYearHandler = (e: any): void => {
    setState({ ...state, class_year_id: e.target.value });
    setClassYear(e.target.value);
    classValidation(e.target.value);
  };

  const questionStatementValidation = (question: Question, value: string): void => {
    const questionsFieldErrors = cloneDeep(fieldErrors.questions);
    let isAlreadyExist = false;
    const ErrorValue = value === '' ? REQUIRED_FIELD : '';
    let questionErrorId = '';
    if (questionsFieldErrors) {
      let questionIndex = -1;
      if (question._id) {
        questionIndex = questionsFieldErrors.findIndex((questionError) => questionError.id === question._id);
        questionErrorId = question._id;
      } else {
        questionIndex = questionsFieldErrors.findIndex((questionError) => questionError.id === question.id);
        questionErrorId = question.id;
      }
      if (questionIndex !== -1 && questionsFieldErrors !== undefined) {
        isAlreadyExist = true;
        const questionError = questionsFieldErrors[questionIndex];
        questionError.question_statement = ErrorValue;
        questionsFieldErrors[questionIndex] = questionError;
      }
    }
    if (!isAlreadyExist) {
      questionsFieldErrors?.push({ id: questionErrorId, question_statement: ErrorValue });
    }
    setFieldErrors({ ...setFieldErrors, questions: questionsFieldErrors });
  };

  const equationBodyValidation = (question: Question, questionIndex: number, equationBody?: EditorInput[]): string => {
    let body = [];
    if (equationBody) {
      body = equationBody;
    } else if (question.active_tab === 'hint') {
      if (question.body === '') {
        return REQUIRED_FIELD;
      }
      if (typeof question.body === 'string') {
        body = JSON.parse(question.body);
      }
    } else {
      body = EditorBoxRef.current[questionIndex].getEquationList();
    }
    if (body.length === 0) {
      return REQUIRED_FIELD;
    }
    // Must have at least one equation or answer input
    let equationField = body.find((input: EditorInput) => input.type === 1 || input.type === 4);
    if (!equationField) {
      return AT_LEAST_ONE_EQUATION_OR_ANSWER_FIELD;
    }
    // All fields are required
    equationField = body.find((input: EditorInput) => input.type !== 3 && input.state === '');
    if (equationField) {
      return BLANK_FIELD;
    }
    // Must have at least one solvable field if no answer is present
    // 1) First check answer field is present or not
    const answerField = body.find((input: EditorInput) => input.type === 4);
    if (!answerField) {
    // 2) Check at least one equation field has solvable field
      const solvableInputField = body.find((input: EditorInput) => input.type === 1 && input.state.search('embed') !== -1);
      if (!solvableInputField) {
        return AT_LEAST_ONE_SOLVABLE_FIELD;
      }
    }
    return '';
  };

  const mcqBodyValidation = (question: Question): string => {
    if (question.body.length < 2) {
      // Validation for min 2 choices
      return MIN_CHOICE;
    } if (typeof question.body !== 'string') {
      // Validation for empty choice state
      const emptyStateChoice = question.body.find((choice: any) => choice.state === '');
      if (emptyStateChoice) {
        return CHOICE_HAVE_EMPTY_STATE;
      } if (!question.answer) {
        // Validation for atleast one choice must be selected
        return SELECT_CHOICE_FIELD;
      }
    }
    return '';
  };

  // Single Question validation
  const questionStateValidation = (question: Question, questionsFieldErrors: SingleQuestionError[], questionIndex: number, equationBody?: EditorInput[]): SingleQuestionError[] => {
    let isAlreadyExist = false;
    const questionErrorId = question._id ? question._id : question.id;
    //  Check if question error is already present in questionsFieldErrors or not
    if (questionsFieldErrors) {
      const index = questionsFieldErrors?.findIndex((questionErr) => questionErr.id === questionErrorId);
      if (index !== -1 && questionsFieldErrors) {
        isAlreadyExist = true;
        const questionFieldError = questionsFieldErrors[index];
        if (returnQuestionTypeName(questionType, question.type_id) === 'Equation Type') {
          const err = equationBodyValidation(question, questionIndex, equationBody);
          questionFieldError.body = err;
        } else {
          const err = mcqBodyValidation(question);
          questionFieldError.choice = err;
        }
        questionsFieldErrors[index] = questionFieldError;
      }
    }
    if (!isAlreadyExist) {
      if (returnQuestionTypeName(questionType, question.type_id) === 'Equation Type') {
        const err = equationBodyValidation(question, questionIndex, equationBody);
        questionsFieldErrors?.push({ id: questionErrorId, body: err });
      } else {
        const err = mcqBodyValidation(question);
        questionsFieldErrors?.push({ id: questionErrorId, choice: err });
      }
    }
    return questionsFieldErrors;
  };

  // For Question Body or Choice CRUD
  const singleQuestionValidation = (question: Question, questionIndex: number, equationBody?: EditorInput[]): void => {
    const questionsFieldErrors = cloneDeep(fieldErrors.questions);
    questionStateValidation(question, questionsFieldErrors, questionIndex, equationBody);
    setFieldErrors({ ...setFieldErrors, questions: questionsFieldErrors });
  };

  // Validate all question before saving a quiz
  const questionBodyValidation = (questionsArr: Question[]): boolean => {
    const questions = cloneDeep(questionsArr);
    const questionsFieldErrors = cloneDeep(fieldErrors.questions);
    questions.forEach((question, questionIndex) => {
      questionStateValidation(question, questionsFieldErrors, questionIndex);
    });
    setFieldErrors({ ...setFieldErrors, questions: questionsFieldErrors });
    const errorIndex = questionsFieldErrors.findIndex((err) => err.choice || err.body);
    if (errorIndex === -1) return true;
    return false;
  };

  const onBlurHandler = (e: React.FocusEvent<HTMLInputElement>, question?: Question): void => {
    const { name, value } = e.target;
    if (question !== undefined) {
      questionStatementValidation(question, value);
    } else if (name === 'name') {
      quizNameValidation(value);
    }
  };

  const questionScrollHandler = (questionIndex: number): void => {
    // We will wait 5ms until the question component will be added into the DOM before accessing its ref
    setTimeout(() => {
      questionRef.current[questionIndex].scrollIntoView({
        behavior: 'smooth', block: 'nearest', inline: 'start',
      });
      setTimeout(() => {
        window.scrollBy(0, -80);
      }, 600);
    }, 500);
  };

  // Get the Equation type questions for toolbar
  const toolbarQuestionListHandler = (questionArr: Question[], questionIndex?: number, action?: string): void => {
    if (questionArr.length === 0) { setEquationTypeQuestion([]); return; }
    const equationQuestions: Options[] = [];
    questionArr.forEach((question, i) => {
      const typeName = questionTypeList?.data.find((item) => item._id === question.type_id)?.name;
      if (typeName && typeName === 'Equation Type') {
        equationQuestions.push({
          value: `Question #${question.order_num}`,
          index: i,
        });
      }
    });
    if (equationQuestions.length === 0) { setEquationTypeQuestion([]); return; }

    // Handle the toolbar current question
    if (action && (questionIndex || questionIndex === 0)) {
      const toolbarActiveQuestionIndex = activeEquationQuestion.index ?? 0;
      const destinationQuestion: Options[] = [];
      if (action === 'jump') {
        destinationQuestion.push({
          value: `Question #${questionIndex + 1}`,
          index: questionIndex,
        });
      } else if (action === 'duplicate') {
        // duplicate active question
        const newDuplicatedQuestion = equationQuestions.find((item) => item.index === questionIndex + 1);
        if (newDuplicatedQuestion) {
          destinationQuestion.push(newDuplicatedQuestion);
        }
      } else {
        const previousIndex = equationTypeQuestions.findIndex((item) => item.index === questionIndex);
        if (action === 'type change') {
          // if ref is present on the current question
          if (toolbarActiveQuestionIndex === questionIndex && previousIndex !== 0) {
            destinationQuestion.push(equationQuestions[previousIndex - 1]);
          } else {
            destinationQuestion.push({
              value: `${activeEquationQuestion.value}`,
              index: activeEquationQuestion.index ?? 0,
            });
          }
        } else if (action === 'dont-change-active-ref') {
          const currentPosition = equationTypeQuestions.findIndex((item) => item.index === activeEquationQuestion.index);
          const currentActiveObject = equationQuestions[currentPosition];
          destinationQuestion.push(currentActiveObject);
        } else if (action === 'delete-equation') {
          // if ref is present on the current question
          if (toolbarActiveQuestionIndex === questionIndex) {
            if (questionIndex === 0) {
              destinationQuestion.push(equationQuestions[previousIndex + 1]);
            } else {
              destinationQuestion.push(equationQuestions[previousIndex - 1]);
            }
          } else {
            // ref is not present on the current question
            destinationQuestion.push(equationQuestions[previousIndex]);
          }
        }
      }
      setEquationTypeQuestion(equationQuestions);
      setActiveEquationQuestion({
        value: destinationQuestion[0].value,
        index: destinationQuestion[0].index || 0,
      });
      return;
    }
    const obj = equationQuestions[equationQuestions.length - 1];
    setActiveEquationQuestion({
      value: obj.value,
      index: obj.index,
    });
    setEquationTypeQuestion(equationQuestions);
  };

  // Get quiz by id
  const [getQuizApi] = useGetQuizByIdMutation();

  useEffect(() => {
    if (!quizId) {
      setLoading(false);
    }
    async function getQuizFunction(): Promise<void> {
      setLoading(true);
      try {
        const { success, data } = await getQuizApi(quizId ?? '').unwrap();
        if (success) {
          const newData = cloneDeep(data);
          newData.questions.forEach((question) => {
            question.active_tab = 'question';
          });
          setState({ ...newData, deleteQuestions: [] });
          setLoading(false);
          // Verify if thiis fix is good
          if (newData.class_year_id) setClassYear(newData.class_year_id);
          toolbarQuestionListHandler(newData.questions);
        }
      } catch (err) {
        console.log('ERR', err);
        enqueueSnackbar('Something went wrong', { variant: 'error' });
        setLoading(false);
      }
    }
    if (quizId && questionTypeList) {
      getQuizFunction();
    } else {
      setState({
        name: '',
        class_year_id: '',
        create_by: '',
        questions: [],
        deleteQuestions: [],
      });
      setEquationTypeQuestion([]);
      toolbarQuestionListHandler([]);
      setFieldErrors({
        name: '',
        questions: [],
      });
      setClassYear('');
    }
  }, [questionTypeList, quizId]);

  const duplicateImageHandler = async (imageUrl: string): Promise<IImage | null> => {
    const imageFile = await imageUrlToFile(`${process.env.REACT_APP_BACKEND_URL}${imageUrl}`, returnImageType(imageUrl));
    const copiedImage = await convertFileToImage(imageFile);
    return copiedImage;
  };

  const addQuestionHandler = (order: number): void => {
    const questionArr = cloneDeep(state.questions);
    const typeId = questionType[0]._id;

    for (let i = order; i < questionArr.length; i += 1) {
      questionArr[i].order_num += 1;
      questionArr[i].is_updated = true;
    }

    questionArr.splice(order, 0, {
      id: uuid(),
      type_id: typeId !== undefined ? typeId : '',
      statement: '',
      image: null,
      image_url: '',
      concept: '',
      difficulty: '',
      body: [],
      answer: '',
      hint_title: '',
      hint_image_url: '',
      hint_body: '',
      hint_image: null,
      is_updated: true,
      active_tab: 'question',
      order_num: order + 1,
    });

    setState({
      ...state,
      questions: questionArr,
    });
    questionScrollHandler(order);
    toolbarQuestionListHandler(questionArr);
  };

  const addExistingQuestionHandler = async (order: number): Promise<void> => {
    try {
      const { data: question }: any = await getQuestion(quizCode || '');
      const questionData = question.data;
      const questionBody: any = questionData.body ? JSON.parse(questionData.body) : [];
      const questionArr = cloneDeep(state.questions);

      if (returnQuestionTypeName(questionType, questionData.type_id).toLowerCase() === 'multiple choice') {
        questionBody.forEach(async (choice: Choice) => {
          if (choice.state === 'image') {
            choice.image = await duplicateImageHandler(`${choice.image_url}`);
            choice.image_url = '';
          }
        });
      }

      const existingQuestion = {
        id: uuid(),
        type_id: questionData.type_id,
        statement: questionData.statement ? questionData.statement : '',
        image: questionData.image_url ? await duplicateImageHandler(`${process.env.REACT_APP_QUESTION_IMAGE_PATH}/${questionData.image_url}`) : null,
        image_url: '',
        concept: questionData.concept ? questionData.concept : '',
        difficulty: questionData.difficulty ? questionData.difficulty : '',
        body: questionData.body ? questionBody : [],
        answer: questionData.answer ? questionData.answer : '',
        hint_title: questionData.hint_title ? questionData.hint_title : '',
        hint_image_url: '',
        hint_body: questionData.hint_body ? questionData.hint_body : '',
        hint_image: questionData.hint_image_url ? await duplicateImageHandler(`${process.env.REACT_APP_QUESTION_IMAGE_PATH}/${questionData.hint_image_url}`) : null,
        is_updated: true,
        active_tab: 'question',
        order_num: order + 1,
      };

      for (let i = order; i < questionArr.length; i += 1) {
        questionArr[i].order_num += 1;
        questionArr[i].is_updated = true;
      }

      questionArr.splice(order, 0, existingQuestion);

      setState({
        ...state,
        questions: questionArr,
      });

      questionScrollHandler(Math.min(order + 1, state.questions.length));
      toolbarQuestionListHandler(questionArr);

      setAddExistingQuestionModal(!addExistingQuestionModal);
      setQuizCode('');
      setExistingQuestionOrder(-1);
      return;
    } catch (e) {
      enqueueSnackbar('Error adding question. Please try again!', { variant: 'error' });
    }
  };

  const questionTypeHandler = (questionIndex: number, type: string): void => {
    const questionArr = cloneDeep(state.questions);
    let questionsFieldError = cloneDeep(fieldErrors.questions);
    const question = questionArr[questionIndex];
    const typeId = questionType.find((item) => item.value === type)?._id;
    if (typeId) {
      question.type_id = typeId;
      question.is_updated = true;
      question.body = [];
      question.answer = '';
      questionArr[questionIndex] = question;
      questionsFieldError = questionsFieldError?.filter((questionErr) => questionErr.id !== question.id);
      setState({ ...state, questions: questionArr });
      setFieldErrors({ ...fieldErrors, questions: questionsFieldError });
      if (type === 'Equation Type') {
        toolbarQuestionListHandler(questionArr, questionIndex, 'jump');
      } else {
        toolbarQuestionListHandler(questionArr, questionIndex, 'type change');
      }
    }
  };

  const questionTabHandler = (questionIndex: number, tab: string): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    question.active_tab = tab;
    question.is_updated = true;
    const isEquationType = returnQuestionTypeName(questionType, question.type_id) === 'Equation Type';
    if (isEquationType && tab === 'hint') {
      const equationList = EditorBoxRef.current[questionIndex].getEquationList();
      if (equationList.length === 0) {
        question.body = '';
      } else {
        question.body = JSON.stringify(equationList);
      }
    }
    questionArr[questionIndex] = question;
    setState({ ...state, questions: questionArr });
  };

  const questionDeleteHandler = (questionIndex: number, _id?: string): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    questionArr.splice(questionIndex, 1);
    EditorBoxRef.current[questionIndex] = null;
    // For the deleted question_ids
    const deleteQuestionsId: string[] = [...state.deleteQuestions];
    let questionId = question.id;
    if (_id !== undefined) {
      deleteQuestionsId.push(_id);
      questionId = _id;
    }
    // update order_num and is_update flag of the next questions
    questionArr.forEach((questionItem, index) => {
      if (index >= questionIndex) {
        questionItem.order_num = index + 1;
        questionItem.is_updated = true;
      }
    });
    // Remove the validation error for deleted question
    let questionFieldError = cloneDeep(fieldErrors.questions);
    questionFieldError = questionFieldError?.filter((questionErr) => questionErr.id !== questionId);
    setState({
      ...state,
      questions: questionArr,
      deleteQuestions: deleteQuestionsId,
    });
    setFieldErrors({ ...fieldErrors, questions: questionFieldError });
    questionScrollHandler(questionIndex - 1);
    if (returnQuestionTypeName(questionType, question.type_id) === 'Multiple Choice') {
      toolbarQuestionListHandler(questionArr, questionIndex, 'dont-change-active-ref');
    } else {
      toolbarQuestionListHandler(questionArr, questionIndex, 'delete-equation');
    }
  };

  const addChoiceHandler = (questionIndex: number): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    if (typeof question.body !== 'string') {
      question.body.push({
        key: returnQuestionOptionNumber(question.body.length), state: '', image_url: '', image: null, type: '',
      });
      question.is_updated = true;
    }
    questionArr[questionIndex] = question;
    singleQuestionValidation(question, questionIndex);
    setState({ ...state, questions: questionArr });
  };

  const choiceImageHandler = (questionIndex: number, img: IImage, choiceId?: string): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    if (typeof question.body !== 'string') {
      const choiceIndex = returnQuestionOptionIndex(choiceId);
      const choice = question.body[choiceIndex];
      choice.image = img;
      choice.state = 'image';
      choice.type = 'image';
      question.body[choiceIndex] = choice;
      question.is_updated = true;
    }
    questionArr[questionIndex] = question;
    singleQuestionValidation(question, questionIndex);
    setState({ ...state, questions: questionArr });
  };

  const choiceStateHandler = (questionIndex: number, value: string, choiceId: string): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    if (typeof question.body !== 'string') {
      const choiceIndex = returnQuestionOptionIndex(choiceId);
      const choice = question.body[choiceIndex];
      choice.state = value;
      question.body[choiceIndex] = choice;
      question.is_updated = true;
    }
    questionArr[questionIndex] = question;
    singleQuestionValidation(question, questionIndex);
    setState({ ...state, questions: questionArr });
  };

  const choiceTypeHandler = (questionIndex: number, choiceId: string, type: string): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    if (typeof question.body !== 'string') {
      const choiceIndex = returnQuestionOptionIndex(choiceId);
      const choice = question.body[choiceIndex];
      choice.type = type;
      question.body[choiceIndex] = choice;
    }
    questionArr[questionIndex] = question;
    setState({ ...state, questions: questionArr });
  };

  const removeChoiceHandler = (questionIndex: number, choiceId: string): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    if (typeof question.body !== 'string') {
      let filterChoice = question.body.filter((choice: any) => choice.key !== choiceId);
      filterChoice = filterChoice.map((choice: any, i: number) => ({ ...choice, key: returnQuestionOptionNumber(i) }));
      question.body = filterChoice;
      question.is_updated = true;
      if (choiceId === question.answer) {
        question.answer = '';
      } else if (choiceId < question.answer) {
        question.answer = returnPreviousAlphabet(question.answer);
      }
    }
    questionArr[questionIndex] = question;
    setState({ ...state, questions: questionArr });
    singleQuestionValidation(question, questionIndex);
  };

  const questionImageHandler = (questionIndex: number, img: IImage | null): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    question.image = img;
    question.is_updated = true;
    if (img === null) {
      question.image_url = '';
    }
    questionArr[questionIndex] = question;
    setState({ ...state, questions: questionArr });
  };

  const hintImageHandler = (questionIndex: number, img: IImage | null): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    question.hint_image = img;
    question.is_updated = true;
    if (img === null) {
      question.hint_image_url = '';
    }
    questionArr[questionIndex] = question;
    setState({ ...state, questions: questionArr });
  };

  const imageHandler = (e: React.ChangeEvent<HTMLInputElement>, type: string, questionIndex: number, choiceId?: string): void => {
    const { files } = e.target;
    if (files) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (): void => {
        if (typeof reader.result === 'string') {
          const fileContent = reader.result.split(',');
          const fileExtension = file.name.split('.').pop();
          const fileName = file.name;
          const fileSize = sizeInMB(file.size);
          const image = {
            file_content: fileContent[1],
            file_extension: fileExtension,
            file_path: fileName,
            file_type: 2,
            file_size: fileSize,
            image_extension: fileContent[0],
          };
          if (type === 'question-image') {
            questionImageHandler(questionIndex, image);
          } else if (type === 'choice-image') {
            choiceImageHandler(questionIndex, image, choiceId);
          } else if (type === 'hint-image') {
            hintImageHandler(questionIndex, image);
          }
        }
      };
    }
  };

  const deleteQuestionImage = (questionIndex: number): void => {
    questionImageHandler(questionIndex, null);
  };
  const deleteHintImage = (questionIndex: number): void => {
    hintImageHandler(questionIndex, null);
  };

  const questionInputHandler = (questionIndex: number, value: string, fieldName: string): void => {
    if (checkEmptySpaces(value)) return;
    const questionArr = cloneDeep(state.questions);
    let question = questionArr[questionIndex];
    question = { ...question, [fieldName]: value };
    question.is_updated = true;
    questionArr[questionIndex] = question;
    if (fieldName === 'statement') {
      questionStatementValidation(question, value);
    }
    setState({ ...state, questions: questionArr });
  };

  const questionTagHandler = (questionIndex: number, name: string, value: string): void => {
    const questionArr = cloneDeep(state.questions);
    let question = questionArr[questionIndex];
    question = { ...question, [name]: value };
    question.is_updated = true;
    questionArr[questionIndex] = question;
    setState({ ...state, questions: questionArr });
  };

  const changeQuestionPositionHandler = (from: number, destination: number, action: string): void => {
    const questionArr = cloneDeep(state.questions);
    const element = questionArr[from];
    const destinationElement = questionArr[destination];
    let temp = null;
    temp = element.order_num;
    // set order_num and is_updated true for the original & destination question
    element.is_updated = true;
    element.order_num = destinationElement.order_num;
    destinationElement.is_updated = true;
    destinationElement.order_num = temp;
    // set position of original & destination question
    questionArr[destination] = element;
    questionArr[from] = destinationElement;
    setState({ ...state, questions: questionArr });
    toolbarQuestionListHandler(questionArr, destination, action);
    questionScrollHandler(destination);
  };

  const moveUpHandler = (questionIndex: number): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    if (returnQuestionTypeName(questionType, question.type_id) === 'Multiple Choice') {
      changeQuestionPositionHandler(questionIndex, questionIndex - 1, 'dont-change-active-ref');
    } else {
      changeQuestionPositionHandler(questionIndex, questionIndex - 1, 'jump');
    }
  };

  const moveDownHandler = (questionIndex: number): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    if (returnQuestionTypeName(questionType, question.type_id) === 'Multiple Choice') {
      changeQuestionPositionHandler(questionIndex, questionIndex + 1, 'dont-change-active-ref');
    } else {
      changeQuestionPositionHandler(questionIndex, questionIndex + 1, 'jump');
    }
  };

  const duplicateHandler = async (questionIndex: number): Promise<void> => {
    const questionArr = cloneDeep(state.questions);
    const question = { ...questionArr[questionIndex] };
    if (returnQuestionTypeName(questionType, question.type_id) === 'Equation Type') {
      if (question.active_tab === 'question') {
        const equationList = EditorBoxRef.current[questionIndex].getEquationList();
        if (equationList.length === 0) {
          question.body = '';
        } else {
          question.body = equationList;
        }
      } else if (question.body !== '' && typeof question.body === 'string') {
        question.body = JSON.parse(question.body);
      }
    } else {
      // duplicate choice images
      question.body.forEach(async (choice: Choice) => {
        if (choice.state === 'image') {
          choice.image = await duplicateImageHandler(choice.image_url);
          choice.image_url = '';
        }
      });
      // duplicate question image
      if (question.image_url) {
        question.image = await duplicateImageHandler(question.image_url);
        question.image_url = '';
      }
      // duplicate hint image
      if (question.hint_image_url) {
        question.hint_image = await duplicateImageHandler(question.hint_image_url);
        question.hint_image_url = '';
      }
    }
    if (question._id) {
      delete question._id;
    }
    const questionElement = { ...question, id: uuid(), active_tab: 'question' };
    questionArr.splice(questionIndex + 1, 0, questionElement);
    // Update order_num and is_update flag
    questionArr.forEach((questionItem, index) => {
      if (index > questionIndex) {
        questionItem.order_num = index + 1;
        questionItem.is_updated = true;
      }
    });
    setState({ ...state, questions: questionArr });
    questionScrollHandler(questionIndex + 1);
    if (returnQuestionTypeName(questionType, question.type_id) === 'Multiple Choice') {
      toolbarQuestionListHandler(questionArr, questionIndex, 'dont-change-active-ref');
    } else {
      toolbarQuestionListHandler(questionArr, questionIndex, 'duplicate');
    }
  };

  const returnQuestNameError = (question: Question): string | undefined => {
    let filterError: any = {};
    if (question._id) {
      filterError = fieldErrors.questions?.find((questionErr) => questionErr.id === question._id);
    } else {
      filterError = fieldErrors.questions?.find((questionErr) => questionErr.id === question.id);
    }
    if (filterError && filterError.question_statement !== '') {
      return filterError.question_statement;
    }
    return undefined;
  };
  const returnQuestionBodyError = (question: Question, name: string): string | undefined => {
    let filterError: any = {};
    if (question._id) {
      filterError = fieldErrors.questions?.find((questionErr) => questionErr.id === question._id);
    } else {
      filterError = fieldErrors.questions?.find((questionErr) => questionErr.id === question.id);
    }
    if (filterError) {
      if (name === 'choice' && filterError.choice !== '') {
        return filterError.choice;
      }
      if (name === 'equation' && filterError.body !== '') {
        return filterError.body;
      }
    }
    return undefined;
  };

  const selectAnswerHandler = (answer: string, questionIndex: number): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    question.answer = answer;
    question.is_updated = true;
    questionArr[questionIndex] = question;
    singleQuestionValidation(question, questionIndex);
    setState({ ...state, questions: questionArr });
  };

  const equationIsUpdateHandler = (questionIndex: number, equationBody: EditorInput[]): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    question.is_updated = true;
    questionArr[questionIndex] = question;
    singleQuestionValidation(question, questionIndex, equationBody);
    setState({ ...state, questions: questionArr });
  };

  const answerFieldHandler = (questionIndex: number, equationBody: EditorInput[], answer: string): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    question.answer = answer;
    question.is_updated = true;
    questionArr[questionIndex] = question;
    singleQuestionValidation(question, questionIndex, equationBody);
    setState({ ...state, questions: questionArr });
  };

  const editSolvableHandler = (questionIndex: number): void => {
    const questionArr = cloneDeep(state.questions);
    const question = questionArr[questionIndex];
    question.answer = EditorBoxRef.current[questionIndex].getAnswerField();
    question.is_updated = true;
    questionArr[questionIndex] = question;
    setState({ ...state, questions: questionArr });
  };

  const refHandler = (el: HTMLDivElement, questionIndex: number): void => {
    questionRef.current[questionIndex] = el;
  };
  const editorRefHandler = (el: any, type: string, questionIndex: number): void => {
    const typeName = questionType.find((item) => item.value === type)?.value;
    if (typeName && typeName === 'Multiple Choice') return;
    EditorBoxRef.current[questionIndex] = el;
  };

  const addEditorComponentHandler = (type: number): void => {
    if (EditorBoxRef === null) return;
    if (activeEquationQuestion.index || activeEquationQuestion.index === 0) {
      const equationBody = EditorBoxRef.current[activeEquationQuestion.index].inserInputHandler(type);
      equationIsUpdateHandler(activeEquationQuestion.index, equationBody);
    }
  };
  const commandFromToolbarHandler = (cmd: string): void => {
    if (EditorBoxRef === null) return;
    if (activeEquationQuestion.index || activeEquationQuestion.index === 0) {
      EditorBoxRef.current[activeEquationQuestion.index].commandFromToolbar(cmd);
    }
  };
  const equationToolsHandler = (name: string): void => {
    if (EditorBoxRef === null) return;
    if (activeEquationQuestion.index || activeEquationQuestion.index === 0) {
      if (name === 'Make solvable fraction') {
        EditorBoxRef.current[activeEquationQuestion.index].addFractionAnswerField();
      } else if (name === 'Make solvable') {
        EditorBoxRef.current[activeEquationQuestion.index].addAnswerField();
      } else if (name === 'Make solvable with degree') {
        const equationBody = EditorBoxRef.current[activeEquationQuestion.index].inserInputHandler(4, '°');
        equationIsUpdateHandler(activeEquationQuestion.index, equationBody);
      } else if (name === 'Apply unit') {
        const equationBody = EditorBoxRef.current[activeEquationQuestion.index].inserInputHandler(4, unit);
        equationIsUpdateHandler(activeEquationQuestion.index, equationBody);
      }
    }
  };

  const questionActiveTabHandler = (response: QuestionState): void => {
    const newState = cloneDeep(response);
    newState.questions.forEach((question) => {
      question.active_tab = 'question';
    });
    setState({ ...newState, deleteQuestions: [] });
    enqueueSnackbar('Successfully Added', { variant: 'success' });
    if (!quizId) {
      navigate(`/quiz-builder/edit/${newState._id}`);
    }
  };

  const [createQuiz, { isLoading }] = useCreateQuizMutation();

  const saveQuizHanlder = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault();
    if (basicInformationValidationHandler()) {
      enqueueSnackbar('Validation Error', { variant: 'error' });
      return;
    }
    const questions = cloneDeep(state.questions);
    questions.forEach((question, i) => {
      const typeName = questionType.find((item) => item._id === question.type_id)?.value;
      if (typeName && typeName === 'Equation Type' && question.active_tab === 'question') {
        // in case of hint we already added the question body
        const equationList = EditorBoxRef.current[i].getEquationList();
        if (equationList.length === 0) {
          question.body = '';
        } else {
          question.body = JSON.stringify(equationList);
        }
      }
    });
    if (questionBodyValidation(questions)) {
      const updatedQuestion = questions.filter((question) => question.is_updated);
      try {
        const user = window.localStorage.getItem('user');
        if (user) {
          const { _id: userId } = JSON.parse(user);
          const body = {
            ...state, create_by: userId, questions: updatedQuestion,
          };
          const response = await createQuiz(body).unwrap();
          if (response.success) {
            questionActiveTabHandler(response.data);
          }
        }
      } catch (err) {
        enqueueSnackbar('Something went wrong', { variant: 'error' });
      }
    } else {
      enqueueSnackbar('Validation Error', { variant: 'error' });
    }
  };
  // Will replace this null with loader
  if (loading) return null;
  return (
    <form onSubmit={saveQuizHanlder}>
      <NavbarWrapper name={quizId ? 'Edit Quiz' : 'Create a new quiz'} showSaveQuizBtn btnLoading={isLoading}>
        <div className='create-new-quiz'>
          <div className='left-side'>
            <BasicCardContainer>
              <div className='label-style-bold'>Basic information</div>
              <InputField
                name='name'
                type='text'
                variant='outlined'
                label='Quiz Name'
                value={state.name}
                containerClassName='mt-24'
                error={fieldErrors.name}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
              />
              {!quizId
              && (
                <div className='select-field-box mt-24'>
                  <div className='label-style-normal'>Class</div>
                  <TextField
                    fullWidth
                    select
                    variant='outlined'
                    id='class'
                    type='class'
                    name='class'
                    value={classYear}
                    onChange={classYearHandler}
                    error={fieldErrors.class !== undefined}
                    helperText={fieldErrors.class}
                  >
                    {classYearList?.data.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {`Year ${option.year}`}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              ) }
            </BasicCardContainer>
            {state.questions.map((question: Question, questionIndex) => (
              <>
                { questionIndex < state.questions.length
                  ? (
                    <div
                      className='add-question-btn-container'
                    >
                      <div className='add-inner-btn-container'>
                        <ButtonComponent
                          type='outlined'
                          text='Add Existing Question'
                          className='w-100 pointer-class btn-height btn-margin'
                          onClick={(): void => { setAddExistingQuestionModal(!addExistingQuestionModal); setExistingQuestionOrder(questionIndex); }}
                        />
                        <ButtonComponent
                          type='contained'
                          text='Add New'
                          className='w-100 pointer-class btn-height'
                          onClick={(): void => { addQuestionHandler(questionIndex); }}
                        />
                      </div>
                      <h3 className='add-question-plus-icon'><PlusIcon /></h3>
                    </div>
                  )
                  : null }
                <QuestionContainer
                  key={question._id ? question._id : question.id}
                  index={questionIndex}
                  data={question}
                  addChoiceHandler={addChoiceHandler}
                  removeChoiceHandler={removeChoiceHandler}
                  onDelete={questionDeleteHandler}
                  imageHandler={imageHandler}
                  deleteQuestionImage={deleteQuestionImage}
                  questionNameHandler={questionInputHandler}
                  questionTypeHandler={questionTypeHandler}
                  questionTagHandler={questionTagHandler}
                  onBlurHandler={onBlurHandler}
                  moveUpHandler={moveUpHandler}
                  moveDownHandler={moveDownHandler}
                  duplicateHandler={duplicateHandler}
                  totalQuestion={state.questions.length - 1}
                  questionStatementError={returnQuestNameError(question)}
                  choiceError={returnQuestionBodyError(question, 'choice')}
                  equationBodyError={returnQuestionBodyError(question, 'equation')}
                  selectAnswer={selectAnswerHandler}
                  choiceStateHandler={choiceStateHandler}
                  choiceTypeHandler={choiceTypeHandler}
                  gettingRef={refHandler}
                  QuestionTypeArr={questionType}
                  deleteHintImage={deleteHintImage}
                  ref={(el): void => { editorRefHandler(el, question.type_id, questionIndex); }}
                  answerFieldHandler={answerFieldHandler}
                  equationIsUpdateHandler={equationIsUpdateHandler}
                  questionTabHandler={questionTabHandler}
                  editSolvableHandler={editSolvableHandler}
                />
              </>
            ))}

            <div className='add-existing-container'>
              <ButtonComponent
                type='outlined'
                text='Add Existing Question'
                className='w-100 pointer-class'
                onClick={(): void => { setAddExistingQuestionModal(!addExistingQuestionModal); setExistingQuestionOrder(state.questions.length); }}
              />
              <ButtonComponent
                type='contained'
                text='Add New'
                className='w-100 pointer-class'
                onClick={(): void => { addQuestionHandler(state.questions.length); }}
              />
            </div>

            {addExistingQuestionModal
              ? (
                <Modal
                  isOpen={addExistingQuestionModal}
                  onCloseHandler={(): void => { setAddExistingQuestionModal(!addExistingQuestionModal); setQuizCode(''); }}
                  title='Enter code to add an existing question.'
                >
                  <div>
                    <InputField
                      name='name'
                      type='text'
                      variant='outlined'
                      label='Quiz Code'
                      value={quizCode || ''}
                      containerClassName='mt-24'
                      onChange={quizCodeChangeHandler}
                    />
                    <div className='modalBtnContainer'>
                      <ButtonComponent
                        type='contained'
                        text={!addQuestionLoader ? 'Add Question' : ''}
                        className='w-100 modalAddQuestionBtn'
                        loading={addQuestionLoader}
                        onClick={(): void => { addExistingQuestionHandler(existingQuestionOrder); }}
                      />
                    </div>
                  </div>
                </Modal>
              ) : null}
          </div>
          <div className='right-side'>
            <Toolbar
              state={activeEquationQuestion?.value}
              EquationTypeQuestions={equationTypeQuestions}
              addEditorComponentHandler={addEditorComponentHandler}
              commandFromToolbarHandler={commandFromToolbarHandler}
              equationToolsHandler={equationToolsHandler}
              setActiveEquationQuestion={setActiveEquationQuestion}
              unitHandler={(selectedUnit: string): void => {
                setUnit(selectedUnit);
              }}
            />
          </div>
        </div>
      </NavbarWrapper>
    </form>
  );
}
