import React from 'react';

export default function DenominatorIcon(): JSX.Element {
  return (
    <svg width='21' height='34' viewBox='0 0 21 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line x1='1.25' y1='16.5' x2='19.25' y2='16.5' stroke='#595E72' strokeWidth='2' strokeLinecap='round' />
      <path d='M5.62953 22.3182H7.71262L10.2034 26.8224H10.3028L12.7936 22.3182H14.8767L11.1729 28.7017V32.5H9.33336V28.7017L5.62953 22.3182Z' fill='#595E72' />
      <path d='M6.27656 9.60938C5.97495 9.60938 5.71643 9.50331 5.50099 9.29119C5.28556 9.07907 5.1795 8.82055 5.18281 8.51562C5.1795 8.21733 5.28556 7.96212 5.50099 7.75C5.71643 7.53788 5.97495 7.43182 6.27656 7.43182C6.56823 7.43182 6.82178 7.53788 7.03721 7.75C7.25596 7.96212 7.367 8.21733 7.37031 8.51562C7.367 8.7178 7.31397 8.90175 7.21122 9.06747C7.11179 9.23319 6.97921 9.36577 6.81349 9.4652C6.65109 9.56132 6.47211 9.60938 6.27656 9.60938ZM10.2531 9.60938C9.9515 9.60938 9.69297 9.50331 9.47754 9.29119C9.2621 9.07907 9.15604 8.82055 9.15936 8.51562C9.15604 8.21733 9.2621 7.96212 9.47754 7.75C9.69297 7.53788 9.9515 7.43182 10.2531 7.43182C10.5448 7.43182 10.7983 7.53788 11.0138 7.75C11.2325 7.96212 11.3435 8.21733 11.3469 8.51562C11.3435 8.7178 11.2905 8.90175 11.1878 9.06747C11.0883 9.23319 10.9558 9.36577 10.79 9.4652C10.6276 9.56132 10.4487 9.60938 10.2531 9.60938ZM14.2297 9.60938C13.928 9.60938 13.6695 9.50331 13.4541 9.29119C13.2387 9.07907 13.1326 8.82055 13.1359 8.51562C13.1326 8.21733 13.2387 7.96212 13.4541 7.75C13.6695 7.53788 13.928 7.43182 14.2297 7.43182C14.5213 7.43182 14.7749 7.53788 14.9903 7.75C15.2091 7.96212 15.3201 8.21733 15.3234 8.51562C15.3201 8.7178 15.2671 8.90175 15.1643 9.06747C15.0649 9.23319 14.9323 9.36577 14.7666 9.4652C14.6042 9.56132 14.4252 9.60938 14.2297 9.60938Z' fill='#868C9F' />
    </svg>
  );
}
