/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import NavbarWrapper from 'components/layout/NavbarWrapper';
import { Table } from 'components/shared/molecules/Table';
import Loader from 'components/shared/molecules/Loader';
import {
  useGetClassQuizListQuery,
  useReorderQuizListMutation,
} from 'store/ApiCall/quizzesApiCall';
import {
  preprocessQuizTableData,
  columnsData,
} from 'utils/tableDataPreprocessing';
import { addDraggableColumn } from 'components/shared/molecules/Table/utils';
import type { DropResult } from 'react-beautiful-dnd';
import ActionsComponent from 'components/shared/atoms/ActionColumn';
import 'styles/components/pages/quizList.scss';
import { useReportsByClassYearQuery } from 'store/ApiCall/reportApiCalls';

export default function ClassQuizList(): JSX.Element {
  const { classYearId, classYearName } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { data, error, isLoading } = useGetClassQuizListQuery(`${classYearId}`);
  const [draggable] = React.useState(true);
  const [reRun, setReRun] = React.useState(false);
  const [tableData, setTableData] = React.useState<any>();

  const { data: performanceData } = useReportsByClassYearQuery({ classYearId: `${classYearId}`, startDate: undefined, endDate: undefined });

  React.useEffect(() => {
    if (data && performanceData && draggable) {
      const updatedData = data.data.allQuiz.map((quiz) => {
        const data = performanceData.data?.filter((pd) => quiz._id === pd.quiz_id);
        return {
          ...quiz,
          performance: { ...data[0] },
        };
      });
      setTableData(addDraggableColumn(preprocessQuizTableData(updatedData, ActionsComponent)));
    }

    if (data) {
      preprocessQuizTableData(data.data.allQuiz, ActionsComponent);
    }
  }, [data, performanceData]);

  React.useEffect(() => {
    if (!reRun) return;
    if (data && draggable) {
      setTableData(addDraggableColumn(preprocessQuizTableData(data.data.allQuiz, ActionsComponent)));
    }

    if (data) {
      preprocessQuizTableData(data.data.allQuiz, ActionsComponent);
    }
    setReRun(false);
  }, [reRun]);

  const [reorderQuizList] = useReorderQuizListMutation();

  async function onDragEndHandler(result: DropResult, currentQuiz: string): Promise<void> {
    try {
      if (!result.destination) {
        return;
      }

      if (result.source.index === result.destination.index) {
        return;
      }
      const mewTableData = Array.from(tableData);
      const [removed] = mewTableData.splice(result.source.index, 1);
      mewTableData.splice(result.destination.index, 0, removed);
      setTableData(mewTableData);

      const response = await reorderQuizList({ quiz_id: currentQuiz, order_number: (result.destination.index + 1).toString() }).unwrap();

      if (response.success) {
        enqueueSnackbar(response.message, { variant: 'success' });
      }

      if (!response.success) {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    } catch (e: any) {
      setReRun(true);
      enqueueSnackbar(e.data.message, { variant: 'error' });
    }
  }

  if (error) {
    enqueueSnackbar('Something went wrong!', { variant: 'error' });
  }

  return (
    <NavbarWrapper name={((): JSX.Element => (
      <span className='navbarTitle'>
        <div
          className='linkElement'
          role='presentation'
          onClick={(): void => { navigate(-1); }}
        >
          Quizzes
        </div>
        <span className='seperator'>{'>'}</span>
        <span className='currentElement'>
          {classYearName}
        </span>
      </span>
    ))()}
    >

      {!isLoading
        ? tableData && !_.isEmpty(tableData)
          ? (
            <div className='tableContainer'>
              <div className='tableInner'>
                <Table data={tableData} columns={columnsData} draggable onDragEndHandler={onDragEndHandler} />
              </div>
            </div>
          )
          : (
            <div className='noDataAvailable'>
              <h2>No data available!</h2>
            </div>
          ) : (<Loader />)}
    </NavbarWrapper>
  );
}
