/* eslint-disable no-nested-ternary */
import React from 'react';

import type { Choice } from 'utils/types';
import clsx from 'clsx';
import { addStyles, StaticMathField } from 'react-mathquill';

addStyles();

interface McqChoiceContainerProps {
  data: Choice;
  answer: string;
  className?: string;
  onClickHandler?: (choiceId: string) => void;
}

export default function McqChoiceContainer(props: McqChoiceContainerProps): JSX.Element {
  const {
    data, answer, className, onClickHandler,
  } = props;

  const answerSelectionHandler = (): void => {
    if (onClickHandler) onClickHandler(data.key);
  };

  return (
    <div className={clsx('mcq-single-choice-container', className)}>
      <div
        role='presentation'
        onClick={answerSelectionHandler}
        className={clsx('choice-id', answer === data.key && 'active')}
      >
        {data.key}

      </div>
      {data.type === 'image'
        ? (
          <div
            className='image-container'
            style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.image_url})` }}
          />
        )
        : data.type === 'text'
          ? (<div className={clsx('text-container', className)}>{data.state}</div>)
          : (
            <StaticMathField
              className={clsx('equation-container', className)}
            >
              {data.state}
            </StaticMathField>
          )}
    </div>
  );
}
