/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'store/ApiCall/baseQuery';
import type {
  AdminQuizReportsResponse, InProgressQuizReport, ReportSummaryResponse, StudentReportSummary, StudentSummaryResponse, AdminAggregatedReport,
} from 'utils/types';

export const reportApi = createApi({
  reducerPath: 'reports',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    inProgressQuizReportTeacher: builder.query<InProgressQuizReport, string | void>({
      query: (classId) => ({
        url: `/api/report/in-progress/${classId}`,
        method: 'GET',
      }),
    }),
    reportsByClassYear: builder.query<AdminQuizReportsResponse, { classYearId?: string | undefined; startDate?: string | undefined; endDate?: string | undefined }>({
      query: (filters) => ({
        url: '/api/report/all',
        params: { ...filters },
        method: 'GET',
      }),
    }),
    reportsByYear: builder.mutation<AdminQuizReportsResponse, { classYearId?: string | undefined; startDate?: string | undefined; endDate?: string | undefined }>({
      query: (filters) => ({
        url: '/api/report/all',
        params: { ...filters },
        method: 'GET',
      }),
    }),
    reportsByClass: builder.query<AdminQuizReportsResponse, { classId: string | undefined; startDate?: string | undefined; endDate?: string | undefined }>({
      query: (filters) => ({
        url: '/api/report/all',
        params: { ...filters },
      }),
    }),
    reportsFilterByClass: builder.mutation<AdminQuizReportsResponse, { classId: string | undefined; startDate?: string | undefined; endDate?: string | undefined }>({
      query: (filters) => ({
        url: '/api/report/all',
        params: { ...filters },
      }),
    }),
    studentQuizSummary: builder.mutation<StudentReportSummary, string | void>({
      query: (quizId) => ({
        url: `/api/report/attempt/${quizId}`,
        method: 'GET',
      }),
    }),
    studentQuizReport: builder.query<StudentReportSummary, { quizId?: string;studentId?: string }>({
      query: ({ quizId, studentId }) => ({
        url: `/api/report/attempt/${quizId}`,
        method: 'GET',
        params: { userId: studentId },
      }),
    }),
    adminReportSummary: builder.query<ReportSummaryResponse, { quizId: string;classId?: string | null | undefined }>({
      query: ({ quizId, classId }) => {
        let url = `/api/report/${quizId}`;
        url = classId ? `${url}?classId=${classId}` : url;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    studentReportSummary: builder.query<StudentSummaryResponse, { studentId: string }>({
      query: ({ studentId }) => {
        const url = `/api/report/student/${studentId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
    adminAggregatedReport: builder.query<AdminAggregatedReport, { quizId: string; classId?: string | undefined }>({
      query: ({ quizId, classId }) => {
        let url = `/api/report/aggregated/${quizId}`;
        if (classId) {
          url += `?classId=${classId}`;
        }
        return {
          url,
          method: 'GET',
        };
      },
    }),
    teacherAggregatedReport: builder.query<AdminAggregatedReport, { quizId: string; classId: string }>({
      query: ({ quizId, classId }) => {
        const url = `/api/report/aggregated/${quizId}?classId=${classId}`;
        return {
          url,
          method: 'GET',
        };
      },
    }),
  }),
});

export const {
  useInProgressQuizReportTeacherQuery,
  useReportsByClassYearQuery,
  useReportsByYearMutation,
  useReportsFilterByClassMutation,
  useReportsByClassQuery,
  useAdminReportSummaryQuery,
  useStudentQuizSummaryMutation,
  useStudentQuizReportQuery,
  useStudentReportSummaryQuery,
  useAdminAggregatedReportQuery,
  useTeacherAggregatedReportQuery,
} = reportApi;
