import React from 'react';

import SideBar from 'components/layout/Sidebar';
import TeacherSideBar from 'components/layout/TeacherSidebar';
import { Outlet } from 'react-router-dom';

interface ControlledRoutesProps {
  userRole: string;
}

export default function ControlledRoutes({ userRole }: ControlledRoutesProps): JSX.Element {
  if (userRole === 'admin') {
    return (
      <div className='main-container'>
        <SideBar />
        <div className='right-side'>
          <Outlet />
        </div>
      </div>
    );
  }

  if (userRole === 'teacher') {
    return (
      <div className='main-container'>
        <TeacherSideBar />
        <div className='right-side'>
          <Outlet />
        </div>
      </div>

    );
  }
  return <Outlet />;
}
