/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'store/ApiCall/baseQuery';
import type {
  TeacherClassesResponseType,
  ClassDataResponseType,
  AddClassDataType,
  StudentListDataResponse,
  TeacherClassInfoResponse,
  QuizStartByTeacherPayload,
  QuizStartByTeacherResponse,
  NextQuizInformationResponse,
  SchoolTeacherListResponse,
  SchoolTeachersListDataType,
  ClassTeacherDeleteDataType,
  ClassTeacherDeleteResponse,
  ClassTeacherAddDataType,
  ClassTeacherAddResponse,
  ClassTeachersListDataType,
} from 'utils/types';

export const classesApi = createApi({
  reducerPath: 'classesApi',
  baseQuery: baseQueryWithAuth,
  tagTypes: ['getNextQuizInformation', 'geTeacherClassById', 'classList', 'classTeacherList'],
  endpoints: (builder) => ({
    getAllTeacherClasses: builder.query<TeacherClassesResponseType, void>({
      query: () => ({
        url: 'api/teacher/classes',
        method: 'GET',
      }),
      providesTags: ['classList'],
    }),
    addClass: builder.mutation<ClassDataResponseType, AddClassDataType>({
      query: (body) => ({
        url: '/api/teacher/class',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['classList'],
    }),
    getAllClass: builder.query<ClassDataResponseType, void>({
      query: () => ({
        url: '/api/class/years',
        method: 'GET',
      }),
    }),
    getAllStudents: builder.query<StudentListDataResponse, string>({
      query: (classId) => ({
        url: `/api/class/${classId}/students`,
        method: 'GET',
      }),
    }),
    getNextQuizInformation: builder.query<NextQuizInformationResponse, string | void>({
      query: (classId) => ({
        url: `/api/teacher/class/${classId}/next-quizzes`,
        method: 'GET',
      }),
      providesTags: ['getNextQuizInformation'],
    }),
    geTeacherClassById: builder.query<TeacherClassInfoResponse, string | null | undefined | void>({
      query: (classId) => ({
        url: `/api/teacher/class/${classId}/info`,
        method: 'GET',
      }),
      providesTags: ['geTeacherClassById'],
    }),
    quizStartByTeacher: builder.mutation<QuizStartByTeacherResponse, QuizStartByTeacherPayload>({
      query: (body) => ({
        url: '/api/teacher/quiz/start',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getNextQuizInformation', 'geTeacherClassById'],
    }),
    schoolTeachersList: builder.query<SchoolTeacherListResponse, SchoolTeachersListDataType>({
      query: (body) => ({
        url: '/api/class/school/teachers',
        method: 'POST',
        body,
      }),
    }),
    classTeacherList: builder.query<SchoolTeacherListResponse, ClassTeachersListDataType>({
      query: (body) => ({
        url: '/api/class/teacher/list',
        method: 'POST',
        body,
      }),
      providesTags: ['classTeacherList'],
    }),
    classTeacherAdd: builder.mutation<ClassTeacherAddResponse, ClassTeacherAddDataType>({
      query: (body) => ({
        url: '/api/class/teacher/add',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['classTeacherList']
    }),
    classTeacherDelete: builder.mutation<ClassTeacherDeleteResponse, ClassTeacherDeleteDataType>({
      query: (body) => ({
        url: '/api/class/teacher/delete',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['classTeacherList'],
    }),
  }),
});

export const {
  useGetAllTeacherClassesQuery,
  useAddClassMutation,
  useGetAllClassQuery,
  useGetAllStudentsQuery,
  useGetNextQuizInformationQuery,
  useGeTeacherClassByIdQuery,
  useQuizStartByTeacherMutation,
  useSchoolTeachersListQuery,
  useClassTeacherListQuery,
  useClassTeacherAddMutation,
  useClassTeacherDeleteMutation,
} = classesApi;
