import React from 'react';

import HintIcon from 'assets/svgs/ts/HintIcon';
import QuestionIcon from 'assets/svgs/ts/QuestionIcon';
import clsx from 'clsx';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import PresentIcon from 'assets/svgs/ts/PresentIcon';
import { useNavigate } from 'react-router-dom';

interface QuizDetailHeaderProps {
  activeTab: string;
  quizId?: string;
  setActiveTab: (tabName: string) => void;
}

export default function QuizDetailHeader(props: QuizDetailHeaderProps): JSX.Element {
  const { activeTab, setActiveTab, quizId } = props;
  const navigate = useNavigate();

  const quizPresentHanlder = (): void => {
    if (quizId) navigate(`/quiz/${quizId}/present`);
  };

  const isQuestionTab = activeTab === 'question';
  return (
    <div className='quiz-detail-page-questions-header'>
      <div className='quiz-detail-page-questions-header-left-side'>
        <ButtonComponent
          startIcon={<QuestionIcon className={!isQuestionTab ? 'fill-mono-dull-light' : 'fill-white'} />}
          size='small'
          className={clsx('h-40 font-inter mr-20', !isQuestionTab && 'disabled')}
          type={isQuestionTab ? 'contained' : 'outlined'}
          text='Questions'
          onClick={(): void => { setActiveTab('question'); }}
        />
        <ButtonComponent
          startIcon={<HintIcon className={isQuestionTab ? 'fill-mono-dull-light' : 'fill-white'} />}
          size='small'
          className={clsx('h-40 font-inter', isQuestionTab && 'disabled')}
          type={!isQuestionTab ? 'contained' : 'outlined'}
          text='Hints'
          onClick={(): void => { setActiveTab('hint'); }}
        />
      </div>
      <div className='quiz-detail-page-questions-header-right-side'>
        {/* TODO: will use these buttons in future
         <ButtonComponent
          className='toolbar-btns light-text'
          type='outlined'
          text='Hide Answers'
          size='small'
          startIcon={<EyeOff className='fill-mono-dull-light' />}
        />
        <ButtonComponent
          className='toolbar-btns light-text'
          type='outlined'
          text='Print'
          size='small'
          startIcon={<PrintIcon className='fill-mono-dull-light' />}
        /> */}
        <ButtonComponent
          className='toolbar-btns light-text'
          type='outlined'
          text='Present'
          size='small'
          startIcon={<PresentIcon />}
          onClick={quizPresentHanlder}
        />
      </div>
    </div>
  );
}
