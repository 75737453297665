import React from 'react';
import type { SVGProps } from 'utils/types';

export default function FalseTickIcon(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M11.0007 21.6667C13.8296 21.6667 16.5427 20.5429 18.5431 18.5425C20.5435 16.5421 21.6673 13.829 21.6673 11C21.6673 8.17106 20.5435 5.45796 18.5431 3.45757C16.5427 1.45718 13.8296 0.333374 11.0007 0.333374C8.17167 0.333374 5.45857 1.45718 3.45818 3.45757C1.45779 5.45796 0.333984 8.17106 0.333984 11C0.333984 13.829 1.45779 16.5421 3.45818 18.5425C5.45857 20.5429 8.17167 21.6667 11.0007 21.6667ZM9.27665 7.39071C9.02518 7.14783 8.68838 7.01344 8.33878 7.01647C7.98919 7.01951 7.65477 7.15974 7.40756 7.40695C7.16035 7.65416 7.02012 7.98858 7.01709 8.33817C7.01405 8.68777 7.14844 9.02457 7.39132 9.27604L9.11532 11L7.39132 12.724C7.26397 12.847 7.16239 12.9942 7.09252 13.1568C7.02264 13.3195 6.98586 13.4945 6.98432 13.6715C6.98278 13.8485 7.01651 14.0241 7.08355 14.188C7.1506 14.3518 7.2496 14.5007 7.37479 14.6259C7.49998 14.7511 7.64885 14.8501 7.81271 14.9171C7.97657 14.9842 8.15215 15.0179 8.32919 15.0164C8.50622 15.0148 8.68118 14.9781 8.84386 14.9082C9.00653 14.8383 9.15365 14.7367 9.27665 14.6094L11.0007 12.8854L12.7247 14.6094C12.9761 14.8523 13.3129 14.9866 13.6625 14.9836C14.0121 14.9806 14.3465 14.8403 14.5937 14.5931C14.841 14.3459 14.9812 14.0115 14.9842 13.6619C14.9873 13.3123 14.8529 12.9755 14.61 12.724L12.886 11L14.61 9.27604C14.8529 9.02457 14.9873 8.68777 14.9842 8.33817C14.9812 7.98858 14.841 7.65416 14.5937 7.40695C14.3465 7.15974 14.0121 7.01951 13.6625 7.01647C13.3129 7.01344 12.9761 7.14783 12.7247 7.39071L11.0007 9.11471L9.27665 7.39071Z' fill='#FF8282' />
    </svg>
  );
}
