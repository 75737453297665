import React from 'react';
import { IconButton } from '@mui/material';

interface PaginationPropsType {
  offset: number;
  limit: number;
  end: number;
  totalRecords: number;
  handler: (direction: number) => void;
}

export default function Pagination({
  offset,
  limit,
  end,
  totalRecords,
  handler,
}: PaginationPropsType): JSX.Element {
  return (
    <div className='pagination'>
      <div className='pagination-button-container'>
        <IconButton className='pagination-prev-icon' onClick={(): void => { handler(-1); }} disabled={offset < 1}>
          {'<'}
        </IconButton>
        <IconButton className='pagination-next-icon' onClick={(): void => { handler(1); }} disabled={offset * limit + limit >= totalRecords}>
          {'>'}
        </IconButton>
      </div>
      <p className='pagination-values'>
        {end > 0 ? offset * limit + 1 : 0}
        -
        {offset * limit + end}
        {' '}
        of
        {' '}
        {totalRecords}
      </p>

    </div>
  );
}
