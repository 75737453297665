import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import type { SelectChangeEvent } from '@mui/material/Select';
import Select from '@mui/material/Select';
import clsx from 'clsx';
import { ListItemIcon, ListItemText } from '@mui/material';
import type { Options } from 'utils/types';

interface SelectFieldProps {
  options: Options[];
  label?: string;
  containerClassName?: string;
  size?: string;
  onChange?: (val: string) => void;
  returnObject?: (val: Options) => void;
  state?: string;
}

export default function SelectField(props: SelectFieldProps): JSX.Element {
  const {
    options, label, containerClassName, size, onChange, state = '', returnObject,
  } = props;

  const handleChange = (event: SelectChangeEvent): void => {
    const { target: { value } } = event;
    if (onChange) onChange(value);
    else if (returnObject) {
      const index = Number(value.split('#')[1]) - 1;
      const ans = options.find((item) => item.index === index);
      if (ans) {
        returnObject(ans);
      }
    }
  };

  return (
    <div className={clsx(containerClassName)}>
      {label !== undefined && <div className='label-style-normal'>{label}</div> }
      <FormControl className='select-field-form-control'>
        <Select
          className={clsx('select-field', size)}
          displayEmpty
          value={state !== '' ? state : options[0]?.value}
          onChange={handleChange}
          input={<OutlinedInput />}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {options.map((opt) => (
            <MenuItem
              key={opt.value}
              value={opt.value}
            >
              {opt.Icon !== undefined && (
                <ListItemIcon>
                  {opt.Icon}
                </ListItemIcon>
              )}
              <ListItemText>{opt.value}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
