/* eslint-disable @typescript-eslint/no-invalid-void-type */
import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from 'store/ApiCall/baseQuery';
import type { SchoolListApiType, SchoolClassListApiType } from 'utils/types';

export const schoolApi = createApi({
  reducerPath: 'schoolApi',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getSchoolList: builder.query<SchoolListApiType, void>({
      query: () => ({
        url: '/api/school/all',
        method: 'GET',
      }),
    }),
    getSchoolClassList: builder.query<SchoolClassListApiType, string>({
      query: (school_id) => ({
        url: `/api/class/by-school/${school_id}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetSchoolListQuery,
  useGetSchoolClassListQuery,
} = schoolApi;
