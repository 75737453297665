/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import React, {
  useRef, useEffect, useImperativeHandle, forwardRef,
} from 'react';
import { addStyles } from 'react-mathquill';
import type { MathField } from './types';

declare global {
  interface Window {
    MathQuill: any;
  }
}

interface StaticMathFieldProps {
  latex: string;
  answers: any;
  readOnly?: boolean;
  experimental?: boolean;
  isAdminStaticField?: boolean;
  isStudentStaticField?: boolean;
  className?: string;
  hideAnswer?: boolean;
}

interface StaticMathFieldAnswer {
  id: string;
  value: string;
  label: string;
  color: string;
  numerator?: string;
  numeratorLabel?: string;
  numeratorColor?: string;
  denominator?: string;
  denominatorLabel?: string;
  denominatorColor?: string;
  is_solvable_fraction?: boolean;
}

addStyles();

const MQStaticField = forwardRef((props: StaticMathFieldProps, ref) => {
  const {
    latex, answers, readOnly, experimental, isAdminStaticField = false,
    className, isStudentStaticField = false, hideAnswer = false,
  } = props;
  // MathQuill fire 2 edit events on startup.
  const ignoreEditEvents = useRef<number[]>([]);
  const MQ = useRef<any>(null);
  const wrapperElement = useRef<any>(null);
  const copyElement = useRef<any>(null);
  const mathField = useRef<MathField>();
  const inputAnswers = useRef<StaticMathFieldAnswer[]>([]);

  useImperativeHandle(ref, () => ({
    getInputAnswers(): any {
      const answerList = inputAnswers.current.map((ans: StaticMathFieldAnswer) => {
        if (ans.is_solvable_fraction) {
          return {
            id: ans.id, numerator: ans.numerator?.trim(), denominator: ans.denominator?.trim(), is_solvable_fraction: true,
          };
        } return { id: ans.id, value: ans.value.trim() };
      });
      return answerList;
    },
  }));

  useEffect(() => {
    if (!window.MathQuill) return;
    MQ.current = window.MathQuill.getInterface(2);
    if (readOnly) {
      MQ.current?.registerEmbed('answer', (data: any) => {
        const dataSplit = data.split('-');
        const answerId = dataSplit[0];
        const label = dataSplit[1] ?? '';
        const color = dataSplit[2] ?? '';
        return {
          htmlString: `<span class="answer-container" answer-id="${answerId}">
                       <span ${color !== '' ? `style="color:#${color}` : ''}"
                       class="mq-label ${label === '' && 'd-none'}">${label}</span>
                       <span class="mq-answer"></span>
                       </span>`,
          text: (): string => '',
          latex: (): string => `\\embed{answer}[${data}]`,
        };
      });
      MQ.current?.registerEmbed('solvableFraction', (data: any) => {
        const splitData = data.split('-');
        return {
          htmlString: `<span class="mq-fraction solvable-fraction-container" answer-id="${splitData[0]}">
                       <span class="mq-numerator">
                       <span class="answer-container" answer-id="${splitData[0]}">
                       <span style="color:#${splitData[2]}"
                        class="mq-label ${splitData[1] === '' && 'd-none'}">${splitData[1]}</span>
                       <span class="mq-answer"></span>
                       </span>     
                       </span>
                       <span class="mq-denominator">
                       <span class="answer-container" answer-id="${splitData[0]}">
                       <span style="color:#${splitData[4]}"
                        class="mq-label ${splitData[3] === '' && 'd-none'}">${splitData[3]}</span>
                       <span class="mq-answer"></span>
                       </span>
                       </span>
                       </span>`,
          text: (): string => '',
          latex: (): string => `\\embed{solvableFraction}[${data}]`,
        };
      });
    } else {
      MQ.current?.registerEmbed('answer', (data: any) => {
        const dataSplit = data.split('-');
        const answerId = dataSplit[0];
        return {
          htmlString: `<span class="student-answer-container" answer-id="${answerId}" onclick="this.querySelector('input').focus()">
                       <input class="mq-answer" />
                       <span class="mq-label"></span>
                       </span>`,
          text: (): string => '',
          latex: (): string => `\\embed{answer}[${data}]`,
        };
      });
      MQ.current?.registerEmbed('solvableFraction', (data: any) => {
        const splitData = data.split('-');
        return {
          htmlString: `<span class="mq-fraction solvable-fraction-container student-attempt" answer-id="${splitData[0]}">
                       <span class="mq-numerator">
                       <span class="student-answer-container" answer-id="${splitData[0]}" onclick="this.querySelector('input').focus()">
                       <span style="color:#${splitData[2]}"
                        class="mq-label ${splitData[1] === '' && 'd-none'}">${splitData[1]}</span>
                       <span class="mq-answer"></span>
                       </span>     
                       </span>
                       <span class="mq-denominator">
                       <span class="student-answer-container" answer-id="${splitData[0]}" onclick="this.querySelector('input').focus()">
                       <span style="color:#${splitData[4]}"
                        class="mq-label ${splitData[3] === '' && 'd-none'}">${splitData[3]}</span>
                       <span class="mq-answer"></span>
                       </span>
                       </span>
                       </span>`,
          text: (): string => '',
          latex: (): string => `\\embed{solvableFraction}[${data}]`,
        };
      });
    }
  }, [readOnly]);

  const convertSolvableToInput = (el: any, index: number, type: string): void => {
    const inputEl = document.createElement('input');
    inputEl.setAttribute('type', 'text');
    inputEl.setAttribute('class', 'mq-answer');
    inputEl.addEventListener('input', (e: any) => {
      const answerClone = cloneDeep(inputAnswers.current);
      if (type === 'numerator') {
        answerClone.splice(index, 1, { ...answerClone[index], numerator: e.target.value });
      } else {
        answerClone.splice(index, 1, { ...answerClone[index], denominator: e.target.value });
      }
      inputAnswers.current = answerClone;
    });
    el.innerHTML = '';
    el.className = 'student-answer-container';
    el.appendChild(inputEl);
    if (inputAnswers.current[index].numeratorLabel || inputAnswers.current[index].denominatorLabel) {
      const label = document.createElement('label');
      label.className = 'mq-label';
      label.innerHTML = type === 'numerator' ? inputAnswers.current[index].numeratorLabel ?? '' : inputAnswers.current[index].denominatorLabel ?? '';
      label.style.color = type === 'numerator' ? `#${inputAnswers.current[index].numeratorColor}` : `#${inputAnswers.current[index].denominatorColor}`;
      inputEl.placeholder = ' ';
      el.appendChild(label);
    }
    if (isStudentStaticField) {
      inputEl.setAttribute('readOnly', 'true');
      answers?.forEach((ans: StaticMathFieldAnswer): void => {
        if (ans.id === el.getAttribute('answer-id')) {
          const value = type === 'numerator' ? ans.numerator : ans.denominator;
          inputEl.setAttribute('value', value ?? '');
        }
      });
    }
  };

  useEffect(() => {
    if (!MQ.current || !wrapperElement.current) return;

    mathField.current = MQ.current.StaticMath(wrapperElement.current);
    if (!readOnly) {
      if (!experimental) {
        copyElement.current.innerHTML = wrapperElement.current.querySelector('span.mq-root-block').innerHTML;
        wrapperElement.current.style.display = 'none';
        const solvalbeFractionContainer = copyElement.current.querySelectorAll('span.solvable-fraction-container');
        if (solvalbeFractionContainer.length > 0) {
          [...solvalbeFractionContainer].forEach((el: HTMLElement, index: number): void => {
            const numeratorFieldAnswer = el.querySelector('.mq-numerator > .student-answer-container');
            const denumeratorFieldAnswer = el.querySelector('.mq-denominator > .student-answer-container');
            convertSolvableToInput(numeratorFieldAnswer, index, 'numerator');
            convertSolvableToInput(denumeratorFieldAnswer, index, 'denominator');
          });
        } else {
          copyElement.current.querySelectorAll('span.student-answer-container').forEach((el: HTMLElement, index: number) => {
            const inputEl = document.createElement('input');
            inputEl.setAttribute('type', 'text');
            inputEl.setAttribute('class', 'mq-answer');
            inputEl.addEventListener('input', (e: any) => {
              const answerClone = cloneDeep(inputAnswers.current);
              answerClone.splice(index, 1, { ...answerClone[index], value: e.target.value });
              inputAnswers.current = answerClone;
            });
            el.innerHTML = '';
            el.className = 'student-answer-container';
            el.appendChild(inputEl);
            if (inputAnswers.current[index].label) {
              const label = document.createElement('label');
              label.className = 'mq-label';
              label.innerHTML = inputAnswers.current[index].label;
              label.style.color = `#${inputAnswers.current[index].color}`;
              inputEl.placeholder = ' ';
              el.appendChild(label);
            }
            if (isStudentStaticField) {
              inputEl.setAttribute('readOnly', 'true');
              answers?.forEach((ans: StaticMathFieldAnswer): void => {
                if (ans.id === el.getAttribute('answer-id')) {
                  inputEl.setAttribute('value', ans.value);
                }
              });
            }
          });
        }
      } if (mathField.current?.innerFields.length !== 0) {
        mathField.current?.innerFields.forEach((field: any, index: number): void => {
          ignoreEditEvents.current.push(2);
          field.latex(inputAnswers.current[index].label);
          field.el().style.color = `#${inputAnswers.current[index].color}`;
          field.el().title = inputAnswers.current[index].label;
          field.el().onclick = (): void => {
            if (field.latex() === inputAnswers.current[index].label) {
              field.latex('');
            }
          };
        });
        mathField.current?.config({
          handlers: {
            edit: (mf: MathField) => {
              const index = mathField.current?.innerFields.findIndex((field: any) => field.id === mf.id);
              if (index && ignoreEditEvents.current[index] > 0) {
                ignoreEditEvents.current[index] -= 1;
                return;
              }
              if (index !== undefined && index >= 0) {
                const currLatex = mf.latex();
                if (currLatex.length > 10 || !/^\d+$/.test(currLatex)) {
                  mf.latex(inputAnswers.current[index].value);
                  return;
                }
                const answerClone = cloneDeep(inputAnswers.current);
                answerClone.splice(index, 1, { ...answerClone[index], value: mf.latex() });
                inputAnswers.current = answerClone;
              }
            },
          },
        });
      }
    } else {
      answers?.forEach((ans: StaticMathFieldAnswer): void => {
        if (ans.is_solvable_fraction) {
          const solvalbeFractionContainer = wrapperElement.current.querySelector(`span[answer-id="${ans.id}"]`);
          const numeratorField = solvalbeFractionContainer.querySelector('.mq-numerator > .answer-container > .mq-answer');
          const denumeratorField = solvalbeFractionContainer.querySelector('.mq-denominator > .answer-container > .mq-answer');
          numeratorField.innerHTML = ans.numerator;
          denumeratorField.innerHTML = ans.denominator;
          if (hideAnswer) {
            numeratorField.classList.add('color-transparent');
            denumeratorField.classList.add('color-transparent');
          }
        } else {
          const el = wrapperElement.current.querySelector(`span[answer-id="${ans.id}"] > .mq-answer`);
          if (el && el.innerHTML === '') {
            el.innerHTML = ans.value;
            if (hideAnswer) {
              el.classList.add('color-transparent');
            }
          }
        }
      });
    }
  }, [wrapperElement, latex, answers, readOnly, experimental, isStudentStaticField]);

  function cleanLatex(l: string): string {
    if (readOnly) return l;

    inputAnswers.current = [];
    let newLatex = '';
    if (l.includes('\\embed')) {
      if (l.search('\\embed{answer}') > -1) {
        l.split('\\embed{answer}').forEach((embedSplits) => {
          if (embedSplits.startsWith('[')) {
            const embedConfSplits = embedSplits.slice(1).split(']', 2);
            const conf = embedConfSplits[0].split('-');
            inputAnswers.current.push({
              id: conf[0], label: conf[1], color: conf[2], value: '',
            });
            newLatex += '\\MathQuillMathField{}';
            newLatex += embedConfSplits[1];
          } else {
            newLatex += embedSplits;
          }
        });
      } else {
        l.split('\\embed{solvableFraction}').forEach((embedSplits) => {
          if (embedSplits.startsWith('[')) {
            const embedConfSplits = embedSplits.slice(1).split(']', 2);
            const conf = embedConfSplits[0].split('-');
            inputAnswers.current.push({
              id: conf[0],
              numerator: '',
              numeratorLabel: conf[1],
              numeratorColor: conf[2],
              denominator: '',
              denominatorLabel: conf[3],
              denominatorColor: conf[4],
              is_solvable_fraction: true,
              value: '',
              label: '',
              color: '',

            });
            newLatex += '\\MathQuillMathField{}';
            newLatex += embedConfSplits[1];
          } else {
            newLatex += embedSplits;
          }
        });
      }
    }
    if (!experimental) return l;
    return newLatex;
  }

  // For admin detail page
  useEffect(() => {
    const rootBlocks = Array.from(document.querySelectorAll('.mq-root-block'));
    rootBlocks.forEach((el) => {
      const bracket = Array.from(el.querySelectorAll('.solvable-fraction-container'));
      if (bracket.length > 0) {
        el.className = 'mq-root-block solvable-fraction-block';
      } else {
        el.className = 'mq-root-block';
      }
    });
  }, []);

  // For student side
  useEffect(() => {
    const rootBlocks = Array.from(document.querySelectorAll('.student-static-field'));
    rootBlocks.forEach((el) => {
      const bracket = Array.from(el.querySelectorAll('.solvable-fraction-container'));
      if (bracket.length > 0) {
        el.className = 'mq-math-mode student-static-field solvable-fraction-block-student';
      } else {
        el.className = 'mq-math-mode student-static-field';
      }
    });
  }, []);

  return (
    <div className={clsx('mq-static-field', className)}>
      <span className={clsx(isAdminStaticField && 'admin-static-field')} ref={wrapperElement}>{cleanLatex(latex)}</span>
      <div className={clsx('mq-math-mode', !isAdminStaticField && 'student-static-field')} ref={copyElement} />
    </div>
  );
});

export default MQStaticField;
