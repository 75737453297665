import React from 'react';

import BasicCardContainer from 'components/shared/atoms/BasicCardContainer';
import ButtonComponent from 'components/shared/atoms/button/ButtonComponent';
import { Operators, Units } from 'utils/constant';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import EquationIcon from 'assets/svgs/ts/EquationIcon';
import InformationIcon from 'assets/svgs/ts/InformationIcon';
import DenominatorIcon from 'assets/svgs/ts/DenominatorIcon';
import MarkSolvable from 'assets/svgs/ts/MarkSolvable';
import SelectEquationTool from 'components/shared/atoms/SelectField/SelectEquationTool';
import clsx from 'clsx';
import SelectField from 'components/shared/atoms/SelectField/SelectField';
import type { Options } from 'utils/types';

interface ToolbarProps {
  EquationTypeQuestions: Options[];
  addEditorComponentHandler: (type: number) => void;
  commandFromToolbarHandler: (cmd: string) => void;
  equationToolsHandler: (tool: string) => void;
  setActiveEquationQuestion: (value: Options) => void;
  state: string;
  unitHandler: (unit: string) => void;
}

function Toolbar(props: ToolbarProps): JSX.Element {
  const {
    EquationTypeQuestions, state,
    addEditorComponentHandler, commandFromToolbarHandler,
    equationToolsHandler, setActiveEquationQuestion, unitHandler,
  } = props;

  const EquationTools = [{ text: 'Make solvable fraction', icon: <DenominatorIcon /> },
    { text: 'Make solvable', icon: <div className='mark-solvable-box'><MarkSolvable /></div> },
    {
      text: 'Apply unit',
      icon:
  <SelectEquationTool
    onChange={(unit): void => {
      unitHandler(unit);
    }}
    options={Units}
  />,
    },
    {
      text: 'Make solvable with degree',
      icon:
  <div className='label-style-normal-3'>
    &deg; (Degree)
  </div>,
    },
  ];

  const isEquationQuestionAvailable = EquationTypeQuestions.length > 0;
  return (
    <BasicCardContainer className={clsx('quiz-toolbar-container', !isEquationQuestionAvailable && 'disabled')}>
      <div className='quiz-toolbar-header'>
        <div className='label-style-bold mb-0'>Toolbar</div>
        {
          isEquationQuestionAvailable && (
            <SelectField
              size='small'
              containerClassName='ml-auto'
              options={EquationTypeQuestions}
              returnObject={(value: Options): void => { setActiveEquationQuestion(value); }}
              state={state}
            />
          )
        }
      </div>
      <div className='toolbar-btns-handler mt-24'>
        <ButtonComponent
          className='toolbar-btns'
          type='outlined'
          text='Equation'
          size='small'
          startIcon={<EquationIcon className='fill-mono-dull-light' />}
          onClick={(): void => { addEditorComponentHandler(1); }}
        />
        <ButtonComponent
          className='toolbar-btns'
          type='outlined'
          text='Text Field'
          size='small'
          startIcon={<TextFieldsIcon className='fill-mono-dull-light' />}
          onClick={(): void => { addEditorComponentHandler(2); }}
        />
        <ButtonComponent
          className='toolbar-btns'
          type='outlined'
          text='New Line'
          size='small'
          startIcon={<SubdirectoryArrowRightIcon className='fill-mono-dull-light' />}
          onClick={(): void => { addEditorComponentHandler(3); }}
        />
      </div>
      <div className='label-style-bold mt-24'>Equation tools</div>
      <div className='equation-tool-info'>
        <InformationIcon />
        <p className='label-style-normal'>You can use these tools once an equation is selected.</p>
      </div>
      <div className='equation-tool-container mt-24'>
        {EquationTools.map((comp) => (
          <div key={comp.text} className='equation-tool-box' role='presentation' onClick={(): void => { equationToolsHandler(comp.text); }}>
            {comp.icon}
            <div className='label-style-normal-2 text-center line-height-1 mt-8'>{comp.text}</div>
          </div>
        ))}
      </div>
      <div className='labael-style-normal-3 mt-24'>
        Operators
      </div>
      <div className='operator-container mt-16'>
        {Operators.map((op) => (
          <ButtonComponent
            key={op.text}
            className='operator-container-box small'
            type='outlined'
            text={op.text}
            onClick={(): void => { commandFromToolbarHandler(op.cmd); }}
          />

        ))}
      </div>
      {/* TODO: may be will use in future */}
      {/* <div className='label-style-bold mt-24'>Image tools</div>
<div className='equation-tool-info'>
  <InformationIcon />
  <p className='label-style-normal'>You can use these tools when an image is selected.</p>
</div>
<div className='labael-style-normal-3 mt-24'>Image size </div>
<div className='image-size-container mt-24'>
  {ImageSize.map((size) => (
    <ButtonComponent
      key={size}
      className='w-100 image-container-box'
      type='outlined'
      text={size}
    />
  ))}
</div> */}

    </BasicCardContainer>
  );
}

export default Toolbar;
