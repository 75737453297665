import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import store from 'store/store';
import { signoutToStore } from 'store/reducer/authSlice';

//  TODO: We dont need this file.
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BACKEND_URL,
  prepareHeaders: (headers) => {
    headers.set('Authorization', `Bearer ${window.localStorage.getItem('token')}`);
    return headers;
  },
});

export const baseQueryWithAuth: BaseQueryFn<FetchArgs | string, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions);
  // Check the unauthorized errors
  if (result.error && result.error.status === 401) {
    // logout API call;
    localStorage.clear();
    store.dispatch(signoutToStore(null));
    window.location.href = '/signin';
  }
  return result;
};
