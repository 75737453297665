/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import ReactEcharts from 'echarts-for-react';
import type { ReportChartDataType } from 'utils/types';

interface ReportSummaryChartProps {
  data: ReportChartDataType;
  verticleLabel: string;
}

export default function ReportSummaryChart(props: ReportSummaryChartProps): JSX.Element {
  const { data, verticleLabel } = props;

  const option = {
    tooltip: {
      trigger: 'axis',
      name: 'Students',
      position(point: any): any {
        return [point[0], '30%'];
      },
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params: any): string => {
        const index = params[0].dataIndex;
        return data.NameArr ? data.NameArr[index] : '';
      },
    },
    xAxis: [{
      type: 'category',
      name: 'Correct Answers',
      nameLocation: 'center',
      nameTextStyle: {
        align: 'center',
        verticalAlign: 'end',
        padding: [20, 0, 0, 0],
      },
      data: data.horizontalAxis,
    }],
    yAxis: [
      {
        type: 'value',
        name: verticleLabel,
        nameLocation: 'end',
        nameTextStyle: {
          align: 'center',
          verticalAlign: 'end',
          padding: [-20, 17, 20, 0],
        },
      },
    ],
    series: [
      {
        data: data.verticleAxis,
        type: 'bar',
        barWidth: '30px',
        showBackground: true,
        backgroundStyle: {
          color: '#F4F8FC',
        },
        itemStyle: {
          color: '#19CB9E',
        },
      },
    ],
    textStyle: {
      fontFamily: 'Inter',
      fontSize: 10,
      fontStyle: 'normal',
      fontWeight: 'normal',
      color: '#595E72',

    },
  };

  return (
    <ReactEcharts option={option} className='pl-25' />
  );
}
