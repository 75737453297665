import React from 'react';
import type { SVGProps } from 'utils/types';

export default function ArrowUpIcon(props: SVGProps): JSX.Element {
  const { className } = props;
  return (
    <svg className={className} width='20' height='22' viewBox='0 0 20 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M1.05737 10.6092C0.807413 10.3592 0.666992 10.0201 0.666992 9.66654C0.666992 9.31299 0.807413 8.97392 1.05737 8.72388L9.05737 0.723878C9.30741 0.473917 9.64649 0.333496 10 0.333496C10.3536 0.333496 10.6927 0.473917 10.9427 0.723878L18.9427 8.72388C19.1856 8.97535 19.32 9.31215 19.3169 9.66175C19.3139 10.0113 19.1737 10.3458 18.9265 10.593C18.6793 10.8402 18.3448 10.9804 17.9952 10.9834C17.6456 10.9865 17.3088 10.8521 17.0574 10.6092L11.3334 4.88521V20.3332C11.3334 20.6868 11.1929 21.026 10.9429 21.276C10.6928 21.5261 10.3537 21.6665 10 21.6665C9.64642 21.6665 9.30728 21.5261 9.05723 21.276C8.80718 21.026 8.66671 20.6868 8.66671 20.3332V4.88521L2.94271 10.6092C2.69267 10.8592 2.35359 10.9996 2.00004 10.9996C1.64649 10.9996 1.30741 10.8592 1.05737 10.6092Z' />
    </svg>
  );
}
