import React from 'react';

import CorrectTickIcon from 'assets/svgs/ts/CorrectTickIcon';
import HintBulb from 'assets/svgs/ts/HintBulb';
import type { StudentReportSummaryQuestionsData } from 'utils/types';
import clsx from 'clsx';
import FalseTickIcon from 'assets/svgs/ts/FalseTickIcon';

interface MCQSummaryQuestionProps {
  data: StudentReportSummaryQuestionsData;
  noBorder: boolean;
}

export default function MCQSummaryQuestion(props: MCQSummaryQuestionProps): JSX.Element {
  const { data, noBorder } = props;
  return (
    <div className={clsx('mcq-summary-question', noBorder && 'no-border')}>
      <div className='left-box'>
        <div className='left-box-row-1 mb-20'>
          <div className='left-box-row-1-order-number'>{data.order_num}</div>
          <div className='left-box-row-1-statement'>{data.statement}</div>
        </div>
        <div className='left-box-row-2'>
          <div className='left-box-row-2-answer-state'>
            <div className='left-box-row-2-icon'>
              {data.is_correct ? <CorrectTickIcon /> : <FalseTickIcon />}
            </div>
          </div>
          <div className='left-box-row-2-answer'>{data.submitted_answer}</div>
          {data.hint_taken && <HintBulb className='ml-24' /> }
        </div>
      </div>
      {data.image_url
      && (
        <div className='right-box'>
          <div
            className='right-box-image'
            style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}${data.image_url})` }}
          />
        </div>
      ) }
    </div>
  );
}
