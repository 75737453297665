import React, { useState } from 'react';
import { addStyles, EditableMathField } from 'react-mathquill';

interface MCQEquationFieldProps {
  initLatex: string;
  onChangeHandler: (value: string) => void;
}

addStyles();

export default function MCQEquationField(props: MCQEquationFieldProps): JSX.Element {
  const {
    onChangeHandler, initLatex,
  } = props;
  const [latex, setLatex] = useState<string>(initLatex);

  return (
    <div className='math-equation-field mcq-equation-field' role='presentation' onClick={(e): void => { e.stopPropagation(); }}>
      <EditableMathField
        onClick={(e): void => { e.stopPropagation(); }}
        latex={latex}
        onChange={(mathField): void => { setLatex(mathField.latex()); onChangeHandler(mathField.latex()); }}
      />
      <div className='label'>Equation</div>
    </div>
  );
}
