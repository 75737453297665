import React from 'react';
import type { Range } from 'react-date-range';
import { DateRangePicker as DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'styles/components/shared/atoms/DateRangePicker/dateRangePicker.scss';

export default function DateRangePicker({ range, setRange }: { range: Range; setRange: (range: Range) => void }): JSX.Element {
  return (
    <DateRange
      ranges={[{ ...range, key: 'selection' }]}
      onChange={(ranges): void => { setRange({ startDate: ranges.selection.startDate, endDate: ranges.selection.endDate }); }}
      months={2}
      showDateDisplay={false}
      moveRangeOnFirstSelection={false}
      direction='horizontal'
      className='range-picker'
    />
  );
}
